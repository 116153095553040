import {Loader} from '@crema';
import {Box, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import * as React from 'react';
import {Link} from 'react-router-dom';
import {StyledTableCell, UseDashBoardStyles} from './UseStyles';
import useDashBoard from './useDashBoard';
import useTaskTable from './Task/useTaskTable';
import ColorStyles from 'shared/constants/AppColor';

export default function BasicTable() {
  const classes = UseDashBoardStyles();
  const {detailsLoading, dashboardDetails, presentTab, routeTopage} =
    useDashBoard();

  const {handleClick} = useTaskTable();

  return (
    <Box className={classes.container}>
      <Box my={3}>
        {detailsLoading ? (
          <Box
            style={{position: 'relative'}}
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight={600}>
            <Loader />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Typography className={classes.rootButton}>Orders</Typography>
            <Table className={classes.minWidth} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.heading}>
                    Shoot Date
                  </StyledTableCell>
                  <StyledTableCell className={classes.heading} align='left'>
                    Title
                  </StyledTableCell>
                  <StyledTableCell className={classes.heading} align='left'>
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dashboardDetails?.orderData?.map(
                  (
                    row: {
                      title: string;
                      status: string;
                      shoot_date: string;
                      reference: string;
                    },
                    index,
                  ) => {
                    if (index < 4) {
                      return (
                        <TableRow
                          key={index}
                          className={classes.minWidth}
                          component={Link}
                          to={`/dashboard/orders/detail/${row.reference}`}>
                          <StyledTableCell component='th' scope='row'>
                            {row?.shoot_date == 'TBC' || row?.shoot_date == null
                              ? 'TBC'
                              :  row?.shoot_date && moment(row?.shoot_date ).tz('UTC').format(
                                  'YYYY-MM-DD HH:mm ',
                                )}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row?.title}
                          </StyledTableCell>
                          <StyledTableCell align='left'>
                            {row?.status?.charAt(0).toUpperCase() +
                              row?.status?.slice(1)}
                          </StyledTableCell>
                        </TableRow>
                      );
                    }
                  },
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Grid container justifyContent='flex-end'>
          <Button
            onClick={() => routeTopage('/dashboard/orders')}
            color='secondary'
            variant='contained'
            style={{textTransform: 'capitalize'}}>
            See More
          </Button>
        </Grid>
      </Box>

      <Box>
        <TableContainer component={Paper}>
          <Typography className={classes.rootButton}>Tickets</Typography>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <StyledTableCell className={classes.heading}>
                  ID
                </StyledTableCell>
                <StyledTableCell className={classes.heading} align='left'>
                  Title
                </StyledTableCell>
                <StyledTableCell className={classes.heading} align='left'>
                  Status
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dashboardDetails?.TicketDetails &&
                dashboardDetails?.TicketDetails.map((row, index) => {
                  if (index < 5) {
                    return (
                      <TableRow
                        key={index}
                        component={Link}
                        to={`/dashboard/tickets/detail/${row.id}`}>
                        <StyledTableCell component='th' scope='row'>
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row?.title}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                          {row?.status?.charAt(0).toUpperCase() +
                            row?.status?.slice(1)}
                        </StyledTableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <Grid container justifyContent='flex-end'>
          <Button
            onClick={() => routeTopage('/dashboard/tickets')}
            color='secondary'
            variant='contained'
            style={{textTransform: 'capitalize'}}>
            See More
          </Button>
        </Grid>
      </Box>

      {presentTab && Object.keys(presentTab).length > 0 && (
        <Box my={3}>
          <TableContainer component={Paper}>
            <Typography className={classes.rootButton}>Tasks</Typography>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.heading}>
                    ID
                  </StyledTableCell>
                  <StyledTableCell className={classes.heading} align='left'>
                    Title
                  </StyledTableCell>
                  <StyledTableCell className={classes.heading} align='left'>
                    Status
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {Array.isArray(dashboardDetails?.TaskList) && (
                <TableBody>
                  {dashboardDetails?.TaskList &&
                    dashboardDetails?.TaskList.map((row, index) => {
                      if (index < 5) {
                        return (
                          <TableRow
                            key={index}
                            onClick={(e) => handleClick(e, row)}
                            style={{cursor: 'pointer'}}>
                            <StyledTableCell component='th' scope='row'>
                              {row?.id}
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              {row?.description}
                            </StyledTableCell>
                            <StyledTableCell align='left'>
                              {row?.status?.charAt(0).toUpperCase() +
                                row?.status?.slice(1)}
                            </StyledTableCell>
                          </TableRow>
                        );
                      }
                    })}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <Grid container justifyContent='flex-end'>
            <Button
              onClick={() => routeTopage('/dashboard/tasks')}
              color='secondary'
              variant='contained'>
              See More
            </Button>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

import {
  FETCH_ADDONS_REQUEST,
  FETCH_ERROR_ADDONS,
  FETCH_ERROR_GET_RECOMMENDED_PRODUCTS,
  FETCH_ERROR_GET_UPGRADED_PRODUCTS,
  FETCH_ERROR_SUB_PRODUCT_TYPES,
  FETCH_REQUEST_ERROR_ADDONS,
  FETCH_REQUEST_SUCCESS_ADDONS,
  FETCH_START_ADDONS,
  FETCH_START_COMPANY_USERS,
  FETCH_START_GET_RECOMMENDED_PRODUCTS,
  FETCH_START_GET_UPGRADED_PRODUCTS,
  FETCH_START_SUB_PRODUCT_TYPES,
  FETCH_SUCCESS_ADDONS,
  FETCH_SUCCESS_COMPANY_USERS,
  FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS,
  FETCH_SUCCESS_GET_UPGRADED_PRODUCTS,
  FETCH_SUCCESS_PRODUCT_CATEGORIES,
  FETCH_SUCCESS_SUB_PRODUCT_TYPES,
  NewOrderActionTypes,
  POST_NEW_ORDER_REQUEST,
  POST_NEW_ORDER_REQUEST_SUCCESS,
  POST_NEW_ORDER_FAIL,
  GET_AVAILABLE_POINTS,
  GET_AVAILABLE_POINTS_SUCCESS,
  GET_AVAILABLE_POINTS_ERROR
} from 'types/actions/NewOrder.action';
import {NewOrder} from 'types/models/NewOrder';

const INIT_STATE: NewOrder = {
  error: '',
  loading: false,
  agents: [],
  companies: [],
  productsCategories: [],
  subProductTypesLoading: false,
  subProductTypes: [],
  addonsLoading: false,
  addons: [],
  recommendedProductsLoading: false,
  recommendedProducts: {
    agreed_packages: null,
    recommended_package: [],
    last_orders: null, 
    
  },
  upgradedProductsLoading: false,
  upgradedProducts: [],
  PlaceOrderLoading: null,
  placeorderData: {},
};

const NewOrderReducer = (
  state = INIT_STATE,
  action: NewOrderActionTypes,
): NewOrder => {
  switch (action.type) {
    case FETCH_START_COMPANY_USERS: {
      return {
        ...state,
        loading: true,
        agents: [],
      };
    }

    case FETCH_SUCCESS_COMPANY_USERS: {
      return {
        ...state,
        loading: false,
        companies: [...state.companies, ...action.payload.companies],
        agents: action.payload.agents,
      };
    }

    case FETCH_SUCCESS_PRODUCT_CATEGORIES: {
      return {
        ...state,
        productsCategories: action.payload,
        subProductTypes: [],
        addons: [],
      };
    }

    case FETCH_START_SUB_PRODUCT_TYPES: {
      return {
        ...state,
        subProductTypesLoading: true,
        addons: [],
      };
    }

    case FETCH_SUCCESS_SUB_PRODUCT_TYPES: {
      return {
        ...state,
        subProductTypesLoading: false,
        subProductTypes: action.payload,
      };
    }

    case FETCH_ERROR_SUB_PRODUCT_TYPES: {
      return {
        ...state,
        subProductTypesLoading: false,
        subProductTypes: [],
      };
    }

    case FETCH_START_ADDONS: {
      return {
        ...state,
        addonsLoading: true,
      };
    }

    case FETCH_SUCCESS_ADDONS: {
      return {
        ...state,
        addonsLoading: false,
        addons: action.payload.sort(
          (a, b) => a.addons_only_group.sequence - b.addons_only_group.sequence,
        ),
      };
    }

    case FETCH_ADDONS_REQUEST: {
      return {
        ...state,
        addonsLoading: true,
      };
    }

    case FETCH_REQUEST_SUCCESS_ADDONS: {
      return {
        ...state,
        addonsLoading: false,
        addons: action.payload,
      };
    }

    case FETCH_REQUEST_ERROR_ADDONS: {
      return {
        ...state,
        addonsLoading: false,
        addons: [],
      };
    }

    case FETCH_ERROR_ADDONS: {
      return {
        ...state,
        addonsLoading: false,
        addons: [],
      };
    }

    case FETCH_START_GET_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProductsLoading: true,
      };
    }

    case FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProductsLoading: false,
        recommendedProducts: action.payload,
      };
    }

    case FETCH_ERROR_GET_RECOMMENDED_PRODUCTS: {
      return {
        ...state,
        recommendedProductsLoading: false,
      };
    }

    case GET_AVAILABLE_POINTS: {
      return {
        ...state,
        availablePoints: true,
      };
    }

    case GET_AVAILABLE_POINTS_SUCCESS: {
      return {
        ...state,
        availablePointsLoading: false,
        availablePoints: action.payload,
      };
    }

    case GET_AVAILABLE_POINTS_ERROR: {
      return {
        ...state,
        availablePointsLoading: false,
      };
    }

    case FETCH_START_GET_UPGRADED_PRODUCTS: {
      return {
        ...state,
        upgradedProductsLoading: true,
      };
    }

    case FETCH_SUCCESS_GET_UPGRADED_PRODUCTS: {
      return {
        ...state,
        upgradedProductsLoading: false,
        upgradedProducts: action.payload,
      };
    }

    case FETCH_ERROR_GET_UPGRADED_PRODUCTS: {
      return {
        ...state,
        upgradedProductsLoading: false,
      };
    }
 
    case POST_NEW_ORDER_REQUEST: {
      return {
        ...state,
        PlaceOrderLoading: true,
      };
    }

    case POST_NEW_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        PlaceOrderLoading: false,
        placeorderData: action.payload,
      };
    }

    case POST_NEW_ORDER_FAIL: {
      return {
        ...state,
        PlaceOrderLoading: false,
      };
    }
    default:
      return state;
  }
};
export default NewOrderReducer;

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'home',
    title: 'Home',
    messageId: 'sidebar.home',
    type: 'item',
    url: '/',
  },
  {
    id: 'about-us',
    title: 'About Us',
    messageId: 'sidebar.aboutUs',
    type: 'item',
    url: '/about-us',
  },
  {
    id: 'blog',
    title: 'Blog',
    messageId: 'sidebar.blog',
    type: 'item',
    url: '/blog',
  },
  {
    id: 'reviews',
    title: 'Reviews',
    messageId: 'sidebar.reviews',
    type: 'item',
    url: '/reviews',
  },
  {
    id: 'jobs',
    title: 'Jobs',
    messageId: 'sidebar.jobs',
    type: 'item',
    url: '/jobs',
  },
  {
    id: 'clients',
    title: 'Clients',
    messageId: 'sidebar.clients',
    type: 'item',
    url: '/clients',
  },
  {
    id: 'contact-us',
    title: 'Contact Us',
    messageId: 'sidebar.contactUs',
    type: 'item',
    url: '/contact-us',
  },
  {
    id: 'sign-in',
    title: 'Sign In',
    messageId: 'sidebar.signIn',
    type: 'item',
    url: '/signin',
  },
];
export default routesConfig;

import { makeStyles } from '@material-ui/core';
import ColorStyles from 'shared/constants/AppColor';
import { CremaTheme } from 'types/AppContextPropsType';

export const UseStyles = makeStyles((theme: CremaTheme) => ({
  container: {
    maxWidth: '1250px',
    width: '100%',
    margin: '2rem auto',
  },
  labelTyposHeading: {
    

    fontWeight: 500,
    fontSize: '15px',
    color: theme.palette.common.black,
  },
  CardAddon: {
    minHeight: 100,
    borderWidth: 2,
    borderColor: theme.palette.gray[200],
    borderStyle: 'solid',
    borderRadius: 16,
    cursor: 'pointer',
    overflow: 'hidden',
    minWidth: 250,
    maxWidth: 300,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 0 5px 5px rgb(0 0 0 / 3%)',
    color: '#6b7280',
    backgroundColor: theme.palette.common.white,
    transition: '300ms ease-in-out',
  },

  rootButton: {
    padding: '0.5em 1rem',
    fontSize: 16,
    fontWeight: 600,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#fff',
    background: ColorStyles.vistaBeeBlue,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  typos: {
    fontSize: '15px',
    color: '#444444',

    fontFamily: 'Poppins',

    '& .makeStyles-darktypos-457': {
      margin: 0,
    }
  },
  bold: {
    fontWeight: 600,
  },
  root: {
    maxWidth: 245,
    margin: '12px',
    border: '1px solid gainsboron',
  },
  card: {
    width: 300,
    border: '1px solid gainsboro',
  },
  padding: {
    padding: '0px',
  },
  timeInput: {
    width: '50px',
    height: '42px',
    border: '1px solid gainsboro',
    borderRadius: 4,
    padding: '12px',
  },

  heading: {
    textTransform: 'capitalize',
    borderRadius: '15px',
    fontSize: '15px',
    margin: '5px 0',
    fontWeight: 600,
  },
  icons: {
    fontSize: '30px',
    margin: '0 12px ',
  },
  marginEqual: {
    margin: '12px 0',
  },
  cardHeading: {
    padding: '8px',
    textAlign: 'center',
  },
  inputFields: {
    height: ' 57px',
    backgroundColor: '#ffffff',
    paddingLeft: '12px',

    textAlign: 'left',
    border: '2px solid #dddddd',
    '&:before ': {
      borderBottomStyle: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
  }
  ,
  HeadingTypos: {
    fontSize: '13px',
    color: '#444444',
    textTransform: 'capitalize',
    fontWeight: 700,
    fontFamily: "Poppins",
  }
}));
import React from 'react';
import {Redirect} from 'react-router-dom';
import {createRoutes} from '../@crema/utility/Utils';
import {initialUrl} from '../shared/constants/AppConst';
import {PaymentRoutes} from './Payment';
import {termsPrivacyRouteConfig} from './TermsAndPrivacyPolicy';
import {authRouteConfig} from './auth';
import {BookOrderRoutes} from './bookorder';
import {commonPagesConfig} from './common';
import {dashboardPagesConfig} from './dashboard';
import {errorPagesConfigs} from './errorPages';
import {MyAccountRoutes} from './myaccount/index';
import {orderPagesConfig} from './order';
import {EnRoutes} from './dashboard/EnRoutes';
import {WeblinkRoutes} from './weblink/index';

const routeConfigs = [
  ...commonPagesConfig,
  ...BookOrderRoutes,
  ...WeblinkRoutes,
  ...orderPagesConfig,
  ...dashboardPagesConfig,
  ...authRouteConfig,
  ...errorPagesConfigs,
  ...MyAccountRoutes,
  ...termsPrivacyRouteConfig,
  ...PaymentRoutes,
  ...EnRoutes,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStart, fetchSuccess, setJWTToken } from '../../redux/actions';

import { onJWTAuthSignout } from '../../redux/actions';
import { AppState } from '../../redux/store';
import { AuthUser } from '../../types/models/AuthUser';
import jwtAxios from '../services/auth/jwt-auth/jwt-api';
export const useAuthToken = (): [boolean, AuthUser | null] => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { user, token } = useSelector<AppState, AppState['auth']>(
    ({ auth }) => auth,
  );

  const timer = useRef<any>(null);

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());

      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      try {
        const res = await jwtAxios.post('accounts/token/refresh/', {
          refresh: token.refresh,
        });
        dispatch(fetchSuccess());
        dispatch(
          setJWTToken({
            ...token,
            access: res.data.access,
          }),
        );

        return;
      } catch (err: any) {
        dispatch(onJWTAuthSignout());


        return;
      }
    };

    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };

    checkAuth();
    timer.current = setInterval(() => {
      checkAuth();
    }, 600000);

    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = (): AuthUser | null => {
  const { user } = useSelector<AppState, AppState['auth']>(({ auth }) => auth);
  if (user) {
    return user;
  }
  return null;
};

const convertTitleToURLKey = (title: string): string =>
  title.replaceAll(' ', '-').replace('---', '-').toLowerCase();

const convertDynamicMenuStructure = (
  category: {
    id: string;
    title: string;
    data: any;
    url: string;
  },
  isMobile: boolean,
) => {
  const initial = {
    id: category.id,
    title: category.title,
    messageId: `sidebar.${category.title}`,
    type: isMobile ? 'collapse' : 'group',
    children: [],
  };

  initial.children = category.data.map((item: any, index: number) => {
    const children = item.childs.map((child: any, index: number) => ({
      id: child.id,
      title: child.name,
      messageId: `sidebar.${child.name}`,
      type: 'item',
      url: `/${category.url}/${convertTitleToURLKey(
        item.name,
      )}/${convertTitleToURLKey(child.name)}?id=${child.id}`,
    }));
    return {
      id: item.id,
      title: item.name,
      messageId: `sidebar.${item.name}`,
      type: children.length ? 'collapse' : 'item',
      url: `/${category.url}/${convertTitleToURLKey(item.name)}?id=${item.id}`,
      children: children,
    };
  });

  return initial;
};

export const mergeProductsAndCitiesRoutes = (
  menus: any,
  routesConfig: any,
  isMobile = false,
) => {
  if (menus) {
    const newRoutesConfig = [...routesConfig];
    const {products, cities} = menus;

    newRoutesConfig.splice(
      1,
      0,
      convertDynamicMenuStructure(
        {
          id: 'products',
          title: 'Products',
          data: products,
          url: 'products',
        },
        isMobile,
      ),
      convertDynamicMenuStructure(
        {
          id: 'cities',
          title: 'Cities',
          data: cities,
          url: 'cities',
        },
        isMobile,
      ),
    );

    return newRoutesConfig;
  }
  return [...routesConfig];
};

import React from 'react';

export const PaymentRoutes = [
    {
         
        routes: [
            {
                path: '/pay/:token',
                component: React.lazy(() => import('./components/index')),
            },
        ],
    },
];

import { Button as MuiButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ADMIN_USER_TYPES } from 'modules/order/Pages/NewOrder/staticData';
import useNewOrder from 'modules/order/Pages/NewOrder/useNewOrder';
import React from 'react';
import ColorStyles from 'shared/constants/AppColor';
import { CremaTheme } from 'types/AppContextPropsType';
const useStyles = makeStyles((theme: CremaTheme) => ({
  rootButton: {
    padding: '0.5em 2em',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: 'Poppins',

    width: ({ width }: any) => width || '185px',
    height: ({ height }: any) => height || '40px',
    letterSpacing: '1px',
    margin: '0  ',

    '&.primary': {
      backgroundColor: ColorStyles.vistaBeeBlue,
      color: theme.palette.text.white,

      '&:hover': {
        backgroundColor: theme.palette.background.primary.dark,
      },
    },
    '&.light': {
      backgroundColor: '#fafafafa',
      color: theme.palette.common.black,

      '&:hover': {
        backgroundColor: theme.palette.background.primary.dark,
      },
    },

    '&.secondary': {
      backgroundColor: ColorStyles.vistaBeePink,
      color: theme.palette.text.white,

      '&:hover': {
        backgroundColor: ColorStyles.vistabeeSageGreen,
      },
    },

    '&.gray': {
      backgroundColor: theme.palette.gray[500],
      color: theme.palette.text.white,

      '&:hover': {
        backgroundColor: theme.palette.gray[600],
      },
    },

    '&.dark': {
      backgroundColor: theme.palette.gray[900],
      color: theme.palette.text.white,

      '&:hover': {
        backgroundColor: theme.palette.gray[800],
      },
    },

    '&[disabled]': {
      opacity: '0.8',
    },
  },
  positionAbosolute: {
    position: 'absolute',
    top: '3rem',
  },
}));

interface IButton extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'gray' | 'dark' | 'light';
  lowercase?: boolean
}

const Button = ({
  children,
  width,
  height,
  className,
  lowercase,
  variant = 'primary',
  ...buttonProps
}: IButton) => {
  const classes = useStyles({
    width: width,
    height: height,
    lowercase
  });

  const {
    user,
  } = useNewOrder();

  return (
    <MuiButton
      className={clsx(
        classes.rootButton,
        className,
        variant,
        children === 'Skip Recommended' && ADMIN_USER_TYPES.includes(user?.user_type || '') && classes.positionAbosolute,
      )}
      variant={variant}
      {...buttonProps}>
      {children}
    </MuiButton>
  );
};

export default Button;

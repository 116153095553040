import React from 'react';

export const termsPrivacyRouteConfig = [
  {
    routes: [
      {
        path: '/pages/terms/',
        component: React.lazy(() => import('./Terms of use')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/pages/privacy/',
        component: React.lazy(() => import('./Privacy Policy')),
      },
    ],
  },
];

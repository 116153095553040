import {AuthType} from './AppEnums';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

interface IUserType {
  uid: string;
  displayName: string;
  email: string;
  token: string;
  role: any;
  authType: any;
  photoURL: string;
}
export const defaultUser: IUserType = {
  uid: 'RFedvhji876rfhjuecvh7',
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: authRole.user,
  authType: AuthType.AUTH0,
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const initialUrl = '/'; 
export const S3_BUCKET_URL = 'https://vistabee.s3.eu-west-1.amazonaws.com/';

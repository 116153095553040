import {makeStyles} from '@material-ui/core';
import {orange} from '@material-ui/core/colors';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
export const useStyles = makeStyles((theme) => {
    return {
      crUserInfo: {
        backgroundColor: 'rgba(0,0,0,.08)',
        paddingTop: 9,
        paddingBottom: 9,
        minHeight: 56,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
          paddingTop: 10,
          paddingBottom: 10,
          minHeight: 70,
        },
      },
      profilePic: {
        fontSize: 24,
        backgroundColor: orange[500],
      },
      userInfo: {
        width: 'calc(100% - 75px)',
      },
      userName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontSize: 16,
        fontWeight: Fonts.MEDIUM,
        color: (props: {themeMode: ThemeMode}) =>
          props.themeMode === ThemeMode.LIGHT ? '#313541' : 'white',
      },
      designation: {
        marginTop: -2,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: theme.palette.text.secondary,
      },
      pointer: {
        cursor: 'pointer',
      },
    };
  });
  
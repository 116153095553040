import Box from '@material-ui/core/Box';

import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import AppContext from '../../../@crema/utility/AppContext';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import {onJWTAuthSignout} from '../../../redux/actions';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
import useHeader from './useHeader';
import {useStyles} from './UseStyles';

interface HeaderUserProps {
  header?: boolean;
}

const HeaderUser: React.FC<HeaderUserProps> = ({header = true}) => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);

  const dispatch = useDispatch();
  const user: AuthUser | null = useAuthUser();
  const {
    anchorEl,
    handleClick,
    handleClose,
    getUserAvatar,
    history,
    getUserAvatarView,
  } = useHeader();

  const classes = useStyles({themeMode, header});

  return (
    <Box
      px={{xs: 2, xl: 6}}
      className={clsx(classes.crHeaderUser, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        <Hidden mdDown> {getUserAvatarView()}</Hidden>
        <Box className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Hidden mdDown>
              <Box ml={4} mb={1.5} className={classes.userName}>
                {user &&
                  user.user &&
                  user.user.first_name &&
                  `${user.user.first_name} ${user.user.last_name}`}
                <Box
                  fontSize={13}
                  fontWeight={Fonts.LIGHT}
                  color='text.secondary'>
                  {user && user.user.company}
                </Box>
              </Box>
            </Hidden>
            <Box
              ml={{md: 3}}
              className={classes.pointer}
              color={
                themeMode === ThemeMode.DARK || !header ? 'white' : '#313541'
              }>
              <Hidden mdDown>
                <ExpandMoreIcon onClick={handleClick} />
              </Hidden>
              <Hidden lgUp>
                <Box component='span' onClick={handleClick}>
                  {getUserAvatarView()}
                </Box>
              </Hidden>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={() => history.push('/myaccount/details')}>
                 {user.is_shooter || user.is_editor ? "Profile" : "My account"}  
                </MenuItem>
                <MenuItem onClick={() => history.push('/dashboard/dashboard')}>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={() => dispatch(onJWTAuthSignout())}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HeaderUser;
HeaderUser.defaultProps = {
  header: true,
};

import * as yup from 'yup';
import {INewOrderPayload} from '../INewOrderPayload';

interface InvalidationProps {
  payload?: INewOrderPayload;
  body: any;
}
 
const TelePhoneRegExp = /^(1-?)?(\d{3}|\(\d{3}\))[- .]?\d{3}[- .]?\d{4}$/;
const useValidation = ({payload, body}: InvalidationProps) => {
  const ContactDetailsValidationSchema = yup.object({
    firstName: yup.string().required('First name required'),
    lastName: yup.string().required('Last name required'),

    telephone: yup.string().min(10).max(12).matches(/^(?!\s).*$/, 'This field should not start with white space').required('Telephone required'),
    mobile: yup.string().min(10).max(12).matches(/^(?!\s).*$/, 'This field should not start with white space').required('Mobile required'),
     
     
    email: yup.string().email().required('Email required'),
  });

  const ReferenceValidationSchema = yup.object({
    isNoSpecialRequirement: yup.boolean(),
    // label: yup.string().required('Reference  is required.')
  });

  const locationAndTimeDetailsValidationSchema = yup.object({
    country: yup.string().required('Country selection required'),
    city: yup.string().min(2).required('City is required'),
    addressLine1: yup
      .string()
      .min(2, 'This field is required')
      .required('Address Line 1 Required'),
    addressLine2: yup.string(),
    postcode: yup
      .string()
      .required(
        `${
          payload?.locationAndTimeDetails?.country?.toString() === '1'
            ? 'Postcode is required'
            : 'Zipcode is required'
        }`,
      )
      .min(
        5,
        `${
          payload?.locationAndTimeDetails?.country?.toString() === '1'
            ? 'Invalid postcode'
            : 'Invalid zipcode'
        }`,
      ),

    contactMe: yup.boolean(),
    contactMeDate: yup.string().when('contactMe', {
      is: true,
      then: yup.string().required('Date is required '),
    }),
    contactMeTime: yup.string().when('contactMe', {
      is: true,
      then: yup.string().required('Select Time is required '),
    }),
  });

  const KeyPickupValidationSchema = yup.object({
    key_address: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .required(' This field is requirred')
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),

    key_address2: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),

    key_address3: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),

    key_city: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .required('This field is requirred')
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),

    shoot_state: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),

    key_post_code: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .required(
          `${
            payload?.key_pick_up_locations?.shoot_country === 'United Kingdom'
              ? 'Postcode is required'
              : 'Zipcode is required'
          }`,
        )
        .min(
          5,
          `${
            payload?.key_pick_up_locations?.shoot_country ===
            'United States of America'
              ? 'Invalid zipcode '
              : 'Invalid postcode'
          }`,
        ),
    }),
    shoot_country: yup.string().when('type', {
      is: 'keypickuplocation',
      then: yup
        .string()
        .required('This field is required')
        .matches(/^(?!\s).*$/, 'This field should not start with white space'),
    }),
    ///////// lock_box_code  , lock_box_note
    keyLockBox: yup.boolean(),
    lock_box_code: yup.string().when('type', {
      is: 'lockboxcodelocation',
      then: yup.string().required('This field is required'),
    }),
    lock_box_note: yup.string().when('type', {
      is: 'lockboxcodelocation',
      then: yup.string().required('This field  is required'),
    }),

    alarm: yup.boolean(),
    alarmDisarmCode: yup.string().when('alarm', {
      is: true,
      then: yup.string().required('Alarm disarm code is required'),
    }),
    alarmRearmCode: yup.string().when('alarm', {
      is: true,
      then: yup.string().required('Alarm  rearm   code is required'),
    }),
    details: yup.string().when('alarm', {
      is: true,
      then: yup.string().required('Alarm details is required'),
    }),
  });

  const uerProfileValidationSchema = yup.object({
    postcode: yup
      .string()
      .required(
        `${
          payload?.address?.country?.toString() === 'United Kingdom'
            ? 'Postcode is required'
            : 'Zipcode is required'
        }`,
      )
      .min(
        5,
        `${
          payload?.address?.country?.toString() === 'United Kingdom'
            ? 'Invalid postcode'
            : 'Invalid zipcode'
        }`,
      ),
    

    mobile: yup.string().matches(/^(?!\s).*$/, 'This field should not start with white space').required(),
    phone: yup.string().matches(/^(?!\s).*$/, 'This field should not start with white space').required(),
    hide_related_movies: yup.boolean(),
    preferences: yup.array().of(yup.number()),
    music: yup.number().integer(),
    user_type: yup.string(),
  });

  const ProfileValidationSchema = yup.object({
    mobile: yup
      .string()
      .required('This field is required')
      .matches(/^\d{10,11}$/, 'Invalid mobile number'),

    phone: yup
      .string()
      .required('This field is required')
      .matches(/^\d{10,11}$/, 'Invalid phone number'),
  });

  const getValidationData = (
    schema: yup.ObjectSchema,
    data: any,
    activeStep?: number,
    key: any,
  ) => {
    try {
      if (activeStep === 3) {
        if (data?.errors) {
          return {
            message: data?.errors.error,
            path: key,
          };
        } else {
          return schema.validateSync(data);
        }
      } else {
        return schema.validateSync(data);
      }
    } catch (err) {
      return err;
    }
  };
  return {
    ContactDetailsValidationSchema,
    ReferenceValidationSchema,

    KeyPickupValidationSchema,
    locationAndTimeDetailsValidationSchema,
    getValidationData,
    uerProfileValidationSchema,
    ProfileValidationSchema,
  };
};

export default useValidation;

import VideoOverlay from '@components/VideoOverlay';
import Box from '@material-ui/core/Box';
import React, { useContext } from 'react';
import { renderRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import routes from '../../../modules';
import { RouteTransition } from '../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { AppSuspense } from '../../index';
import AppContext from '../../utility/AppContext';
import AppErrorBoundary from '../AppErrorBoundary';
import AppFooter from '../AppLayout/AppFooter';
import Scrollbar from '../Scrollbar';

interface TransitionWrapperProps {
  children: any;
}

const TransitionWrapper: React.FC<TransitionWrapperProps> = ({ children }) => {
  const { rtAnim } = useContext<AppContextPropsType>(AppContext);
  const location = useLocation();
  if (rtAnim === RouteTransition.NONE) {
    return <>{children}</>;
  }
  return (
    <TransitionGroup appear enter exit>
      <CSSTransition
        key={location.key}
        timeout={{ enter: 300, exit: 300 }}
        classNames={rtAnim}>
        {children}
      </CSSTransition>
    </TransitionGroup>
  );
};


const ContentView = () => {
  return (
    <Scrollbar>
      <Box
        display='flex'
        flex={1}
        flexDirection='column'
        className='main-content-view'>
        <AppSuspense>
          <AppErrorBoundary>
            <>
              <VideoOverlay />
              <TransitionWrapper>{renderRoutes(routes)}</TransitionWrapper>
            </>
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </Scrollbar>
  );
};

export default ContentView;

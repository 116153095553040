import { makeStyles } from '@material-ui/core';
import ColorStyles from 'shared/constants/AppColor';
import { CremaTheme } from 'types/AppContextPropsType';
export const useStyles = makeStyles((theme: CremaTheme) => ({
  header: {
    paddingBottom: '8px',
    borderBottom: '1px solid #656565',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center"
  },

  inputHeight: { height: '100px' },
  HeaderTitle: {
    fontSize: '24px',
    color: ColorStyles.vistabeeBW80Gray,
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  selectorsTypos: {
    fontSize: '13px',
    color: '#444444',
    fontWeight: 700,
    fontFamily: 'Poppins',
    marginBottom: 5,
  },
  editBtn: {
    fontSize: '14px',
    color: ColorStyles.vistaBeeBlue,
    fontWeight: 700,
    fontFamily: 'Raleway',
  },
  selectTag: {
    height: ' 37px',
    backgroundColor: '#ffffff',
    paddingLeft: '5px',
    paddingTop: '0px',
    textAlign: 'left',
    border: '2px solid #dddddd',
    '&:before ': {
      borderBottomStyle: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
  },
  inputFields: {
    height: ' 37px',
    backgroundColor: '#ffffff',
    paddingLeft: '12px',

    textAlign: 'left',
    border: '2px solid #dddddd',
    '&:before ': {
      borderBottomStyle: 'none !important',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '0px !important',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '0px !important',
    },
  },
  boldWeight: {
    '& .MuiTypography-body1': {
      fontWeight: 600
    }

  }
}));

import React from 'react';

export const EnRoutes = [
  {
    routes: [
      {
        path: '/en/products/hosting-action/:ref/:id/:related_object_id/',
        component: React.lazy(
          () => import('./components/Task/TaskPages/HostingDetail'),
        ),
      },

      {
        path: '/en/products/:ref/:id',
        component: React.lazy(
          () => import('./components/Task/TaskPages/RenewHosting'),
        ),
      },
    ],
  },
];

import {IMenuItems} from '../models/Dashboard';

export const FETCH_MENU_LIST = 'FETCH_MENU_LIST';
export const FETCH_MENULIST_SUCCESSFULL = 'FETCH_MENULIST_SUCCESSFULL';
export const FETCH_MENULIST_FAILED = 'FETCH_MENULIST_FAILED';

export const FETCH_ORDER_TABLE_DETAILS = 'FETCH_ORDER_TABLE_DETAILS';

export const GET_INFORMATION_PAGE = 'GET_INFORMATION_PAGE';
export const GET_INFORMATION_PAGE_SUCCESS = 'GET_INFORMATION_PAGE_SUCCESS';
export const GET_INFORMATION_PAGE_ERROR = 'GET_INFORMATION_PAGE_ERROR';

export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const GET_CLIENT_LIST_SUCCESS = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_ERROR = 'GET_CLIENT_LIST_ERROR';

export const GET_BEES_LIST = 'GET_BEES_LIST';
export const GET_BEES_LIST_SUCCESS = 'GET_BEES_LIST_SUCCESS';
export const GET_BEES_LIST_ERROR = 'GET_BEES_LIST_ERROR';

export const GET_COMPANY_LIST_NEW = 'GET_COMPANY_LIST_NEW';
export const GET_COMPANY_LIST_NEW_SUCCESS = 'GET_COMPANY_LIST_NEW_SUCCESS';
export const GET_COMPANY_LIST_NEW_ERROR = 'GET_COMPANY_LIST_NEW_ERROR';

export const GET_MOVIES_LIST = 'GET_MOVIES_LIST';
export const GET_MOVIES_LIST_SUCCESS = 'GET_MOVIES_LIST_SUCCESS';
export const GET_MOVIES_LIST_ERROR = 'GET_MOVIES_LIST_ERROR';

export const GET_COMPANY_DETAIL = 'GET_COMPANY_DETAIL';
export const GET_COMPANY_DETAIL_SUCCESS = 'GET_COMPANY_DETAIL_SUCCESS';
export const GET_COMPANY_DETAIL_ERROR = 'GET_COMPANY_DETAIL_ERROR';

export const GET_BEES_LIST_NEW = 'GET_BEES_LIST_NEW';
export const GET_BEES_LIST_NEW_SUCCESS = 'GET_BEES_LIST_NEW_SUCCESS';
export const GET_BEES_LIST_NEW_ERROR = 'GET_BEES_LIST_NEW_ERROR';

export const POST_COMPANY_LIST = 'POST_COMPANY_LIST';
export const POST_COMPANY_LIST_SUCCESS = 'POST_COMPANY_LIST_SUCCESS';
export const POST_COMPANY_LIST_ERROR = 'POST_COMPANY_LIST_ERROR';

export const POSTPOND_CALL = 'POSTPOND_CALL';
export const POSTPOND_CALL_SUCCESS = 'POSTPOND_CALL_SUCCESS';
export const POSTPOND_CALL_ERROR = 'POSTPOND_CALL_ERROR';

export const BEE_NOTES = 'BEE_NOTES';
export const BEE_NOTES_SUCCESS = 'BEE_NOTES_SUCCESS';
export const BEE_NOTES_ERROR = 'BEE_NOTES_ERROR';

export const GET_COMPANY_LIST_ALL = 'GET_COMPANY_LIST_ALL';
export const GET_COMPANY_LIST_ALL_SUCCESS = 'GET_COMPANY_LIST_ALL_SUCCESS';
export const GET_COMPANY_LIST_ALL_ERROR = 'GET_COMPANY_LIST_ALL_ERROR';
export const FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL =
  'FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL';
export const FETCH_ORDER_TABLE_DETAILS_FAILED =
  'FETCH_ORDER_TABLE_DETAILS_FAILED';

  export const FETCH_ORDER_DETAILS_REQUEST = 'FETCH_ORDER_DETAILS_REQUEST';
export const FETCH_ORDER_DETAILS_REQUEST_SUCCESS =
  'FETCH_ORDER_DETAILS_REQUEST_SUCCESS';
export const FETCH_ORDER_DETAILS_REQUEST_ERROR =
  'FETCH_ORDER_DETAILS_REQUEST_ERROR';
export const FETCH_MANAGEMENT_DETAILS = 'FETCH_MANAGEMENT_DETAILS';
export const FETCH_MANAGEMENT_DETAILS_SUCCESS =
  'FETCH_MANAGEMENT_DETAILS_SUCCESS';
export const FETCH_MANAGEMENT_DETAILS_ERROR = 'FETCH_MANAGEMENT_DETAILS_ERROR';
export const FETCH_MANAGEMENT_TABLE_DETAILS = 'FETCH_MANAGEMENT_TABLE_DETAILS';
export const FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS =
  'FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS';
export const FETCH_MANAGEMENT_TABLE_DETAILS_ERROR =
  'FETCH_MANAGEMENT_TABLE_DETAILS_ERROR';
export const FETCH_CLIENT_DROPDOWN_INFORMATION =
  'FETCH_CLIENT_DROPDOWN_INFORMATION';
export const FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS =
  'FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS';
export const FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR =
  'FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR';
export const FETCH_PACKAGE_DROPDOWN = 'FETCH_PACKAGE_DROPDOWN';
export const FETCH_PACKAGE_DROPDOWN_SUCCESS = 'FETCH_PACKAGE_DROPDOWN_SUCCESS';
export const FETCH_PACKAGE_DROPDOWN_ERROR = 'FETCH_PACKAGE_DROPDOWN_ERROR';
export const FETCH_PRODUCT_DROPDOWN = 'FETCH_PRODUCT_DROPDOWN';
export const FETCH_PRODUCT_DROPDOWN_SUCCESS = 'FETCH_PRODUCT_DROPDOWN_SUCCESS';
export const FETCH_PRODUCT_DROPDOWN_ERROR = 'FETCH_PRODUCT_DROPDOWN_ERROR';

export const FETCH_PRODUCT_ADDON_DROPDOWN = 'FETCH_PRODUCT_ADDON_DROPDOWN';
export const FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS =
  'FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS';
export const FETCH_PRODUCT_ADDON_DROPDOWN_ERROR =
  'FETCH_PRODUCT_ADDON_DROPDOWN_ERROR';

export const FETCH_PROPERTY_REQUEST = 'FETCH_PROPERTY_REQUEST';
export const FETCH_PROPERTY_REQUEST_SUCCESS = 'FETCH_PROPERTY_REQUEST_SUCCESS';
export const FETCH_PROPERTY_REQUEST_ERROR = 'FETCH_PROPERTY_REQUEST_ERROR';

export const FETCH_PROPERTY_PRICE_MODIFIERS = 'FETCH_PROPERTY_PRICE_MODIFIERS';
export const FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS =
  'FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS';
export const FETCH_PROPERTY_PRICE_MODIFIERS_ERROR =
  'FETCH_PROPERTY_PRICE_MODIFIERS_ERROR';

export const PUT_CHECKLIST_REQUEST = 'PUT_CHECKLIST_REQUEST';
export const PUT_CHECKLIST_REQUEST_SUCCESS = 'PUT_CHECKLIST_REQUEST_SUCCESS';
export const PUT_CHECKLIST_REQUEST_ERROR = 'PUT_CHECKLIST_REQUEST_ERROR';

export const PUT_MANAGEMENT_ORDER_REQUEST = 'PUT_MANAGEMENT_ORDER_REQUEST';
export const PUT_MANAGEMENT_ORDER_REQUEST_SUCCESS =
  'PUT_MANAGEMENT_ORDER_REQUEST_SUCCESS';
export const PUT_MANAGEMENT_ORDER_REQUEST_ERROR =
  'PUT_MANAGEMENT_ORDER_REQUEST_ERROR';

export const PUT_PROPERTY_REQUEST = 'PUT_PROPERTY_REQUEST';
export const PUT_PROPERTY_REQUEST_SUCCESS = 'PUT_PROPERTY_REQUEST_SUCCESS';
export const PUT_PROPERTY_REQUEST_ERROR = 'PUT_PROPERTY_REQUEST_ERROR';

export const SUPPORT_NOTES = 'SUPPORT_NOTES';
export const SUPPORT_NOTES_SUCCESS = 'SUPPORT_NOTES_SUCCESS';
export const SUPPORT_NOTES_ERROR = 'SUPPORT_NOTES_ERROR';

export const POST_AMEND_INVOICE = 'POST_AMEND_INVOICE';
export const POST_AMEND_INVOICE_SUCCESS = 'POST_AMEND_INVOICE_SUCCESS';
export const POST_AMEND_INVOICE_ERROR = 'POST_AMEND_INVOICE_ERROR';


export const GET_REQUEST_DROPDOWN_LIST = 'GET_REQUEST_DROPDOWN_LIST';
export const GET_REQUEST_DROPDOWN_LIST_SUCCESS =
  'GET_REQUEST_DROPDOWN_LIST_SUCCESS';
export const GET_REQUEST_DROPDOWN_LIST_ERROR =
  'GET_REQUEST_DROPDOWN_LIST_ERROR';

export const GET_REQUEST_DROPDOWN = 'GET_REQUEST_DROPDOWN';
export const GET_REQUEST_DROPDOWN_SUCCESS = 'GET_REQUEST_DROPDOWN_SUCCESS';
export const GET_REQUEST_DROPDOWN_ERROR = 'GET_REQUEST_DROPDOWN_ERROR';

export const GET_ORDER_BUTTONS = 'GET_ORDER_BUTTONS_ERROR';
export const GET_ORDER_BUTTONS_ERROR = 'GET_ORDER_BUTTONS';
export const GET_ORDER_BUTTONS_SUCCESS = 'GET_ORDER_BUTTONS_SUCCESS';

export const GET_SAGE_CSV_DOWNLOAD = 'GET_SAGE_CSV_DOWNLOAD';
export const GET_SAGE_CSV_DOWNLOAD_SUCCESS = 'GET_SAGE_CSV_DOWNLOAD_SUCCESS';
export const GET_SAGE_CSV_DOWNLOAD_ERROR = 'GET_SAGE_CSV_DOWNLOAD_ERROR';

export const POST_PROPERTY_REQUEST = 'POST_PROPERTY_REQUEST';
export const POST_PROPERTY_REQUEST_SUCCESS = 'POST_PROPERTY_REQUEST_SUCCESS';
export const POST_PROPERTY_REQUEST_ERROR = 'POST_PROPERTY_REQUEST_ERROR';

export const PUT_ORDER_REQUEST = 'PUT_ORDER_REQUEST';
export const PUT_ORDER_REQUEST_SUCCESS = 'PUT_ORDER_REQUEST_SUCCESS';
export const PUT_ORDER_REQUEST_ERROR = 'PUT_ORDER_REQUEST_ERROR';

export const PUT_KEYPICKUP_REQUEST = 'PUT_KEYPICKUP_REQUEST';
export const PUT_KEYPICKUP_REQUEST_SUCCESS = 'PUT_KEYPICKUP_REQUEST_SUCCESS';
export const PUT_KEYPICKUP_REQUEST_ERROR = 'PUT_KEYPICKUP_REQUEST_ERROR';

export const PUT_INVOICE_BEE_RATE_REQUEST = 'PUT_INVOICE_BEE_RATE_REQUEST';
export const PUT_INVOICE_BEE_RATE_REQUEST_SUCCESS =
  'PUT_INVOICE_BEE_RATE_REQUEST_SUCCESS';
export const PUT_INVOICE_BEE_RATE_REQUEST_ERROR =
  'PUT_INVOICE_BEE_RATE_REQUEST_ERROR';

export const HOLD_ORDER_REQUEST = 'HOLD_ORDER_REQUEST';
export const HOLD_ORDER_REQUEST_SUCCESS = 'HOLD_ORDER_REQUEST_SUCCESS';
export const HOLD_ORDER_REQUEST_ERROR = 'HOLD_ORDER_REQUEST_ERROR';

export const RESUME_ORDER_REQUEST = 'RESUME_ORDER_REQUEST';
export const RESUME_ORDER_REQUEST_ERROR = 'RESUME_ORDER_REQUEST_ERROR';
export const RESUME_ORDER_REQUEST_SUCCESS = 'RESUME_ORDER_REQUEST_SUCCESS';

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST';
export const CANCEL_ORDER_REQUEST_ERROR = 'CANCEL_ORDER_REQUEST_ERROR';
export const CANCEL_ORDER_REQUEST_SUCCESS = 'CANCEL_ORDER_REQUEST_SUCCESS';

export const POST_INVOICE_AMMEND = 'POST_INVOICE_AMMEND';
export const POST_INVOICE_AMMEND_SUCCESS = 'POST_INVOICE_AMMEND_SUCCESS';
export const POST_INVOICE_AMMEND_ERROR = 'POST_INVOICE_AMMEND_ERROR';

export const POST_UNAVAILABLE_SLOT = 'POST_UNAVAILABLE_SLOT';
export const POST_UNAVAILABLE_SLOT_SUCCESS = 'POST_UNAVAILABLE_SLOT_SUCCESS';
export const POST_UNAVAILABLE_SLOT_ERROR = 'POST_UNAVAILABLE_SLOT_ERROR';

export const PUT_ORDER_RENEW_REQUEST = 'PUT_ORDER_RENEW_REQUEST';
export const PUT_ORDER_RENEW_REQUEST_ERROR = 'PUT_ORDER_RENEW_REQUEST_ERROR';
export const PUT_ORDER_RENEW_REQUEST_SUCCESS =
  'PUT_ORDER_RENEW_REQUEST_SUCCESS';

export const PUT_CANCEL_HOISTING = 'PUT_CANCEL_HOISTING';
export const PUT_CANCEL_HOISTING_SUCCESS = 'PUT_CANCEL_HOISTING_SUCCESS';
export const PUT_CANCEL_HOISTING_ERROR = 'PUT_CANCEL_HOISTING_ERROR';

export const UPDATE_PACKAGE_ADDON = 'UPDATE_PACKAGE_ADDON';
export const UPDATE_PACKAGE_ADDON_SUCCESS = 'UPDATE_PACKAGE_ADDON_SUCCESS';
export const UPDATE_PACKAGE_ADDON_ERROR = 'UPDATE_PACKAGE_ADDON_ERROR';

export const PUT_REGENERATE_ORDER = 'PUT_REGENERATE_ORDER';
export const PUT_REGENERATE_ORDER_SUCCESS = 'PUT_REGENERATE_ORDER_SUCCESS';
export const PUT_REGENERATE_ORDER_ERROR = 'PUT_REGENERATE_ORDER_ERROR';

export const PUT_YOUTUBE_UPLOAD = 'PUT_YOUTUBE_UPLOAD';
export const PUT_YOUTUBE_UPLOAD_SUCCESS = 'PUT_YOUTUBE_UPLOAD_SUCCESS';
export const PUT_YOUTUBE_UPLOAD_ERROR = 'PUT_YOUTUBE_UPLOAD_ERROR';

export const POST_SHOOTER_ALLOCATION = 'POST_SHOOTER_ALLOCATION';
export const POST_SHOOTER_ALLOCATION_SUCCESS =
  'POST_SHOOTER_ALLOCATION_SUCCESS';
export const POST_SHOOTER_ALLOCATION_ERROR = ',POST_SHOOTER_ALLOCATION_ERROR';
export const POST_BEST_BEES = 'POST_BEST_BEES';
export const POST_BEST_BEES_SUCCESS = 'POST_BEST_BEES_SUCCESS';
export const POST_BEST_BEES_ERROR = 'POST_BEST_BEES_ERROR';

export const GET_MANUAL_BEES = 'GET_MANUAL_BEES';
export const GET_MANUAL_BEES_SUCCESS = 'GET_MANUAL_BEES_SUCCESS';
export const GET_MANUAL_BEES_ERROR = 'GET_MANUAL_BEES_ERROR';

export const GET_TICKET_LIST = 'GET_TICKET_LIST';
export const GET_TICKET_LIST_SUCCESS = 'GET_TICKET_LIST_SUCCESS';
export const GET_TICKET_LIST_ERROR = 'GET_TICKET_LIST_ERROR';

export const GET_TICKET_DETAIL = 'GET_TICKET_DETAIL';
export const GET_TICKET_DETAIL_SUCCESS = 'GET_TICKET_DETAIL_SUCCESS';
export const GET_TICKET_DETAIL_ERROR = 'GET_TICKET_DETAIL_ERROR';

export const GET_COMPANY_LIST = 'GET_COMPANY_LIST';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_ERROR = 'GET_COMPANY_LIST_ERROR';

export const POST_ASSET_REQUEST = 'POST_ASSET_REQUEST';
export const POST_ASSET_REQUEST_SUCCESS = 'POST_ASSET_REQUEST_SUCCESS';
export const POST_ASSET_REQUEST_ERROR = 'POST_ASSET_REQUEST_ERROR';

export const POST_SHOOTER_AVAILABILITY = 'POST_SHOOTER_AVAILABILITY';
export const POST_SHOOTER_AVAILABILITY_SUCCESS =
  'POST_SHOOTER_AVAILABILITY_SUCCESS';
export const POST_SHOOTER_AVAILABILITY_ERROR =
  'POST_SHOOTER_AVAILABILITY_ERROR';

export const GET_TASK_LIST = 'GET_TASK_LIST';
export const GET_TASK_LIST_SUCCESS = 'GET_TASK_LIST_SUCCESS';
export const GET_TASK_LIST_ERROR = 'GET_TASK_LIST_ERROR';

export const GET_ORDER_TICEKT_LIST = 'GET_ORDER_TICEKT_LIST';
export const GET_ORDER_TICEKT_LIST_SUCCESS = 'GET_ORDER_TICEKT_LIST_SUCCESS';
export const GET_ORDER_TICEKT_LIST_ERROR = 'GET_ORDER_TICEKT_LIST_ERROR';

export const GET_BILLING_REQUEST = 'GET_BILLING_REQUEST';
export const GET_BILLING_REQUEST_SUCCESS = 'GET_BILLING_REQUEST_SUCCESS';
export const GET_BILLING_REQUEST_ERROR = 'GET_BILLING_REQUEST_ERROR';

export const GET_ORDER_SPECIALREQUEST = 'GET_ORDER_SPECIALREQUEST';
export const GET_ORDER_SPECIALREQUEST_SUCCESS =
  'GET_ORDER_SPECIALREQUEST_SUCCESS';
export const GET_ORDER_SPECIALREQUEST_ERROR = 'GET_ORDER_SPECIALREQUEST_ERROR';
export const PUT_ORDER_SPECIAL_REQUEST = 'PUT_ORDER_SPECIAL_REQUEST';
export const PUT_ORDER_SPECIAL_REQUEST_SUCCESS =
  'PUT_ORDER_SPECIAL_REQUEST_SUCCESS';
export const PUT_ORDER_SPECIAL_REQUEST_ERROR =
  'PUT_ORDER_SPECIAL_REQUEST_ERROR';

export const DELETE_ORDER_SPECIAL_REQUEST = 'DELETE_ORDER_SPECIAL_REQUEST';
export const DELETE_ORDER_SPECIAL_REQUEST_SUCCESS =
  'DELETE_ORDER_SPECIAL_REQUEST_SUCCESS';
export const DELETE_ORDER_SPECIAL_REQUEST_ERROR =
  'DELETE_ORDER_SPECIAL_REQUEST_ERROR';

export const DELETE_ORDER_TICKET = 'DELETE_ORDER_TICKET';
export const DELETE_ORDER_TICKET_SUCCESS = 'DELETE_ORDER_TICKET_SUCCESS';
export const DELETE_ORDER_TICKET_ERROR = 'DELETE_ORDER_TICKET_ERROR';

export const GET_DASHBOARD_TICKET_DETAIL = 'GET_DASHBOARD_TICKET_DETAIL';
export const GET_DASHBOARD_TICKET_DETAIL_SUCCESS =
  'GET_DASHBOARD_TICKET_DETAIL_SUCCESS';
export const GET_DASHBOARD_TICKET_DETAIL_ERROR =
  'GET_DASHBOARD_TICKET_DETAIL_ERROR';

export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_ERROR = 'GET_COMPANY_DETAILS_ERROR';

export const GET_QUESTIONNAIRE_REQUEST = 'GET_QUESTIONNAIRE_REQUEST';
export const GET_QUESTIONNAIRE_REQUEST_SUCCESS =
  'GET_QUESTIONNAIRE_REQUEST_SUCCESS';
export const GET_QUESTIONNAIRE_REQUEST_ERROR =
  'GET_QUESTIONNAIRE_REQUEST_ERROR';

export const POST_PROMOCODE = 'POST_PROMOCODE';
export const POST_PROMOCODE_SUCCESS = 'POST_PROMOCODE_SUCCESS';
export const POST_PROMOCODE_ERROR = 'POST_PROMOCODE_ERROR';

export const GET_FILTER_ORDER = 'GET_FILTER_ORDER';
export const GET_FILTER_ORDER_SUCCESS = 'GET_FILTER_ORDER_SUCCESS';
export const GET_FILTER_ORDER_ERROR = 'GET_FILTER_ORDER_ERROR';


export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS';
export const GET_INVOICE_DETAILS_ERROR = 'GET_INVOICE_DETAILS_ERROR';

export const GET_FILTER_TASK = 'GET_FILTER_TASK';
export const GET_FILTER_TASK_SUCCESS = 'GET_FILTER_TASK_SUCCESS';
export const GET_FILTER_TASK_ERROR = 'GET_FILTER_TASK_ERROR';

export const BILLING_FILTER_INVOICE = 'BILLING_FILTER_INVOICE';
export const BILLING_FILTER_INVOICE_SUCCESS = 'BILLING_FILTER_INVOICE_SUCCESS';
export const BILLING_FILTER_INVOICE_ERROR = 'BILLING_FILTER_INVOICE_ERROR';

export const DOWNLOAD_BILLING_CSV = 'DOWNLOAD_BILLING_CSV';
export const DOWNLOAD_BILLING_CSV_SUCCESS = 'DOWNLOAD_BILLING_CSV_SUCCESS';
export const DOWNLOAD_BILLING_CSV_ERROR = 'DOWNLOAD_BILLING_CSV_ERROR';

export const GET_BEE_INVOICE = 'GET_BEE_INVOICE';
export const GET_BEE_INVOICE_SUCCESS = 'GET_BEE_INVOICE_SUCCESS';
export const GET_BEE_INVOICE_ERROR = 'GET_BEE_INVOICE_ERROR';

export const GET_PROMOCODE_LIST = 'GET_PROMOCODE_LIST';
export const GET_PROMOCODE_LIST_SUCCESS = 'GET_PROMOCODE_LIST_SUCCESS';
export const GET_PROMOCODE_LIST_ERROR = 'GET_PROMOCODE_LIST_ERROR';

export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';

export const DEL_UNAVAILABLE_SLOT = 'DEL_UNAVAILABLE_SLOT';
export const DEL_UNAVAILABLE_SLOT_SUCCESS = 'DEL_UNAVAILABLE_SLOT_SUCCESS';
export const DEL_UNAVAILABLE_SLOT_ERROR = 'DEL_UNAVAILABLE_SLOT_ERROR';

export const DELETE_BACKEND_COMPANY_DETAILS = 'DELETE_BACKEND_COMPANY_DETAILS';
export const DELETE_BACKEND_COMPANY_DETAILS_SUCCESS =
  'DELETE_BACKEND_COMPANY_DETAILS_SUCCESS';
export const DELETE_BACKEND_COMPANY_DETAILS_ERROR =
  'DELETE_BACKEND_COMPANY_DETAILS_ERROR';

export const POST_BEE_NOTES = 'POST_BEE_NOTES';
export const POST_BEE_NOTES_SUCCESS = 'POST_BEE_NOTES_SUCCESS';
export const POST_BEE_NOTES_ERROR = 'POST_BEE_NOTES_ERROR';

export const POST_SUPPORT_NOTES = 'POST_SUPPORT_NOTES';
export const POST_SUPPORT_NOTES_SUCCESS = 'POST_SUPPORT_NOTES_SUCCESS';
export const POST_SUPPORT_NOTES_ERROR = 'POST_SUPPORT_NOTES_ERROR';

export const GET_TASK_DETAILS = 'GET_TASK_DETAILS';
export const GET_TASK_DETAILS_SUCCESS = 'GET_TASK_DETAILS_SUCCESS';
export const GET_TASK_DETAILS_ERROR = 'GET_TASK_DETAILS_ERROR';

export const GET_COMPANY_PROMOCODE_LIST = 'GET_COMPANY_PROMOCODE_LIST';
export const GET_COMPANY_PROMOCODE_LIST_SUCCESS =
  'GET_COMPANY_PROMOCODE_LIST_SUCCESS';
export const GET_COMPANY_PROMOCODE_LIST_ERROR =
  'GET_COMPANY_PROMOCODE_LIST_ERROR';

export const GET_BEE_FILTER_INVOICE = 'GET_BEE_FILTER_INVOICE';
export const GET_BEE_FILTER_INVOICE_SUCCESS = 'GET_BEE_FILTER_INVOICE_SUCCESS';
export const GET_BEE_FILTER_INVOICE_ERROR = 'GET_BEE_FILTER_INVOICE_ERROR';

export const DOWNLOAD_INVOICE_CSV = 'DOWNLOAD_INVOICE_CSV';
export const DOWNLOAD_INVOICE_CSV_SUCCESS = 'DOWNLOAD_INVOICE_CSV_SUCCESS';
export const DOWNLOAD_INVOICE_CSV_ERROR = 'DOWNLOAD_INVOICE_CSV_ERROR';

export const PUT_CONTACT_AND_LOCATION = 'PUT_CONTACT_AND_LOCATION';
export const PUT_CONTACT_AND_LOCATION_SUCCESS =
  'PUT_CONTACT_AND_LOCATION_SUCCESS';
export const PUT_CONTACT_AND_LOCATION_ERROR = 'PUT_CONTACT_AND_LOCATION_ERROR';

export const POST_CREDIT_REQUEST = 'POST_CREDIT_REQUEST';
export const POST_CREDIT_REQUEST_SUCCESS = 'POST_CREDIT_REQUEST_SUCCESS';
export const POST_CREDIT_REQUEST_ERROR = 'POST_CREDIT_REQUEST_ERROR';

export const GET_OVERVIEW_DETAILS = 'GET_OVERVIEW_DETAILS';
export const GET_OVERVIEW_DETAILS_SUCCESS = 'GET_OVERVIEW_DETAILS_SUCCESS';
export const GET_OVERVIEW_DETAILS_ERROR = 'GET_OVERVIEW_DETAILS_ERROR';

export const GET_PROMOCODE_USER_LIST = 'GET_PROMOCODE_USER_LIST';
export const GET_PROMOCODE_USER_LIST_SUCCESS =
  'GET_PROMOCODE_USER_LIST_SUCCESS';
export const GET_PROMOCODE_USER_LIST_ERROR = 'GET_PROMOCODE_USER_LIST_ERROR';

export const GET_PROMO_CODE_COMPANY = 'GET_PROMO_CODE_COMPANY';
export const GET_PROMO_CODE_COMPANY_SUCCESS = 'GET_PROMO_CODE_COMPANY_SUCCESS';
export const GET_PROMO_CODE_COMPANY_ERROR = 'GET_PROMO_CODE_COMPANY_ERROR';

export const POST_SPECIAL_CHARGE = 'POST_SPECIAL_CHARGE';
export const POST_SPECIAL_CHARGE_SUCCESS = 'POST_SPECIAL_CHARGE_SUCCESS';
export const POST_SPECIAL_CHARGE_ERROR = 'POST_SPECIAL_CHARGE_ERROR';

export const POST_BEE_SPECIAL_CHARGE = 'POST_BEE_SPECIAL_CHARGE';
export const POST_BEE_SPECIAL_CHARGE_SUCCESS =
  'POST_BEE_SPECIAL_CHARGE_SUCCESS';
export const POST_BEE_SPECIAL_CHARGE_ERROR = 'POST_BEE_SPECIAL_CHARGE_ERROR';

export const GET_HOSTING_PERIOD = 'GET_HOSTING_PERIOD';
export const GET_HOSTING_PERIOD_SUCCESS = 'GET_HOSTING_PERIOD_SUCCESS';
export const GET_HOSTING_PERIOD_ERROR = 'GET_HOSTING_PERIOD_ERROR';

export const GET_ORDER_ASSET = 'GET_ORDER_ASSET';
export const GET_ORDER_ASSET_SUCCESS = 'GET_ORDER_ASSET_SUCCESS';
export const GET_ORDER_ASSET_ERROR = 'GET_ORDER_ASSET_ERROR';


export const PUT_BULK_REQUEST = 'PUT_BULK_REQUEST';
export const PUT_BULK_REQUEST_SUCCESS = 'PUT_BULK_REQUEST_SUCCESS';
export const PUT_BULK_REQUEST_ERROR = 'PUT_BULK_REQUEST_ERROR';

export const POST_NEW_TICKET = 'POST_NEW_TICKET';
export const POST_NEW_TICKET_SUCCESS = 'POST_NEW_TICKET_SUCCESS';
export const POST_NEW_TICKET_ERROR = 'POST_NEW_TICKET_ERROR';

export const POST_ASSET_APPROVAL = 'POST_ASSET_APPROVAL';
export const POST_ASSET_APPROVAL_SUCCESS = 'POST_ASSET_APPROVAL_SUCCESS';
export const POST_ASSET_APPROVAL_ERROR = 'POST_ASSET_APPROVAL_ERROR';

export const GET_GROUP_MANUAL_BEES = 'GET_GROUP_MANUAL_BEES';
export const GET_GROUP_MANUAL_BEES_SUCCESS = 'GET_GROUP_MANUAL_BEES_SUCCESS';
export const GET_GROUP_MANUAL_BEES_ERROR = 'GET_GROUP_MANUAL_BEES_ERROR';
export const GET_ORDER_STATS_DETAILS = 'GET_ORDER_STATS_DETAILS';
export const GET_ORDER_STATS_DETAILS_SUCCESS =
  'GET_ORDER_STATS_DETAILS_SUCCESS';
export const GET_ORDER_STATS_DETAILS_ERROR = 'GET_ORDER_STATS_DETAILS_ERROR';

export const GET_BEE_INVOICE_DETAILS = 'GET_BEE_INVOICE_DETAILS';
export const GET_BEE_INVOICE_DETAILS_SUCCESS =
  'GET_BEE_INVOICE_DETAILS_SUCCESS';
export const GET_BEE_INVOICE_DETAILS_ERROR = 'GET_BEE_INVOICE_DETAILS_ERROR';

export const POST_REGENERATE_ASSET_GROUP = 'POST_REGENERATE_ASSET_GROUP';
export const POST_REGENERATE_ASSET_GROUP_SUCCESS =
  'POST_REGENERATE_ASSET_GROUP_SUCCESS';
export const POST_REGENERATE_ASSET_GROUP_ERROR =
  'POST_REGENERATE_ASSET_GROUP_ERROR';

export const DELETE_ASSET_GROUP = 'DELETE_ASSET_GROUP';
export const DELETE_ASSET_GROUP_SUCCESS = 'DELETE_ASSET_GROUP_SUCCESS';
export const DELETE_ASSET_GROUP_ERROR = 'DELETE_ASSET_GROUP_ERROR';
export const POST_SET_ASSET_APPROVAL = 'POST_SET_ASSET_APPROVAL';
export const POST_SET_ASSET_APPROVAL_SUCCESS =
  'POST_SET_ASSET_APPROVAL_SUCCESS';
export const POST_SET_ASSET_APPROVAL_ERROR = 'POST_SET_ASSET_APPROVAL_ERROR';

export const POST_ZIP_ASSET_GROUP = 'POST_ZIP_ASSET_GROUP';
export const POST_ZIP_ASSET_GROUP_SUCCESS = 'POST_ZIP_ASSET_GROUP_SUCCESS';
export const POST_ZIP_ASSET_GROUP_ERROR = 'POST_ZIP_ASSET_GROUP_ERROR';

export const GET_CALENDER_MONTH_VIEW = 'GET_CALENDER_MONTH_VIEW';
export const GET_CALENDER_MONTH_VIEW_SUCCESS =
  'GET_CALENDER_MONTH_VIEW_SUCCESS';
export const GET_CALENDER_MONTH_VIEW_ERROR = 'GET_CALENDER_MONTH_VIEW_ERROR';

export const GET_CALENDER_MONTH_VIEW_AVAIL = 'GET_CALENDER_MONTH_VIEW_AVAIL';
export const GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS =
  'GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS';
export const GET_CALENDER_MONTH_VIEW_AVAIL_ERROR =
  'GET_CALENDER_MONTH_VIEW_AVAIL_ERROR';

export const GET_CALENDER_MONTH_VIEW_DAY = 'GET_CALENDER_MONTH_VIEW_DAY';
export const GET_CALENDER_MONTH_VIEW_DAY_SUCCESS =
  'GET_CALENDER_MONTH_VIEW_DAY_SUCCESS';
export const GET_CALENDER_MONTH_VIEW_DAY_ERROR =
  'GET_CALENDER_MONTH_VIEW_DAY_ERROR';

export const GET_CALENDER_DAY_VIEW = 'GET_CALENDER_DAY_VIEW';
export const GET_CALENDER_DAY_VIEW_SUCCESS = 'GET_CALENDER_DAY_VIEW_SUCCESS';
export const GET_CALENDER_DAY_VIEW_ERROR = 'GET_CALENDER_DAY_VIEW_ERROR';

export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_ALL_USER_SUCCESS = 'GET_ALL_USER_SUCCESS';
export const GET_ALL_USER_ERROR = 'GET_ALL_USER_ERROR';

export const GET_DEBTOR_LIST = 'GET_DEBTOR_LIST';
export const GET_DEBTOR_LIST_SUCCESS = 'GET_DEBTOR_LIST_SUCCESS';
export const GET_DEBTOR_LIST_ERROR = 'GET_DEBTOR_LIST_ERROR';

export const GET_DEBTOR_DETAILS = 'GET_DEBTOR_DETAILS';
export const GET_DEBTOR_DETAILS_SUCCESS = 'GET_DEBTOR_DETAILS_SUCCESS';
export const GET_DEBTOR_DETAILS_ERROR = 'GET_DEBTOR_DETAILS_ERROR';


export const GET_CREDITOR_LIST = 'GET_CREDITOR_LIST';
export const GET_CREDITOR_LIST_SUCCESS = 'GET_CREDITOR_LIST_SUCCESS';
export const GET_CREDITOR_LIST_ERROR = 'GET_CREDITOR_LIST_ERROR';


export const GET_CREDITOR_DETAILS = 'GET_CREDITOR_DETAILS';
export const GET_CREDITOR_DETAILS_SUCCESS = 'GET_CREDITOR_DETAILS_SUCCESS';
export const GET_CREDITOR_DETAILS_ERROR = 'GET_CREDITOR_DETAILS_ERROR';


export const GET_DEBTOR_CSV = 'GET_DEBTOR_CSV';
export const GET_DEBTOR_CSV_SUCCESS = 'GET_DEBTOR_CSV_SUCCESS';
export const GET_DEBTOR_CSV_ERROR = 'GET_DEBTOR_CSV_ERROR';

export const GET_ACTIVE_ORDER = 'GET_ACTIVE_ORDER';
export const GET_ACTIVE_ORDER_SUCCESS = 'GET_ACTIVE_ORDER_SUCCESS';
export const GET_ACTIVE_ORDER_ERROR = 'GET_ACTIVE_ORDER_ERROR';

export const PUT_MOVIE_DETAIL = 'PUT_MOVIE_DETAIL';
export const PUT_MOVIE_DETAIL_SUCCESS = 'PUT_MOVIE_DETAIL_SUCCESS';
export const PUT_MOVIE_DETAIL_ERROR = 'PUT_MOVIE_DETAIL_ERROR';

export const GET_MATTERPORT_DETAILS = 'GET_MATTERPORT_DETAILS';
export const GET_MATTERPORT_DETAILS_SUCCESS = 'GET_MATTERPORT_DETAILS_SUCCESS';
export const GET_MATTERPORT_DETAILS_ERROR = 'GET_MATTERPORT_DETAILS_ERROR';


export const GET_CREDITOR_CSV = 'GET_CREDITOR_CSV';
export const GET_CREDITOR_CSV_SUCCESS = 'GET_CREDITOR_CSV_SUCCESS';
export const GET_CREDITOR_CSV_ERROR = 'GET_CREDITOR_CSV_ERROR';

export const PUT_MARK_PAID = 'PUT_MARK_PAID';
export const PUT_MARK_PAID_SUCCESS = 'PUT_MARK_PAID_SUCCESS';
export const PUT_MARK_PAID_ERROR = 'PUT_MARK_PAID_ERROR';

export const GET_BACKEND_COMPANY_STATS = 'GET_BACKEND_COMPANY_STATS';
export const GET_BACKEND_COMPANY_STATS_SUCCESS =
  'GET_BACKEND_COMPANY_STATS_SUCCESS';
export const GET_BACKEND_COMPANY_STATS_ERROR =
  'GET_BACKEND_COMPANY_STATS_ERROR';
export const GET_BACKEND_COUNTRY_STATS = 'GET_BACKEND_COUNTRY_STATS';
export const GET_BACKEND_COUNTRY_STATS_SUCCESS =
  'GET_BACKEND_COUNTRY_STATS_SUCCESS';
export const GET_BACKEND_COUNTRY_STATS_ERROR =
  'GET_BACKEND_COUNTRY_STATS_ERROR';
export const GET_REGIONAL_STATS = 'GET_REGIONAL_STATS';
export const GET_REGIONAL_STATS_SUCCESS = 'GET_REGIONAL_STATS_SUCCESS';
export const GET_REGIONAL_STATS_ERROR = 'GET_REGIONAL_STATS_ERROR';
export const GET_ORDER_STATS = 'GET_ORDER_STATS';
export const GET_ORDER_STATS_SUCCESS = 'GET_ORDER_STATS_SUCCESS';
export const GET_ORDER_STATS_ERROR = 'GET_ORDER_STATS_ERROR';

export const GET_BACKEND_STRIPE = 'GET_BACKEND_STRIPE';
export const GET_BACKEND_STRIPE_SUCCESS = 'GET_BACKEND_STRIPE_SUCCESS';
export const GET_BACKEND_STRIPE_ERROR = 'GET_BACKEND_STRIPE_ERROR';
export const DOWNLOAD_STRIPE_CSV = 'DOWNLOAD_STRIPE_CSV';
export const DOWNLOAD_STRIPE_CSV_SUCCESS = 'DOWNLOAD_STRIPE_CSV_SUCCESS';
export const DOWNLOAD_STRIPE_CSV_ERROR = 'DOWNLOAD_STRIPE_CSV_ERROR';

export const GET_CARDLESS_EVENT_LIST = 'GET_CARDLESS_EVENT_LIST';
export const GET_CARDLESS_EVENT_LIST_SUCCESS =
  'GET_CARDLESS_EVENT_LIST_SUCCESS';
export const GET_CARDLESS_EVENT_LIST_ERROR = 'GET_CARDLESS_EVENT_LIST_ERROR';

export const GET_CARDLESS_EVENT_DETAILS = 'GET_CARDLESS_EVENT_DETAILS';
export const GET_CARDLESS_EVENT_DETAILS_SUCCESS =
  'GET_CARDLESS_EVENT_DETAILS_SUCCESS';
export const GET_CARDLESS_EVENT_DETAILS_ERROR =
  'GET_CARDLESS_EVENT_DETAILS_ERROR';

export const GET_FAILED_DD = 'GET_FAILED_DD';
export const GET_FAILED_DD_SUCCESS = 'GET_FAILED_DD_SUCCESS';
export const GET_FAILED_DD_ERROR = 'GET_FAILED_DD_ERROR';

export const GET_FAILED_DD_DETAILS = 'GET_FAILED_DD_DETAILS';
export const GET_FAILED_DD_DETAILS_SUCCESS = 'GET_FAILED_DD_DETAILS_SUCCESS';
export const GET_FAILED_DD_DETAILS_ERROR = 'GET_FAILED_DD_DETAILS_ERROR';

export const GET_MANDATE_LIST = 'GET_MANDATE_LIST';
export const GET_MANDATE_LIST_SUCCESS = 'GET_MANDATE_LIST_SUCCESS';
export const GET_MANDATE_LIST_ERROR = 'GET_MANDATE_LIST_ERROR';

export const GET_REDIRECT_FLOWS = 'GET_REDIRECT_FLOWS';
export const GET_REDIRECT_FLOWS_SUCCESS = 'GET_REDIRECT_FLOWS_SUCCESS';
export const GET_REDIRECT_FLOWS_ERROR = 'GET_REDIRECT_FLOWS_ERROR';


export const GET_CARDLESS_PAYMENT = 'GET_CARDLESS_PAYMENT';
export const GET_CARDLESS_PAYMENT_SUCCESS = 'GET_CARDLESS_PAYMENT_SUCCESS';
export const GET_CARDLESS_PAYMENT_ERROR = 'GET_CARDLESS_PAYMENT_ERROR';


export const GET_REDIRECT_FLOW_DETAILS = 'GET_REDIRECT_FLOW_DETAILS';
export const GET_REDIRECT_FLOW_DETAILS_SUCCESS =
  'GET_REDIRECT_FLOW_DETAILS_SUCCESS';
export const GET_REDIRECT_FLOW_DETAILS_ERROR =
  'GET_REDIRECT_FLOW_DETAILS_ERROR';


export const POST_COMPANY_SIGNUP = 'POST_COMPANY_SIGNUP';
export const POST_COMPANY_SIGNUP_SUCCESS = 'POST_COMPANY_SIGNUP_SUCCESS';
export const POST_COMPANY_SIGNUP_ERROR = 'POST_COMPANY_SIGNUP_ERROR';

export const POST_TICKET_ACTION = 'POST_TICKET_ACTION';
export const POST_TICKET_ACTION_SUCCESS = 'POST_TICKET_ACTION_SUCCESS';
export const POST_TICKET_ACTION_ERROR = 'POST_TICKET_ACTION_ERROR';
export const GET_TRANSCODE_MOVIE = 'GET_TRANSCODE_MOVIE';
export const GET_TRANSCODE_MOVIE_SUCCESS = 'GET_TRANSCODE_MOVIE_SUCCESS';
export const GET_TRANSCODE_MOVIE_ERROR = 'GET_TRANSCODE_MOVIE_ERROR';

export const GET_ANALYTICS_DETAILS = 'GET_ANALYTICS_DETAILS';
export const GET_ANALYTICS_DETAILS_SUCCESS = 'GET_ANALYTICS_DETAILS_SUCCESS';
export const GET_ANALYTICS_DETAILS_ERROR = 'GET_ANALYTICS_DETAILS_ERROR';

export const POST_NEW_MOVIE = 'POST_NEW_MOVIE';
export const POST_NEW_MOVIE_SUCCESS = 'POST_NEW_MOVIE_SUCCESS';
export const POST_NEW_MOVIE_ERROR = 'POST_NEW_MOVIE_ERROR';

export const POST_MARK_PAID_OR_DUE = 'POST_MARK_PAID_OR_DUE';
export const POST_MARK_PAID_OR_DUE_SUCCESS = 'POST_MARK_PAID_OR_DUE_SUCCESS';
export const POST_MARK_PAID_OR_DUE_ERROR = 'POST_MARK_PAID_OR_DUE_ERROR';

export const POST_RESTORE_MOVIE = 'POST_RESTORE_MOVIE';
export const POST_RESTORE_MOVIE_SUCCESS = 'POST_RESTORE_MOVIE_SUCCESS';
export const POST_RESTORE_MOVIE_ERROR = 'POST_RESTORE_MOVIE_ERROR';

export const POST_ARCHIVE_MOVIE = 'POST_ARCHIVE_MOVIE';
export const POST_ARCHIVE_MOVIE_SUCCESS = 'POST_ARCHIVE_MOVIE_SUCCESS';
export const POST_ARCHIVE_MOVIE_ERROR = 'POST_ARCHIVE_MOVIE_ERROR';

export const GET_MENDATE_DETAILS = 'GET_MENDATE_DETAILS';
export const GET_MENDATE_DETAILS_SUCCESS = 'GET_MENDATE_DETAILS_SUCCESS';
export const GET_MENDATE_DETAILS_ERROR = 'GET_MENDATE_DETAILS_ERROR';

export const GET_PAYMENT_DETAILS = 'GET_PAYMENT_DETAILS';
export const GET_PAYMENT_DETAILS_SUCCESS = 'GET_PAYMENT_DETAILS_SUCCESS';
export const GET_PAYMENT_DETAILS_ERROR = 'GET_PAYMENT_DETAILS_ERROR';

export const GET_MONTHLY_AVAILABILITY = 'GET_MONTHLY_AVAILABILITY';
export const GET_MONTHLY_AVAILABILITY_SUCCESS =
  'GET_MONTHLY_AVAILABILITY_SUCCESS';
export const GET_MONTHLY_AVAILABILITY_ERROR = 'GET_MONTHLY_AVAILABILITY_ERROR';

export const GET_WEEKLY_AVAILABILITY = 'GET_WEEKLY_AVAILABILITY';
export const GET_WEEKLY_AVAILABILITY_SUCCESS =
  'GET_WEEKLY_AVAILABILITY_SUCCESS';
export const GET_WEEKLY_AVAILABILITY_ERROR = 'GET_WEEKLY_AVAILABILITY_ERROR';

export const GET_DAILY_AVAILABILITY = 'GET_DAILY_AVAILABILITY';
export const GET_DAILY_AVAILABILITY_SUCCESS = 'GET_DAILY_AVAILABILITY_SUCCESS';
export const GET_DAILY_AVAILABILITY_ERROR = 'GET_DAILY_AVAILABILITY_ERROR';

export const GET_CALENDAR_MONTH = 'GET_CALENDAR_MONTH';
export const GET_CALENDAR_MONTH_SUCCESS = 'GET_CALENDAR_MONTH_SUCCESS';
export const GET_CALENDAR_MONTH_ERROR = 'GET_CALENDAR_MONTH_ERROR';

export const GET_PAYMENT_LIST = 'GET_PAYMENT_LIST';
export const GET_PAYMENT_LIST_SUCCESS = 'GET_PAYMENT_LIST_SUCCESS';
export const GET_PAYMENT_LIST_ERROR = 'GET_PAYMENT_LIST_ERROR';
export const POST_NEW_PAYMENTLINK = 'POST_NEW_PAYMENTLINK';
export const POST_NEW_PAYMENTLINK_SUCCESS = 'POST_NEW_PAYMENTLINK_SUCCESS';
export const POST_NEW_PAYMENTLINK_ERROR = 'POST_NEW_PAYMENTLINK_ERROR';

export const GET_ORDER_WEBLINK = 'GET_ORDER_WEBLINK';
export const GET_ORDER_WEBLINK_SUCCESS = 'GET_ORDER_WEBLINK_SUCCESS';
export const GET_ORDER_WEBLINK_ERROR = 'GET_ORDER_WEBLINK_ERROR';

export const POST_PENDING_ASSET = 'POST_PENDING_ASSET';
export const POST_PENDING_ASSET_SUCCESS = 'POST_PENDING_ASSET_SUCCESS';
export const POST_PENDING_ASSET_ERROR = 'POST_PENDING_ASSET_ERROR';


export const PUT_ORDER_COMPLETION = 'PUT_ORDER_COMPLETION';
export const PUT_ORDER_COMPLETION_SUCCESS = 'PUT_ORDER_COMPLETION_SUCCESS';
export const PUT_ORDER_COMPLETION_ERROR = 'PUT_ORDER_COMPLETION_ERROR';

export const POST_ALL_REPORTS = 'POST_ALL_REPORTS';
export const POST_ALL_REPORTS_SUCCESS = 'POST_ALL_REPORTS_SUCCESS';
export const POST_ALL_REPORTS_ERROR = 'POST_ALL_REPORTS_ERROR';

export const POST_COUNTRY_REPORTS = 'POST_COUNTRY_REPORTS';
export const POST_COUNTRY_REPORTS_SUCCESS = 'POST_COUNTRY_REPORTS_SUCCESS';
export const POST_COUNTRY_REPORTS_ERROR = 'POST_COUNTRY_REPORTS_ERROR';
export const POST_MANAGEMENT_REPORTS = 'POST_MANAGEMENT_REPORTS';
export const POST_MANAGEMENT_REPORTS_SUCCESS =
  'POST_MANAGEMENT_REPORTS_SUCCESS';
export const POST_MANAGEMENT_REPORTS_ERROR = 'POST_MANAGEMENT_REPORTS_ERROR';
export const POST_REFERRAL_REPORT = 'POST_REFERRAL_REPORT';
export const POST_REFERRAL_REPORT_SUCCESS = 'POST_REFERRAL_REPORT_SUCCESS';
export const POST_REFERRAL_REPORT_ERROR = 'POST_REFERRAL_REPORT_ERROR';

export const GET_COMPANY_STATS = 'GET_COMPANY_STATS';
export const GET_COMPANY_STATS_SUCCESS = 'GET_COMPANY_STATS_SUCCESS';
export const GET_COMPANY_STATS_ERROR = 'GET_COMPANY_STATS_ERROR';

export const GET_DATE_RANGE = 'GET_DATE_RANGE';
export const GET_DATE_RANGE_SUCCESS = 'GET_DATE_RANGE_SUCCESS';
export const GET_DATE_RANGE_ERROR = 'GET_DATE_RANGE_ERROR';

export const GET_RENEW_TASK = 'GET_RENEW_TASK';
export const GET_RENEW_TASK_SUCCESS = 'GET_RENEW_TASK_SUCCESS';
export const GET_RENEW_TASK_ERROR = 'GET_RENEW_TASK_ERROR';

export const POST_ORDER_ACTIONS = 'POST_ORDER_ACTIONS';
export const POST_ORDER_ACTIONS_SUCCESS = 'POST_ORDER_ACTIONS_SUCCESS';
export const POST_ORDER_ACTIONS_ERROR = 'POST_ORDER_ACTIONS_ERROR';

export const POST_COMPANY_DETAILS = 'POST_COMPANY_DETAILS';
export const POST_COMPANY_DETAILS_SUCCESS = 'POST_COMPANY_DETAILS_SUCCESS';
export const POST_COMPANY_DETAILS_ERROR = 'POST_COMPANY_DETAILS_ERROR';

export const POST_COMPLETE_TASK = 'POST_COMPLETE_TASK';
export const POST_COMPLETE_TASK_SUCCESS = 'POST_COMPLETE_TASK_SUCCESS';
export const POST_COMPLETE_TASK_ERROR = 'POST_COMPLETE_TASK_ERROR';

export const POST_FEILD_CHANGE_TASK = 'POST_FEILD_CHANGE_TASK';
export const POST_FEILD_CHANGE_TASK_SUCCESS = 'POST_FEILD_CHANGE_TASK_SUCCESS';
export const POST_FEILD_CHANGE_TASK_ERROR = 'POST_FEILD_CHANGE_TASK_ERROR';

export const POST_CLIENT_TASK = 'POST_CLIENT_TASK';
export const POST_CLIENT_TASK_SUCCESS = 'POST_CLIENT_TASK_SUCCESS';
export const POST_CLIENT_TASK_ERROR = 'POST_CLIENT_TASK_ERROR';

export const POST_APPROVE_ASSET_GROUP = 'POST_APPROVE_ASSET_GROUP';
export const POST_APPROVE_ASSET_GROUP_SUCCESS =
  'POST_APPROVE_ASSET_GROUP_SUCCESS';
export const POST_APPROVE_ASSET_GROUP_ERROR = 'POST_APPROVE_ASSET_GROUP_ERROR';
export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS';
export const GET_PROFILE_DETAILS_ERROR = 'GET_PROFILE_DETAILS_ERROR';

export const GET_BACKEND_COMPANY_DETAILS = 'GET_BACKEND_COMPANY_DETAILS';
export const GET_BACKEND_COMPANY_DETAILS_SUCCESS =
  'GET_BACKEND_COMPANY_DETAILS_SUCCESS';
export const GET_BACKEND_COMPANY_DETAILS_ERROR =
  'GET_BACKEND_COMPANY_DETAILS_ERROR';

export const GET_PAY_DETAILS = 'GET_PAY_DETAILS';
export const GET_PAY_DETAILS_SUCCESS = 'GET_PAY_DETAILS_SUCCESS';
export const GET_PAY_DETAILS_ERROR = 'GET_PAY_DETAILS_ERROR';

export const GET_CLOSED_TICKETS = 'GET_CLOSED_TICKETS';
export const GET_CLOSED_TICKETS_SUCCESS = 'GET_CLOSED_TICKETS_SUCCESS';
export const GET_CLOSED_TICKETS_ERROR = 'GET_CLOSED_TICKETS_ERROR';
export const GET_OPEN_TICKETS = 'GET_OPEN_TICKETS';
export const GET_OPEN_TICKETS_SUCCESS = 'GET_OPEN_TICKETS_SUCCESS';
export const GET_OPEN_TICKETS_ERROR = 'GET_OPEN_TICKETS_ERROR';

export const GET_VIRTUAL_TOOL = 'GET_VIRTUAL_TOOL';
export const GET_VIRTUAL_TOOL_SUCCESS = 'GET_VIRTUAL_TOOL_SUCCESS';
export const GET_VIRTUAL_TOOL_ERROR = 'GET_VIRTUAL_TOOL_ERROR';

export const PUT_CLIENT_NOTES = 'PUT_CLIENT_NOTES';
export const PUT_CLIENT_NOTES_SUCCESS = 'PUT_CLIENT_NOTES_SUCCESS';
export const PUT_CLIENT_NOTES_ERROR = 'PUT_CLIENT_NOTES_ERROR';

export const PUT_BEE_UPDATE = 'PUT_BEE_UPDATE';
export const PUT_BEE_UPDATE_SUCCESS = 'PUT_BEE_UPDATE_SUCCESS';
export const PUT_BEE_UPDATE_ERROR = 'PUT_BEE_UPDATE_ERROR';

export const GET_TOUR_THREE_SIXTY = 'GET_TOUR_THREE_SIXTY';
export const GET_TOUR_THREE_SIXTY_SUCCESS = 'GET_TOUR_THREE_SIXTY_SUCCESS';
export const GET_TOUR_THREE_SIXTY_ERROR = 'GET_TOUR_THREE_SIXTY_ERROR';

export const PUT_TOUR_DETAILS = 'PUT_TOUR_DETAILS';
export const PUT_TOUR_DETAILS_SUCCESS = 'PUT_TOUR_DETAILS_SUCCESS';
export const PUT_TOUR_DETAILS_ERROR = 'PUT_TOUR_DETAILS_ERROR';

export const GET_WEBLINK_DETAILS = 'GET_WEBLINK_DETAILS';
export const GET_WEBLINK_DETAILS_SUCCESS = 'GET_WEBLINK_DETAILS_SUCCESS';
export const GET_WEBLINK_DETAILS_ERROR = 'GET_WEBLINK_DETAILS_ERROR';

export const COMPANY_ACTION = 'COMPANY_ACTION';
export const COMPANY_ACTION_SUCCESS = 'COMPANY_ACTION_SUCCESS';
export const COMPANY_ACTION_ERROR = 'COMPANY_ACTION_ERROR';
export const APPROVE_INVOICE = 'APPROVE_INVOICE';
export const APPROVE_INVOICE_SUCCESS = 'APPROVE_INVOICE_SUCCESS';
export const APPROVE_INVOICE_ERROR = 'APPROVE_INVOICE_ERROR';

export const DELETE_INVOICE_ENTRY = 'DELETE_INVOICE_ENTRY';
export const DELETE_INVOICE_ENTRY_SUCCESS = 'DELETE_INVOICE_ENTRY_SUCCESS';
export const DELETE_INVOICE_ENTRY_ERROR = 'DELETE_INVOICE_ENTRY_ERROR';

export const POST_NEW_INVOICE = 'POST_NEW_INVOICE';
export const POST_NEW_INVOICE_SUCCESS = 'POST_NEW_INVOICE_SUCCESS';
export const POST_NEW_INVOICE_ERROR = 'POST_NEW_INVOICE_ERROR';

export const UPDATE_INVOICE_ENTRY = 'UPDATE_INVOICE_ENTRY';
export const UPDATE_INVOICE_ENTRY_SUCCESS = 'UPDATE_INVOICE_ENTRY_SUCCESS';
export const UPDATE_INVOICE_ENTRY_ERROR = 'UPDATE_INVOICE_ENTRY_ERROR';

export const POST_APPROVE_INVOICE = 'POST_APPROVE_INVOICE';
export const POST_APPROVE_INVOICE_SUCCESS = 'POST_APPROVE_INVOICE_SUCCESS';
export const POST_APPROVE_INVOICE_ERROR = 'POST_APPROVE_INVOICE_ERROR';

export const ADD_CLIENT_REQ = 'ADD_CLIENT_REQ';
export const ADD_CLIENT_REQ_SUCCESS = 'ADD_CLIENT_REQ_SUCCESS';
export const ADD_CLIENT_REQ_ERROR = 'ADD_CLIENT_REQ_ERROR';

export const ADD_BEE_REQ = 'ADD_BEE_REQ';
export const ADD_BEE_REQ_SUCCESS = 'ADD_BEE_REQ_SUCCESS';
export const ADD_BEE_REQ_ERROR = 'ADD_BEE_REQ_ERROR';

export const GET_PREFERENCES = 'GET_PREFERENCES';
export const GET_PREFERENCES_SUCCESS = 'GET_PREFERENCES_SUCCESS';
export const GET_PREFERENCES_ERROR = 'GET_PREFERENCES_ERROR';

export const GET_MUSIC_REQ = 'GET_MUSIC_REQ';
export const GET_MUSIC_REQ_SUCCESS = 'GET_MUSIC_REQ_SUCCESS';
export const GET_MUSIC_REQ_ERROR = 'GET_MUSIC_REQ_ERROR';

export const GET_USER_TYPE = 'GET_USER_TYPE';
export const GET_USER_TYPE_SUCCESS = 'GET_USER_TYPE_SUCCESS';
export const GET_USER_TYPE_ERROR = 'GET_USER_TYPE_ERROR';

export const ADD_NEW_COMPANY = 'ADD_NEW_COMPANY';
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS';
export const ADD_NEW_COMPANY_ERROR = 'ADD_NEW_COMPANY_ERROR';

export const GET_PERMISSION_REQ = 'GET_PERMISSION_REQ';
export const GET_PERMISSION_REQ_SUCCESS = 'GET_PERMISSION_REQ_SUCCESS';
export const GET_PERMISSION_REQ_ERROR = 'GET_PERMISSION_REQ_ERROR';

export const GET_GROUPS_REQ = 'GET_GROUPS_REQ';
export const GET_GROUPS_REQ_SUCCESS = 'GET_GROUPS_REQ_SUCCESS';
export const GET_GROUPS_REQ_ERROR = 'GET_GROUPS_REQ_ERROR';

export const GET_SLOTS_REQ = 'GET_SLOTS_REQ';
export const GET_SLOTS_REQ_SUCCESS = 'GET_SLOTS_REQ_SUCCESS';
export const GET_SLOTS_REQ_ERROR = 'GET_SLOTS_REQ_ERROR';

export const GET_BEE_KIT_LIST = 'GET_BEE_KIT_LIST';
export const GET_BEE_KIT_LIST_SUCCESS = 'GET_BEE_KIT_LIST_SUCCESS';
export const GET_BEE_KIT_LIST_ERROR = 'GET_BEE_KIT_LIST_ERROR';

export const POST_NEW_PASSWORD = 'POST_NEW_PASSWORD';
export const POST_NEW_PASSWORD_SUCCESS = 'POST_NEW_PASSWORD_SUCCESS';
export const POST_NEW_PASSWORD_ERROR = 'POST_NEW_PASSWORD_ERROR';

export const PUT_CLIENT_UPDATE = 'PUT_CLIENT_UPDATE';
export const PUT_CLIENT_UPDATE_SUCCESS = 'PUT_CLIENT_UPDATE_SUCCESS';
export const PUT_CLIENT_UPDATE_ERROR = 'PUT_CLIENT_UPDATE_ERROR';

export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const UPDATE_COMPANY_DETAILS_SUCCESS = 'UPDATE_COMPANY_DETAILS_SUCCESS';
export const UPDATE_COMPANY_DETAILS_ERROR = 'UPDATE_COMPANY_DETAILS_ERROR';

export const GET_REGION_REQ = 'GET_REGION_REQ';
export const GET_REGION_REQ_SUCCESS = 'GET_REGION_REQ_SUCCESS';
export const GET_REGION_REQ_ERROR = 'GET_REGION_REQ_ERROR';

export const GET_BROCHURE_REQ = 'GET_BROCHURE_REQ';
export const GET_BROCHURE_REQ_SUCCESS = 'GET_BROCHURE_REQ_SUCCESS';
export const GET_BROCHURE_REQ_ERROR = 'GET_BROCHURE_REQ_ERROR';

export const GET_LISTING_SITE = 'GET_LISTING_SITE';
export const GET_LISTING_SITE_SUCCESS = 'GET_LISTING_SITE_SUCCESS';
export const GET_LISTING_SITE_ERROR = 'GET_LISTING_SITE_ERROR';

export const GET_PUBLIC_LOGO = 'GET_PUBLIC_LOGO';
export const GET_PUBLIC_LOGO_SUCCESS = 'GET_PUBLIC_LOGO_SUCCESS';
export const GET_PUBLIC_LOGO_ERROR = 'GET_PUBLIC_LOGO_ERROR';

export const GET_WATER_MARK = 'GET_WATER_MARK';
export const GET_WATER_MARK_SUCCESS = 'GET_WATER_MARK_SUCCESS';
export const GET_WATER_MARK_ERROR = 'GET_WATER_MARK_ERROR';

export const GET_ACCOUNT_LIST = 'GET_ACCOUNT_LIST';
export const GET_ACCOUNT_LIST_SUCCESS = 'GET_ACCOUNT_LIST_SUCCESS';
export const GET_ACCOUNT_LIST_ERROR = 'GET_ACCOUNT_LIST_ERROR';

export const GET_PROJECT_FILE = 'GET_PROJECT_FILE';
export const GET_PROJECT_FILE_SUCCESS = 'GET_PROJECT_FILE_SUCCESS';
export const GET_PROJECT_FILE_ERROR = 'GET_PROJECT_FILE_ERROR';

export const POST_3D_LOGO = 'POST_3D_LOGO';
export const POST_3D_LOGO_SUCCESS = 'POST_3D_LOGO_SUCCESS';
export const POST_3D_LOGO_ERROR = 'POST_3D_LOGO_ERROR';

export const POST_CLIENT_COMPANY_DETAILS = 'POST_CLIENT_COMPANY_DETAILS';
export const POST_CLIENT_COMPANY_DETAILS_SUCCESS =
  'POST_CLIENT_COMPANY_DETAILS_SUCCESS';
export const POST_CLIENT_COMPANY_DETAILS_ERROR =
  'POST_CLIENT_COMPANY_DETAILS_ERROR';

export const DELETE_USER_REQ = 'DELETE_USER_REQ';
export const DELETE_USER_REQ_SUCCESS = 'DELETE_USER_REQ_SUCCESS';
export const DELETE_USER_REQ_ERROR = 'DELETE_USER_REQ_ERROR';

export const PUT_CLIENT_USER = 'PUT_CLIENT_USER';
export const PUT_CLIENT_USER_SUCCESS = 'PUT_CLIENT_USER_SUCCESS';
export const PUT_CLIENT_USER_ERROR = 'PUT_CLIENT_USER_ERROR';

export const GET_ORDER_TOTAL_PRICE = 'GET_ORDER_TOTAL_PRICE';
export const GET_ORDER_TOTAL_PRICE_SUCCESS = 'GET_ORDER_TOTAL_PRICE_SUCCESS';
export const GET_ORDER_TOTAL_PRICE_ERROR = 'GET_ORDER_TOTAL_PRICE_ERROR';

export const POST_COMPANY_INVITATION = 'POST_COMPANY_INVITATION';
export const POST_COMPANY_INVITATION_SUCCESS =
  'POST_COMPANY_INVITATION_SUCCESS';
export const POST_COMPANY_INVITATION_ERROR = 'POST_COMPANY_INVITATION_ERROR';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR';

export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST';
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS';
export const GET_COUNTRY_LIST_ERROR = 'GET_COUNTRY_LIST_ERROR';

export const GET_COMPANY_LINK_STATUS = 'GET_COMPANY_LINK_STATUS';
export const GET_COMPANY_LINK_STATUS_SUCCESS =
  'GET_COMPANY_LINK_STATUS_SUCCESS';
export const GET_COMPANY_LINK_STATUS_ERROR = 'GET_COMPANY_LINK_STATUS_ERROR';


export const POST_RESHOOT_DETAILS = 'POST_RESHOOT_DETAILS';
export const POST_RESHOOT_DETAILS_SUCCESS = 'POST_RESHOOT_DETAILS_SUCCESS';
export const POST_RESHOOT_DETAILS_ERROR = 'POST_RESHOOT_DETAILS_ERROR';

export const GET_UNAVAILABLE_LIST = 'GET_UNAVAILABLE_LIST';
export const GET_UNAVAILABLE_LIST_SUCCESS = 'GET_UNAVAILABLE_LIST_SUCCESS';
export const GET_UNAVAILABLE_LIST_ERROR = 'GET_UNAVAILABLE_LIST_ERROR';

export const GET_AVAILABLE_LIST = 'GET_AVAILABLE_LIST';
export const GET_AVAILABLE_LIST_SUCCESS = 'GET_AVAILABLE_LIST_SUCCESS';
export const GET_AVAILABLE_LIST_ERROR = 'GET_AVAILABLE_LIST_ERROR';

export const GET_ALL_SHOOTERLIST = 'GET_ALL_SHOOTERLIST';
export const GET_ALL_SHOOTERLIST_SUCCESS = 'GET_ALL_SHOOTERLIST_SUCCESS';
export const GET_ALL_SHOOTERLIST_ERROR = 'GET_ALL_SHOOTERLIST_ERROR';

export const POST_AMEND_PHOTO = 'POST_AMEND_PHOTO';
export const POST_AMEND_PHOTO_SUCCESS = 'POST_AMEND_PHOTO_SUCCESS';
export const POST_AMEND_PHOTO_ERROR = 'POST_AMEND_PHOTO_ERROR';

export const POST_AMEND_BROUCHER = 'POST_AMEND_BROUCHER';
export const POST_AMEND_BROUCHER_SUCCESS = 'POST_AMEND_BROUCHER_SUCCESS';
export const POST_AMEND_BROUCHER_ERROR = 'POST_AMEND_BROUCHER_ERROR';

export const POST_AMEND_FLOORPLAN = 'POST_AMEND_FLOORPLAN';
export const POST_AMEND_FLOORPLAN_SUCCESS = 'POST_AMEND_FLOORPLAN_SUCCESS';
export const POST_AMEND_FLOORPLAN_ERROR = 'POST_AMEND_FLOORPLAN_ERROR';

//GET_INVOICE_EDIT_DETAIL,GET_INVOICE_EDIT_DETAIL_SUCCESS,GET_INVOICE_EDIT_DETAIL_ERROR
export const GET_INVOICE_EDIT_DETAIL = 'GET_INVOICE_EDIT_DETAIL';
export const GET_INVOICE_EDIT_DETAIL_SUCCESS = 'GET_INVOICE_EDIT_DETAIL_SUCCESS';
export const GET_INVOICE_EDIT_DETAIL_ERROR = 'GET_INVOICE_EDIT_DETAIL_ERROR';

//RENEW_HOISTING,RENEW_HOISTING_SUCCESS,RENEW_HOISTING_ERROR

export const RENEW_HOISTING = 'RENEW_HOISTING';
export const RENEW_HOISTING_SUCCESS = 'RENEW_HOISTING_SUCCESS';
export const RENEW_HOISTING_ERROR = 'RENEW_HOISTING_ERROR';

//CANCEL_HOISTING,CANCEL_HOISTING_SUCCESS,CANCEL_HOISTING_ERROR
export const CANCEL_HOISTING = 'CANCEL_HOISTING';
export const CANCEL_HOISTING_SUCCESS = 'CANCEL_HOISTING_SUCCESS';
export const CANCEL_HOISTING_ERROR = 'CANCEL_HOISTING_ERROR';

//GET_MSL_DETAILS,GET_MSL_DETAILS_SUCCESS,GET_MSL_DETAILS_ERROR
export const GET_MSL_DETAILS = 'GET_MSL_DETAILS';
export const GET_MSL_DETAILS_SUCCESS = 'GET_MSL_DETAILS_SUCCESS';
export const GET_MSL_DETAILS_ERROR = 'GET_MSL_DETAILS_ERROR';

export interface GetMslDetails {
  type: typeof GET_MSL_DETAILS;
}
export interface GetMslDetailsSuccess {
  type: typeof GET_MSL_DETAILS_SUCCESS;
  payload: {};
}
export interface GetMslDetailsError {
  type: typeof GET_MSL_DETAILS_ERROR;
}

export interface CancelHoisting {
  type: typeof CANCEL_HOISTING;
}
export interface CancelHoistingSuccess {
  type: typeof CANCEL_HOISTING_SUCCESS;
  payload: {};
}
export interface CancelHoistingError {
  type: typeof CANCEL_HOISTING_ERROR;
}


export interface RenewHoisting {
  type: typeof RENEW_HOISTING;
}
export interface RenewHoistingSuccess {
  type: typeof RENEW_HOISTING_SUCCESS;
  payload: {};
}
export interface RenewHoistingError {
  type: typeof RENEW_HOISTING_ERROR;
}

export interface GetInoviceEdit {
  type: typeof GET_INVOICE_EDIT_DETAIL;
}
export interface GetInoviceEditSuccess {
  type: typeof GET_INVOICE_EDIT_DETAIL_SUCCESS;
  payload: {};
}
export interface GetInoviceEditError {
  type: typeof GET_INVOICE_EDIT_DETAIL_ERROR;
}

export interface PostAmendPhoto {
  type: typeof POST_AMEND_PHOTO;
}
export interface PostAmendPhotoSuccess {
  type: typeof POST_AMEND_PHOTO_SUCCESS;
  payload: {};
}
export interface PostAmendPhotoError {
  type: typeof POST_AMEND_PHOTO_ERROR;
}


export interface PostAmendBroucher {
  type: typeof POST_AMEND_BROUCHER;
}
export interface PostAmendBroucherSuccess {
  type: typeof POST_AMEND_BROUCHER_SUCCESS;
  payload: {};
}
export interface PostAmendBroucherError {
  type: typeof POST_AMEND_BROUCHER_ERROR;
}


export interface PostAmendFloorplan {
  type: typeof POST_AMEND_FLOORPLAN;
}
export interface PostAmendFloorplanSuccess {
  type: typeof POST_AMEND_FLOORPLAN_SUCCESS;
  payload: {};
}
export interface PostAmendFloorplanError {
  type: typeof POST_AMEND_FLOORPLAN_ERROR;
}

export interface GetAllShooterList {
  type: typeof GET_ALL_SHOOTERLIST;
}
export interface GetAllShooterListSuccess {
  type: typeof GET_ALL_SHOOTERLIST_SUCCESS;
  payload: {};
}
export interface GetAllShooterListError {
  type: typeof GET_ALL_SHOOTERLIST_ERROR;
}

export interface GetAvailbleList {
  type: typeof GET_AVAILABLE_LIST;
}
export interface GetAvailbleListSuccess {
  type: typeof GET_AVAILABLE_LIST_SUCCESS;
  payload: {};
}
export interface GetAvailbleListError {
  type: typeof GET_AVAILABLE_LIST_ERROR;
}

export interface GetUnavailbleList {
  type: typeof GET_UNAVAILABLE_LIST;
}
export interface GetUnavailbleListSuccess {
  type: typeof GET_UNAVAILABLE_LIST_SUCCESS;
  payload: {};
}
export interface GetUnavailbleListError {
  type: typeof GET_UNAVAILABLE_LIST_ERROR;
}

export interface PostReshootDetails {
  type: typeof POST_RESHOOT_DETAILS;
}
export interface PostReshootDetailsSuccess {
  type: typeof POST_RESHOOT_DETAILS_SUCCESS;
  payload: {};
}
export interface PostReshootDetailsError {
  type: typeof POST_RESHOOT_DETAILS_ERROR;
}

export interface PostNewRequest {
  type: typeof POST_NEW_TICKET;
}
export interface PostNewRequestSuccess {
  type: typeof POST_NEW_TICKET_SUCCESS;
  payload: {};
}
export interface PostNewRequestError {
  type: typeof POST_NEW_TICKET_ERROR;
}

export interface GetCompanyStatus {
  type: typeof GET_COMPANY_LINK_STATUS;
}
export interface GetCompanyStatusSuccess {
  type: typeof GET_COMPANY_LINK_STATUS_SUCCESS;
  payload: {};
}
export interface GetCompanyStatusError {
  type: typeof GET_COMPANY_LINK_STATUS_ERROR;
}
export interface GetCountryList {
  type: typeof GET_COUNTRY_LIST;
}
export interface GetCountryListSuccess {
  type: typeof GET_COUNTRY_LIST_SUCCESS;
  payload: {};
}
export interface GetCountryListError {
  type: typeof GET_COUNTRY_LIST_ERROR;
}

export interface DeleteCompany {
  type: typeof DELETE_COMPANY;
}
export interface DeleteCompanySuccess {
  type: typeof DELETE_COMPANY_SUCCESS;
  payload: {};
}
export interface DeleteCompanyError {
  type: typeof DELETE_COMPANY_ERROR;
}

export interface PostCompanyRegistration {
  type: typeof POST_COMPANY_INVITATION;
}
export interface PostCompanyRegistrationSuccess {
  type: typeof POST_COMPANY_INVITATION_SUCCESS;
  payload: {};
}
export interface PostCompanyRegistrationError {
  type: typeof POST_COMPANY_INVITATION_ERROR;
}

export interface GetOrderPrice {
  type: typeof GET_ORDER_TOTAL_PRICE;
}
export interface GetOrderPriceSuccess {
  type: typeof GET_ORDER_TOTAL_PRICE_SUCCESS;
  payload: {};
}
export interface GetOrderPriceError {
  type: typeof GET_ORDER_TOTAL_PRICE_ERROR;
}

export interface PutClientChange {
  type: typeof PUT_CLIENT_USER;
}
export interface PutClientChangeSuccess {
  type: typeof PUT_CLIENT_USER_SUCCESS;
  payload: {};
}
export interface PutClientChangeError {
  type: typeof PUT_CLIENT_USER_ERROR;
}

export interface DeleteUserReq {
  type: typeof DELETE_USER_REQ;
}
export interface DeleteUserReqSuccess {
  type: typeof DELETE_USER_REQ_SUCCESS;
  payload: {};
}
export interface DeleteUserReqError {
  type: typeof DELETE_USER_REQ_ERROR;
}

export interface Post3DLogo {
  type: typeof POST_3D_LOGO;
}
export interface Post3DLogoSuccess {
  type: typeof POST_3D_LOGO_SUCCESS;
  payload: {};
}
export interface Post3DLogoError {
  type: typeof POST_3D_LOGO_ERROR;
}

export interface GetProjectFile {
  type: typeof GET_PROJECT_FILE;
}

export interface GetProjectFile {
  type: typeof GET_PROJECT_FILE;
  payload: {};
}
export interface GetProjectFileError {
  type: typeof GET_PROJECT_FILE_ERROR;
}

export interface PostClientCompanyDetails {
  type: typeof POST_CLIENT_COMPANY_DETAILS;
}
export interface PostClientCompanyDetailsSuccess {
  type: typeof POST_CLIENT_COMPANY_DETAILS_SUCCESS;
  payload: {};
}
export interface PostClientCompanyDetailsError {
  type: typeof POST_CLIENT_COMPANY_DETAILS_ERROR;
}

export interface GetProjectFile {
  type: typeof GET_PROJECT_FILE;
}
export interface GetProjectFileSuccess {
  type: typeof GET_PROJECT_FILE_SUCCESS;
  payload: {};
}
export interface GetProjectFileError {
  type: typeof GET_PROJECT_FILE_ERROR;
}

export interface GetAccountList {
  type: typeof GET_ACCOUNT_LIST;
}
export interface GetAccountListSuccess {
  type: typeof GET_ACCOUNT_LIST_SUCCESS;
  payload: {};
}
export interface GetAccountListError {
  type: typeof GET_ACCOUNT_LIST_ERROR;
}

export interface GetWaterMark {
  type: typeof GET_WATER_MARK;
}
export interface GetWaterMarkSuccess {
  type: typeof GET_WATER_MARK_SUCCESS;
  payload: {};
}
export interface GetWaterMarkError {
  type: typeof GET_WATER_MARK_ERROR;
}

export interface GetPublicLogo {
  type: typeof GET_PUBLIC_LOGO;
}
export interface GetPublicLogoSuccess {
  type: typeof GET_PUBLIC_LOGO_SUCCESS;
  payload: {};
}
export interface GetPublicLogoError {
  type: typeof GET_PUBLIC_LOGO_ERROR;
}

export interface GetListingSite {
  type: typeof GET_LISTING_SITE;
}
export interface GetListingSiteSuccess {
  type: typeof GET_LISTING_SITE_SUCCESS;
  payload: {};
}
export interface GetListingSiteError {
  type: typeof GET_LISTING_SITE_ERROR;
}

export interface GetBrochureList {
  type: typeof GET_BROCHURE_REQ;
}
export interface GetBrochureListSuccess {
  type: typeof GET_BROCHURE_REQ_SUCCESS;
  payload: {};
}
export interface GetBrochureListError {
  type: typeof GET_BROCHURE_REQ_ERROR;
}

export interface GetRegions {
  type: typeof GET_REGION_REQ;
}
export interface GetRegionsSuccess {
  type: typeof GET_REGION_REQ_SUCCESS;
  payload: {};
}
export interface GetRegionsError {
  type: typeof GET_REGION_REQ_ERROR;
}

export interface PutCompanyUpdate {
  type: typeof UPDATE_COMPANY_DETAILS;
}
export interface PutCompanyUpdateSuccess {
  type: typeof UPDATE_COMPANY_DETAILS_SUCCESS;
  payload: {};
}
export interface PutCompanyUpdateError {
  type: typeof UPDATE_COMPANY_DETAILS_ERROR;
}

export interface PutClientUpdate {
  type: typeof PUT_CLIENT_UPDATE;
}
export interface PutClientUpdateSuccess {
  type: typeof PUT_CLIENT_UPDATE_SUCCESS;
  payload: {};
}
export interface PutClientUpdateError {
  type: typeof PUT_CLIENT_UPDATE_ERROR;
}

export interface PostNewPassword {
  type: typeof POST_NEW_PASSWORD;
}
export interface PostNewPasswordSuccess {
  type: typeof POST_NEW_PASSWORD_SUCCESS;
  payload: {};
}
export interface PostNewPasswordError {
  type: typeof POST_NEW_PASSWORD_ERROR;
}

export interface putBeeUpdate {
  type: typeof PUT_BEE_UPDATE;
}
export interface PutBeeUpdateSuccess {
  type: typeof PUT_BEE_UPDATE_SUCCESS;
  payload: {};
}
export interface PutBeeUpdateError {
  type: typeof PUT_BEE_UPDATE_ERROR;
}

export interface GetSlotsReq {
  type: typeof GET_SLOTS_REQ;
}
export interface GetSlotsReqSuccess {
  type: typeof GET_SLOTS_REQ_SUCCESS;
  payload: {};
}
export interface GetSlotsReqError {
  type: typeof GET_SLOTS_REQ_ERROR;
}

export interface GetBeekitList {
  type: typeof GET_BEE_KIT_LIST;
}
export interface GetBeekitListSuccess {
  type: typeof GET_BEE_KIT_LIST_SUCCESS;
  payload: {};
}
export interface GetBeekitListError {
  type: typeof GET_BEE_KIT_LIST_ERROR;
}

export interface GetGroupsReq {
  type: typeof GET_GROUPS_REQ;
}
export interface GetGroupsReqSuccess {
  type: typeof GET_GROUPS_REQ_SUCCESS;
  payload: {};
}
export interface GetGroupsReqError {
  type: typeof GET_GROUPS_REQ_ERROR;
}

export interface GetPermission {
  type: typeof GET_PERMISSION_REQ;
}
export interface GetPermissionSuccess {
  type: typeof GET_PERMISSION_REQ_SUCCESS;
  payload: {};
}
export interface GetPermissionError {
  type: typeof GET_PERMISSION_REQ_ERROR;
}

export interface PostNewCompany {
  type: typeof ADD_NEW_COMPANY;
}
export interface PostNewCompanySuccess {
  type: typeof ADD_NEW_COMPANY_SUCCESS;
  payload: {};
}
export interface PostNewCompanyError {
  type: typeof ADD_NEW_COMPANY_ERROR;
}

export interface GetUserTypes {
  type: typeof GET_USER_TYPE;
}
export interface GetUserTypesSuccess {
  type: typeof GET_USER_TYPE_SUCCESS;
  payload: {};
}
export interface GetUserTypesError {
  type: typeof GET_USER_TYPE_ERROR;
}

export interface GetMusicReq {
  type: typeof GET_MUSIC_REQ;
}
export interface GetMusicReqSuccess {
  type: typeof GET_MUSIC_REQ_SUCCESS;
  payload: {};
}
export interface GetMusicReqError {
  type: typeof GET_MUSIC_REQ_ERROR;
}

export interface GetPrefernces {
  type: typeof GET_PREFERENCES;
}
export interface GetPreferncesSuccess {
  type: typeof GET_PREFERENCES_SUCCESS;
  payload: {};
}
export interface GetPreferncesError {
  type: typeof GET_PREFERENCES_ERROR;
}

export interface AddCLientReq {
  type: typeof ADD_CLIENT_REQ;
}
export interface AddCLientReqSuccess {
  type: typeof ADD_CLIENT_REQ_SUCCESS;
  payload: {};
}
export interface AddCLientReqError {
  type: typeof ADD_CLIENT_REQ_ERROR;
}

export interface AddBeeReq {
  type: typeof ADD_BEE_REQ;
}
export interface AddBeeReqSuccess {
  type: typeof ADD_BEE_REQ_SUCCESS;
  payload: {};
}
export interface AddBeeReqError {
  type: typeof ADD_BEE_REQ_ERROR;
}

export interface PostApproveInvoice {
  type: typeof POST_APPROVE_INVOICE;
}
export interface PostApproveInvoiceSuccess {
  type: typeof POST_APPROVE_INVOICE_SUCCESS;
  payload: {};
}
export interface PostApproveInvoiceError {
  type: typeof POST_APPROVE_INVOICE_ERROR;
}

export interface UpdateInvoiceEntry {
  type: typeof UPDATE_INVOICE_ENTRY;
}
export interface UpdateInvoiceEntrySuccess {
  type: typeof UPDATE_INVOICE_ENTRY_SUCCESS;
  payload: {};
}
export interface UpdateInvoiceEntryError {
  type: typeof UPDATE_INVOICE_ENTRY_ERROR;
}
export interface PostNewInvoice {
  type: typeof POST_NEW_INVOICE;
}
export interface PostNewInvoiceSuccess {
  type: typeof POST_NEW_INVOICE_SUCCESS;
  payload: {};
}
export interface PostNewInvoiceError {
  type: typeof POST_NEW_INVOICE_ERROR;
}

export interface DeleteInvoice {
  type: typeof DELETE_INVOICE_ENTRY;
}
export interface DeleteInvoiceSuccess {
  type: typeof DELETE_INVOICE_ENTRY_SUCCESS;
  payload: {};
}
export interface DeleteInvoiceError {
  type: typeof DELETE_INVOICE_ENTRY_ERROR;
}

export interface ApproveInvoice {
  type: typeof APPROVE_INVOICE;
}
export interface ApproveInvoiceSuccess {
  type: typeof APPROVE_INVOICE_SUCCESS;
  payload: {};
}
export interface ApproveInvoiceError {
  type: typeof APPROVE_INVOICE_ERROR;
}

export interface CompanyAction {
  type: typeof COMPANY_ACTION;
}
export interface CompanyActionSuccess {
  type: typeof COMPANY_ACTION_SUCCESS;
  payload: {};
}
export interface CompanyActionError {
  type: typeof COMPANY_ACTION_ERROR;
}

export interface PostYoutube {
  type: typeof PUT_YOUTUBE_UPLOAD;
}
export interface PostYoutubeSuccess {
  type: typeof PUT_YOUTUBE_UPLOAD_SUCCESS;
  payload: {};
}
export interface PostYoutubeError {
  type: typeof PUT_YOUTUBE_UPLOAD_ERROR;
}

export interface GetWeblinkDetails {
  type: typeof GET_WEBLINK_DETAILS;
}
export interface GetWeblinkDetailsSuccess {
  type: typeof GET_WEBLINK_DETAILS_SUCCESS;
  payload: {};
}
export interface GetWeblinkDetailsError {
  type: typeof GET_WEBLINK_DETAILS_ERROR;
}

export interface PutTourDetails {
  type: typeof PUT_TOUR_DETAILS;
}
export interface PutTourDetailsSuccess {
  type: typeof PUT_TOUR_DETAILS_SUCCESS;
  payload: {};
}
export interface PutTourDetailsError {
  type: typeof PUT_TOUR_DETAILS_ERROR;
}
export interface GetTourTHREESIXTY {
  type: typeof GET_TOUR_THREE_SIXTY;
}
export interface GetTourTHREESIXTYSuccess {
  type: typeof GET_TOUR_THREE_SIXTY_SUCCESS;
  payload: {};
}
export interface GetTourTHREESIXTYError {
  type: typeof GET_TOUR_THREE_SIXTY_ERROR;
}

export interface PostUpdateClientNotes {
  type: typeof PUT_CLIENT_NOTES;
}
export interface PostUpdateClientNotesSuccess {
  type: typeof PUT_CLIENT_NOTES_SUCCESS;
  payload: {};
}
export interface PostUpdateClientNotesError {
  type: typeof PUT_CLIENT_NOTES_ERROR;
}

export interface PostTicketActions {
  type: typeof POST_TICKET_ACTION;
}
export interface PostTicketActionsSuccess {
  type: typeof POST_TICKET_ACTION_SUCCESS;
  payload: {};
}
export interface PostTicketActionsError {
  type: typeof POST_TICKET_ACTION_ERROR;
}

export interface GetVirtualTool {
  type: typeof GET_VIRTUAL_TOOL;
}
export interface GetVirtualToolSuccess {
  type: typeof GET_VIRTUAL_TOOL_SUCCESS;
  payload: {};
}
export interface GetVirtualToolError {
  type: typeof GET_VIRTUAL_TOOL_ERROR;
}

export interface GetClosedTicketsList {
  type: typeof GET_CLOSED_TICKETS;
}
export interface GetClosedTicketsListSuccess {
  type: typeof GET_CLOSED_TICKETS_SUCCESS;
  payload: {};
}
export interface GetClosedTicketsListError {
  type: typeof GET_CLOSED_TICKETS_ERROR;
}

export interface GetopenTickets {
  type: typeof GET_OPEN_TICKETS;
}
export interface GetopenTicketsSuccess {
  type: typeof GET_OPEN_TICKETS_SUCCESS;
  payload: {};
}
export interface GetopenTicketsError {
  type: typeof GET_OPEN_TICKETS_ERROR;
}

export interface GetPayDetails {
  type: typeof GET_PAY_DETAILS;
}
export interface GetPayDetailsSuccess {
  type: typeof GET_PAY_DETAILS_SUCCESS;
  payload: {};
}
export interface GetPayDetailsError {
  type: typeof GET_PAY_DETAILS_ERROR;
}

export interface GetBackendCompany {
  type: typeof GET_BACKEND_COMPANY_DETAILS;
}
export interface GetBackendCompanySuccess {
  type: typeof GET_BACKEND_COMPANY_DETAILS_SUCCESS;
  payload: {};
}
export interface GetBackendCompanyError {
  type: typeof GET_BACKEND_COMPANY_DETAILS_ERROR;
}

export interface GetProfileDetails {
  type: typeof GET_PROFILE_DETAILS;
}
export interface GetProfileDetailsSuccess {
  type: typeof GET_PROFILE_DETAILS_SUCCESS;
  payload: {};
}
export interface GetProfileDetailsError {
  type: typeof GET_PROFILE_DETAILS_ERROR;
}

export interface PostApproveAssetGroup {
  type: typeof POST_APPROVE_ASSET_GROUP;
  payload:any,
}
export interface PostApproveAssetGroupSuccess {
  type: typeof POST_APPROVE_ASSET_GROUP_SUCCESS;
  payload: {};
}
export interface PostApproveAssetGroupError {
  type: typeof POST_APPROVE_ASSET_GROUP_ERROR;
}

export interface PostOrderActions {
  type: typeof POST_ORDER_ACTIONS;
}
export interface PostOrderActionsSuccess {
  type: typeof POST_ORDER_ACTIONS_SUCCESS;
  payload: {};
}
export interface PostOrderActionsError {
  type: typeof POST_ORDER_ACTIONS_ERROR;
}

export interface GetDateRange {
  type: typeof GET_DATE_RANGE;
}
export interface GetDateRangeSuccess {
  type: typeof GET_DATE_RANGE_SUCCESS;
  payload: {};
}
export interface GetDateRangeError {
  type: typeof GET_DATE_RANGE_ERROR;
}

export interface GetRenewTask {
  type: typeof GET_RENEW_TASK;
}
export interface GetRenewTaskSuccess {
  type: typeof GET_RENEW_TASK_SUCCESS;
  payload: {};
}
export interface GetRenewTaskError {
  type: typeof GET_RENEW_TASK_ERROR;
}

export interface GetCompanyStats {
  type: typeof GET_COMPANY_STATS;
}
export interface GetCompanyStatsSuccess {
  type: typeof GET_COMPANY_STATS_SUCCESS;
  payload: {};
}
export interface GetCompanyStatsError {
  type: typeof GET_COMPANY_STATS_ERROR;
}

export interface PostManagementReport {
  type: typeof POST_MANAGEMENT_REPORTS;
}
export interface PostManagementReportSuccess {
  type: typeof POST_MANAGEMENT_REPORTS_SUCCESS;
  payload: {};
}
export interface PostManagementReportError {
  type: typeof POST_MANAGEMENT_REPORTS_ERROR;
}

export interface PostManagementReport {
  type: typeof POST_MANAGEMENT_REPORTS;
}
export interface PostManagementReportSuccess {
  type: typeof POST_MANAGEMENT_REPORTS_SUCCESS;
  payload: {};
}
export interface PostManagementReportError {
  type: typeof POST_MANAGEMENT_REPORTS_ERROR;
}

export interface PostReferalReport {
  type: typeof POST_REFERRAL_REPORT;
}
export interface PostReferalReportSuccess {
  type: typeof POST_REFERRAL_REPORT_SUCCESS;
  payload: {};
}
export interface PostReferalReportError {
  type: typeof POST_REFERRAL_REPORT_ERROR;
}

export interface PostCountryResult {
  type: typeof POST_COUNTRY_REPORTS;
}
export interface PostCountryResultSuccess {
  type: typeof POST_COUNTRY_REPORTS_SUCCESS;
  payload: {};
}
export interface PostCountryResultError {
  type: typeof POST_COUNTRY_REPORTS_ERROR;
}

export interface PostCompanyDetails {
  type: typeof POST_COMPANY_DETAILS;
}
export interface PostCompanyDetailsSuccess {
  type: typeof POST_COMPANY_DETAILS_SUCCESS;
  payload: {};
}
export interface PostCompanyDetailsError {
  type: typeof POST_COMPANY_DETAILS_ERROR;
}

export interface PostBackendReports {
  type: typeof POST_ALL_REPORTS;
}
export interface PostBackendReportsSuccess {
  type: typeof POST_ALL_REPORTS_SUCCESS;
  payload: {};
}
export interface PostBackendReportsError {
  type: typeof POST_ALL_REPORTS_ERROR;
}

export interface PutOrderCompletion {
  type: typeof PUT_ORDER_COMPLETION;
}
export interface PutOrderCompletionSuccess {
  type: typeof PUT_ORDER_COMPLETION_SUCCESS;
  payload: {};
}
export interface PutOrderCompletionError {
  type: typeof PUT_ORDER_COMPLETION_ERROR;
}

export interface PostPendingAsset {
  type: typeof POST_PENDING_ASSET;
}
export interface PostPendingAssetSuccess {
  type: typeof POST_PENDING_ASSET_SUCCESS;
  payload: {};
}
export interface PostPendingAssetError {
  type: typeof POST_PENDING_ASSET_ERROR;
}

export interface GetWebLink {
  type: typeof GET_ORDER_WEBLINK;
}
export interface GetWebLinkSuccess {
  type: typeof GET_ORDER_WEBLINK_SUCCESS;
  payload: {};
}
export interface GetWebLinkError {
  type: typeof GET_ORDER_WEBLINK_ERROR;
}
//
export interface PostNewPaymentLink {
  type: typeof POST_NEW_PAYMENTLINK;
}
export interface PostNewPaymentLinkSuccess {
  type: typeof POST_NEW_PAYMENTLINK_SUCCESS;
  payload: {};
}
export interface PostNewPaymentLinkError {
  type: typeof POST_NEW_PAYMENTLINK_ERROR;
}

export interface GetPaymentList {
  type: typeof GET_PAYMENT_LIST;
}
export interface GetPaymentListSuccess {
  type: typeof GET_PAYMENT_LIST_SUCCESS;
  payload: {};
}
export interface GetPaymentListError {
  type: typeof GET_PAYMENT_LIST_ERROR;
}

export interface GetCalendarMonth {
  type: typeof GET_CALENDAR_MONTH;
}
export interface GetCalendarMonthSuccess {
  type: typeof GET_CALENDAR_MONTH_SUCCESS;
  payload: {};
}
export interface GetCalendarMonthError {
  type: typeof GET_CALENDAR_MONTH_ERROR;
}

export interface GetDailyAvailability {
  type: typeof GET_DAILY_AVAILABILITY;
}
export interface GetDailyAvailabilitySuccess {
  type: typeof GET_DAILY_AVAILABILITY_SUCCESS;
  payload: {};
}
export interface GetDailyAvailabilityError {
  type: typeof GET_DAILY_AVAILABILITY_ERROR;
}

export interface GetWeeklyAvailability {
  type: typeof GET_WEEKLY_AVAILABILITY;
}
export interface GetWeeklyAvailabilitySuccess {
  type: typeof GET_WEEKLY_AVAILABILITY_SUCCESS;
  payload: {};
}
export interface GetWeeklyAvailabilityError {
  type: typeof GET_WEEKLY_AVAILABILITY_ERROR;
}

export interface GetMonthlyAvailability {
  type: typeof GET_MONTHLY_AVAILABILITY;
}
export interface GetMonthlyAvailabilitySuccess {
  type: typeof GET_MONTHLY_AVAILABILITY_SUCCESS;
  payload: {};
}
export interface GetMonthlyAvailabilityError {
  type: typeof GET_MONTHLY_AVAILABILITY_ERROR;
}
export interface GetPaymentDetails {
  type: typeof GET_PAYMENT_DETAILS;
}
export interface GetPaymentDetailsSuccess {
  type: typeof GET_PAYMENT_DETAILS_SUCCESS;
  payload: {};
}
export interface GetPaymentDetailsError {
  type: typeof GET_PAYMENT_DETAILS_ERROR;
}

export interface GetMendateDetails {
  type: typeof GET_MENDATE_DETAILS;
}
export interface GetMendateDetailsSuccess {
  type: typeof GET_MENDATE_DETAILS_SUCCESS;
  payload: {};
}
export interface GetMendateDetailsError {
  type: typeof GET_MENDATE_DETAILS_ERROR;
}

export interface PostArchiveDetail {
  type: typeof POST_ARCHIVE_MOVIE;
}
export interface PostArchiveDetailSuccess {
  type: typeof POST_ARCHIVE_MOVIE_SUCCESS;
  payload: {};
}
export interface PostArchiveDetailError {
  type: typeof POST_ARCHIVE_MOVIE_ERROR;
}

export interface PostRenewMovie {
  type: typeof POST_RESTORE_MOVIE;
}
export interface PostRenewMovieSuccess {
  type: typeof POST_RESTORE_MOVIE_SUCCESS;
  payload: {};
}
export interface PostRenewMovieError {
  type: typeof POST_RESTORE_MOVIE_ERROR;
}

export interface PostNewMovie {
  type: typeof POST_NEW_MOVIE;
}
export interface PostNewMovieSuccess {
  type: typeof POST_NEW_MOVIE_SUCCESS;
  payload: {};
}
export interface PostNewMovieError {
  type: typeof POST_NEW_MOVIE_ERROR;
}

export interface PostMarkPaidOrDue {
  type: typeof POST_MARK_PAID_OR_DUE;
}
export interface PostMarkPaidOrDueSuccess {
  type: typeof POST_MARK_PAID_OR_DUE_SUCCESS;
  payload: {};
}
export interface PostMarkPaidOrDueError {
  type: typeof POST_MARK_PAID_OR_DUE_ERROR;
}

export interface GetAnalyticsDetails {
  type: typeof GET_ANALYTICS_DETAILS;
}
export interface GetAnalyticsDetailsSuccess {
  type: typeof GET_ANALYTICS_DETAILS_SUCCESS;
  payload: {};
}
export interface GetAnalyticsDetailsError {
  type: typeof GET_ANALYTICS_DETAILS_ERROR;
}

export interface GetCompanyTranscode {
  type: typeof GET_TRANSCODE_MOVIE;
}
export interface GetCompanyTranscodeSuccess {
  type: typeof GET_TRANSCODE_MOVIE_SUCCESS;
  payload: {};
}
export interface GetCompanyTranscodeError {
  type: typeof GET_TRANSCODE_MOVIE_ERROR;
}

export interface PostCompanySignup {
  type: typeof POST_COMPANY_SIGNUP;
}
export interface PostCompanySignupSuccess {
  type: typeof POST_COMPANY_SIGNUP_SUCCESS;
  payload: {};
}
export interface PostCompanySignupError {
  type: typeof POST_COMPANY_SIGNUP_ERROR;
}

export interface GetRedirectDetails {
  type: typeof GET_REDIRECT_FLOW_DETAILS;
}
export interface GetRedirectDetailsSuccess {
  type: typeof GET_REDIRECT_FLOW_DETAILS_SUCCESS;
  payload: {};
}
export interface GetRedirectDetailsError {
  type: typeof GET_REDIRECT_FLOW_DETAILS_ERROR;
}

export interface GetCalenderMonthView {
  type: typeof GET_CALENDER_MONTH_VIEW;
}
export interface GetCalenderMonthViewSuccess {
  type: typeof GET_CALENDER_MONTH_VIEW_SUCCESS;
  payload: {};
}
export interface GetCalenderMonthViewError {
  type: typeof GET_CALENDER_MONTH_VIEW_ERROR;
}

export interface GetCalenderMonthViewDay {
  type: typeof GET_CALENDER_MONTH_VIEW_DAY;
}
export interface GetCalenderMonthViewDaySuccess {
  type: typeof GET_CALENDER_MONTH_VIEW_DAY_SUCCESS;
  payload: {};
}
export interface GetCalenderMonthViewDayError {
  type: typeof GET_CALENDER_MONTH_VIEW_DAY_ERROR;
}

export interface GetCalenderMonthViewAvail {
  type: typeof GET_CALENDER_MONTH_VIEW_AVAIL;
}
export interface GetCalenderMonthViewAvailSuccess {
  type: typeof GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS;
  payload: {};
}
export interface GetCalenderMonthViewAvailError {
  type: typeof GET_CALENDER_MONTH_VIEW_AVAIL_ERROR;
}

export interface GetCalenderDayView {
  type: typeof GET_CALENDER_DAY_VIEW;
}
export interface GetCalenderDayViewSuccess {
  type: typeof GET_CALENDER_DAY_VIEW_SUCCESS;
  payload: {};
}
export interface GetCalenderDayViewError {
  type: typeof GET_CALENDER_DAY_VIEW_ERROR;
}

export interface GetFailedDdDetails {
  type: typeof GET_FAILED_DD_DETAILS;
}
export interface GetFailedDdDetailsSuccess {
  type: typeof GET_FAILED_DD_DETAILS_SUCCESS;
  payload: {};
}
export interface GetFailedDdDetailsError {
  type: typeof GET_FAILED_DD_DETAILS_ERROR;
}

export interface GetCardlessPayment {
  type: typeof GET_CARDLESS_PAYMENT;
}
export interface GetCardlessPaymentSuccess {
  type: typeof GET_CARDLESS_PAYMENT_SUCCESS;
  payload: [];
}
export interface GetCardlessPaymentError {
  type: typeof GET_CARDLESS_PAYMENT_ERROR;
}

export interface GetRedirectFlows {
  type: typeof GET_REDIRECT_FLOWS;
}
export interface GetRedirectFlowsSuccess {
  type: typeof GET_REDIRECT_FLOWS_SUCCESS;
  payload: [];
}
export interface GetRedirectFlowsError {
  type: typeof GET_REDIRECT_FLOWS_ERROR;
}

export interface GetMandateList {
  type: typeof GET_MANDATE_LIST;
}
export interface GetMandateListSuccess {
  type: typeof GET_MANDATE_LIST_SUCCESS;
  payload: [];
}
export interface GetMandateListError {
  type: typeof GET_MANDATE_LIST_ERROR;
}

export interface GetFailedDD {
  type: typeof GET_FAILED_DD;
}
export interface GetFailedDDSuccess {
  type: typeof GET_FAILED_DD_SUCCESS;
  payload: [];
}
export interface GetFailedDDError {
  type: typeof GET_FAILED_DD_ERROR;
}

export interface GetCardlessEventDetails {
  type: typeof GET_CARDLESS_EVENT_DETAILS;
}
export interface GetCardlessEventDetailsSuccess {
  type: typeof GET_CARDLESS_EVENT_DETAILS_SUCCESS;
  payload: [];
}
export interface GetCardlessEventDetailsError {
  type: typeof GET_CARDLESS_EVENT_DETAILS_ERROR;
}

export interface GetCardlessEvent {
  type: typeof GET_CARDLESS_EVENT_LIST;
}
export interface GetCardlessEventSuccess {
  type: typeof GET_CARDLESS_EVENT_LIST_SUCCESS;
  payload: [];
}
export interface GetCardlessEventError {
  type: typeof GET_CARDLESS_EVENT_LIST_ERROR;
}


export interface GetMovieDetails {
  type: typeof GET_COMPANY_DETAIL;
}
export interface GetMovieDetailsSuccess {
  type: typeof GET_COMPANY_DETAIL_SUCCESS;
  payload: [];
}
export interface GetMovieDetailsError {
  type: typeof GET_COMPANY_DETAIL_ERROR;
}

export interface DownloadStripeCSV {
  type: typeof DOWNLOAD_STRIPE_CSV;
}
export interface DownloadStripeCSVSuccess {
  type: typeof DOWNLOAD_STRIPE_CSV_SUCCESS;
  payload: [];
}
export interface DownloadStripeCSVError {
  type: typeof DOWNLOAD_STRIPE_CSV_ERROR;
}
export interface GetBackendStripe {
  type: typeof GET_BACKEND_STRIPE;
}
export interface GetBackendStripeSuccess {
  type: typeof GET_BACKEND_STRIPE_SUCCESS;
  payload: [];
}
export interface GetBackendStripeError {
  type: typeof GET_BACKEND_STRIPE_ERROR;
}

export interface GetOrderStats {
  type: typeof GET_ORDER_STATS;
}
export interface GetOrderStatsSuccess {
  type: typeof GET_ORDER_STATS_SUCCESS;
  payload: [];
}
export interface GetOrderStatsError {
  type: typeof GET_ORDER_STATS_ERROR;
}

export interface GetBackendStats {
  type: typeof GET_BACKEND_COMPANY_STATS;
}
export interface GetBackendStatsSuccess {
  type: typeof GET_BACKEND_COMPANY_STATS_SUCCESS;
  payload: [];
}
export interface GetBackendStatsError {
  type: typeof GET_BACKEND_COMPANY_STATS_ERROR;
}

export interface GetBackendCountryStats {
  type: typeof GET_BACKEND_COUNTRY_STATS;
}
export interface GetBackendCountryStatsSuccess {
  type: typeof GET_BACKEND_COUNTRY_STATS_SUCCESS;
  payload: [];
}
export interface GetBackendCountryStatsError {
  type: typeof GET_BACKEND_COUNTRY_STATS_ERROR;
}

export interface GetRegionalStats {
  type: typeof GET_REGIONAL_STATS;
}
export interface GetRegionalStatsSuccess {
  type: typeof GET_REGIONAL_STATS_SUCCESS;
  payload: [];
}
export interface GetRegionalStatsError {
  type: typeof GET_REGIONAL_STATS_ERROR;
}

export interface PutMarkPaid {
  type: typeof PUT_MARK_PAID;
}
export interface PutMarkPaidSuccess {
  type: typeof PUT_MARK_PAID_SUCCESS;
  payload: [];
}
export interface PutMarkPaidError {
  type: typeof PUT_MARK_PAID_ERROR;
}

export interface PutMovieDetail {
  type: typeof PUT_MOVIE_DETAIL;
}
export interface PutMovieDetailSuccess {
  type: typeof PUT_MOVIE_DETAIL_SUCCESS;
  payload: [];
}
export interface PutMovieDetailError {
  type: typeof PUT_MOVIE_DETAIL_ERROR;
}

export interface GetCreditorCSV {
  type: typeof GET_CREDITOR_CSV;
}
export interface GetCreditorCSVSuccess {
  type: typeof GET_CREDITOR_CSV_SUCCESS;
  payload: [];
}
export interface GetCreditorCSVError {
  type: typeof GET_CREDITOR_CSV_ERROR;
}

export interface GetMatterPortDetails {
  type: typeof GET_MATTERPORT_DETAILS;
}
export interface GetMatterPortDetailsSuccess {
  type: typeof GET_MATTERPORT_DETAILS_SUCCESS;
  payload: [];
}
export interface GetMatterPortDetailsError {
  type: typeof GET_MATTERPORT_DETAILS_ERROR;
}

export interface GetBackendActiveOrders {
  type: typeof GET_ACTIVE_ORDER;
}
export interface GetBackendActiveOrdersSuccess {
  type: typeof GET_ACTIVE_ORDER_SUCCESS;
  payload: [];
}
export interface GetBackendActiveOrdersError {
  type: typeof GET_ACTIVE_ORDER_ERROR;
}

export interface GetDebtorCSV {
  type: typeof GET_DEBTOR_CSV;
}
export interface GetDebtorCSVSuccess {
  type: typeof GET_DEBTOR_CSV_SUCCESS;
  payload: [];
}
export interface GetDebtorCSVError {
  type: typeof GET_DEBTOR_CSV_ERROR;
}

export interface GetCreditorsDetails {
  type: typeof GET_CREDITOR_DETAILS;
}
export interface GetCreditorsDetailsSuccess {
  type: typeof GET_CREDITOR_DETAILS_SUCCESS;
  payload: [];
}
export interface GetCreditorsDetailsError {
  type: typeof GET_CREDITOR_DETAILS_ERROR;
}

export interface GetCreditorsList {
  type: typeof GET_CREDITOR_LIST;
}
export interface GetCreditorsListSuccess {
  type: typeof GET_CREDITOR_LIST_SUCCESS;
  payload: [];
}
export interface GetCreditorsListError {
  type: typeof GET_CREDITOR_LIST_ERROR;
}

export interface GetDebtorsDetails {
  type: typeof GET_DEBTOR_DETAILS;
}
export interface GetDebtorsDetailsSuccess {
  type: typeof GET_DEBTOR_DETAILS_SUCCESS;
  payload: [];
}
export interface GetDebtorsDetailsError {
  type: typeof GET_DEBTOR_DETAILS_ERROR;
}

export interface GetDebtorList {
  type: typeof GET_DEBTOR_LIST;
}
export interface GetDebtorListSuccess {
  type: typeof GET_DEBTOR_LIST_SUCCESS;
  payload: [];
}
export interface GetDebtorListError {
  type: typeof GET_DEBTOR_LIST_ERROR;
}
export interface GetAllUser {
  type: typeof GET_ALL_USER;
}
export interface GetAllUserSuccess {
  type: typeof GET_ALL_USER_SUCCESS;
  payload: [];
}
export interface GetAllUserError {
  type: typeof GET_ALL_USER_ERROR;
}

export interface PostZipfileAssetGroup {
  type: typeof POST_ZIP_ASSET_GROUP;
}
export interface PostZipfileAssetGroupSuccess {
  type: typeof POST_ZIP_ASSET_GROUP_SUCCESS;
  payload: [];
}
export interface PostZipfileAssetGroupError {
  type: typeof POST_ZIP_ASSET_GROUP_ERROR;
}

export interface PostSetApproval {
  type: typeof DELETE_ASSET_GROUP;
}
export interface PostSetApprovalSuccess {
  type: typeof DELETE_ASSET_GROUP_SUCCESS;
  payload: [];
}
export interface PostSetApprovalError {
  type: typeof DELETE_ASSET_GROUP_ERROR;
}

export interface DeleteAssetGroup {
  type: typeof DELETE_ASSET_GROUP;
}
export interface DeleteAssetGroupSuccess {
  type: typeof DELETE_ASSET_GROUP_SUCCESS;
  payload: [];
}
export interface DeleteAssetGroupError {
  type: typeof DELETE_ASSET_GROUP_ERROR;
}

export interface PostRegenrateAssetGroup {
  type: typeof POST_REGENERATE_ASSET_GROUP;
}
export interface PostRegenrateAssetGroupSuccess {
  type: typeof POST_REGENERATE_ASSET_GROUP_SUCCESS;
  payload: [];
}
export interface PostRegenrateAssetGroupError {
  type: typeof POST_REGENERATE_ASSET_GROUP_ERROR;
}

export interface GetBeeInvoiceDetails {
  type: typeof GET_BEE_INVOICE_DETAILS;
}
export interface GetBeeInvoiceDetailsSuccess {
  type: typeof GET_BEE_INVOICE_DETAILS_SUCCESS;
  payload: [];
}
export interface GetBeeInvoiceDetailsError {
  type: typeof GET_BEE_INVOICE_DETAILS_ERROR;
}

export interface GetOrderStatsDetails {
  type: typeof GET_ORDER_STATS_DETAILS;
}
export interface GetOrderStatsDetailsSuccess {
  type: typeof GET_ORDER_STATS_DETAILS_SUCCESS;
  payload: [];
}
export interface GetOrderStatsDetailsError {
  type: typeof GET_ORDER_STATS_DETAILS_ERROR;
}

export interface GetGroupManualBees {
  type: typeof GET_GROUP_MANUAL_BEES;
}
export interface GetGroupManualBeesSuccess {
  type: typeof GET_GROUP_MANUAL_BEES_SUCCESS;
  payload: [];
}
export interface GetGroupManualBeesError {
  type: typeof GET_GROUP_MANUAL_BEES_ERROR;
} 
export interface PostAssetApproval {
  type: typeof POST_ASSET_APPROVAL;
}
export interface PostAssetApprovalSuccess {
  type: typeof POST_ASSET_APPROVAL_SUCCESS;
  payload: {};
}
export interface PostAssetApprovalError {
  type: typeof POST_ASSET_APPROVAL_ERROR;
}

export interface PostNewTicket {
  type: typeof PUT_BULK_REQUEST;
}
export interface PostNewTicketSuccess {
  type: typeof PUT_BULK_REQUEST_SUCCESS;
  payload: {};
}
export interface PostNewTicketError {
  type: typeof PUT_BULK_REQUEST_ERROR;
} 

export interface PostBulkUpload {
  type: typeof PUT_BULK_REQUEST;
}
export interface PostBulkUploadSuccess {
  type: typeof PUT_BULK_REQUEST_SUCCESS;
  payload: {};
}
export interface PostBulkUploadError {
  type: typeof PUT_BULK_REQUEST_ERROR;
} //
export interface GetOrderAssetDetails {
  type: typeof GET_ORDER_ASSET;
}
export interface GetOrderAssetDetailsSuccess {
  type: typeof GET_ORDER_ASSET_SUCCESS;
  payload: {};
}
export interface GetOrderAssetDetailsError {
  type: typeof GET_ORDER_ASSET_ERROR;
}

export interface GetHostingPeriod {
  type: typeof GET_HOSTING_PERIOD;
}
export interface GetHostingPeriodSuccess {
  type: typeof GET_HOSTING_PERIOD_SUCCESS;
  payload: {};
}
export interface GetHostingPeriodError {
  type: typeof GET_HOSTING_PERIOD_ERROR;
}

export interface PostBeeSpecialCharge {
  type: typeof POST_BEE_SPECIAL_CHARGE;
}
export interface PostBeeSpecialChargeSuccess {
  type: typeof POST_BEE_SPECIAL_CHARGE_SUCCESS;
  payload: {};
}
export interface PostBeeSpecialChargeError {
  type: typeof POST_BEE_SPECIAL_CHARGE_ERROR;
}

export interface PostSpecialCharge {
  type: typeof POST_SPECIAL_CHARGE;
}
export interface PostSpecialChargeSuccess {
  type: typeof POST_SPECIAL_CHARGE_SUCCESS;
  payload: {};
}
export interface PostSpecialChargeError {
  type: typeof POST_SPECIAL_CHARGE_ERROR;
}
export interface GetPromocodeCompanyList {
  type: typeof GET_PROMO_CODE_COMPANY;
}
export interface GetPromocodeCompanyListSuccess {
  type: typeof GET_PROMO_CODE_COMPANY_SUCCESS;
  payload: {};
}
export interface GetPromocodeCompanyListError {
  type: typeof GET_PROMO_CODE_COMPANY_ERROR;
}
export interface GetPromocodeUserList {
  type: typeof GET_PROMOCODE_USER_LIST;
}
export interface GetPromocodeUserListSuccess {
  type: typeof GET_PROMOCODE_USER_LIST_SUCCESS;
  payload: {};
}
export interface GetPromocodeUserListError {
  type: typeof GET_PROMOCODE_USER_LIST_ERROR;
} 
export interface GetOverviewDetails {
  type: typeof GET_OVERVIEW_DETAILS;
}
export interface GetOverviewDetailsSuccess {
  type: typeof GET_OVERVIEW_DETAILS_SUCCESS;
  payload: {};
}
export interface GetOverviewDetailsError {
  type: typeof GET_OVERVIEW_DETAILS_ERROR;
} 
export interface PostNewCreditRequest {
  type: typeof POST_CREDIT_REQUEST;
}
export interface PostNewCreditRequestSuccess {
  type: typeof POST_CREDIT_REQUEST_SUCCESS;
  payload: {};
}
export interface PostNewCreditRequestError {
  type: typeof POST_CREDIT_REQUEST_ERROR;
} 
export interface PutContactAndLocation {
  type: typeof PUT_CONTACT_AND_LOCATION;
}
export interface PutContactAndLocationSuccess {
  type: typeof PUT_CONTACT_AND_LOCATION_SUCCESS;
  payload: {};
}
export interface PutContactAndLocationError {
  type: typeof PUT_CONTACT_AND_LOCATION_ERROR;
}
export interface DownloadInvoiceCSV {
  type: typeof DOWNLOAD_INVOICE_CSV;
}
export interface DownloadInvoiceCSVSuccess {
  type: typeof DOWNLOAD_INVOICE_CSV_SUCCESS;
  payload: {};
}
export interface DownloadInvoiceCSVError {
  type: typeof DOWNLOAD_INVOICE_CSV_ERROR;
} 
export interface GetFilterBeeInvoices {
  type: typeof GET_BEE_FILTER_INVOICE;
}
export interface GetFilterBeeInvoicesSuccess {
  type: typeof GET_BEE_FILTER_INVOICE_SUCCESS;
  payload: {};
}
export interface GetFilterBeeInvoicesError {
  type: typeof GET_BEE_FILTER_INVOICE_ERROR;
}
export interface GetCompanyPromoCode {
  type: typeof GET_COMPANY_PROMOCODE_LIST;
}
export interface GetCompanyPromoCodeSuccess {
  type: typeof GET_COMPANY_PROMOCODE_LIST_SUCCESS;
  payload: {};
}
export interface GetCompanyPromoCodeError {
  type: typeof GET_COMPANY_PROMOCODE_LIST_ERROR;
}
export interface GetTaskDetails {
  type: typeof GET_TASK_DETAILS;
}
export interface GetTaskDetailsSuccess {
  type: typeof GET_TASK_DETAILS_SUCCESS;
  payload: {};
}
export interface GetTaskDetailsError {
  type: typeof GET_TASK_DETAILS_ERROR;
}

export interface PostBeeNotes {
  type: typeof DELETE_ORDER;
}
export interface PostBeeNotesSuccess {
  type: typeof DELETE_ORDER_SUCCESS;
  payload: {};
}
export interface PostBeeNotesError {
  type: typeof DELETE_ORDER_ERROR;
}

export interface DeleteBackendCompanyDetails {
  type: typeof DELETE_BACKEND_COMPANY_DETAILS;
}
export interface DeleteBackendCompanyDetailsSuccess {
  type: typeof DELETE_BACKEND_COMPANY_DETAILS_SUCCESS;
  payload: {};
}
export interface DeleteBackendCompanyDetailsError {
  type: typeof DELETE_BACKEND_COMPANY_DETAILS_ERROR;
}

export interface DeleteUnavailableSlot {
  type: typeof DEL_UNAVAILABLE_SLOT;
}
export interface DeleteUnavailableSlotSuccess {
  type: typeof DEL_UNAVAILABLE_SLOT_SUCCESS;
  payload: {};
}
export interface DeleteUnavailableSlotError {
  type: typeof DEL_UNAVAILABLE_SLOT_ERROR;
}

export interface DeleteOrder {
  type: typeof DELETE_ORDER;
}
export interface DeleteOrderSuccess {
  type: typeof DELETE_ORDER_SUCCESS;
  payload: {};
}
export interface DeleteOrderError {
  type: typeof DELETE_ORDER_ERROR;
} 
export interface GetPromocodeList {
  type: typeof GET_PROMOCODE_LIST;
}
export interface GetPromocodeListSuccess {
  type: typeof GET_PROMOCODE_LIST_SUCCESS;
  payload: {};
}
export interface GetPromocodeListError {
  type: typeof GET_PROMOCODE_LIST_ERROR;
}

export interface GetBeeInvoiceList {
  type: typeof GET_BEE_INVOICE;
}
export interface GetBeeInvoiceListSuccess {
  type: typeof GET_BEE_INVOICE_SUCCESS;
  payload: [];
}
export interface GetBeeInvoiceListError {
  type: typeof GET_BEE_INVOICE_ERROR;
}

export interface DownloadBillingCSV {
  type: typeof DOWNLOAD_BILLING_CSV;
}
export interface DownloadBillingCSVSuccess {
  type: typeof DOWNLOAD_BILLING_CSV_SUCCESS;
  payload: [];
}
export interface DownloadBillingCSVError {
  type: typeof DOWNLOAD_BILLING_CSV_ERROR;
}

export interface GetFilterInvoice {
  type: typeof BILLING_FILTER_INVOICE;
}
export interface GetFilterInvoiceSuccess {
  type: typeof BILLING_FILTER_INVOICE_SUCCESS;
  payload: [];
}
export interface GetFilterInvoiceError {
  type: typeof BILLING_FILTER_INVOICE_ERROR;
}

export interface GetfilterTask {
  type: typeof GET_FILTER_TASK;
}
export interface GetfilterTaskSuccess {
  type: typeof GET_FILTER_TASK_SUCCESS;
  payload: [];
}
export interface GetfilterTaskError {
  type: typeof GET_FILTER_TASK_ERROR;
}

export interface GetInvoiceDetails {
  type: typeof GET_INVOICE_DETAILS;
}
export interface GetInvoiceDetailsSuccess {
  type: typeof GET_INVOICE_DETAILS_SUCCESS;
  payload: [];
}
export interface GetInvoiceDetailsError {
  type: typeof GET_INVOICE_DETAILS_ERROR;
}

export interface Getfilterorder {
  type: typeof GET_FILTER_ORDER;
}
export interface GetfilterorderSuccess {
  type: typeof GET_FILTER_ORDER_SUCCESS;
  payload: [];
}
export interface GetfilterorderError {
  type: typeof GET_FILTER_ORDER_ERROR;
}

export interface PostPromocode {
  type: typeof POST_PROMOCODE;
}
export interface PostPromocodeSuccess {
  type: typeof POST_PROMOCODE_SUCCESS;
}
export interface PostPromocodeError {
  type: typeof POST_PROMOCODE_ERROR;
}
export interface GetQuesstionnaire {
  type: typeof GET_QUESTIONNAIRE_REQUEST;
}
export interface GetQuesstionnaireSuccess {
  type: typeof GET_QUESTIONNAIRE_REQUEST_SUCCESS;
  payload: any;
}
export interface GetQuesstionnaireError {
  type: typeof GET_QUESTIONNAIRE_REQUEST_ERROR;
}

export interface GetCompanyDetails {
  type: typeof GET_COMPANY_DETAILS;
}
export interface GetCompanyDetailsSuccess {
  type: typeof GET_COMPANY_DETAILS_SUCCESS;
  payload: any;
}
export interface GetCompanyDetailsError {
  type: typeof GET_COMPANY_DETAILS_ERROR;
}

export interface GetDashboardRequest {
  type: typeof GET_DASHBOARD_TICKET_DETAIL;
}
export interface GetDashboardRequestSuccess {
  type: typeof GET_DASHBOARD_TICKET_DETAIL_SUCCESS;
  payload: any;
}
export interface GetDashboardRequestError {
  type: typeof GET_DASHBOARD_TICKET_DETAIL_ERROR;
}

export interface DeleteTicketRequest {
  type: typeof DELETE_ORDER_TICKET;
}
export interface DeleteTicketRequestSuccess {
  type: typeof DELETE_ORDER_TICKET_SUCCESS;
}
export interface DeleteTicketRequestError {
  type: typeof DELETE_ORDER_TICKET_ERROR;
}
export interface DeletOrderSpecialRequest {
  type: typeof DELETE_ORDER_SPECIAL_REQUEST;
}
export interface DeletOrderSpecialRequestSuccess {
  type: typeof DELETE_ORDER_SPECIAL_REQUEST_SUCCESS;
}
export interface DeletOrderSpecialRequestError {
  type: typeof DELETE_ORDER_SPECIAL_REQUEST_ERROR;
}

export interface PutSpecialRequest {
  type: typeof PUT_ORDER_SPECIAL_REQUEST;
}
export interface PutSpecialRequestSuccess {
  type: typeof PUT_ORDER_SPECIAL_REQUEST_SUCCESS;
  payload: any;
}
export interface PutSpecialRequestError {
  type: typeof PUT_ORDER_SPECIAL_REQUEST_ERROR;
}

export interface GetOrderSpecialRequest {
  type: typeof GET_ORDER_SPECIALREQUEST;
}
export interface GetOrderSpecialRequestSuccess {
  type: typeof GET_ORDER_SPECIALREQUEST_SUCCESS;
  payload: any;
}
export interface GetOrderSpecialRequestError {
  type: typeof GET_ORDER_SPECIALREQUEST_ERROR;
}

export interface GetBillingList {
  type: typeof GET_BILLING_REQUEST;
}
export interface GetBillingListSuccess {
  type: typeof GET_BILLING_REQUEST_SUCCESS;
  payload: any;
}
export interface GetBillingListError {
  type: typeof GET_BILLING_REQUEST_ERROR;
}

export interface GetOrderTicketList {
  type: typeof GET_ORDER_TICEKT_LIST;
}
export interface GetOrderTicketListSuccess {
  type: typeof GET_ORDER_TICEKT_LIST_SUCCESS;
  payload: any;
}
export interface GetOrderTicketListError {
  type: typeof GET_ORDER_TICEKT_LIST_ERROR;
}

export interface GetTaskList {
  type: typeof GET_TASK_LIST;
}
export interface GetTaskListSuccess {
  type: typeof GET_TASK_LIST_SUCCESS;
  payload: any;
}
export interface GetTaskListError {
  type: typeof GET_TASK_LIST_ERROR;
}

export interface PostShooterAvailability {
  type: typeof POST_SHOOTER_AVAILABILITY;
}
export interface PostShooterAvailabilitySuccess {
  type: typeof POST_SHOOTER_AVAILABILITY_SUCCESS;
  payload: any;
}
export interface PostShooterAvailabilityError {
  type: typeof POST_SHOOTER_AVAILABILITY_ERROR;
}

export interface PostAssetRequest {
  type: typeof POST_ASSET_REQUEST;
}
export interface PostAssetRequestSuccess {
  type: typeof POST_ASSET_REQUEST_SUCCESS;
  payload: any;
}
export interface PostAssetRequestError {
  type: typeof POST_ASSET_REQUEST_ERROR;
}

export interface GetCompanyList {
  type: typeof GET_COMPANY_LIST;
}
export interface GetCompanyListSuccess {
  type: typeof GET_COMPANY_LIST_SUCCESS;
  payload: any;
}
export interface GetCompanyListError {
  type: typeof GET_COMPANY_LIST_ERROR;
}

export interface GetCompanyListAll {
  type: typeof GET_COMPANY_LIST_ALL;
}
export interface GetCompanyListAllSuccess {
  type: typeof GET_COMPANY_LIST_ALL_SUCCESS;
  payload: any;
}
export interface GetCompanyListAllError {
  type: typeof GET_COMPANY_LIST_ALL_ERROR;
}

export interface GetTicketDetails {
  type: typeof GET_TICKET_DETAIL;
}
export interface GetTicketDetailsSuccess {
  type: typeof GET_TICKET_DETAIL_SUCCESS;
  payload: any;
}
export interface GetTicketDetailsError {
  type: typeof GET_TICKET_DETAIL_ERROR;
}

export interface GetTicketLists {
  type: typeof GET_TICKET_LIST;
}
export interface GetTicketListsSuccess {
  type: typeof GET_TICKET_LIST_SUCCESS;
  payload: any;
}
export interface GetTicketListsError {
  type: typeof GET_TICKET_LIST_ERROR;
}

export interface GetManualBees {
  type: typeof GET_MANUAL_BEES;
}

export interface GetManualBeesSuccess {
  type: typeof GET_MANUAL_BEES_SUCCESS;
  payload: any;
}

export interface GetManualBeesError {
  type: typeof GET_MANUAL_BEES_ERROR;
}

export interface PostBestBees {
  type: typeof POST_BEST_BEES;
}

export interface PostBestBeesSuccess {
  type: typeof POST_BEST_BEES_SUCCESS;
  payload: any;
}

export interface PostBestBeesError {
  type: typeof POST_BEST_BEES_ERROR;
}

export interface PostSupportNotes {
  type: typeof SUPPORT_NOTES;
}

export interface PostSupportNotesSuccess {
  type: typeof SUPPORT_NOTES_SUCCESS;
  payload: any;
}

export interface PostSupportNotesError {
  type: typeof SUPPORT_NOTES_ERROR;
}

export interface PostShooterAllocation {
  type: typeof POST_SHOOTER_ALLOCATION;
}

export interface PostShooterAllocationSuccess {
  type: typeof POST_SHOOTER_ALLOCATION_SUCCESS;
  payload: any;
}

export interface PostShooterAllocationError {
  type: typeof POST_SHOOTER_ALLOCATION_ERROR;
}

export interface PostOrderRegenerate {
  type: typeof PUT_REGENERATE_ORDER;
}

export interface PostOrderRegenerateSuccess {
  type: typeof PUT_REGENERATE_ORDER_SUCCESS;
  payload: any;
}

export interface PostOrderRegenerateError {
  type: typeof PUT_REGENERATE_ORDER_SUCCESS;
}

export interface PostUpdatePackage {
  type: typeof UPDATE_PACKAGE_ADDON;
}

export interface PostUpdatePackageSuccess {
  type: typeof UPDATE_PACKAGE_ADDON_SUCCESS;
  payload: any;
}

export interface PostUpdatePackageError {
  type: typeof UPDATE_PACKAGE_ADDON_ERROR;
}

export interface PutCancelHosting {
  type: typeof PUT_CANCEL_HOISTING;
}

export interface PutCancelHostingSuccess {
  type: typeof PUT_CANCEL_HOISTING_SUCCESS;
  payload: any;
}

export interface PutCancelHostingError {
  type: typeof PUT_CANCEL_HOISTING_ERROR;
}

export interface PutOrderRenew {
  type: typeof PUT_ORDER_RENEW_REQUEST;
}

export interface PutOrderRenewSuccess {
  type: typeof PUT_ORDER_RENEW_REQUEST_SUCCESS;
  payload: any;
}

export interface PutOrderRenewError {
  type: typeof PUT_ORDER_RENEW_REQUEST_ERROR;
}

export interface GetActionButton {
  type: typeof GET_ORDER_BUTTONS;
}

export interface GetActionButtonSuccess {
  type: typeof GET_ORDER_BUTTONS_SUCCESS;
  payload: any;
}

export interface GetActionButtonError {
  type: typeof GET_ORDER_BUTTONS_ERROR;
}

export interface GetSageCsvDownload {
  type: typeof GET_SAGE_CSV_DOWNLOAD;
}

export interface GetSageCsvDownloadSuccess {
  type: typeof GET_SAGE_CSV_DOWNLOAD_SUCCESS;
  payload: any;
}

export interface GetSageCsvDownloadError {
  type: typeof GET_SAGE_CSV_DOWNLOAD_ERROR;
}

export interface PutAmmendInvoice {
  type: typeof POST_INVOICE_AMMEND;
}

export interface PutAmmendInvoiceSuccess {
  type: typeof POST_INVOICE_AMMEND_SUCCESS;
  payload: any;
}

export interface PutAmmendInvoiceError {
  type: typeof POST_INVOICE_AMMEND_ERROR;
}

export interface PostUnavailableSlot {
  type: typeof POST_UNAVAILABLE_SLOT;
}

export interface PostUnavailableSlotSuccess {
  type: typeof POST_UNAVAILABLE_SLOT_SUCCESS;
  payload: any;
}

export interface PostUnavailableSlotError {
  type: typeof POST_UNAVAILABLE_SLOT_ERROR;
}

export interface CancelOrder {
  type: typeof CANCEL_ORDER_REQUEST;
}

export interface CancelOrderSuccess {
  type: typeof CANCEL_ORDER_REQUEST_SUCCESS;
  payload: any;
}

export interface CancelOrderError {
  type: typeof CANCEL_ORDER_REQUEST_ERROR;
}

export interface ResumeRequest {
  type: typeof RESUME_ORDER_REQUEST;
}

export interface ResumeRequestSuccess {
  type: typeof RESUME_ORDER_REQUEST_SUCCESS;
  payload: any;
}

export interface ResumeRequestError {
  type: typeof RESUME_ORDER_REQUEST_ERROR;
}

export interface HoldRequest {
  type: typeof HOLD_ORDER_REQUEST;
}

export interface HoldRequestSuccess {
  type: typeof HOLD_ORDER_REQUEST_SUCCESS;
  payload: any;
}

export interface HoldRequestError {
  type: typeof HOLD_ORDER_REQUEST_ERROR;
}

export interface PutInvoiceBeeRate {
  type: typeof PUT_INVOICE_BEE_RATE_REQUEST;
}

export interface PutInvoiceBeeRateSuccess {
  type: typeof PUT_INVOICE_BEE_RATE_REQUEST_SUCCESS;
  payload: any;
}

export interface PutInvoiceBeeRateError {
  type: typeof PUT_INVOICE_BEE_RATE_REQUEST_ERROR;
}

export interface PutKeyPickup {
  type: typeof PUT_KEYPICKUP_REQUEST;
}

export interface PutKeyPickupSuccess {
  type: typeof PUT_KEYPICKUP_REQUEST_SUCCESS;
  payload: any;
}

export interface PutKeyPickupError {
  type: typeof PUT_KEYPICKUP_REQUEST_ERROR;
}

export interface PutRequestData {
  type: typeof PUT_ORDER_REQUEST;
}

export interface PutRequestDataSuccess {
  type: typeof PUT_ORDER_REQUEST_SUCCESS;
  payload: any;
}

export interface PutRequestDataError {
  type: typeof PUT_ORDER_REQUEST_ERROR;
}

export interface GePostRequestData {
  type: typeof POST_PROPERTY_REQUEST;
}

export interface GePostRequestDataSuccess {
  type: typeof POST_PROPERTY_REQUEST_SUCCESS;
  payload: any;
}

export interface GePostRequestDataError {
  type: typeof POST_PROPERTY_REQUEST_ERROR;
}
export interface GetrequestData {
  type: typeof GET_REQUEST_DROPDOWN;
}

export interface GetrequestDataSuccess {
  type: typeof GET_REQUEST_DROPDOWN_SUCCESS;
  payload: any;
}

export interface GetrequestDataError {
  type: typeof GET_REQUEST_DROPDOWN_ERROR;
}
export interface GetrequestDropdown {
  type: typeof GET_REQUEST_DROPDOWN_LIST;
}

export interface GetrequestDropdownSuccess {
  type: typeof GET_REQUEST_DROPDOWN_LIST_SUCCESS;
  payload: any;
}

export interface GetrequestDropdownError {
  type: typeof GET_REQUEST_DROPDOWN_LIST_ERROR;
}

export interface Putproperty {
  type: typeof PUT_PROPERTY_REQUEST;
}

export interface PutpropertySuccess {
  type: typeof PUT_PROPERTY_REQUEST_SUCCESS;
  payload: any;
}

export interface PutpropertyError {
  type: typeof PUT_PROPERTY_REQUEST_ERROR;
}

export interface PostAmendInvoice {
  type: typeof POST_AMEND_INVOICE;
}

export interface PostAmendInvoiceSuccess {
  type: typeof POST_AMEND_INVOICE_SUCCESS;
  payload: any;
}

export interface PostAmendInvoiceError {
  type: typeof POST_AMEND_INVOICE_ERROR;
}

export interface GetPropertyPriceModifier {
  type: typeof FETCH_PROPERTY_PRICE_MODIFIERS;
}

export interface GetPropertyPriceModifierSuccess {
  type: typeof FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS;
  payload: any;
}

export interface GetPropertyPriceModifierError {
  type: typeof FETCH_PROPERTY_PRICE_MODIFIERS_ERROR;
}
//
export interface GetPropertyStatus {
  type: typeof FETCH_PROPERTY_REQUEST;
}

export interface GetPropertyStatusSuccess {
  type: typeof FETCH_PROPERTY_REQUEST_SUCCESS;
  payload: any;
}

export interface GetPropertyStatusError {
  type: typeof FETCH_PROPERTY_REQUEST_ERROR;
}

//
export interface PutManagementOrder {
  type: typeof PUT_MANAGEMENT_ORDER_REQUEST;
}

export interface PutManagementOrderSuccess {
  type: typeof PUT_MANAGEMENT_ORDER_REQUEST_SUCCESS;
  payload: any;
}

export interface PutManagementOrderError {
  type: typeof PUT_MANAGEMENT_ORDER_REQUEST_ERROR;
}

//
export interface PutChecklistRequest {
  type: typeof PUT_CHECKLIST_REQUEST;
}

export interface PutChecklistRequestSuccess {
  type: typeof PUT_CHECKLIST_REQUEST_SUCCESS;
  payload: any;
}

export interface PutChecklistRequestError {
  type: typeof PUT_CHECKLIST_REQUEST_ERROR;
}

export interface GetProductAddons {
  type: typeof FETCH_PRODUCT_ADDON_DROPDOWN;
}

export interface GetProductAddonsSuccess {
  type: typeof FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS;
  payload: any;
}

export interface GetProductAddonsError {
  type: typeof FETCH_PRODUCT_ADDON_DROPDOWN_ERROR;
}
export interface GetProductArrayInformation {
  type: typeof FETCH_PRODUCT_DROPDOWN;
}

export interface GetProductArrayInformationSuccess {
  type: typeof FETCH_PRODUCT_DROPDOWN_SUCCESS;
  payload: any;
}

export interface GetProductArrayInformationError {
  type: typeof FETCH_PRODUCT_DROPDOWN_ERROR;
}

export interface GetPackageArrayInformation {
  type: typeof FETCH_PACKAGE_DROPDOWN;
}

export interface GetPackageArrayInformationSuccess {
  type: typeof FETCH_PACKAGE_DROPDOWN_SUCCESS;
  payload: any;
}

export interface GetPackageArrayInformationError {
  type: typeof FETCH_PACKAGE_DROPDOWN_ERROR;
}

export interface GetClientInformation {
  type: typeof FETCH_CLIENT_DROPDOWN_INFORMATION;
}

export interface GetClientInformationSuccess {
  type: typeof FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS;
  payload: any;
}

export interface GetClientInformationError {
  type: typeof FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR;
}
export interface GetManagementTable {
  type: typeof FETCH_MANAGEMENT_TABLE_DETAILS;
}

export interface GetManagementTableSuccess {
  type: typeof FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS;
  payload: any;
}

export interface GetManagementTableError {
  type: typeof FETCH_MANAGEMENT_TABLE_DETAILS_ERROR;
}
export interface GetMenuList {
  type: typeof FETCH_MENU_LIST;
}

export interface GetManagementDetail {
  type: typeof FETCH_MANAGEMENT_DETAILS;
}
export interface GetManagementDetailSuccess {
  type: typeof FETCH_MANAGEMENT_DETAILS_SUCCESS;
  payload: any;
}
export interface GetManagementDetailError {
  type: typeof FETCH_MANAGEMENT_DETAILS_ERROR;
}

//

export interface GetMenuListSuccess {
  type: typeof FETCH_MENULIST_SUCCESSFULL;
  payload: IMenuItems;
}

export interface GetMenuListError {
  type: typeof FETCH_MENULIST_FAILED;
  payload: any;
}

export interface GetOrderList {
  type: typeof FETCH_ORDER_TABLE_DETAILS;
}

export interface GetOrderListSuccess {
  type: typeof FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL;
  payload: any;
}

export interface GetOrderListError {
  type: typeof FETCH_ORDER_TABLE_DETAILS_FAILED;
  payload: any;
}

export interface GetOrderDetailsRequest {
  type: typeof FETCH_ORDER_DETAILS_REQUEST;
}
export interface GetOrderDetailsSuccess {
  type: typeof FETCH_ORDER_DETAILS_REQUEST_SUCCESS;
  payload: any;
}
export interface GetOrderDetailsFails {
  type: typeof FETCH_ORDER_DETAILS_REQUEST_ERROR;
  payload: any;
}

export interface GetInformationPage {
  type: typeof GET_INFORMATION_PAGE;
  payload: any;
}

export interface GetInformationPageSuccess {
  type: typeof GET_INFORMATION_PAGE_SUCCESS;
  payload: any;
}

export interface GetInformationPageError {
  type: typeof GET_INFORMATION_PAGE_ERROR;
  payload: any;
}

export interface GetClientList {
  type: typeof GET_CLIENT_LIST;
  payload: any;
}

export interface GetClientListSuccess {
  type: typeof GET_CLIENT_LIST_ERROR;
  payload: any;
}

export interface GetClientListError {
  type: typeof GET_CLIENT_LIST_SUCCESS;
  payload: any;
}

export interface GetBeesList {
  type: typeof GET_BEES_LIST;
  payload: any;
}

export interface GetBeesListSuccess {
  type: typeof GET_BEES_LIST_SUCCESS;
  payload: any;
}

export interface GetBeesListError {
  type: typeof GET_BEES_LIST_ERROR;
  payload: any;
}

export interface GetCompanyListNew {
  type: typeof GET_COMPANY_LIST_NEW;
  payload: any;
}

export interface GetCompanyListNewSuccess {
  type: typeof GET_COMPANY_LIST_NEW_SUCCESS;
  payload: any;
}

export interface GetCompanyListNewError {
  type: typeof GET_COMPANY_LIST_NEW_ERROR;
  payload: any;
}

export interface GetMoviesList {
  type: typeof GET_MOVIES_LIST;
  payload: any;
}

export interface GetMoviesListSuccess {
  type: typeof GET_MOVIES_LIST_SUCCESS;
  payload: any;
}

export interface GetMoviesListError {
  type: typeof GET_MOVIES_LIST_ERROR;
  payload: any;
}

export interface GetBeesListNew {
  type: typeof GET_BEES_LIST_NEW;
  payload: any;
}

export interface GetBeesListNewSuccess {
  type: typeof GET_BEES_LIST_NEW_SUCCESS;
  payload: any;
}

export interface GetBeesListNewError {
  type: typeof GET_BEES_LIST_NEW_ERROR;
  payload: any;
}

export interface PostCompanyList {
  type: typeof POST_COMPANY_LIST;
  payload: any;
}

export interface PostCompanyListSuccess {
  type: typeof POST_COMPANY_LIST_SUCCESS;
  payload: any;
}

export interface PostCompanyListError {
  type: typeof POST_COMPANY_LIST_ERROR;
  payload: any;
}

export interface PostCompleteTask {
  type: typeof POST_COMPLETE_TASK;
  payload: any;
}

export interface PostCompleteTaskSuccess {
  type: typeof POST_COMPLETE_TASK_SUCCESS;
  payload: any;
}

export interface PostCompleteTaskError {
  type: typeof POST_COMPLETE_TASK_ERROR;
  payload: any;
}

export interface PostFeildChangeTask {
  type: typeof POST_FEILD_CHANGE_TASK;
  payload: any;
}

export interface PostFeildChangeTaskSuccess {
  type: typeof POST_FEILD_CHANGE_TASK_SUCCESS;
  payload: any;
}

export interface PostFeildChangeTaskError {
  type: typeof POST_FEILD_CHANGE_TASK_ERROR;
  payload: any;
}

export interface PostClientFollowUpTask {
  type: typeof POST_CLIENT_TASK;
  payload: any;
}

export interface PostClientFollowUpTaskSuccess {
  type: typeof POST_CLIENT_TASK_SUCCESS;
  payload: any;
}

export interface PostClientFollowUpTaskError {
  type: typeof POST_CLIENT_TASK_ERROR;
  payload: any;
}

export type DashboardActionsTypes =
  | GetMenuList
  | GetMenuListSuccess
  | GetMenuListError
  | GetOrderList
  | GetOrderListSuccess
  | GetOrderListError
  | GetFilterBeeInvoices
  | GetFilterBeeInvoicesSuccess
  | GetFilterBeeInvoicesError
  | GetCompanyList
  | GetCompanyListSuccess
  | GetCompanyListError
  | GetOrderDetailsRequest
  | GetOrderDetailsSuccess
  | GetManagementDetail
  | GetManagementDetailSuccess
  | GetManagementDetailError
  | GetCompanyDetails
  | GetCompanyDetailsSuccess
  | GetCompanyDetailsError
  | GetManagementTable
  | GetManagementTableSuccess
  | GetDebtorCSV
  | GetDebtorCSVSuccess
  | GetDebtorCSVError
  | GetManagementTableError
  | GetClientInformation
  | GetClientInformationSuccess
  | GetClientInformationError
  | GetPackageArrayInformation
  | PostNewCreditRequest
  | PostNewCreditRequestSuccess
  | PostNewCreditRequestError
  | GetPackageArrayInformationSuccess
  | GetPackageArrayInformationError
  | GetProductArrayInformation
  | GetProductArrayInformationSuccess
  | GetProductArrayInformationError
  | GetProductAddons
  | GetManualBees
  | GetManualBeesSuccess
  | GetManualBeesError
  | GetProductAddonsSuccess
  | GetProductAddonsError
  | PostUpdatePackage
  | PostUpdatePackageSuccess
  | PostUpdatePackageError
  | PutChecklistRequest
  | PutChecklistRequestSuccess
  | PutChecklistRequestError
  | GetTaskList
  | GetTaskListSuccess
  | GetTaskListError
  | GetPropertyStatus
  | DownloadInvoiceCSV
  | DownloadInvoiceCSVSuccess
  | DownloadInvoiceCSVError
  | GetPropertyStatusSuccess
  | GetCreditorsList
  | GetCreditorsListSuccess
  | GetCreditorsListError
  | GetTaskDetails
  | GetTaskDetailsSuccess
  | GetTaskDetailsError
  | GetPropertyStatusError
  | GetPropertyPriceModifier
  | GetPropertyPriceModifierSuccess
  | GetPropertyPriceModifierError
  | PutManagementOrder
  | PutContactAndLocation
  | PutContactAndLocationSuccess
  | PutContactAndLocationError
  | PutManagementOrderSuccess
  | Putproperty
  | PostBackendReports
  | PostBackendReportsSuccess
  | PostBackendReportsError
  | PutpropertySuccess
  | GetOrderStatsDetails
  | GetOrderStatsDetailsSuccess
  | GetOrderStatsDetailsError
  | PutpropertyError
  | PutManagementOrderError
  | PostShooterAllocation
  | PostShooterAllocationSuccess
  | PostShooterAllocationError
  | GetrequestDropdown
  | GetrequestDropdownSuccess
  | GetrequestDropdownError
  | GetrequestData
  | GetBeeInvoiceDetails
  | GetBeeInvoiceDetailsSuccess
  | GetBeeInvoiceDetailsError
  | GetrequestDataSuccess
  | GetrequestDataError
  | PostBestBees
  | PostBestBeesSuccess
  | PostBestBeesError
  | PostRegenrateAssetGroup
  | PostRegenrateAssetGroupSuccess
  | PostRegenrateAssetGroupError
  | GePostRequestData
  | GePostRequestDataSuccess
  | GetCompanyPromoCode
  | GetCompanyPromoCodeSuccess
  | GetCompanyPromoCodeError
  | GePostRequestDataError
  | GetBeesList
  | GetBeesListSuccess
  | GetBeesListError
  | PutOrderCompletion
  | PutOrderCompletionSuccess
  | PutOrderCompletionError
  | PutRequestData
  | PutRequestDataSuccess
  | GetOrderSpecialRequest
  | GetOrderSpecialRequestSuccess
  | GetOrderSpecialRequestError
  | PutRequestDataError
  | GetCardlessEvent
  | GetCardlessEventSuccess
  | GetCardlessEventError
  | PutKeyPickup
  | GetBillingList
  | GetBillingListSuccess
  | GetBillingListError
  | GetOrderTicketList
  | GetOrderTicketListSuccess
  | GetOrderTicketListError
  | PutKeyPickupSuccess
  | GetTicketLists
  | GetTicketListsSuccess
  | GetTicketListsError
  | PutInvoiceBeeRate
  | PostSetApproval
  | PostSetApprovalSuccess
  | PostSetApprovalError
  | PostShooterAvailability
  | PostShooterAvailabilitySuccess
  | PostShooterAvailabilityError
  | PutInvoiceBeeRateSuccess
  | GetOrderAssetDetails
  | GetOrderAssetDetailsSuccess
  | GetOrderAssetDetailsError
  | PutInvoiceBeeRateError
  | Getfilterorder
  | GetfilterorderSuccess
  | GetfilterorderError
  | PutKeyPickupError
  | HoldRequest
  | GetCreditorCSV
  | GetCreditorCSVSuccess
  | GetCreditorCSVError
  | PostAssetApproval
  | PostAssetApprovalSuccess
  | PostAssetApprovalError
  | HoldRequestSuccess
  | GetOverviewDetails
  | GetOverviewDetailsSuccess
  | GetOverviewDetailsError
  | HoldRequestError
  | GetDebtorList
  | GetDebtorListSuccess
  | GetDebtorListError
  | GetPaymentDetails
  | GetPaymentDetailsSuccess
  | GetPaymentDetailsError
  | ResumeRequest
  | PostTicketActions
  | PostTicketActionsSuccess
  | PostTicketActionsError
  | ResumeRequestSuccess
  | GetAllUser
  | GetAllUserSuccess
  | GetAllUserError
  | ResumeRequestError
  | DeleteAssetGroup
  | DeleteAssetGroupSuccess
  | DeleteAssetGroupError
  | PutAmmendInvoice
  | DeletOrderSpecialRequest
  | DeletOrderSpecialRequestSuccess
  | PutAmmendInvoiceSuccess
  | DeleteOrder
  | DeleteOrderSuccess
  | DeleteOrderError
  | DownloadBillingCSV
  | DownloadBillingCSVSuccess
  | DownloadBillingCSVError
  | PutAmmendInvoiceError
  | GetWebLink
  | GetWebLinkSuccess
  | GetWebLinkError
  | GetActionButton
  | GetActionButtonSuccess
  | GetActionButtonError
  | PostUpdateClientNotes
  | PostUpdateClientNotesSuccess
  | PostUpdateClientNotesError
  | GetCalendarMonth
  | GetCalendarMonthSuccess
  | GetCalendarMonthError
  | GetDashboardRequest
  | GetDashboardRequestSuccess
  | GetDashboardRequestError
  | PutOrderRenew
  | GetFilterInvoice
  | GetFilterInvoiceSuccess
  | GetFilterInvoiceError
  | PutOrderRenewSuccess
  | GetInvoiceDetails
  | GetInvoiceDetailsSuccess
  | GetInvoiceDetailsError
  | GetTicketDetails
  | GetTicketDetailsSuccess
  | GetTicketDetailsError
  | PutOrderRenewError
  | PutCancelHosting
  | PutCancelHostingSuccess
  | PutCancelHostingError
  | PostOrderRegenerate
  | PostOrderRegenerateSuccess
  | PostOrderRegenerateError
  | PostAssetRequest
  | PostAssetRequestSuccess
  | PostAssetRequestError
  | GetOrderStats
  | GetOrderStatsSuccess
  | GetOrderStatsError
  | PutSpecialRequest
  | PutSpecialRequestSuccess
  | PutSpecialRequestError
  | GetQuesstionnaire
  | GetQuesstionnaireSuccess
  | GetQuesstionnaireError
  | GetBeeInvoiceList
  | GetBeeInvoiceListSuccess
  | GetBeeInvoiceListError
  | GetfilterTask
  | GetfilterTaskSuccess
  | GetfilterTaskError
  | GetHostingPeriod
  | GetHostingPeriodSuccess
  | GetHostingPeriodError
  | PostBeeNotes
  | PostBeeNotesSuccess
  | PostBeeNotesError
  | GetCreditorsDetails
  | GetCreditorsDetailsSuccess
  | GetCreditorsDetailsError
  | GetDebtorsDetails
  | GetDebtorsDetailsSuccess
  | GetDebtorsDetailsError
  | GetCalenderMonthViewDay
  | GetCalenderMonthViewDaySuccess
  | GetCalenderMonthViewDayError
  | GetPromocodeUserList
  | GetPromocodeUserListSuccess
  | GetPromocodeUserListError
  | PostNewTicket
  | PostNewTicketSuccess
  | PostNewTicketError
  | GetPromocodeList
  | GetPromocodeListSuccess
  | GetPromocodeListError
  | PostZipfileAssetGroup
  | PostZipfileAssetGroupSuccess
  | PostZipfileAssetGroupError
  | GetCalenderMonthViewAvail
  | GetCalenderMonthViewAvailSuccess
  | GetCalenderMonthViewAvailError
  | GetGroupManualBees
  | GetGroupManualBeesSuccess
  | GetGroupManualBeesError
  | PostBeeSpecialCharge
  | PostBeeSpecialChargeSuccess
  | PostBeeSpecialChargeError
  | GetBackendActiveOrders
  | GetBackendActiveOrdersSuccess
  | GetBackendActiveOrdersError
  | GetPromocodeCompanyList
  | GetPromocodeCompanyListSuccess
  | GetPromocodeCompanyListError
  | PutMarkPaid
  | PutMarkPaidSuccess
  | PutMarkPaidError
  | GetBackendStats
  | GetBackendStatsSuccess
  | GetBackendStatsError
  | GetBackendCountryStats
  | GetBackendCountryStatsSuccess
  | GetBackendCountryStatsError
  | GetBackendStripe
  | GetBackendStripeSuccess
  | GetBackendStripeError
  | GetMatterPortDetails
  | GetMatterPortDetailsSuccess
  | GetMatterPortDetailsError
  | PostSpecialCharge
  | PostSpecialChargeSuccess
  | PostSpecialChargeError
  | PostBulkUpload
  | PostBulkUploadSuccess
  | PostBulkUploadError
  | GetCardlessEventDetails
  | GetCardlessEventDetailsSuccess
  | GetCardlessEventDetailsError
  | GetFailedDD
  | GetFailedDDSuccess
  | GetFailedDDError
  | GetMandateList
  | GetMandateListSuccess
  | GetMandateListError
  | GetRedirectFlows
  | GetRedirectFlowsSuccess
  | GetRedirectFlowsError
  | GetCardlessPayment
  | GetCardlessPaymentSuccess
  | GetCardlessPaymentError
  | GetFailedDdDetails
  | GetFailedDdDetailsSuccess
  | GetFailedDdDetailsError
  | PostRenewMovie
  | PostRenewMovieSuccess
  | PostRenewMovieError
  | GetMovieDetails
  | GetMovieDetailsSuccess
  | GetMovieDetailsError
  | GetCalenderDayView
  | GetCalenderDayViewSuccess
  | GetCalenderDayViewError
  | GetRedirectDetails
  | GetRedirectDetailsSuccess
  | GetRedirectDetailsError
  | PostArchiveDetail
  | PostArchiveDetailSuccess
  | PostArchiveDetailError
  | PostNewMovie
  | PostNewMovieSuccess
  | PostNewMovieError
  | PostCompanySignup
  | PostCompanySignupSuccess
  | PostCompanySignupError
  | GetMendateDetails
  | GetMendateDetailsSuccess
  | GetMendateDetailsError
  | GetOrderDetailsFails
  | GetInformationPage
  | GetInformationPageError
  | GetInformationPageSuccess
  | GetClientList
  | GetClientListSuccess
  | GetClientListError
  | GetCompanyListNew
  | GetCompanyListNewError
  | GetCompanyListNewSuccess
  | GetMoviesList
  | GetMoviesListSuccess
  | GetMoviesListError
  | GetBeesListNew
  | GetBeesListNewSuccess
  | GetBeesListNewError
  | PostCompanyList
  | PostCompanyListSuccess
  | PostCompanyListError
  | GetCompanyListAll
  | GetCompanyListAllSuccess
  | GetCompanyListAllError
  | GetAnalyticsDetails
  | GetAnalyticsDetailsSuccess
  | GetAnalyticsDetailsError
  | GetCompanyTranscode
  | GetCompanyTranscodeSuccess
  | GetCompanyTranscodeError
  | GetMonthlyAvailability
  | GetMonthlyAvailabilitySuccess
  | GetMonthlyAvailabilityError
  | GetWeeklyAvailability
  | GetWeeklyAvailabilitySuccess
  | GetWeeklyAvailabilityError
  | GetDailyAvailability
  | GetDailyAvailabilitySuccess
  | GetDailyAvailabilityError
  | PostManagementReportSuccess
  | PostManagementReportError
  | GetPaymentList
  | GetPaymentListSuccess
  | GetPaymentListError
  | GetRegionalStats
  | GetRegionalStatsSuccess
  | GetRegionalStatsError
  | GetCompanyStats
  | GetCompanyStatsSuccess
  | GetCompanyStatsError
  | GetVirtualTool
  | GetVirtualToolError
  | GetVirtualToolSuccess
  | PostNewPaymentLink
  | PostNewPaymentLinkSuccess
  | PostNewPaymentLinkError
  | PostPendingAsset
  | PostPendingAssetSuccess
  | PostPendingAssetError
  | PostMarkPaidOrDue
  | PostMarkPaidOrDueSuccess
  | PostMarkPaidOrDueError
  | GetCalenderMonthView
  | GetCalenderMonthViewSuccess
  | GetCalenderMonthViewError
  | PostApproveAssetGroup
  | PostApproveAssetGroupSuccess
  | PostApproveAssetGroupError
  | PostCountryResult
  | PostCountryResultSuccess
  | PostCountryResultError
  | GetTourTHREESIXTY
  | GetTourTHREESIXTYSuccess
  | GetTourTHREESIXTYError
  | GetDateRange
  | GetDateRangeSuccess
  | GetDateRangeError
  | PostOrderActions
  | PostOrderActionsSuccess
  | PostOrderActionsError
  | GetProfileDetails
  | GetProfileDetailsSuccess
  | GetProfileDetailsError
  | GetBackendCompany
  | GetBackendCompanySuccess
  | GetBackendCompanyError
  | GetPayDetails
  | GetPayDetailsSuccess
  | GetPayDetailsError
  | GetClosedTicketsList
  | GetClosedTicketsListSuccess
  | GetClosedTicketsListError
  | GetopenTickets
  | GetopenTicketsSuccess
  | GetopenTicketsError
  | GetSageCsvDownload
  | GetSageCsvDownloadSuccess
  | GetSageCsvDownloadError
  | PutMovieDetail
  | PutMovieDetailError
  | PutMovieDetailSuccess
  | DeleteBackendCompanyDetails
  | DeleteBackendCompanyDetailsError
  | DeleteBackendCompanyDetailsSuccess
  | PostCompanyDetails
  | PostCompanyDetailsError
  | PostCompanyDetailsSuccess
  | PostAmendInvoice
  | PostAmendInvoiceSuccess
  | PostAmendInvoiceError
  | PutTourDetails
  | PutTourDetailsSuccess
  | PutTourDetailsError
  | PostYoutube
  | PostYoutubeSuccess
  | PostYoutubeError
  | GetWeblinkDetails
  | GetWeblinkDetailsSuccess
  | GetWeblinkDetailsError
  | PostCompleteTask
  | PostCompleteTaskError
  | PostCompleteTaskSuccess
  | PostSupportNotes
  | PostSupportNotesSuccess
  | PostSupportNotesError
  | GetRenewTask
  | GetRenewTaskSuccess
  | GetRenewTaskError
  | DeleteUnavailableSlot
  | DeleteUnavailableSlotSuccess
  | DeleteUnavailableSlotError
  | PostUnavailableSlot
  | PostUnavailableSlotError
  | PostUnavailableSlotSuccess
  | DeleteInvoice
  | DeleteInvoiceSuccess
  | DeleteInvoiceError
  | PostNewInvoice
  | PostNewInvoiceSuccess
  | PostNewInvoiceError
  | UpdateInvoiceEntry
  | UpdateInvoiceEntrySuccess
  | UpdateInvoiceEntryError
  | PostApproveInvoice
  | PostApproveInvoiceSuccess
  | PostApproveInvoiceError
  | AddCLientReq
  | AddCLientReqSuccess
  | AddCLientReqError
  | GetPrefernces
  | GetPreferncesSuccess
  | GetPreferncesError
  | GetMusicReq
  | GetMusicReqSuccess
  | GetMusicReqError
  | GetUserTypes
  | GetUserTypesSuccess
  | GetUserTypesError
  | AddBeeReq
  | AddBeeReqSuccess
  | AddBeeReqError
  | GetBeekitList
  | GetBeekitListSuccess
  | GetBeekitListError
  | PostNewCompany
  | PostNewCompanySuccess
  | PostNewCompanyError
  | GetPermission
  | GetPermissionSuccess
  | GetPermissionError
  | GetGroupsReq
  | GetGroupsReqSuccess
  | GetGroupsReqError
  | GetSlotsReq
  | GetSlotsReqSuccess
  | GetSlotsReqError
  | PostNewPassword
  | PostNewPasswordSuccess
  | PostNewPasswordError
  | PutBeeUpdate
  | PutBeeUpdateSuccess
  | PutBeeUpdateError
  | PutClientUpdate
  | PutClientUpdateSuccess
  | PutClientUpdateError
  | PutCompanyUpdate
  | PutCompanyUpdateSuccess
  | PutCompanyUpdateError
  | GetRegions
  | GetRegionsSuccess
  | GetRegionsError
  | GetBrochureList
  | GetBrochureListSuccess
  | GetBrochureListError
  | GetListingSite
  | GetListingSiteSuccess
  | GetListingSiteError
  | GetPublicLogo
  | GetPublicLogoSuccess
  | GetPublicLogoError
  | GetWaterMark
  | GetWaterMarkSuccess
  | GetWaterMarkError
  | GetAccountList
  | GetAccountListSuccess
  | GetAccountListError
  | GetProjectFile
  | GetProjectFileSuccess
  | GetProjectFileError
  | Post3DLogo
  | Post3DLogoSuccess
  | Post3DLogoError
  | PostFeildChangeTask
  | PostFeildChangeTaskSuccess
  | PostFeildChangeTaskError
  | PostClientFollowUpTask
  | PostClientFollowUpTaskSuccess
  | PostClientFollowUpTaskError
  | PostClientCompanyDetails
  | PostClientCompanyDetailsSuccess
  | PostClientCompanyDetailsError
  | DeleteUserReq
  | DeleteUserReqSuccess
  | DeleteUserReqError
  | PutClientChange
  | PutClientChangeSuccess
  | PutClientChangeError
  | GetOrderPrice
  | GetOrderPriceSuccess
  | GetOrderPriceError
  | PostCompanyRegistration
  | PostCompanyRegistrationSuccess
  | PostCompanyRegistrationError
  | DeleteCompany
  | DeleteCompanySuccess
  | DeleteCompanyError
  | GetCountryList
  | GetCountryListSuccess
  | GetCountryListError
  | GetCompanyStatus
  | GetCompanyStatusSuccess
  | GetCompanyStatusError
  | PostNewRequest
  | PostNewRequestSuccess
  | PostNewRequestError
  | PostReshootDetails
  | PostReshootDetailsSuccess
  | PostReshootDetailsError
  | GetUnavailbleList
  | GetUnavailbleListSuccess
  | GetUnavailbleListError
  | GetAvailbleList
  | GetAvailbleListSuccess
  | GetAvailbleListError
  | GetAllShooterList
  | GetAllShooterListSuccess
  | GetAllShooterListError 
  | PostAmendPhoto 
  | PostAmendPhotoSuccess 
  | PostAmendPhotoError
  | PostAmendBroucher 
  | PostAmendBroucherSuccess
  | PostAmendBroucherError
  | PostAmendFloorplan
  | PostAmendFloorplanError
  | PostAmendFloorplanSuccess
  |GetInoviceEdit|GetInoviceEditSuccess|GetInoviceEditError|RenewHoisting|RenewHoistingSuccess|RenewHoistingError|CancelHoisting|CancelHoistingSuccess|CancelHoistingError|GetMslDetails|GetMslDetailsSuccess|GetMslDetailsError
  ;

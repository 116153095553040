import React from 'react';

export const BookOrderRoutes = [
    {
     
        routes: [
            {
                path: '/approval/:ref/new/:ticketId',
                component: React.lazy(() => import('./components/BookOrder')),
            },
        ],
    },
];

import React from 'react';
 
export const WeblinkRoutes = [
    {
       
        routes: [
            {
                path: '/en/v/unlisted/:movie_key/:movie_slug',
                component: React.lazy(() => import('./Weblink')),
            },
            {
                path: '/en/v/:movie_key/:movie_slug',
                component: React.lazy(() => import('./Weblink')),
            },
            {
                path: '/mls/:movie_key',
                component: React.lazy(() => import('./Weblink')),
            },
        ],
    },
];

import React from 'react';
import {AuthUser} from '../../../types/models/AuthUser';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import {useStyles} from "./UseStyles"
import AppContextPropsType from '../../../types/AppContextPropsType';
import AppContext from '../../../@crema/utility/AppContext';
import Avatar from '@material-ui/core/Avatar';
import {useHistory} from 'react-router-dom';

const useHeader = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user: AuthUser | null = useAuthUser();
  const {themeMode} = React.useContext<AppContextPropsType>(AppContext);
  const history = useHistory();
   
  const classes = useStyles({themeMode});
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getUserAvatar = () => {
    if (user && user.user.first_name) {
      return user.user.first_name.charAt(0).toUpperCase();
    }
    if (user && user.user.company) {
      return user.user.company.charAt(0).toUpperCase();
    }
  };
  const getUserAvatarView = () => {
    if (user && user.photoURL) {
      return <Avatar className={classes.profilePic} src={user.photoURL} />;
    } else {
      return <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>;
    }
  };
  return {anchorEl,history, handleClick, handleClose, getUserAvatar,getUserAvatarView};
};

export default useHeader;

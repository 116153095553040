import { store } from 'App';
import axios from 'axios';
import { history } from 'redux/store';
import { SIGNOUT_AUTH_SUCCESS } from 'types/actions/Auth.actions';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const STAGE_2_BASE_URL=process.env.REACT_APP_STAGE_2_API
const jwtAxios = axios.create({
  baseURL: API_BASE_URL, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

 
jwtAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    
    if (err.response?.status === 401) {
  
      store.dispatch({ type: SIGNOUT_AUTH_SUCCESS });
      localStorage.removeItem('token');
      localStorage.removeItem('refresh-token');
      localStorage.removeItem('statesForDropDown');

      history.push('/signin');
    }
    return Promise.reject(err);
  },
);

export const setAuthToken = (
  token: { access: string; refresh: string } | null,
) => {
  if (token) {
    jwtAxios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${token.access}`;

 

    localStorage.setItem('token', token.access);
    localStorage.setItem('refresh-token', token.refresh);
  } else {
    delete jwtAxios.defaults.headers.common['Authorization'];
     
    localStorage.removeItem('token');
    localStorage.removeItem('refresh-token');
  }
};

export default jwtAxios;

import {OptionsEntity} from 'types/models/NewOrder';

export const packagesData: OptionsEntity[] = [
  {
    name: 'Essential Listing package',
    key: 'essential-listing-package',

    id: 20,
    price: '$199.99',
    attributes: {
      Photos: '20HD Photographs',
      '3D Social Images': '1',
      'Virtual View Tool': 'Yes',
      RRP: '$300',
    },
    override_price: 199.99,
  },
  {
    name: 'Tour Listing Package',
    key: 'tour-listing-package',

    id: 40,
    price: '$199.99',
    attributes: {
      Photos: '20HD Photographs',
      '3D Social Images': '2',
      'Virtual View Tool': 'Yes',
      'Substitute video for Matterport': 'Optional',
      'HD Listing Video': 'Yes',
      RRP: '$490',
    },
    override_price: 299.99,
  },
  {
    name: 'Property Movie Plus',
    key: 'property-movie-plus',

    id: 60,
    price: '$199.99',
    attributes: {
      'Music Choice': 'Yes',
      Length: 'up to 1 1/2 min',
      'Movie Shots': '15',
      SEO: 'Yes',
      'Vistabee Hoisting': '12 Months',
      'True HD Movie': '1080p',
      name: 'Video',
      override_price: 399.99,
    },
  },
];

export const ADMIN_USER_TYPES = ['admin', 'support-admin', 'manager'];

export const GetSteps = (user_type: string | undefined) => {
  const steps = [
    {
      label: 'Select Client',
      subHeading: 'Choose the client that you are creating a order for.',
      disabled: !ADMIN_USER_TYPES.includes(user_type || ''),
    },
    {
      label: 'Recommended Products',
      subHeading: 'Suggested package from previous order',
    },
    {
      label: 'Customise Package',
      subHeading:
        'Need something extra with your package , please select addons below',
    },
    {
      label: 'Location & Time',
      subHeading: 'Choose location and time for our team to vist.',
    },
    {
      label: 'Contact Details',
      subHeading:
        'Enter the contact details of the person we need to scheduled with.',
    },
    {
      label: 'Reference & Specific Request',
      subHeading:
        'Enter your reference details, and if you are looking for something specific let us know',
    },
    {
      label: 'Review Order',
    },
  ];

  return steps;
};

export const Dummycards = [
  {
    flag: 'Popular',
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    name: 'Ariel Addon',
    price: '000.00',
  },
  {
    flag: 'Popular',
    name: 'Ariel Addon',
    price: '000.00',
  },
];

import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {getAllTickets} from 'redux/actions/Dashboard';
import {AppState} from 'redux/store';
const useDashBoard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const routeTopage = (path: string) => {
    history.push(path);
  };

  const {
    OrderList,
    detailsLoading,
    MenuList,
    menuLoading,
    TicketLoading,
    TicketLists,
    TaskListLoading,
    TaskList,
  } = useSelector<AppState, AppState['dashboard']>(({dashboard}) => dashboard);
  interface IDashboardDetails {
    orderData?: [];
    TicketDetails?: [];
    TaskList?: [];
  }

  const [presentTab, setPresentTab] = React.useState({});

  React.useEffect(() => {
    if (!menuLoading && MenuList) {
      let tt = MenuList.find((element) => element?.name === 'Tasks');
      setPresentTab(tt);
    }
  }, [menuLoading]);

  const [dashboardDetails, setDashboardDetails] =
    React.useState<IDashboardDetails>();

  React.useEffect(() => {
    dispatch(getAllTickets({offset: 0}));
  }, []);
  React.useEffect(() => {
    if (!detailsLoading) {
      setDashboardDetails({...dashboardDetails, orderData: OrderList?.result});
    }
  }, [detailsLoading]);

  React.useEffect(() => {
    if (!TicketLoading && TicketLists) {
      setDashboardDetails({
        ...dashboardDetails,
        TicketDetails: TicketLists?.result,
      });
    }
  }, [TicketLoading]);

  React.useEffect(() => {
    if (!TaskListLoading && TaskList) {
      setDashboardDetails({...dashboardDetails, TaskList: TaskList?.result});
    }
  }, [TaskListLoading]);

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    if (row?.status !== 'complete' && row?.task === 'Approve asset') {
      window.open(`/dashboard/approval/asset/${row.id}`);
    }
    if (row?.task === 'New Order') {
      window.open(`/approval/${row.order_reference}/new/${row.id}`);
    }
    if (row?.type === 'Create' || row?.type === 'Edit') {
      window.open(`/dashboard/orders/detail/${row.order_reference}`);
    }
    if (row?.task === 'Ticket' && row?.related_object_id) {
      history.push(`/dashboard/tickets/detail/${row.related_object_id}`);
    }
    if (row?.task === 'Ticket') {
      history.push(`/dashboard/tickets/detail/${row.id}`);
    }

    if (row?.task === 'Approve invoice') {
      history.push(`/dashboard/bee-invoice-approve/${row?.id}`);
    }
    if (row?.task === 'Enquiry' || row?.task === 'contact') {
      history.push(`/dashboard/enquiry/${row.id}`);
    }

    if (row?.task === 'Approve change field') {
      history.push(`/dashboard/approval/field-change/${row.id}/`);
    }
    if (row?.task === 'Renew Hosting') {
      history.push(`/en/products/${row.order_reference}/${row.id}`);
    }

    if (row?.task === 'Approve payment') {
      history.push(`/dashboard/approval/payment/${row.id}/`);
    }
    if (row?.task === 'Approve Company Invoice') {
      history.push(`/dashboard/company-invoice-approve/${row.id}/`);
    }
    if (row?.task === 'Deactivate Hosting') {
      history.push(
        `/en/products/hosting-action/${row.order_reference}/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'Client Follow up') {
      history.push(
        `/dashboard/scheduler/company_active_followup/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'Create 3D logo') {
      history.push(
        `/dashboard/approval/logo3d/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'verify customer') {
      history.push(`/dashboard/approval/client/verify/135544`);
    }

    if (
      row?.task === 'Extend Hosting' ||
      row?.task === 'Confirm to Client' ||
      row?.task === 'Deactivate Hosting'
    ) {
      history.push(
        `/en/products/hosting-action/${row.order_reference}/${row.id}/${row.related_object_id}`,
      );
    }
  };

  return {
    history,
    OrderList,
    detailsLoading,
    dashboardDetails,
    MenuList,
    presentTab,
    setDashboardDetails,
    routeTopage,
    handleClick,
  };
};

export default useDashBoard;

import Notification from '@components/AppNotifications';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import ContextProvider from '@crema/utility/ContextProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import TicketView from '@components/TicketHandelNotification/TicketView';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import configureStore, { history } from './redux/store';


export const store = configureStore();
const persistor = persistStore(store);

const App = () => (
  <ContextProvider>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                  <TicketView/> 
                  <Notification />
                </AuthRoutes>
              </ConnectedRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
      </PersistGate>
    </Provider>
  </ContextProvider>
);

export default App;

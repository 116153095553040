import {
  FETCH_ERROR_MY_ACCOUNT_TYPES,
  FETCH_ERROR_MY_NOTIFICATION,
  FETCH_START_MY_ACCOUNT_TYPES,
  FETCH_START_MY_NOTIFICATION,
  FETCH_SUCCESS_MY_ACCOUNT_TYPES,
  FETCH_SUCCESS_MY_NOTIFICATION,
} from '../../types/actions/MyAccount.action';

import {MyAccountActionsTypes} from '../../types/actions/MyAccount.action';
import {IMyAccount} from '../../types/models/MyAccounts';

const INIT_STATE: IMyAccount = {
  error: '',
  loading: false,
  accountLoading: false,
  MyAccountDetails: null,
  notificationDeatails: null,
};

const MyAccountReducer = (
  state = INIT_STATE,
  action: MyAccountActionsTypes,
): IMyAccount => {
  switch (action.type) {
    case FETCH_START_MY_ACCOUNT_TYPES: {
      return {
        ...state,
        accountLoading: true,
      };
    }

    case FETCH_SUCCESS_MY_ACCOUNT_TYPES: {
      return {
        ...state,
        accountLoading: false,
        MyAccountDetails: action.payload,
      };
    }

    case FETCH_ERROR_MY_ACCOUNT_TYPES: {
      return {
        ...state,
        accountLoading: false,
        error: action.payload,
      };
    }

    case FETCH_START_MY_NOTIFICATION: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_SUCCESS_MY_NOTIFICATION: {
      return {
        ...state,
        loading: false,
        notificationDeatails: action.payload,
      };
    }

    case FETCH_ERROR_MY_NOTIFICATION: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default MyAccountReducer;

import { api } from '@crema/services/ApiConfig';
import { IVideoOverlay } from 'types/models/Common';
import {
  CLEAR_VIDEO_OVERLAY_DATA,
  CURRENT_DATE,
  CommonActionTypes,
  EXPAND_ALL_TOGGLER,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_BLOGS_DATA_ERROR,
  GET_BLOGS_DATA_REQ,
  GET_BLOGS_DATA_SUCCESS,
  GET_BLOG_DATA_SUCCESS,
  GET_CITY_DATA_ERROR,
  GET_CITY_DATA_REQ,
  GET_CITY_DATA_SUCCESS,
  GET_CLIENTS_DATA_ERROR,
  GET_CLIENTS_DATA_REQ,
  GET_CLIENTS_DATA_SUCCESS,
  GET_JOBS_DATA_ERROR,
  GET_JOBS_DATA_REQ,
  GET_JOBS_DATA_SUCCESS,
  GET_MENUS_ERROR,
  GET_MENUS_REQ,
  GET_MENUS_SUCCESS,
  GET_PRODUCT_DATA_ERROR,
  GET_PRODUCT_DATA_REQ,
  GET_PRODUCT_DATA_SUCCESS,
  CLOSE_HEADER_BAR,
  GET_REVIEWS_DATA_ERROR,
  GET_REVIEWS_DATA_REQ,
  GET_REVIEWS_DATA_SUCCESS,
  HIDE_MESSAGE,
  INFO_MESSAGE,
  POST_CONTACT_US_ENQUIRY_ERROR,
  POST_CONTACT_US_ENQUIRY_REQ,
  POST_CONTACT_US_ENQUIRY_SUCCESS,
  SET_VIDEO_OVERLAY_DATA,
  SET_VIRTUAL_TOOL,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  WARNING_MESSAGE,
} from '../../types/actions/Common.action';

export const fetchStart = (): CommonActionTypes => ({ type: FETCH_START });

export const fetchSuccess = (): CommonActionTypes => ({ type: FETCH_SUCCESS });

export const fetchError = (
  error: string = 'Something went wrong!',
): CommonActionTypes => ({
  type: FETCH_ERROR,
  error,
});

export const showMessage = (message: string): CommonActionTypes => ({
  type: SHOW_MESSAGE,
  message,
});

export const showInfo = (message: string): CommonActionTypes => ({
  type: INFO_MESSAGE,
  message,
});
export const setCurrentDate = (label: string): CommonActionTypes => ({
  type: CURRENT_DATE,
  label,
});

export const ExpandAllCollapseAccordion = (label: any[]): CommonActionTypes => ({
  type: EXPAND_ALL_TOGGLER,
  label,
})


export const OpenCard = (label: any[]): CommonActionTypes => ({
  type: SET_OPEN_CARD,
  label,
})



export const CLoseHeader = (action:boolean) :CommonActionTypes => ({
  type:CLOSE_HEADER_BAR,
  payload:action,
})
export const SetVirtualToolCode = (code: string) => ({
  type: SET_VIRTUAL_TOOL,
  code
})
export const showWarning = (message: string): CommonActionTypes => ({
  type: WARNING_MESSAGE,
  message,
});

export const onToggleAppDrawer = (): CommonActionTypes => ({
  type: TOGGLE_APP_DRAWER,
});

export const hideMessage = (): CommonActionTypes => ({ type: HIDE_MESSAGE });

export const getMenusData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_MENUS_REQ,
    });
    try {
      const productsListRes = await api.get('pages/product-list/');
      const citiesListRes = await api.get('cities/city-list/');
      const products = productsListRes?.data?.result || [];
      const cities = citiesListRes?.data?.result || [];
      dispatch({
        type: GET_MENUS_SUCCESS,
        payload: {
          products,
          cities,
        },
      });
    } catch (err) {
      dispatch({ type: GET_MENUS_ERROR });
    }
  };
};

export const getProductData = ({ id }: { id: string }) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_PRODUCT_DATA_REQ,
    });

    try {
      const res = await api.get(`pages/product-details/?id=${id}`);
      if (res.data && res.data.success) {
        dispatch({
          type: GET_PRODUCT_DATA_SUCCESS,
          payload: res.data.result,
        });
      } else {
        throw new Error(`Error fetching product details for id-${id}`);
      }
    } catch (err) {
      dispatch({ type: GET_PRODUCT_DATA_ERROR });
    }
  };
};

export const getCityData = ({ id }: { id: string }) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_CITY_DATA_REQ,
    });
    try {
      const res = await api.get(`cities/city-details/?id=${id}`);
      if (res.data && res.data.success) {
        dispatch({
          type: GET_CITY_DATA_SUCCESS,
          payload: res.data.result,
        });
      } else {
        throw new Error(`Error fetching city details for id-${id}`);
      }
    } catch (err) {
      dispatch({ type: GET_CITY_DATA_ERROR });
    }
  };
};

export const postContactUsEnquiry = (payload: any, cb: () => void) => {
  return async (dispatch: any) => {
    dispatch({
      type: POST_CONTACT_US_ENQUIRY_REQ,
    });
    try {
      const res = await api.post(`contact/enquiry/`, payload);
      
      if (res.status === 201) {
        cb();
        dispatch({
          type: POST_CONTACT_US_ENQUIRY_SUCCESS,
        });
      } else {
        throw new Error(`Error posting contact us enquiry`);
      }
    } catch (err) {
      dispatch({ type: POST_CONTACT_US_ENQUIRY_ERROR });
    }
  };
};

export const getReviewsData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_REVIEWS_DATA_REQ,
    });
    try {
      const res = await api.get(`stories/list/`);
      if (res.data && res.data.success) {
        dispatch({
          type: GET_REVIEWS_DATA_SUCCESS,
          payload: res.data.result,
        });
      } else {
        throw new Error(`Error fetching reviews data`);
      }
    } catch (err) { 
      dispatch({ type: GET_REVIEWS_DATA_ERROR });
    }
  };
};

export const getClientsData = (offset:number) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_CLIENTS_DATA_REQ,
    });
    try {
      const res = await api.get(`other/client-list/?offset=${offset}`);
      if (res.data && res.data.success) {
        dispatch({
          type: GET_CLIENTS_DATA_SUCCESS,
          payload: res.data,
        });
      } else {
        throw new Error(`Error fetching clients data`);
      }
    } catch (err) {
      dispatch({ type: GET_CLIENTS_DATA_ERROR });
    }
  };
};

export const getJobsData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_JOBS_DATA_REQ,
    });
    try {
      const res = await api.get(`careers/list/`);
      if (res.data && res.data.success) {
        dispatch({
          type: GET_JOBS_DATA_SUCCESS,
          payload: res.data.result,
        });
      } else {
        throw new Error(`Error fetching jobs data`);
      }
    } catch (err) {
      dispatch({ type: GET_JOBS_DATA_ERROR });
    }
  };
};

export const getBlogsData = ({ blog_slug }: { blog_slug?: string } = {}) => {
  return async (dispatch: any) => {
    dispatch({
      type: GET_BLOGS_DATA_REQ,
    });
    try {
      if (blog_slug) {
        const res = await api.get(`weblog/details/?slug=${blog_slug}`);
        if (res.data && res.data.success) {
          dispatch({
            type: GET_BLOG_DATA_SUCCESS,
            payload: res.data.result,
          });
        } else {
          throw new Error(`Error fetching jobs data`);
        }
      } else {
        const res = await api.get(`weblog/list/`);
        if (res.data && res.data.success) {
          dispatch({
            type: GET_BLOGS_DATA_SUCCESS,
            payload: res.data.result,
          });
        } else {
          throw new Error(`Error fetching jobs data`);
        }
      }
    } catch (err) {
      dispatch({ type: GET_BLOGS_DATA_ERROR });
    }
  };
};

export const setVideoOverlayData = (payload: IVideoOverlay) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_VIDEO_OVERLAY_DATA,
      payload,
    });
  };
};

export const clearVideoOverlayData = () => {
  return async (dispatch: any) => {
    dispatch({
      type: CLEAR_VIDEO_OVERLAY_DATA,
    });
  };
};

import {makeStyles} from '@material-ui/core';
import {CremaTheme} from 'types/AppContextPropsType';

export const UseStyles = makeStyles((theme: CremaTheme) => ({
  root: {
    background: '#fff',
    width: '100%',
    padding: '22px 0',
  },
  stepper: {
    '& .MuiStepper-root': {
      backgroundColor: 'transparent',
      paddingInline: 0,
    },
  },
  container: {
    maxWidth: '1250px',
    width: '100%',
    margin: 'auto',
  },
  contentContainer: {
    minHeight: '50vh',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    padding: 12,

    borderRadius: '1rem',

    '&::-webkit-scrollbar': {
      width: '4px',
      offsetTop: 20,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.light,
    },

    '@media (max-width: 1024px)': {
      padding: '12px 40px',
    },

    '& .content-footer': {},
  },
  marginInlineEndAuto: {
    marginInlineEnd: 'auto',
  },
  totalPrice: {
    margin: '13px 6px',
    fontWeight: 600,
    '& svg': {
      marginRight: '12px',
    },
  },
  buttonsClasses: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    width: '42%',
    margin: '2rem  auto',
    '@media (max-width: 767px)': {
      width: '90%',
    },
  },
}));

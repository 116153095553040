import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoSsr from '@material-ui/core/NoSsr';
import React from 'react';

interface LoaderProps {
  blackBg?:boolean
}

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <NoSsr>
      <Box
        height='100%'
        display='flex' 
        flex={1}
        alignItems='center'
        justifyContent='center'
        style={props.blackBg ? {background:"#00000063",zIndex:999}:{}}
        
        position='absolute'
        top={0}
        left={0}
        right={0}
        bottom={0}>
        <CircularProgress size={60} />
      </Box>
    </NoSsr>
  );
};

export default Loader;

import React, { useEffect } from 'react';
import {AppState} from 'redux/store';
import { POST_NEW_TICKET_SUCCESS } from 'types/actions/Dashboard.action';
import {useSelector,useDispatch} from 'react-redux';

const useTicketView = () => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch()
    const handelClose = () => {
      setOpen(!open);
      dispatch({
        type: POST_NEW_TICKET_SUCCESS,
        payload: {},
      });
    };
  const {PostTicketLoading, PostTicket} = useSelector<
    AppState,
    AppState['dashboard']
  >(({dashboard}) => dashboard);

  useEffect(() => {
   if(PostTicket?.id){
    setOpen(true)
   }
  },[PostTicket])

  return {PostTicketLoading, PostTicket , open,handelClose};
};

export default useTicketView;

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {filterTask} from 'redux/actions/Dashboard';
import {AppState} from 'redux/store';
import {TaskType} from './data';
import {useAuthUser} from '@crema/utility/AppHooks';

const useTaskTable = () => {
  const {TaskList, TaskListLoading} = useSelector<
    AppState,
    AppState['dashboard']
  >(({dashboard}) => dashboard);
  const [page, setPage] = React.useState(0);
  const [taskList, setTaskList] = useState([]);

  const user = useAuthUser();

  useEffect(() => {
    if (!TaskListLoading && TaskList?.result) {
      setTaskList(TaskList);
    }
  }, [TaskListLoading]);

  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedTaskType, setSelectedTaskType] = useState(TaskType);

  const [selectedType, setSelectedType] = useState(
    user?.user_type.includes('photography')
      ? selectedTaskType[3] ?? []
      : user?.user_type.includes('-bee')
      ? selectedTaskType[1] ?? []
      : selectedTaskType[0] ?? [],
  );
  const [selectedTarget, setTarget] = useState(selectedTaskType[0]?.target[0]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const handelSelectedDropDown = (
    event: React.ChangeEvent<{}>,
    newValue: any,
  ) => {
    setSelectedType(newValue);
    setTarget({id: '', target: []});
  };

  const handelTargetDropDown = (
    event: React.ChangeEvent<{}>,
    newValue: any,
  ) => {
    setTarget(newValue);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    if (row?.status !== 'complete' && row?.task === 'Approve asset') {
      window.open(`/dashboard/approval/asset/${row.id}`);
    }
    if (row?.task === 'New Order') {
      window.open(`/approval/${row.order_reference}/new/${row.id}`);
    }
    if (row?.type === 'Create' || row?.type === 'Edit') {
      window.open(`/dashboard/orders/detail/${row.order_reference}`);
    }
    if (row?.task === 'Ticket' && row?.related_object_id) {
      history.push(`/dashboard/tickets/detail/${row.related_object_id}`);
    }
    if (row?.task === 'Ticket') {
      history.push(`/dashboard/tickets/detail/${row.id}`);
    }

    if (row?.task === 'Approve invoice') {
      window.open(`/dashboard/bee-invoice-approve/${row?.id}`);
    }
    if (row?.task === 'Enquiry' || row?.task === 'contact') {
      history.push(`/dashboard/enquiry/${row.id}`);
    }

    if (row?.task === 'Approve change field') {
      history.push(`/dashboard/approval/field-change/${row.id}/`);
    }
    if (row?.task === 'Renew Hosting') {
      history.push(`/en/products/${row.order_reference}/${row.id}`);
    }

    if (row?.task === 'Approve payment') {
      history.push(`/dashboard/approval/payment/${row.id}/`);
    }
    if (row?.task === 'Approve Company Invoice') {
      history.push(`/dashboard/company-invoice-approve/${row.id}/`);
    }
    if (row?.task === 'Deactivate Hosting') {
      history.push(
        `/en/products/hosting-action/${row.order_reference}/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'Client Follow up') {
      history.push(
        `/dashboard/scheduler/company_active_followup/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'Create 3D logo') {
      history.push(
        `/dashboard/approval/logo3d/${row.id}/${row.related_object_id}`,
      );
    }
    if (row?.task === 'verify customer') {
      history.push(`/dashboard/approval/client/verify/135544`);
    }

    if (
      row?.task === 'Extend Hosting' ||
      row?.task === 'Confirm to Client' ||
      row?.task === 'Deactivate Hosting'
    ) {
      history.push(
        `/en/products/hosting-action/${row.order_reference}/${row.id}/${row.related_object_id}`,
      );
    }
  };

  const [checkbox, setCheckbox] = useState({
    active_only: true,
    with_complete: null,
  });

  const searchTask = (pageNum :any) => {
    let trimmedSearchKey = searchKeyword.trim();
    let apiBody = {
      target: selectedTarget.id,
      task_type: selectedType.id,
      ...checkbox,
      search_key: trimmedSearchKey,
      offset: pageNum ? pageNum :page,
    };

    dispatch(filterTask(apiBody));
  };
  useEffect(() => {
    if (checkbox.active_only !== null || checkbox.with_complete !== null) {
      searchTask();
    }
  }, [checkbox]);

  useEffect(() => {
    searchTask();
  }, [page]);

  const handelCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = e.target;
    let temp: any = {...checkbox};
    temp[name] = checked;
    if (name === 'active_only' && checked) {
      temp.with_complete = false;
    } else if (name === 'with_complete' && checked) {
      temp.active_only = false;
    }
    setCheckbox(temp);
  };

  return {
    selectedTaskType,
    history,
    checkbox,
    handelCheckboxChange,
    searchKeyword,
    setSearchKeyword,
    searchTask,
    handelTargetDropDown,
    handleClick,
    setPage,
    handelSelectedDropDown,
    selectedType,
    taskList,
    TaskListLoading,
  };
};
export default useTaskTable;

import {makeStyles} from '@material-ui/core';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import ColorStyles from 'shared/constants/AppColor';
import {CremaTheme} from 'types/AppContextPropsType';
export const UseDashBoardStyles = makeStyles((theme: CremaTheme) => ({
  rootButton: {
    padding: '0.5em 1rem',
    fontSize: 16,
    fontWeight: 600,
    color: '#fff',
    background: ColorStyles.vistaBeeBlue,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  heading: {
    fontWeight: 600,
  },
  container: {
    maxWidth: '1250px',
    width: '100%',
    margin: '2rem auto',
  },
  minWidth: {
    minWidth: '450px',
  },
}));

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: ColorStyles.vistabeeBW70Gray,
      border: '1px solid gainsboro',
      color: theme.palette.common.white,
      padding: '10px 8px',
    },
    body: {
      fontSize: 14,
      border: '1px solid gainsboro',
      padding: '10px 8px',
    },
  }),
)(TableCell);

export const UsePointerStyles = makeStyles((theme: CremaTheme) => ({
  rootButton: {
    padding: '0.5em 1rem',
    fontSize: 16,
    fontWeight: 600,
    color: '#fff',
    background: theme.palette.background.primary.light,
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  heading: {
    fontWeight: 600,
  },
  minWidth: {
    minWidth: '450px',
  },
}));

export const UseStyles = makeStyles((theme: CremaTheme) => ({
  rootButton: {
    maxWidth: '1250px',
    width: '100%',
    margin: ' 11px auto',
    display: 'flex',

    height: '50px',
    alignItems: 'flex-start',

    padding: 0,
    '&.gray': {
      backgroundColor: theme.palette.gray[500],
      color: theme.palette.text.white,
      '&:hover': {
        backgroundColor: theme.palette.gray[600],
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },

  tabsButton: {
    padding: 0,
    minWidth: '94px',
  },

  container: {
    maxWidth: '1250px',
    margin: ' 1rem auto',
    height: '57px',
    display: 'flex',
    borderBottom: '2px solid gainsboro',
    alignItems: 'flex-start',
  },
  newMedia: {},
  links: {
    fontSize: 14,
    fontWeight: 500,
    background: ColorStyles.vistabeeBW50Gray,
    color: '#fff',
    marginRight: '0.8rem',
    textWrap: 'nowrap',
    padding: '0.7rem',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
    marginTop: 0,
    '&:hover': {
      color: '#fff !important',

      transition: 'all 0.2s ease 0s',
      boxShadow:
        'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    },
    '@media (max-width: 1100px)': {
      fontSize: 13,
    },
  },
  Sublinks: {
    fontSize: 14,
    fontWeight: 500,
    background: '#fff',
    color: '#000',
    border: '1px solid gainsboro',
    textTransform: 'capitalize',

    padding: '0.7rem',

    marginTop: 0,
    '&:hover': {
      color: '#000',

      transition: 'all 0.2s ease 0s',
    },
  },
  active: {
    background: ColorStyles.vistaBeeBlue,

    boxShadow:
      'rgba(14, 30, 37, 0.12) 0px 10px 6px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px !important',
    transition: 'all 0.5s ease 0s',
  },
  Subactive: {
    background: ColorStyles.vistaBeePink,
    color: '#fff',

    pointerEvents: 'none',

    transition: 'all 0.5s ease 0s',
  },
  media: {
    width: '60rem',
    border: '1px solid',
    Height: '500px !important',
  },
}));

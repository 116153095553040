import {makeStyles} from '@material-ui/core/styles';
import {Fonts} from '../../../../shared/constants/AppEnums';
import {CremaTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    root: {
      '&.active, &.active:hover, &.active:focus': {
      },
      '& .list-item-text': {
        padding: '0 0 0 16px',
      },
      '&.level-0': {
        height: 48,
        borderRadius: 4,
        '&:hover': {
          background: 'transparent',
        },
      },
      '&.dense': {
        padding: '8px 12px 8px 12px',
        minHeight: 40,
        '&.level-0': {
          height: 44,
        },
        '& .list-item-text': {
          padding: '0 0 0 8px',
        },
      },
    },
    children: {
      maxHeight: '70vh',
      overflow: 'auto',
      marginBottom: 0,

      '&::-webkit-scrollbar': {
        width: '4px',
        offsetTop: 20,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    popper: {
      zIndex: 999,
    },
    popperPaper: {
      backdropFilter: 'blur(10px)',
      backgroundColor: '#ffffff88',
    },
    popperClose: {
      pointerEvents: 'none',
    },
    pl0: {
      paddingLeft: 0,
    },
    fontBold: {
      fontWeight: Fonts.MEDIUM,
    },
    ml2: {
      marginLeft: 8,
    },
    textLg: {
      fontSize: 18,
    },
  };
});
export default useStyles;

import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import '@crema/services';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './shared/styles/index.css';

ReactDOM.render(<App />, document.getElementById('root'));

 
reportWebVitals();

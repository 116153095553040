import AppMessageView from '@components/AppMessageView';
import {Loader} from '@crema';
import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from 'redux/store';

const Notification = () => {
  const {error, message, info, warning, loading} = useSelector<
    AppState,
    AppState['common']
  >(({common}) => common);

  const showMessage = () => {
    return <AppMessageView variant='success' message={message.toString()} />;
  };

  const showError = () => {
    return <AppMessageView variant='error' message={error.toString()} />;
  };

  const showWarning = () => {
    return <AppMessageView variant='warning' message={warning.toString()} />;
  };

  const showInfo = () => {
    return <AppMessageView variant='info' message={info.toString()} />;
  };

  return (
    <div>
      {loading && <Loader />}
      {message && showMessage()}
      {error && showError()}
      {warning && showWarning()}
      {info && showInfo()}
    </div>
  );
};

export default Notification;

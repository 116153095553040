import { makeStyles } from '@material-ui/core/styles';
import ColorStyles from 'shared/constants/AppColor';
import { CremaTheme } from '../../../../types/AppContextPropsType';
const useStyles = makeStyles((theme: CremaTheme) => ({
  headerMain: {
    minHeight: 46, 
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: ColorStyles.vistaBeeBlue,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('sm')]: {
      minHeight: 50,
    },
    '& .crAppsSearch': {
      backgroundColor: theme.palette.primary.contrastText,
      borderRadius: 4,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    },
    '& .crAppsSearchIcon': {
      color: theme.palette.primary.contrastText,
    },
    '& .langBtn': {
      borderLeft: '0 none',
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        color: ColorStyles.vistaBeePink,
        backgroundColor: 'transparent',
      },
    },
    '& .smsIcon, & .notiIcon': {
      color: theme.palette.primary.contrastText,
      '&:hover, &:focus': {
        color: ColorStyles.vistaBeePink,
        backgroundColor: 'transparent',
      },
    },
    '& .notiBtn': {
      color: theme.palette.grey[800],
      borderLeft: '0 none',
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.up('md')]: {
        color: theme.palette.primary.contrastText,
        '&:hover, &:focus': {
          color: ColorStyles.vistaBeePink,
        },
      },
    },
  },
  hamburger:{
    [theme.breakpoints.down('sm')]: {
     display:'none'
    },
    '-moz-transition': 'all .5s',
    '-ms-transition': 'all .5s',
    '-o-transition': 'all .5s',
    '-webkit-transition': 'all .5s',
    transition: 'all .5s',
  },
  headerMainFlex: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
  },
  headerContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 20,
    paddingRight: 20,
    '@media (max-width: 767px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  btnPink: {
    background: ColorStyles.vistaBeePink,
  },

  FlagContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 200,
    '@media (max-width: 767px)': {
      width: 100,
    },
    '& p': {
      marginLeft: 11,
    },
    '& button': {
      textTransform: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionWeb: {
    display: 'flex',
    justifyContent: 'center',
    width: 600,
    '@media (max-width: 768px)': {
      display: 'none',
    },

    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  PlaceOrderButton: {
    padding: 0,
    minWidth: 100
  },
  headerNav: {
    color: theme.palette.common.white,
    fontWeight: 700,
    '& .navbarNav': {
      display: 'flex',
      padding: 0,
      marginLeft: -16,
      marginRight: -16,
      [theme.breakpoints.up('lg')]: {
        marginLeft: -20,
        marginRight: -20,
      },
    },
    '& .navItem': {
      width: 'auto',
      cursor: 'pointer',
      color: theme.palette.common.white,
      position: 'relative',
      '& span': {
        position: 'relative',

        '&:before': {
          content: '""',
          backgroundColor: theme.palette.common.white,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 2,
          transformOrigin: 'center',
          transform: 'scale(0)',
          transition: '200ms ease-out',
        },
      },

      [theme.breakpoints.up('lg')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
      '&:hover, &.active, &:focus, &:focus-within': {
        '& span:before': {
          transform: 'scale(1)',
        },
      },
    },
    '& .navLinkIcon': {
      marginRight: 10,
      color: theme.palette.grey[800],
      fontSize: 20,
    },
  },
  menuItemRoot: {
    padding: 0,
  },
  pointer: {
    cursor: 'pointer',
  },
  menuIconRoot: {
    width: '2.5rem',
    height: '2.5rem',
  },
  logoRoot: {
    height: 30,
  },
}));
export default useStyles;

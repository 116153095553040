import {
  CLEAR_VIDEO_OVERLAY_DATA,
  CURRENT_DATE,
  CommonActionTypes,
  EXPAND_ALL_TOGGLER,
  FETCH_ERROR,
  
  FETCH_START,
  FETCH_SUCCESS,
  GET_BLOGS_DATA_ERROR,
  GET_BLOGS_DATA_REQ,
  GET_BLOGS_DATA_SUCCESS,
  GET_BLOG_DATA_SUCCESS,
  GET_CITY_DATA_ERROR,
  GET_CITY_DATA_REQ,
  GET_CITY_DATA_SUCCESS,
  GET_CLIENTS_DATA_ERROR,
  GET_CLIENTS_DATA_REQ,
  GET_CLIENTS_DATA_SUCCESS,
  GET_JOBS_DATA_ERROR,
  GET_JOBS_DATA_REQ,
  GET_JOBS_DATA_SUCCESS,
  GET_MENUS_SUCCESS,
  GET_PRODUCT_DATA_ERROR,
  GET_PRODUCT_DATA_REQ,
  GET_PRODUCT_DATA_SUCCESS,
  GET_REVIEWS_DATA_ERROR,
  GET_REVIEWS_DATA_REQ,
  GET_REVIEWS_DATA_SUCCESS,
  HIDE_MESSAGE,
  INFO_MESSAGE,
  POST_CONTACT_US_ENQUIRY_ERROR,
  POST_CONTACT_US_ENQUIRY_REQ,
  POST_CONTACT_US_ENQUIRY_SUCCESS,
  SET_VIDEO_OVERLAY_DATA,
  SET_VIRTUAL_TOOL,
  SHOW_MESSAGE,
  TOGGLE_APP_DRAWER,
  WARNING_MESSAGE,
  CLOSE_HEADER_BAR,
} from '../../types/actions/Common.action';
import {Common} from '../../types/models/Common';

export const INIT_STATE: Common = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  menus: null,
  productPageData: {
    loading: false,
    data: null,
  },
  cityPageData: {
    loading: false,
    data: null,
  },
  contactUsFormSubmitting: false,
  reviewsLoading: false,
  reviews: [],
  clients: [],
  clientsLoading: false,
  jobs: [],
  jobsLoading: false,
  videoOverlayData: {
    iframeSrc: '',
    show: true,
  },
  blogsLoading: false,
  blog: null,
  blogsList: [],

  info: '',
  warning: '',
  currentDateLabel: '',
  accordion: '',
  virtualToolcode: '',
  CloseHeader: false,
};

const CommonReducer = (
  state = INIT_STATE,
  action: CommonActionTypes,
): Common => {
  switch (action.type) {
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: '',
        message: '',
        loading: false,
        updatingContent: false,
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        error: '',
        message: action.message,
        loading: false,
        updatingContent: false,
      };
    }
    case FETCH_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        message: '',
        updatingContent: false,
      };
    }

    case EXPAND_ALL_TOGGLER: {
      return {
        ...state,
        accordion: action.label,
      };
    }
    case INFO_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        info: action.message,
        updatingContent: false,
      };
    }

    case SET_VIRTUAL_TOOL: {
      return {
        ...state,
        virtualToolcode: action.code,
      };
    }
    case WARNING_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        info: '',
        warning: action.message,
        updatingContent: false,
      };
    }

    case HIDE_MESSAGE: {
      return {
        ...state,
        loading: false,
        error: '',
        message: '',
        info: '',
        warning: '',
        updatingContent: false,
      };
    }

    case GET_MENUS_SUCCESS: {
      return {
        ...state,
        menus: action.payload,
      };
    }

    case GET_PRODUCT_DATA_REQ: {
      return {
        ...state,
        productPageData: {
          ...state.productPageData,
          loading: true,
        },
      };
    }

    case GET_PRODUCT_DATA_SUCCESS: {
      return {
        ...state,
        productPageData: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case GET_PRODUCT_DATA_ERROR: {
      return {
        ...state,
        productPageData: {
          data: null,
          loading: false,
        },
      };
    }

    case GET_CITY_DATA_REQ: {
      return {
        ...state,
        cityPageData: {
          ...state.productPageData,
          loading: true,
        },
      };
    }

    case GET_CITY_DATA_SUCCESS: {
      return {
        ...state,
        cityPageData: {
          data: action.payload,
          loading: false,
        },
      };
    }

    case GET_CITY_DATA_ERROR: {
      return {
        ...state,
        cityPageData: {
          data: null,
          loading: false,
        },
      };
    }

    case GET_REVIEWS_DATA_REQ: {
      return {
        ...state,
        reviewsLoading: true,
      };
    }

    case GET_REVIEWS_DATA_SUCCESS: {
      return {
        ...state,
        reviewsLoading: false,
        reviews: action.payload,
      };
    }

    case GET_REVIEWS_DATA_ERROR: {
      return {
        ...state,
        reviewsLoading: false,
        reviews: [],
      };
    }

    case GET_CLIENTS_DATA_REQ: {
      return {
        ...state,
        clientsLoading: true,
      };
    }

    case GET_CLIENTS_DATA_SUCCESS: {
      return {
        ...state,
        clientsLoading: false,
        clients: action.payload,
      };
    }

    case GET_CLIENTS_DATA_ERROR: {
      return {
        ...state,
        clientsLoading: false,
        clients: [],
      };
    }

    case GET_JOBS_DATA_REQ: {
      return {
        ...state,
        jobsLoading: true,
      };
    }

    case GET_JOBS_DATA_SUCCESS: {
      return {
        ...state,
        jobsLoading: false,
        jobs: action.payload,
      };
    }

    case GET_JOBS_DATA_ERROR: {
      return {
        ...state,
        jobsLoading: false,
        jobs: [],
      };
    }

    case GET_BLOGS_DATA_REQ: {
      return {
        ...state,
        blogsLoading: true,
      };
    }

    case GET_BLOGS_DATA_SUCCESS: {
      return {
        ...state,
        blogsLoading: false,
        blogsList: action.payload,
      };
    }

    case GET_BLOG_DATA_SUCCESS: {
      return {
        ...state,
        blogsLoading: false,
        blog: action.payload,
      };
    }

    case GET_BLOGS_DATA_ERROR: {
      return {
        ...state,
        blogsLoading: false,
        blog: null,
        blogsList: [],
      };
    }

    case POST_CONTACT_US_ENQUIRY_REQ: {
      return {
        ...state,
        contactUsFormSubmitting: true,
      };
    }

    case POST_CONTACT_US_ENQUIRY_SUCCESS: {
      return {
        ...state,
        contactUsFormSubmitting: false,
      };
    }

    case POST_CONTACT_US_ENQUIRY_ERROR: {
      return {
        ...state,
        contactUsFormSubmitting: false,
      };
    }

    case TOGGLE_APP_DRAWER: {
      return {
        ...state,
        isAppDrawerOpen: !state.isAppDrawerOpen,
      };
    }

    case SET_VIDEO_OVERLAY_DATA: {
      return {
        ...state,

        videoOverlayData: {
          show: true,
          ...action.payload,
        },
      };
    }

    case CLEAR_VIDEO_OVERLAY_DATA: {
      return {
        ...state,
        videoOverlayData: {
          iframeSrc: '',
          show: true,
        },
      };
    }
    case CURRENT_DATE: {
      return {
        ...state,
        currentDateLabel: action.label,
      };
    }
    case CLOSE_HEADER_BAR: {
      return {
        ...state,
        CloseHeader: action.payload,
      };
    }

    default:
      return state;
  }
};
export default CommonReducer;

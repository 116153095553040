import {
  BEE_NOTES,
  BEE_NOTES_ERROR,
  BEE_NOTES_SUCCESS,
  BILLING_FILTER_INVOICE,
  RENEW_HOISTING,RENEW_HOISTING_SUCCESS,RENEW_HOISTING_ERROR,
  GET_MSL_DETAILS,GET_MSL_DETAILS_SUCCESS,GET_MSL_DETAILS_ERROR,
  GET_UNAVAILABLE_LIST,
  GET_ALL_SHOOTERLIST,
  GET_ALL_SHOOTERLIST_SUCCESS,
  GET_INVOICE_EDIT_DETAIL,
  GET_INVOICE_EDIT_DETAIL_SUCCESS,
  GET_INVOICE_EDIT_DETAIL_ERROR,
  GET_ALL_SHOOTERLIST_ERROR,
  PUT_BEE_UPDATE,
  PUT_BEE_UPDATE_SUCCESS,
  PUT_BEE_UPDATE_ERROR,
  POST_ZIP_ASSET_GROUP,
  POST_ZIP_ASSET_GROUP_ERROR,
  POST_ZIP_ASSET_GROUP_SUCCESS,
  GET_UNAVAILABLE_LIST_SUCCESS,
  GET_UNAVAILABLE_LIST_ERROR,
  BILLING_FILTER_INVOICE_ERROR,
  POST_NEW_TICKET,
  POST_NEW_TICKET_SUCCESS,
  POST_NEW_TICKET_ERROR,
  BILLING_FILTER_INVOICE_SUCCESS,
  DELETE_COMPANY,
  UPDATE_COMPANY_DETAILS,
  GET_AVAILABLE_LIST,
  GET_AVAILABLE_LIST_SUCCESS,
  GET_AVAILABLE_LIST_ERROR,
  UPDATE_COMPANY_DETAILS_SUCCESS,
  UPDATE_COMPANY_DETAILS_ERROR,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,
  GET_COUNTRY_LIST,
  GET_COUNTRY_LIST_SUCCESS,
  GET_COUNTRY_LIST_ERROR,
  POST_COMPANY_INVITATION,
  POST_RESHOOT_DETAILS,
  POST_RESHOOT_DETAILS_SUCCESS,
  POST_RESHOOT_DETAILS_ERROR,
  POST_SHOOTER_ALLOCATION,
  POST_SHOOTER_ALLOCATION_SUCCESS,
  POST_SHOOTER_ALLOCATION_ERROR,
  POST_COMPANY_INVITATION_SUCCESS,
  POST_COMPANY_INVITATION_ERROR,
  GET_ORDER_TOTAL_PRICE,
  POST_APPROVE_ASSET_GROUP,
  POST_APPROVE_ASSET_GROUP_SUCCESS,
  POST_APPROVE_ASSET_GROUP_ERROR,
  GET_ORDER_TOTAL_PRICE_SUCCESS,
  GET_ORDER_TOTAL_PRICE_ERROR,
  GET_PROJECT_FILE,
  GET_PROJECT_FILE_SUCCESS,
  GET_PROJECT_FILE_ERROR,
  GET_SLOTS_REQ,
  GET_SLOTS_REQ_SUCCESS,
  GET_WATER_MARK,
  GET_WATER_MARK_SUCCESS,
  GET_WATER_MARK_ERROR,
  GET_SLOTS_REQ_ERROR,
  GET_BEE_KIT_LIST,
  GET_BEE_KIT_LIST_SUCCESS,
  GET_BEE_KIT_LIST_ERROR,
  GET_ACCOUNT_LIST,
  GET_ACCOUNT_LIST_SUCCESS,
  GET_ACCOUNT_LIST_ERROR,
  GET_MUSIC_REQ,
  GET_MUSIC_REQ_SUCCESS,
  GET_REGION_REQ,
  GET_REGION_REQ_SUCCESS,
  GET_REGION_REQ_ERROR,
  GET_BROCHURE_REQ,
  GET_BROCHURE_REQ_SUCCESS,
  GET_BROCHURE_REQ_ERROR,
  GET_LISTING_SITE,
  GET_LISTING_SITE_SUCCESS,
  GET_LISTING_SITE_ERROR,
  GET_MUSIC_REQ_ERROR,
  GET_TOUR_THREE_SIXTY,
  GET_TOUR_THREE_SIXTY_SUCCESS,
  GET_TOUR_THREE_SIXTY_ERROR,
  GET_USER_TYPE,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_ERROR,
  DashboardActionsTypes,
  GET_WEBLINK_DETAILS,
  CANCEL_HOISTING,CANCEL_HOISTING_SUCCESS,CANCEL_HOISTING_ERROR,
  GET_WEBLINK_DETAILS_SUCCESS,
  GET_WEBLINK_DETAILS_ERROR,
  FETCH_CLIENT_DROPDOWN_INFORMATION,
  FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR,
  FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS,
  FETCH_MANAGEMENT_DETAILS,
  FETCH_MANAGEMENT_DETAILS_ERROR,
  FETCH_MANAGEMENT_DETAILS_SUCCESS,
  FETCH_MANAGEMENT_TABLE_DETAILS,
  FETCH_MANAGEMENT_TABLE_DETAILS_ERROR,
  FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS,
  FETCH_MENULIST_FAILED,
  FETCH_MENULIST_SUCCESSFULL,
  FETCH_MENU_LIST,
  FETCH_ORDER_DETAILS_REQUEST,
  FETCH_ORDER_DETAILS_REQUEST_ERROR,
  FETCH_ORDER_DETAILS_REQUEST_SUCCESS,
  FETCH_ORDER_TABLE_DETAILS,
  FETCH_ORDER_TABLE_DETAILS_FAILED,
  FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL,
  FETCH_PACKAGE_DROPDOWN,
  FETCH_PACKAGE_DROPDOWN_ERROR,
  FETCH_PACKAGE_DROPDOWN_SUCCESS,
  FETCH_PRODUCT_ADDON_DROPDOWN,
  FETCH_PRODUCT_ADDON_DROPDOWN_ERROR,
  FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS,
  FETCH_PRODUCT_DROPDOWN,
  FETCH_PRODUCT_DROPDOWN_ERROR,
  FETCH_PRODUCT_DROPDOWN_SUCCESS,
  FETCH_PROPERTY_PRICE_MODIFIERS,
  FETCH_PROPERTY_PRICE_MODIFIERS_ERROR,
  FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS,
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_REQUEST_ERROR,
  FETCH_PROPERTY_REQUEST_SUCCESS,
  GET_ACTIVE_ORDER,
  GET_ACTIVE_ORDER_ERROR,
  GET_ACTIVE_ORDER_SUCCESS,
  GET_ALL_USER,
  GET_ALL_USER_ERROR,
  GET_ALL_USER_SUCCESS,
  GET_ANALYTICS_DETAILS,
  GET_ANALYTICS_DETAILS_ERROR,
  GET_ANALYTICS_DETAILS_SUCCESS,
  GET_BACKEND_COMPANY_DETAILS,
  GET_BACKEND_COMPANY_DETAILS_ERROR,
  GET_BACKEND_COMPANY_DETAILS_SUCCESS,
  GET_BACKEND_COMPANY_STATS,
  GET_BACKEND_COMPANY_STATS_ERROR,
  GET_BACKEND_COMPANY_STATS_SUCCESS,
  GET_BACKEND_COUNTRY_STATS,
  GET_BACKEND_COUNTRY_STATS_ERROR,
  GET_BACKEND_COUNTRY_STATS_SUCCESS,
  GET_BACKEND_STRIPE,
  GET_BACKEND_STRIPE_ERROR,
  GET_BACKEND_STRIPE_SUCCESS,
  GET_BEES_LIST,
  GET_BEES_LIST_ERROR,
  GET_BEES_LIST_NEW,
  GET_BEES_LIST_NEW_ERROR,
  GET_BEES_LIST_NEW_SUCCESS,
  GET_BEES_LIST_SUCCESS,
  GET_BEE_FILTER_INVOICE,
  GET_BEE_FILTER_INVOICE_ERROR,
  GET_BEE_FILTER_INVOICE_SUCCESS,
  GET_BEE_INVOICE,
  GET_BEE_INVOICE_DETAILS,
  GET_BEE_INVOICE_DETAILS_ERROR,
  GET_BEE_INVOICE_DETAILS_SUCCESS,
  GET_BEE_INVOICE_ERROR,
  GET_BEE_INVOICE_SUCCESS,
  GET_BILLING_REQUEST,
  GET_BILLING_REQUEST_ERROR,
  GET_BILLING_REQUEST_SUCCESS,
  GET_CALENDAR_MONTH,
  GET_CALENDAR_MONTH_ERROR,
  GET_CALENDAR_MONTH_SUCCESS,
  GET_CALENDER_DAY_VIEW,
  GET_CALENDER_DAY_VIEW_ERROR,
  GET_CALENDER_DAY_VIEW_SUCCESS,
  GET_CALENDER_MONTH_VIEW,
  GET_CALENDER_MONTH_VIEW_AVAIL,
  GET_CALENDER_MONTH_VIEW_AVAIL_ERROR,
  GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS,
  GET_CALENDER_MONTH_VIEW_DAY,
  GET_CALENDER_MONTH_VIEW_DAY_ERROR,
  GET_CALENDER_MONTH_VIEW_DAY_SUCCESS,
  GET_CALENDER_MONTH_VIEW_ERROR,
  GET_CALENDER_MONTH_VIEW_SUCCESS,
  GET_CARDLESS_EVENT_DETAILS,
  GET_CARDLESS_EVENT_DETAILS_ERROR,
  GET_CARDLESS_EVENT_DETAILS_SUCCESS,
  GET_CARDLESS_EVENT_LIST,
  GET_CARDLESS_EVENT_LIST_ERROR,
  GET_CARDLESS_EVENT_LIST_SUCCESS,
  GET_CARDLESS_PAYMENT,
  GET_CARDLESS_PAYMENT_ERROR,
  GET_CARDLESS_PAYMENT_SUCCESS,
  GET_CLIENT_LIST,
  GET_CLIENT_LIST_ERROR,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLOSED_TICKETS,
  GET_CLOSED_TICKETS_ERROR,
  GET_CLOSED_TICKETS_SUCCESS,
  GET_COMPANY_DETAIL,
  GET_COMPANY_DETAILS,
  GET_COMPANY_DETAILS_ERROR,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAIL_ERROR,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_ALL,
  GET_COMPANY_LIST_ALL_ERROR,
  GET_COMPANY_LIST_ALL_SUCCESS,
  GET_COMPANY_LIST_ERROR,
  GET_COMPANY_LIST_NEW,
  GET_COMPANY_LIST_NEW_ERROR,
  GET_COMPANY_LIST_NEW_SUCCESS,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_PROMOCODE_LIST,
  GET_COMPANY_PROMOCODE_LIST_ERROR,
  GET_COMPANY_PROMOCODE_LIST_SUCCESS,
  GET_COMPANY_STATS,
  GET_COMPANY_STATS_ERROR,
  GET_COMPANY_STATS_SUCCESS,
  GET_CREDITOR_CSV,
  GET_CREDITOR_CSV_ERROR,
  GET_CREDITOR_CSV_SUCCESS,
  GET_CREDITOR_DETAILS,
  GET_CREDITOR_DETAILS_ERROR,
  GET_CREDITOR_DETAILS_SUCCESS,
  GET_CREDITOR_LIST,
  GET_CREDITOR_LIST_ERROR,
  GET_CREDITOR_LIST_SUCCESS,
  GET_DAILY_AVAILABILITY,
  GET_DAILY_AVAILABILITY_ERROR,
  GET_DAILY_AVAILABILITY_SUCCESS,
  GET_DASHBOARD_TICKET_DETAIL,
  GET_DASHBOARD_TICKET_DETAIL_ERROR,
  GET_DASHBOARD_TICKET_DETAIL_SUCCESS,
  GET_DATE_RANGE,
  GET_DATE_RANGE_ERROR,
  GET_DATE_RANGE_SUCCESS,
  GET_DEBTOR_CSV,
  GET_DEBTOR_CSV_ERROR,
  GET_DEBTOR_CSV_SUCCESS,
  GET_DEBTOR_DETAILS,
  GET_DEBTOR_DETAILS_ERROR,
  GET_DEBTOR_DETAILS_SUCCESS,
  GET_DEBTOR_LIST,
  GET_DEBTOR_LIST_ERROR,
  GET_DEBTOR_LIST_SUCCESS,
  GET_FAILED_DD,
  GET_FAILED_DD_DETAILS,
  GET_FAILED_DD_DETAILS_ERROR,
  GET_FAILED_DD_DETAILS_SUCCESS,
  GET_FAILED_DD_ERROR,
  GET_FAILED_DD_SUCCESS,
  GET_FILTER_ORDER,
  GET_FILTER_ORDER_ERROR,
  GET_FILTER_ORDER_SUCCESS,
  GET_FILTER_TASK,
  GET_FILTER_TASK_ERROR,
  GET_FILTER_TASK_SUCCESS,
  GET_GROUP_MANUAL_BEES,
  GET_GROUP_MANUAL_BEES_ERROR,
  GET_GROUP_MANUAL_BEES_SUCCESS,
  GET_HOSTING_PERIOD,
  GET_HOSTING_PERIOD_ERROR,
  GET_HOSTING_PERIOD_SUCCESS,
  GET_INFORMATION_PAGE,
  GET_INFORMATION_PAGE_ERROR,
  GET_INFORMATION_PAGE_SUCCESS,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_ERROR,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_MANDATE_LIST,
  GET_MANDATE_LIST_ERROR,
  GET_MANDATE_LIST_SUCCESS,
  GET_MANUAL_BEES,
  GET_MANUAL_BEES_ERROR,
  GET_MANUAL_BEES_SUCCESS,
  GET_MATTERPORT_DETAILS,
  GET_MATTERPORT_DETAILS_ERROR,
  GET_MATTERPORT_DETAILS_SUCCESS,
  GET_MENDATE_DETAILS,
  GET_MENDATE_DETAILS_ERROR,
  GET_MENDATE_DETAILS_SUCCESS,
  GET_MONTHLY_AVAILABILITY,
  GET_MONTHLY_AVAILABILITY_ERROR,
  GET_MONTHLY_AVAILABILITY_SUCCESS,
  GET_MOVIES_LIST,
  GET_MOVIES_LIST_ERROR,
  GET_MOVIES_LIST_SUCCESS,
  GET_OPEN_TICKETS,
  GET_OPEN_TICKETS_ERROR,
  GET_OPEN_TICKETS_SUCCESS,
  GET_ORDER_ASSET,
  GET_ORDER_ASSET_ERROR,
  GET_ORDER_ASSET_SUCCESS,
  GET_ORDER_BUTTONS,
  GET_ORDER_BUTTONS_ERROR,
  GET_ORDER_BUTTONS_SUCCESS,
  GET_ORDER_SPECIALREQUEST,
  GET_ORDER_SPECIALREQUEST_ERROR,
  GET_ORDER_SPECIALREQUEST_SUCCESS,
  GET_ORDER_STATS,
  GET_ORDER_STATS_DETAILS,
  GET_ORDER_STATS_DETAILS_ERROR,
  GET_ORDER_STATS_DETAILS_SUCCESS,
  GET_ORDER_STATS_ERROR,
  GET_ORDER_STATS_SUCCESS,
  GET_ORDER_TICEKT_LIST,
  GET_ORDER_TICEKT_LIST_ERROR,
  GET_ORDER_TICEKT_LIST_SUCCESS,
  GET_ORDER_WEBLINK,
  GET_ORDER_WEBLINK_ERROR,
  GET_ORDER_WEBLINK_SUCCESS,
  GET_OVERVIEW_DETAILS,
  GET_OVERVIEW_DETAILS_ERROR,
  GET_OVERVIEW_DETAILS_SUCCESS,
  GET_PAYMENT_DETAILS,
  GET_PAYMENT_DETAILS_ERROR,
  GET_PAYMENT_DETAILS_SUCCESS,
  GET_PAYMENT_LIST,
  GET_PAYMENT_LIST_ERROR,
  GET_PAYMENT_LIST_SUCCESS,
  GET_PAY_DETAILS,
  GET_PAY_DETAILS_ERROR,
  GET_PAY_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS,
  GET_PROFILE_DETAILS_ERROR,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROMOCODE_LIST,
  GET_PROMOCODE_LIST_ERROR,
  GET_PROMOCODE_LIST_SUCCESS,
  GET_PROMOCODE_USER_LIST,
  GET_PROMOCODE_USER_LIST_ERROR,
  GET_PROMOCODE_USER_LIST_SUCCESS,
  GET_PROMO_CODE_COMPANY,
  GET_PROMO_CODE_COMPANY_ERROR,
  GET_PROMO_CODE_COMPANY_SUCCESS,
  GET_PUBLIC_LOGO,
  GET_PUBLIC_LOGO_SUCCESS,
  GET_PUBLIC_LOGO_ERROR,
  GET_QUESTIONNAIRE_REQUEST,
  GET_QUESTIONNAIRE_REQUEST_ERROR,
  GET_QUESTIONNAIRE_REQUEST_SUCCESS,
  GET_REDIRECT_FLOWS,
  GET_REDIRECT_FLOWS_ERROR,
  GET_REDIRECT_FLOWS_SUCCESS,
  GET_REDIRECT_FLOW_DETAILS,
  GET_REDIRECT_FLOW_DETAILS_ERROR,
  GET_REDIRECT_FLOW_DETAILS_SUCCESS,
  GET_REGIONAL_STATS,
  GET_REGIONAL_STATS_ERROR,
  GET_REGIONAL_STATS_SUCCESS,
  GET_REQUEST_DROPDOWN,
  GET_REQUEST_DROPDOWN_ERROR,
  GET_PREFERENCES,
  GET_PREFERENCES_SUCCESS,
  GET_PREFERENCES_ERROR,
  GET_REQUEST_DROPDOWN_LIST,
  GET_REQUEST_DROPDOWN_LIST_ERROR,
  GET_REQUEST_DROPDOWN_LIST_SUCCESS,
  GET_REQUEST_DROPDOWN_SUCCESS,
  GET_SAGE_CSV_DOWNLOAD,
  GET_SAGE_CSV_DOWNLOAD_ERROR,
  GET_SAGE_CSV_DOWNLOAD_SUCCESS,
  GET_TASK_DETAILS,
  GET_TASK_DETAILS_ERROR,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_LIST,
  GET_TASK_LIST_ERROR,
  GET_TASK_LIST_SUCCESS,
  GET_TICKET_DETAIL,
  GET_TICKET_DETAIL_ERROR,
  GET_TICKET_DETAIL_SUCCESS,
  GET_TICKET_LIST,
  GET_TICKET_LIST_ERROR,
  GET_TICKET_LIST_SUCCESS,
  GET_TRANSCODE_MOVIE,
  GET_TRANSCODE_MOVIE_ERROR,
  GET_TRANSCODE_MOVIE_SUCCESS,
  GET_VIRTUAL_TOOL,
  GET_VIRTUAL_TOOL_ERROR,
  GET_VIRTUAL_TOOL_SUCCESS,
  GET_WEEKLY_AVAILABILITY,
  GET_WEEKLY_AVAILABILITY_ERROR,
  GET_WEEKLY_AVAILABILITY_SUCCESS,
  POSTPOND_CALL,
  POSTPOND_CALL_ERROR,
  POSTPOND_CALL_SUCCESS,
  POST_BEST_BEES,
  POST_BEST_BEES_ERROR,
  POST_BEST_BEES_SUCCESS,
  POST_COMPANY_DETAILS,
  POST_COMPANY_DETAILS_ERROR,
  POST_COMPANY_DETAILS_SUCCESS,
  POST_COMPANY_LIST,
  POST_COMPANY_LIST_ERROR,
  POST_COMPANY_LIST_SUCCESS,
  POST_MARK_PAID_OR_DUE,
  POST_MARK_PAID_OR_DUE_ERROR,
  POST_MARK_PAID_OR_DUE_SUCCESS,
  POST_SHOOTER_AVAILABILITY,
  GET_COMPANY_LINK_STATUS,
  GET_COMPANY_LINK_STATUS_SUCCESS,
  GET_COMPANY_LINK_STATUS_ERROR,
  POST_SHOOTER_AVAILABILITY_ERROR,
  POST_SHOOTER_AVAILABILITY_SUCCESS,
  PUT_BULK_REQUEST,
  PUT_BULK_REQUEST_ERROR,
  PUT_BULK_REQUEST_SUCCESS,
  PUT_KEYPICKUP_REQUEST,
  PUT_KEYPICKUP_REQUEST_ERROR,
  PUT_KEYPICKUP_REQUEST_SUCCESS,
  PUT_MARK_PAID,
  PUT_MARK_PAID_ERROR,
  PUT_MARK_PAID_SUCCESS,
  PUT_MOVIE_DETAIL,
  PUT_MOVIE_DETAIL_ERROR,
  PUT_MOVIE_DETAIL_SUCCESS,
  PUT_ORDER_REQUEST,
  PUT_ORDER_REQUEST_ERROR,
  PUT_ORDER_REQUEST_SUCCESS,
  PUT_ORDER_SPECIAL_REQUEST,
  PUT_ORDER_SPECIAL_REQUEST_ERROR,
  PUT_ORDER_SPECIAL_REQUEST_SUCCESS,
  GET_PERMISSION_REQ,
  GET_PERMISSION_REQ_SUCCESS,
  GET_PERMISSION_REQ_ERROR,
  GET_GROUPS_REQ,
  GET_GROUPS_REQ_SUCCESS,
  GET_GROUPS_REQ_ERROR,
  POST_PENDING_ASSET,
  POST_PENDING_ASSET_SUCCESS,
  POST_PENDING_ASSET_ERROR,
} from '../../types/actions/Dashboard.action';
import {Dashboard} from '../../types/models/Dashboard';

const INIT_STATE: Dashboard = {
  error: '',
  menuLoading: false,
  MenuList: [],
  OrderList: [],
  OrderDetails: null,
  detailsLoading: null,
  ManagementLoading: true,
  ManagementLargeTabs: [],
  ManagementSmallTabs: null,
  ClientLoading: null,
  ClientDropDown: [],
  PackageLoading: null,
  PackageArray: [],
  ProductLoading: null,
  ProductArray: [],
  ProductAddonLoading: null,
  ProductAddons: [],
  PropertyLoading: null,
  PropertyStatus: [],
  PriceModifiersLoading: null,
  PriceModifiers: [],
  RequestLoading: null,
  RequestList: [],
  RequestData: [],
  UpdatedRequest: [],
  ActionLoading: null,
  Actions: [],
  bestBeesLoading: null,
  bestBees: {},
  companyDetailsPostLoading: null,
  companyDetailsPost: {},
  ManualBeesLoading: null,
  ManualBees: [],
  TicketLoading: null,
  TicketLists: [],
  TicketDetailLoading: null,
  TicketDetails: {},
  beeListLoading: null,
  beeList: [],
  companyListLoading: null,
  postpondLoading: null,
  beeNotesLoading: null,
  companyList: [],
  TaskListLoading: null,
  TaskList: [],
  OrderTicketLoading: null,
  OrderTicket: [],
  BillingListLoading: null,
  BillingList: {},
  OrderSpecificRequestLoading: null,
  OrderSpecificRequest: [],
  DashboardTicketLoading: null,
  DashboardTicke: {},
  CompanyDetailsLoading: null,
  CompanyDetails: {},
  QuestionnaireLoading: null,
  Questionnaire: [],
  invoiceLoading: null,
  Invoices: {},
  BeeInvoicesLoading: null,
  BeeInvoiceList: [],
  PromocodeLoading: null,
  PromocodeList: {},
  TaskDetailsLoading: null,
  TaskDetails: {},
  OverViewLoading: null,
  OverView: {},
  PromocodeUserListLoading: null,
  PromocodeUserList: [],
  PromocodeCompanyListLoading: null,
  PromocodeCompanyList: [],
  HostingPeriodLoading: null,
  HostingPeriod: [],
  OrderAssetDetailsLoading: null,
  OrderAssetDetails: {},
  availabilityLoading: true,
  AvailableShooter: [],
  GroupManualLoading: null,
  GroupManualBees: [],
  orderStatsLoading: null,
  orderStats: {},
  BeeInvoiceDetailsLoading: null,
  BeeInvoiceDetails: {},
  AllCompanyUserLoading: null,
  AllCompanyUser: [],
  DebtorListLoading: null,
  DebtorList: [],
  CreditorListLoading: null,
  CreditorList: [],
  DebtorDetailsLoading: null,
  DebtorDetail: {},
  CreditorDetailsLoading: null,
  CreditorDetails: {},
  ActiveOrderLoading: null,
  ActiveOrderList: [],
  MatterPortLoading: null,
  MatterPortDetails: {},
  DownloadFileLoading: null,
  DownloadFile: {},
  PutMarkPaidLoading: null,
  PutMarkPaid: {},
  BackedCompanyStatsLoading: null,
  BackedCompanyStats: {},
  BackendCountryStats: {},
  BackendRegionStats: {},
  OrderStatsLoading: null,
  OrderStats: [],
  backendStripeLoading: null,
  backendStripe: [],
  cardlessLoading: null,
  cardlessEventData: [],
  cardlessEventDetails: {},
  cardlessMandateList: [],
  cardlessRedirectList: [],
  cardlessPaymentList: [],
  cardlessFailedDetails: {},
  cardlessPaymentDetails: {},
  cardlessMandateDetails: {},
  RedirectFlowDetails: {},
  infoPageLoading: null,
  infoDetails: {},
  clientListLoading: null,
  clientList: {},
  beesListLoading: null,
  beesList: [],
  companyListNewLoading: null,
  companyListNew: {},
  beesListNewLoading: null,
  beesListNew: {},
  movieListLoading: null,
  movieList: {},
  companyListAllLoading: null,
  companyListAll: [],
  movieLoading: null,
  movieDetails: {},
  movieTranscodeDetails: {},
  movieAnalyticsDetails: {},
  BackendAvailabilityLoading: null,
  BackendMonthAvailability: {},
  BackendWeekAvailability: {},
  BackendDailyAvailability: {},
  CalendarLoading: null,
  CalendarMonthlyData: [],
  PaymentLoading: null,
  PaymentList: [],
  WeblinkLoading: false,
  Weblink: {},
  statsLoading: null,
  statsData: [],
  dateRangeData: [],
  markPaidOrDueLoading: false,
  markPaidOrDue: {},
  calenderMonthViewLoading: false,
  calenderMonthView: {},

  calenderDayViewLoading: false,
  calenderDayView: {},
  calenderMonthViewAvailLoading: false,
  calenderMonthViewAvail: {},
  calenderMonthViewDayLoading: false,
  calenderMonthViewDay: {},
  sageCsvLoading: false,
  sageCsv: {},
  profileLoading: null,
  ProfileData: {},
  PayLoading: null,
  PayDetails: {},
  openTicketsLoadings: null,
  openTickets: {},
  closeTicketLoading: null,
  closeTickets: {},
  putMovieDetailLoading: null,
  putMovieDetail: {},
  VirtualToolLoading: null,
  VirtualTool: {},
  tourLoading: null,
  tourData: {},

  WeblinkData: {},
  PreferncesLoading: true,
  Perferences: [],
  MusicLoading: true,
  MusicList: [],
  UserTypeLoading: true,
  UserTypes: [],
  PermissionsLoading: true,
  Permissions: [],
  GroupsLoading: true,
  Groups: [],
  SlotsLoading: true,
  SlotsLIst: [],
  beeKitListLoading: true,
  beeKitList: [],
  RegionsLoading: false,
  RegionsList: [],
  BrochureLoading: false,
  BrochureList: [],
  PublicLogo: false,
  PublicLogoList: [],
  ProjectFileLoading: false,
  ProjectList: [],
  WaterMarkLoading: false,
  WaterMarkList: [],
  AccountListLoading: false,
  AccountList: [],
  ListingSiteLoading: false,
  ListingSite: [],
  OrderTotalPriceLoading: false,
  OrderTotalPrice: {},
  ApporveTaskLoading: null,
  ApproveTask: {},
  PostLoading: null,
  Post: {},
  PostInvitationLoading: false,
  PostInvitation: {},
  DeleteCompanyLoading: false,
  DeleteCompany: {},
  GetCountryLoading: null,
  CountryList: [],
  UpdateCompanyLoading: null,
  UpdateCompany: {},
  linkLoading: null,
  linkLoadData: {},
  PostTicketLoading: null,
  PostTicket: {},
  ReshootLoading: null,
  UnAvailableList: [],
  AvailableList: [],
  UpdateBeeLoading: null,
  UpdateBeeDetails: [],
  ZipDowloadLoading: null,
  ZipDETAILS: [],
  AllShooterLoading: null,
  AllShooters: [],
  ReshootPostLoading: null,
  Reshoot: {},
  AllocationLoading: false,
  Allocation: {},
  InvoiceEditLoading: false,
  InvoiceEditDetails: {},
  msl_loading: false,
        msl_details: {},
};

const DashboardReducer = (
  state = INIT_STATE,
  action: DashboardActionsTypes,
): any => {
  switch (action.type) {
    case FETCH_MENU_LIST: {
      return {
        ...state,
        menuLoading: true,
      };
    }

    case FETCH_MENULIST_SUCCESSFULL: {
      return {
        ...state,
        menuLoading: false,
        MenuList: action.payload,
      };
    }

    case FETCH_MENULIST_FAILED: {
      return {
        ...state,
        menuLoading: false,
        error: action.payload,
      };
    }

    case FETCH_ORDER_TABLE_DETAILS: {
      return {
        ...state,
        detailsLoading: true,
      };
    }

    case FETCH_ORDER_TABLE_DETAILS_SUCCESSFULL: {
      return {
        ...state,
        detailsLoading: false,
        OrderList: action.payload,
      };
    }

    case FETCH_ORDER_TABLE_DETAILS_FAILED: {
      return {
        ...state,
        detailsLoading: false,
        error: action.payload,
      };
    }

    case FETCH_ORDER_DETAILS_REQUEST: {
      return {
        ...state,
        detailsLoading: true,
      };
    }

    case FETCH_ORDER_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        detailsLoading: false,
        OrderDetails: action.payload,
      };
    }
    case FETCH_ORDER_DETAILS_REQUEST_ERROR: {
      return {
        ...state,
        detailsLoading: false,
        error: action.payload,
      };
    }

    case FETCH_MANAGEMENT_DETAILS: {
      return {
        ...state,
        ManagementSmallTabLoading: true,
      };
    }

    case FETCH_MANAGEMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        ManagementSmallTabLoading: false,
        ManagementSmallTabs: action.payload,
      };
    }
    case FETCH_MANAGEMENT_DETAILS_ERROR: {
      return {
        ...state,
        ManagementSmallTabLoading: false,
      };
    }

    case FETCH_MANAGEMENT_TABLE_DETAILS: {
      return {
        ...state,
        ManagementLoading: true,
      };
    }

    case FETCH_MANAGEMENT_TABLE_DETAILS_SUCCESS: {
      return {
        ...state,
        ManagementLoading: false,
        ManagementLargeTabs: action.payload,
      };
    }
    case FETCH_MANAGEMENT_TABLE_DETAILS_ERROR: {
      return {
        ...state,
        ManagementLoading: false,
      };
    }

    case FETCH_CLIENT_DROPDOWN_INFORMATION: {
      return {
        ...state,
        ClientLoading: true,
      };
    }

    case FETCH_CLIENT_DROPDOWN_INFORMATION_SUCCESS: {
      return {
        ...state,
        ClientLoading: false,
        ClientDropDown: action.payload,
      };
    }
    case FETCH_CLIENT_DROPDOWN_INFORMATION_ERROR: {
      return {
        ...state,
        ClientLoading: false,
      };
    }

    case FETCH_PACKAGE_DROPDOWN: {
      return {
        ...state,
        PackageLoading: true,
      };
    }

    case FETCH_PACKAGE_DROPDOWN_SUCCESS: {
      return {
        ...state,
        PackageLoading: false,
        PackageArray: action.payload,
      };
    }
    case FETCH_PACKAGE_DROPDOWN_ERROR: {
      return {
        ...state,
        PackageLoading: false,
      };
    }

    case FETCH_PRODUCT_DROPDOWN: {
      return {
        ...state,
        ProductLoading: true,
      };
    }

    case FETCH_PRODUCT_DROPDOWN_SUCCESS: {
      return {
        ...state,
        ProductLoading: false,
        ProductArray: action.payload,
      };
    }
    case FETCH_PRODUCT_DROPDOWN_ERROR: {
      return {
        ...state,
        ProductLoading: false,
      };
    }

    case FETCH_PRODUCT_ADDON_DROPDOWN: {
      return {
        ...state,
        ProductAddonLoading: true,
      };
    }

    case FETCH_PRODUCT_ADDON_DROPDOWN_SUCCESS: {
      return {
        ...state,
        ProductAddonLoading: false,
        ProductAddons: action.payload,
      };
    }
    case FETCH_PRODUCT_ADDON_DROPDOWN_ERROR: {
      return {
        ...state,
        ProductAddonLoading: false,
      };
    }

    case FETCH_PROPERTY_REQUEST: {
      return {
        ...state,
        PropertyLoading: true,
      };
    }

    case FETCH_PROPERTY_REQUEST_SUCCESS: {
      return {
        ...state,
        PropertyLoading: false,
        PropertyStatus: action.payload,
      };
    }
    case FETCH_PROPERTY_REQUEST_ERROR: {
      return {
        ...state,
        PropertyLoading: false,
      };
    }

    case FETCH_PROPERTY_PRICE_MODIFIERS: {
      return {
        ...state,
        PriceModifiersLoading: true,
      };
    }

    case FETCH_PROPERTY_PRICE_MODIFIERS_SUCCESS: {
      return {
        ...state,
        PriceModifiersLoading: false,
        PriceModifiers: action.payload,
      };
    }
    case FETCH_PROPERTY_PRICE_MODIFIERS_ERROR: {
      return {
        ...state,
        PriceModifiersLoading: false,
      };
    }

    case GET_REQUEST_DROPDOWN_LIST: {
      return {
        ...state,
        RequestLoading: true,
      };
    }

    case GET_REQUEST_DROPDOWN_LIST_SUCCESS: {
      return {
        ...state,
        RequestLoading: false,
        RequestList: action.payload,
      };
    }
    case GET_REQUEST_DROPDOWN_LIST_ERROR: {
      return {
        ...state,
        RequestLoading: false,
      };
    }

    case GET_CALENDER_MONTH_VIEW: {
      return {
        ...state,
        calenderMonthViewLoading: true,
      };
    }

    case GET_CALENDER_MONTH_VIEW_SUCCESS: {
      return {
        ...state,
        calenderMonthViewLoading: false,
        calenderMonthView: action.payload,
      };
    }
    case GET_CALENDER_MONTH_VIEW_ERROR: {
      return {
        ...state,
        calenderMonthViewLoading: false,
      };
    }

    case GET_CALENDER_MONTH_VIEW_DAY: {
      return {
        ...state,
        calenderMonthViewDayLoading: true,
      };
    }

    case GET_CALENDER_MONTH_VIEW_DAY_SUCCESS: {
      return {
        ...state,
        calenderMonthViewDayLoading: false,
        calenderMonthViewDay: action.payload,
      };
    }
    case GET_CALENDER_MONTH_VIEW_DAY_ERROR: {
      return {
        ...state,
        calenderMonthViewDayLoading: false,
      };
    }

    case GET_SAGE_CSV_DOWNLOAD: {
      return {
        ...state,
        sageCsvLoading: true,
      };
    }

    case GET_SAGE_CSV_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        sageCsvLoading: false,
        sageCsv: action.payload,
      };
    }
    case GET_SAGE_CSV_DOWNLOAD_ERROR: {
      return {
        ...state,
        sageCsvLoading: false,
      };
    }

    case GET_CALENDER_MONTH_VIEW_AVAIL: {
      return {
        ...state,
        calenderMonthViewAvailLoading: true,
      };
    }

    case GET_CALENDER_MONTH_VIEW_AVAIL_SUCCESS: {
      return {
        ...state,
        calenderMonthViewAvailLoading: false,
        calenderMonthViewAvail: action.payload,
      };
    }
    case GET_CALENDER_MONTH_VIEW_AVAIL_ERROR: {
      return {
        ...state,
        calenderMonthViewAvailLoading: false,
      };
    }

    case GET_CALENDER_DAY_VIEW: {
      return {
        ...state,
        calenderDayViewLoading: true,
      };
    }

    case GET_CALENDER_DAY_VIEW_SUCCESS: {
      return {
        ...state,
        calenderDayViewLoading: false,
        calenderDayView: action.payload,
      };
    }
    case GET_CALENDER_DAY_VIEW_ERROR: {
      return {
        ...state,
        calenderDayViewLoading: false,
      };
    }

    case GET_REQUEST_DROPDOWN: {
      return {
        ...state,
        RequestLoading: true,
      };
    }

    case GET_REQUEST_DROPDOWN_SUCCESS: {
      return {
        ...state,
        RequestLoading: false,
        RequestData: action.payload,
      };
    }
    case GET_REQUEST_DROPDOWN_ERROR: {
      return {
        ...state,
        RequestLoading: false,
      };
    }

    case PUT_ORDER_REQUEST: {
      return {
        ...state,
        RequestLoading: true,
      };
    }

    case PUT_ORDER_REQUEST_SUCCESS: {
      return {
        ...state,
        RequestLoading: false,
        UpdatedRequest: action.payload,
      };
    }
    case PUT_ORDER_REQUEST_ERROR: {
      return {
        ...state,
        RequestLoading: false,
      };
    }

    case PUT_KEYPICKUP_REQUEST: {
      return {
        ...state,
        RequestLoading: true,
      };
    }

    case PUT_KEYPICKUP_REQUEST_SUCCESS: {
      return {
        ...state,
        ManagementLoading: false,
        UpdateKeyPickup: action.payload,
      };
    }
    case PUT_KEYPICKUP_REQUEST_ERROR: {
      return {
        ...state,
        RequestLoading: false,
      };
    }

    case GET_ORDER_BUTTONS: {
      return {
        ...state,
        ActionLoading: true,
      };
    }

    case GET_ORDER_BUTTONS_SUCCESS: {
      return {
        ...state,
        ActionLoading: false,
        Actions: action.payload,
      };
    }
    case GET_ORDER_BUTTONS_ERROR: {
      return {
        ...state,
        ActionLoading: false,
      };
    }

    case POST_BEST_BEES: {
      return {
        ...state,
        bestBeesLoading: true,
      };
    }

    case POST_BEST_BEES_SUCCESS: {
      return {
        ...state,
        bestBeesLoading: false,
        bestBees: action.payload,
      };
    }

    case POST_BEST_BEES_ERROR: {
      return {
        ...state,
        bestBeesLoading: false,
      };
    }

    case POST_COMPANY_DETAILS: {
      return {
        ...state,
        companyDetailsPostLoading: true,
      };
    }

    case POST_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        companyDetailsPostLoading: false,
        companyDetailsPost: action.payload,
      };
    }

    case POST_COMPANY_DETAILS_ERROR: {
      return {
        ...state,
        companyDetailsPostLoading: false,
      };
    }

    case POST_MARK_PAID_OR_DUE: {
      return {
        ...state,
        markPaidOrDueLoading: true,
      };
    }

    case POST_MARK_PAID_OR_DUE_SUCCESS: {
      return {
        ...state,
        markPaidOrDueLoading: false,
        markPaidOrDue: action.payload,
      };
    }

    case POST_MARK_PAID_OR_DUE_ERROR: {
      return {
        ...state,
        markPaidOrDueLoading: false,
      };
    }

    case GET_MANUAL_BEES: {
      return {
        ...state,
        availabilityLoading: true,
        ManualBeesLoading: true,
      };
    }

    case GET_MANUAL_BEES_SUCCESS: {
      return {
        ...state,
        ManualBeesLoading: false,
        availabilityLoading: false,
        ManualBees: action.payload,
      };
    }

    case GET_MANUAL_BEES_ERROR: {
      return {
        ...state,
        ManualBeesLoading: false,
      };
    }
    case GET_TICKET_LIST: {
      return {
        ...state,
        TicketLoading: true,
      };
    }

    case GET_TICKET_LIST_ERROR: {
      return {
        ...state,
        TicketLoading: false,
      };
    }

    case GET_TICKET_LIST_SUCCESS: {
      return {
        ...state,
        TicketLoading: false,
        TicketLists: action.payload,
      };
    }

    case GET_TICKET_DETAIL: {
      return {
        ...state,
        TicketDetailLoading: true,
      };
    }

    case GET_TICKET_DETAIL_ERROR: {
      return {
        ...state,
        TicketDetailLoading: false,
      };
    }

    case GET_TICKET_DETAIL_SUCCESS: {
      return {
        ...state,
        TicketDetailLoading: false,
        TicketDetails: action.payload,
      };
    }

    case GET_COMPANY_LIST: {
      return {
        ...state,
        companyListLoading: true,
      };
    }

    case GET_COMPANY_LIST_ERROR: {
      return {
        ...state,
        companyListLoading: false,
      };
    }

    case GET_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        companyListLoading: false,
        companyList: action.payload,
      };
    }
    case POSTPOND_CALL: {
      return {
        ...state,
        postpondLoading: true,
      };
    }

    case POSTPOND_CALL_ERROR: {
      return {
        ...state,
        postpondLoading: false,
      };
    }

    case POSTPOND_CALL_SUCCESS: {
      return {
        ...state,
        postpondLoading: false,
        // companyList: action.payload
      };
    }
    case BEE_NOTES: {
      return {
        ...state,
        beeNotesLoading: true,
      };
    }

    case BEE_NOTES_ERROR: {
      return {
        ...state,
        beeNotesLoading: false,
      };
    }

    case BEE_NOTES_SUCCESS: {
      return {
        ...state,
        beeNotesLoading: false,
        // companyList: action.payload
      };
    }

    case GET_COMPANY_LIST_ALL: {
      return {
        ...state,
        companyListAllLoading: true,
      };
    }

    case GET_COMPANY_LIST_ALL_ERROR: {
      return {
        ...state,
        companyListAllLoading: false,
      };
    }

    case GET_COMPANY_LIST_ALL_SUCCESS: {
      return {
        ...state,
        companyListAllLoading: false,
        companyListAll: action.payload,
      };
    }

    case GET_TASK_LIST: {
      return {
        ...state,
        TaskListLoading: true,
      };
    }

    case GET_TASK_LIST_SUCCESS: {
      return {
        ...state,
        TaskListLoading: false,
        TaskList: action.payload,
      };
    }

    case GET_TASK_LIST_ERROR: {
      return {
        ...state,
        TaskListLoading: false,
      };
    }

    case GET_ORDER_TICEKT_LIST: {
      return {
        ...state,
        OrderTicketLoading: true,
      };
    }

    case GET_ORDER_TICEKT_LIST_SUCCESS: {
      return {
        ...state,
        OrderTicketLoading: false,
        OrderTicket: action.payload,
      };
    }

    case GET_ORDER_TICEKT_LIST_ERROR: {
      return {
        ...state,
        OrderTicketLoading: false,
      };
    }

    case GET_BILLING_REQUEST: {
      return {
        ...state,
        BillingListLoading: true,
      };
    }

    case GET_BILLING_REQUEST_SUCCESS: {
      return {
        ...state,
        BillingListLoading: false,
        BillingList: action.payload,
      };
    }

    case GET_BILLING_REQUEST_ERROR: {
      return {
        ...state,
        BillingListLoading: false,
      };
    }

    case GET_ORDER_SPECIALREQUEST: {
      return {
        ...state,
        OrderSpecificRequestLoading: true,
      };
    }

    case GET_ORDER_SPECIALREQUEST_SUCCESS: {
      return {
        ...state,
        OrderSpecificRequestLoading: false,
        OrderSpecificRequest: action.payload,
      };
    }

    case GET_ORDER_SPECIALREQUEST_ERROR: {
      return {
        ...state,
        OrderSpecificRequestLoading: false,
      };
    }
    case PUT_ORDER_SPECIAL_REQUEST: {
      return {
        ...state,
        OrderSpecificRequestLoading: true,
      };
    }

    case PUT_ORDER_SPECIAL_REQUEST_SUCCESS: {
      return {
        ...state,
        OrderSpecificRequestLoading: false,
        OrderSpecificRequest: action.payload,
      };
    }

    case PUT_ORDER_SPECIAL_REQUEST_ERROR: {
      return {
        ...state,
        OrderSpecificRequestLoading: false,
      };
    }

    case GET_DASHBOARD_TICKET_DETAIL: {
      return {
        ...state,
        DashboardTicketLoading: true,
      };
    }

    case GET_DASHBOARD_TICKET_DETAIL_SUCCESS: {
      return {
        ...state,
        DashboardTicketLoading: false,
        DashboardTicket: action.payload,
      };
    }

    case GET_DASHBOARD_TICKET_DETAIL_ERROR: {
      return {
        ...state,
        DashboardTicketLoading: false,
      };
    }

    case GET_COMPANY_DETAILS: {
      return {
        ...state,
        CompanyDetailsLoading: true,
      };
    }

    case GET_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        CompanyDetailsLoading: false,
        CompanyDetails: action.payload,
      };
    }

    case GET_COMPANY_DETAILS_ERROR: {
      return {
        ...state,
        CompanyDetailsLoading: false,
      };
    }

    case GET_QUESTIONNAIRE_REQUEST: {
      return {
        ...state,
        QuestionnaireLoading: true,
      };
    }

    case GET_QUESTIONNAIRE_REQUEST_SUCCESS: {
      return {
        ...state,
        QuestionnaireLoading: false,
        Questionnaire: action.payload,
      };
    }

    case GET_QUESTIONNAIRE_REQUEST_ERROR: {
      return {
        ...state,
        QuestionnaireLoading: false,
      };
    }

    case GET_INVOICE_DETAILS: {
      return {
        ...state,
        InvoicesLoading: false,
      };
    }

    case GET_INVOICE_DETAILS_SUCCESS: {
      return {
        ...state,
        invoiceLoading: false,
        Invoices: action.payload,
      };
    }

    case GET_INVOICE_DETAILS_ERROR: {
      return {
        ...state,
        invoiceLoading: false,
      };
    }

    case GET_FILTER_TASK: {
      return {
        ...state,
        TaskListLoading: true,
      };
    }

    case GET_FILTER_TASK_SUCCESS: {
      return {
        ...state,
        TaskListLoading: false,
        TaskList: action.payload,
      };
    }

    case GET_FILTER_TASK_ERROR: {
      return {
        ...state,
        TaskListLoading: false,
      };
    }

    case BILLING_FILTER_INVOICE: {
      return {
        ...state,
        BillingListLoading: true,
      };
    }

    case BILLING_FILTER_INVOICE_SUCCESS: {
      return {
        ...state,
        BillingListLoading: false,
        BillingList: action.payload,
      };
    }

    case BILLING_FILTER_INVOICE_ERROR: {
      return {
        ...state,
        BillingListLoading: false,
      };
    }
    case GET_BEE_INVOICE: {
      return {
        ...state,
        BeeInvoicesLoading: true,
      };
    }

    case GET_BEE_INVOICE_SUCCESS: {
      return {
        ...state,

        BeeInvoicesLoading: false,
        BeeInvoiceList: action.payload,
      };
    }

    case GET_BEE_INVOICE_ERROR: {
      return {
        ...state,
        BeeInvoicesLoading: false,
      };
    }

    case GET_PROMOCODE_LIST: {
      return {
        ...state,
        PromocodeLoading: true,
      };
    }

    case GET_PROMOCODE_LIST_SUCCESS: {
      return {
        ...state,

        PromocodeLoading: false,
        PromocodeList: action.payload,
      };
    }

    case GET_PROMOCODE_LIST_ERROR: {
      return {
        ...state,
        BeeInvoicesLoading: false,
      };
    }

    case GET_TASK_DETAILS: {
      return {
        ...state,
        TaskDetailsLoading: true,
      };
    }

    case GET_TASK_DETAILS_SUCCESS: {
      return {
        ...state,
        TaskDetailsLoading: false,
        TaskDetails: action.payload,
      };
    }

    case GET_TASK_DETAILS_ERROR: {
      return {
        ...state,
        TaskDetailsLoading: false,
      };
    }

    case GET_COMPANY_PROMOCODE_LIST: {
      return {
        ...state,
        PromocodeLoading: true,
      };
    }

    case GET_COMPANY_PROMOCODE_LIST_SUCCESS: {
      return {
        ...state,
        PromocodeLoading: false,
        PromocodeList: action.payload,
      };
    }

    case GET_COMPANY_PROMOCODE_LIST_ERROR: {
      return {
        ...state,
        PromocodeLoading: false,
      };
    }

    case GET_BEE_FILTER_INVOICE: {
      return {
        ...state,
        BeeInvoicesLoading: true,
      };
    }

    case GET_BEE_FILTER_INVOICE_SUCCESS: {
      return {
        ...state,
        BeeInvoicesLoading: false,
        BeeInvoiceList: action.payload,
      };
    }

    case GET_BEE_FILTER_INVOICE_ERROR: {
      return {
        ...state,
        BeeInvoicesLoading: false,
      };
    }
    case GET_OVERVIEW_DETAILS: {
      return {
        ...state,
        OverViewLoading: true,
      };
    }

    case GET_OVERVIEW_DETAILS_SUCCESS: {
      return {
        ...state,
        OverViewLoading: false,
        OverView: action.payload,
      };
    }

    case GET_OVERVIEW_DETAILS_ERROR: {
      return {
        ...state,
        OverViewLoading: false,
      };
    }

    case GET_PROMOCODE_USER_LIST: {
      return {
        ...state,
        PromocodeUserListLoading: true,
      };
    }

    case GET_PROMOCODE_USER_LIST_SUCCESS: {
      return {
        ...state,
        PromocodeUserListLoading: false,
        PromocodeUserList: action.payload,
      };
    }

    case GET_PROMOCODE_USER_LIST_ERROR: {
      return {
        ...state,
        PromocodeUserListLoading: false,
      };
    }

    case GET_PROMO_CODE_COMPANY: {
      return {
        ...state,
        PromocodeCompanyListLoading: true,
      };
    }

    case GET_PROMO_CODE_COMPANY_SUCCESS: {
      return {
        ...state,
        PromocodeCompanyListLoading: false,
        PromocodeCompanyList: action.payload,
      };
    }

    case GET_PROMO_CODE_COMPANY_ERROR: {
      return {
        ...state,
        PromocodeCompanyListLoading: false,
      };
    }

    case GET_HOSTING_PERIOD: {
      return {
        ...state,
        HostingPeriodLoading: true,
      };
    }

    case GET_HOSTING_PERIOD_SUCCESS: {
      return {
        ...state,
        HostingPeriodLoading: false,
        HostingPeriod: action.payload,
      };
    }

    case GET_HOSTING_PERIOD_ERROR: {
      return {
        ...state,
        HostingPeriodLoading: false,
      };
    }

    case GET_ORDER_ASSET: {
      return {
        ...state,
        OrderAssetDetailsLoading: true,
      };
    }

    case GET_ORDER_ASSET_SUCCESS: {
      return {
        ...state,
        OrderAssetDetailsLoading: false,
        OrderAssetDetails: action.payload,
      };
    }

    case GET_ORDER_ASSET_ERROR: {
      return {
        ...state,
        OrderAssetDetailsLoading: false,
      };
    }

    case PUT_BULK_REQUEST: {
      return {
        ...state,
        RequestLoading: true,
      };
    }

    case PUT_BULK_REQUEST_SUCCESS: {
      return {
        ...state,
        RequestLoading: false,
        UpdatedRequest: action.payload,
      };
    }

    case PUT_BULK_REQUEST_ERROR: {
      return {
        ...state,
        RequestLoading: false,
      };
    }

    case PUT_MOVIE_DETAIL: {
      return {
        ...state,
        putMovieDetailLoading: true,
      };
    }

    case PUT_MOVIE_DETAIL_SUCCESS: {
      return {
        ...state,
        putMovieDetailLoading: false,
        putMovieDetail: action.payload,
      };
    }

    case PUT_MOVIE_DETAIL_ERROR: {
      return {
        ...state,
        putMovieDetailLoading: false,
      };
    }

    case POST_SHOOTER_AVAILABILITY: {
      return {
        ...state,
        availabilityLoading: true,
      };
    }

    case POST_SHOOTER_AVAILABILITY_SUCCESS: {
      let updatedAvailableShooter = [...state.AvailableShooter];

      if (action.payload.clearPrevious) {
        // If clearPrevious flag is true, remove previous data
        updatedAvailableShooter = [];
      }

      // Add new data to the updatedAvailableShooter array
      updatedAvailableShooter.push(...action.payload.dataToAdd);

      return {
        ...state,
        availabilityLoading: false,
        AvailableShooter: updatedAvailableShooter,
      };
    }

    case POST_SHOOTER_AVAILABILITY_ERROR: {
      return {
        ...state,
        availabilityLoading: false,
      };
    }

    case GET_GROUP_MANUAL_BEES: {
      return {
        ...state,
        GroupManualLoading: true,
        GroupManualBees: [...(state?.GroupManualBees ?? [])],
      };
    }

    case GET_GROUP_MANUAL_BEES_SUCCESS: {
      return {
        ...state,
        GroupManualLoading: false,
        GroupManualBees: [...(state?.GroupManualBees ?? []), action.payload],
      };
    }

    case GET_GROUP_MANUAL_BEES_ERROR: {
      return {
        ...state,
        GroupManualLoading: false,
      };
    }

    case GET_ORDER_STATS_DETAILS: {
      return {
        ...state,
        orderStatsLoading: true,
      };
    }

    case GET_ORDER_STATS_DETAILS_SUCCESS: {
      return {
        ...state,
        orderStatsLoading: false,
        orderStats: action.payload,
      };
    }

    case GET_ORDER_STATS_DETAILS_ERROR: {
      return {
        ...state,
        orderStatsLoading: false,
      };
    }

    case GET_BEE_INVOICE_DETAILS: {
      return {
        ...state,
        BeeInvoiceDetailsLoading: true,
      };
    }

    case GET_BEE_INVOICE_DETAILS_SUCCESS: {
      return {
        ...state,
        BeeInvoiceDetailsLoading: false,
        BeeInvoiceDetails: action.payload,
      };
    }

    case GET_BEE_INVOICE_DETAILS_ERROR: {
      return {
        ...state,
        BeeInvoiceDetailsLoading: false,
      };
    }

    case GET_FILTER_ORDER: {
      return {
        ...state,
        detailsLoading: true,
      };
    }

    case GET_FILTER_ORDER_SUCCESS: {
      return {
        ...state,
        detailsLoading: false,
        OrderList: action.payload,
      };
    }

    case GET_FILTER_ORDER_ERROR: {
      return {
        ...state,
        detailsLoading: false,
      };
    }

    case GET_ALL_USER: {
      return {
        ...state,
        AllCompanyUserLoading: true,
      };
    }

    case GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        AllCompanyUserLoading: false,
        AllCompanyUser: action.payload,
      };
    }

    case GET_ALL_USER_ERROR: {
      return {
        ...state,
        AllCompanyUserLoading: false,
      };
    }

    case GET_DEBTOR_LIST: {
      return {
        ...state,
        DebtorListLoading: true,
      };
    }

    case GET_DEBTOR_LIST_SUCCESS: {
      return {
        ...state,
        DebtorListLoading: false,
        DebtorList: action.payload,
      };
    }

    case GET_DEBTOR_LIST_ERROR: {
      return {
        ...state,
        DebtorListLoading: false,
      };
    }

    case GET_DEBTOR_DETAILS: {
      return {
        ...state,
        DebtorDetailsLoading: true,
      };
    }

    case GET_DEBTOR_DETAILS_SUCCESS: {
      return {
        ...state,
        DebtorDetailsLoading: false,
        DebtorDetail: action.payload,
      };
    }

    case GET_DEBTOR_DETAILS_ERROR: {
      return {
        ...state,
        DebtorDetailsLoading: false,
      };
    }

    case GET_CREDITOR_LIST: {
      return {
        ...state,
        CreditorListLoading: true,
      };
    }

    case GET_CREDITOR_LIST_SUCCESS: {
      return {
        ...state,
        CreditorListLoading: false,
        CreditorList: action.payload,
      };
    }

    case GET_CREDITOR_LIST_ERROR: {
      return {
        ...state,
        CreditorListLoading: false,
      };
    }

    case GET_CREDITOR_DETAILS: {
      return {
        ...state,
        CreditorDetailsLoading: true,
      };
    }

    case GET_CREDITOR_DETAILS_SUCCESS: {
      return {
        ...state,
        CreditorDetailsLoading: false,
        CreditorDetails: action.payload,
      };
    }

    case GET_CREDITOR_DETAILS_ERROR: {
      return {
        ...state,
        CreditorDetailsLoading: false,
      };
    }

    case GET_ACTIVE_ORDER: {
      return {
        ...state,
        ActiveOrderLoading: true,
      };
    }

    case GET_ACTIVE_ORDER_SUCCESS: {
      return {
        ...state,
        ActiveOrderLoading: false,
        ActiveOrderList: action.payload,
      };
    }

    case GET_ACTIVE_ORDER_ERROR: {
      return {
        ...state,
        ActiveOrderLoading: false,
      };
    }

    case GET_MATTERPORT_DETAILS: {
      return {
        ...state,
        MatterPortLoading: true,
      };
    }

    case GET_MATTERPORT_DETAILS_SUCCESS: {
      return {
        ...state,
        MatterPortLoading: false,
        MatterPortDetails: action.payload,
      };
    }

    case GET_MATTERPORT_DETAILS_ERROR: {
      return {
        ...state,
        MatterPortLoading: false,
      };
    }

    case GET_DEBTOR_CSV: {
      return {
        ...state,
        DownloadFileLoading: true,
      };
    }

    case GET_DEBTOR_CSV_SUCCESS: {
      return {
        ...state,
        DownloadFileLoading: false,
        DownloadFile: action.payload,
      };
    }

    case GET_DEBTOR_CSV_ERROR: {
      return {
        ...state,
        DownloadFileLoading: false,
      };
    }

    case GET_CREDITOR_CSV: {
      return {
        ...state,
        DownloadFileLoading: true,
      };
    }

    case GET_CREDITOR_CSV_SUCCESS: {
      return {
        ...state,
        DownloadFileLoading: false,
        DownloadFile: action.payload,
      };
    }

    case GET_CREDITOR_CSV_ERROR: {
      return {
        ...state,
        DownloadFileLoading: false,
      };
    }

    case PUT_MARK_PAID: {
      return {
        ...state,
        PutMarkPaidLoading: true,
      };
    }

    case PUT_MARK_PAID_SUCCESS: {
      return {
        ...state,
        PutMarkPaidLoading: false,
        PutMarkPaid: action.payload,
      };
    }

    case PUT_MARK_PAID_ERROR: {
      return {
        ...state,
        PutMarkPaidLoading: false,
      };
    }

    case GET_BACKEND_COMPANY_STATS: {
      return {
        ...state,
        BackedCompanyStatsLoading: true,
      };
    }

    case GET_BACKEND_COMPANY_STATS_SUCCESS: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
        BackedCompanyStats: action.payload,
      };
    }

    case GET_BACKEND_COMPANY_STATS_ERROR: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
      };
    }

    case GET_ORDER_STATS: {
      return {
        ...state,
        OrderStatsLoading: true,
      };
    }

    case GET_ORDER_STATS_SUCCESS: {
      return {
        ...state,
        OrderStatsLoading: false,
        OrderStats: action.payload,
      };
    }

    case GET_ORDER_STATS_ERROR: {
      return {
        ...state,
        OrderStatsLoading: false,
      };
    }

    case GET_BACKEND_STRIPE: {
      return {
        ...state,
        backendStripeLoading: true,
      };
    }

    case GET_BACKEND_STRIPE_SUCCESS: {
      return {
        ...state,
        backendStripeLoading: false,
        backendStripe: action.payload,
      };
    }

    case GET_BACKEND_STRIPE_ERROR: {
      return {
        ...state,
        backendStripeLoading: false,
      };
    }

    case GET_CARDLESS_EVENT_LIST: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_CARDLESS_EVENT_LIST_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessEventData: action.payload,
      };
    }

    case GET_CARDLESS_EVENT_LIST_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_CARDLESS_EVENT_DETAILS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_CARDLESS_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessEventDetails: action.payload,
      };
    }

    case GET_CARDLESS_EVENT_DETAILS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_FAILED_DD: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_FAILED_DD_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessFailedDD: action.payload,
      };
    }

    case GET_FAILED_DD_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_MANDATE_LIST: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_MANDATE_LIST_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessMandateList: action.payload,
      };
    }

    case GET_MANDATE_LIST_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_REDIRECT_FLOWS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_REDIRECT_FLOWS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessRedirectList: action.payload,
      };
    }

    case GET_REDIRECT_FLOWS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_CARDLESS_PAYMENT: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_CARDLESS_PAYMENT_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessPaymentList: action.payload,
      };
    }

    case GET_CARDLESS_PAYMENT_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_FAILED_DD_DETAILS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_FAILED_DD_DETAILS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessFailedDetails: action.payload,
      };
    }

    case GET_FAILED_DD_DETAILS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_REDIRECT_FLOW_DETAILS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_REDIRECT_FLOW_DETAILS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        RedirectFlowDetails: action.payload,
      };
    }

    case GET_REDIRECT_FLOW_DETAILS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_INFORMATION_PAGE: {
      return {
        ...state,
        infoPageLoading: true,
      };
    }

    case GET_INFORMATION_PAGE_SUCCESS: {
      return {
        ...state,
        infoPageLoading: false,
        infoDetails: action.payload,
      };
    }

    case GET_INFORMATION_PAGE_ERROR: {
      return {
        ...state,
        infoPageLoading: false,
      };
    }

    case GET_CLIENT_LIST: {
      return {
        ...state,
        clientListLoading: true,
      };
    }

    case GET_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        clientListLoading: false,
        clientList: action.payload,
      };
    }

    case GET_CLIENT_LIST_ERROR: {
      return {
        ...state,
        clientListLoading: false,
      };
    }

    case GET_BEES_LIST: {
      return {
        ...state,
        beesListLoading: true,
      };
    }

    case GET_BEES_LIST_SUCCESS: {
      return {
        ...state,
        beesListLoading: false,
        beesList: action.payload,
      };
    }

    case GET_BEES_LIST_ERROR: {
      return {
        ...state,
        beesListLoading: false,
      };
    }

    case GET_COMPANY_LIST_NEW: {
      return {
        ...state,
        companyListNewLoading: true,
      };
    }

    case GET_COMPANY_LIST_NEW_SUCCESS: {
      return {
        ...state,
        companyListNewLoading: false,
        companyListNew: action.payload,
      };
    }

    case GET_COMPANY_LIST_NEW_ERROR: {
      return {
        ...state,
        companyListNewLoading: false,
      };
    }

    case GET_BEES_LIST_NEW: {
      return {
        ...state,
        beesListNewLoading: true,
      };
    }

    case GET_BEES_LIST_NEW_SUCCESS: {
      return {
        ...state,
        beesListNewLoading: false,
        beesListNew: action.payload,
      };
    }

    case GET_BEES_LIST_NEW_ERROR: {
      return {
        ...state,
        beesListNewLoading: false,
      };
    }

    case POST_COMPANY_LIST: {
      return {
        ...state,
        companyListLoading: true,
      };
    }

    case POST_COMPANY_LIST_SUCCESS: {
      return {
        ...state,
        companyListLoading: false,
        companyList: action.payload,
      };
    }

    case POST_COMPANY_LIST_ERROR: {
      return {
        ...state,
        companyListLoading: false,
      };
    }

    case GET_MOVIES_LIST: {
      return {
        ...state,
        movieListLoading: true,
      };
    }

    case GET_MOVIES_LIST_SUCCESS: {
      return {
        ...state,
        movieListLoading: false,
        movieList: action.payload,
      };
    }

    case GET_MOVIES_LIST_ERROR: {
      return {
        ...state,
        movieListLoading: false,
      };
    }

    case GET_COMPANY_DETAIL: {
      return {
        ...state,
        movieLoading: true,
      };
    }

    case GET_COMPANY_DETAIL_SUCCESS: {
      return {
        ...state,
        movieLoading: false,
        movieDetails: action.payload,
      };
    }

    case GET_COMPANY_DETAIL_ERROR: {
      return {
        ...state,
        movieLoading: false,
      };
    }

    case GET_TRANSCODE_MOVIE: {
      return {
        ...state,
        movieLoading: true,
      };
    }

    case GET_TRANSCODE_MOVIE_SUCCESS: {
      return {
        ...state,
        movieLoading: false,
        movieTranscodeDetails: action.payload,
      };
    }

    case GET_TRANSCODE_MOVIE_ERROR: {
      return {
        ...state,
        movieLoading: false,
      };
    }

    case GET_ANALYTICS_DETAILS: {
      return {
        ...state,
        movieLoading: true,
      };
    }

    case GET_ANALYTICS_DETAILS_SUCCESS: {
      return {
        ...state,
        movieLoading: false,
        movieAnalyticsDetails: action.payload,
      };
    }

    case GET_ANALYTICS_DETAILS_ERROR: {
      return {
        ...state,
        movieLoading: false,
      };
    }

    case GET_MENDATE_DETAILS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_MENDATE_DETAILS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessMandateDetails: action.payload,
      };
    }

    case GET_MENDATE_DETAILS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_PAYMENT_DETAILS: {
      return {
        ...state,
        cardlessLoading: true,
      };
    }

    case GET_PAYMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        cardlessLoading: false,
        cardlessPaymentDetails: action.payload,
      };
    }

    case GET_PAYMENT_DETAILS_ERROR: {
      return {
        ...state,
        cardlessLoading: false,
      };
    }

    case GET_MONTHLY_AVAILABILITY: {
      return {
        ...state,
        BackendAvailabilityLoading: true,
      };
    }

    case GET_MONTHLY_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
        BackendMonthAvailability: action.payload,
      };
    }

    case GET_MONTHLY_AVAILABILITY_ERROR: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
      };
    }

    case GET_WEEKLY_AVAILABILITY: {
      return {
        ...state,
        BackendAvailabilityLoading: true,
      };
    }

    case GET_WEEKLY_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
        BackendWeekAvailability: action.payload,
      };
    }

    case GET_WEEKLY_AVAILABILITY_ERROR: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
      };
    }

    case GET_DAILY_AVAILABILITY: {
      return {
        ...state,
        BackendAvailabilityLoading: true,
      };
    }

    case GET_DAILY_AVAILABILITY_SUCCESS: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
        BackendDailyAvailability: action.payload,
      };
    }

    case GET_DAILY_AVAILABILITY_ERROR: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
      };
    }

    case GET_CALENDAR_MONTH: {
      return {
        ...state,
        CalendarLoading: true,
      };
    }

    case GET_CALENDAR_MONTH_SUCCESS: {
      return {
        ...state,
        CalendarLoading: false,
        CalendarMonthlyData: action.payload,
      };
    }

    case GET_CALENDAR_MONTH_ERROR: {
      return {
        ...state,
        CalendarLoading: false,
      };
    }

    case GET_PAYMENT_LIST: {
      return {
        ...state,
        PaymentLoading: true,
      };
    }

    case GET_PAYMENT_LIST_SUCCESS: {
      return {
        ...state,
        PaymentLoading: false,
        PaymentList: action.payload,
      };
    }

    case GET_PAYMENT_LIST_ERROR: {
      return {
        ...state,
        PaymentLoading: false,
      };
    }

    case GET_ORDER_WEBLINK: {
      return {
        ...state,
        WeblinkLoading: true,
      };
    }

    case GET_ORDER_WEBLINK_SUCCESS: {
      return {
        ...state,
        WeblinkLoading: false,
        Weblink: action.payload,
      };
    }

    case GET_ORDER_WEBLINK_ERROR: {
      return {
        ...state,
        WeblinkLoading: false,
      };
    }

    case GET_COMPANY_STATS: {
      return {
        ...state,
        statsLoading: true,
      };
    }

    case GET_COMPANY_STATS_SUCCESS: {
      return {
        ...state,
        statsLoading: false,
        statsData: action.payload,
      };
    }

    case GET_COMPANY_STATS_ERROR: {
      return {
        ...state,
        statsLoading: false,
      };
    }

    case GET_DATE_RANGE: {
      return {
        ...state,
        BackendAvailabilityLoading: true,
      };
    }

    case GET_DATE_RANGE_SUCCESS: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
        dateRangeData: action.payload,
      };
    }

    case GET_DATE_RANGE_ERROR: {
      return {
        ...state,
        BackendAvailabilityLoading: false,
      };
    }

    case GET_PROFILE_DETAILS: {
      return {
        ...state,
        profileLoading: true,
      };
    }

    case GET_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        profileLoading: false,
        ProfileData: action.payload,
      };
    }

    case GET_PROFILE_DETAILS_ERROR: {
      return {
        ...state,
        profileLoading: false,
      };
    }

    case GET_BACKEND_COMPANY_DETAILS: {
      return {
        ...state,
        companyDetailsLoading: true,
      };
    }

    case GET_BACKEND_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        companyDetailsLoading: false,
        companyDetails: action.payload,
      };
    }

    case GET_BACKEND_COMPANY_DETAILS_ERROR: {
      return {
        ...state,
        companyDetailsLoading: false,
      };
    }

    case GET_PAY_DETAILS: {
      return {
        ...state,
        PayLoading: true,
      };
    }

    case GET_PAY_DETAILS_SUCCESS: {
      return {
        ...state,
        PayLoading: false,
        PayDetails: action.payload,
      };
    }

    case GET_PAY_DETAILS_ERROR: {
      return {
        ...state,
        PayLoading: false,
      };
    }

    case GET_OPEN_TICKETS: {
      return {
        ...state,
        openTicketsLoadings: true,
      };
    }

    case GET_OPEN_TICKETS_SUCCESS: {
      return {
        ...state,
        openTicketsLoadings: false,
        openTickets: action.payload,
      };
    }

    case GET_OPEN_TICKETS_ERROR: {
      return {
        ...state,
        openTicketsLoadings: false,
      };
    }

    case GET_CLOSED_TICKETS: {
      return {
        ...state,
        closeTicketLoading: true,
      };
    }

    case GET_CLOSED_TICKETS_SUCCESS: {
      return {
        ...state,
        closeTicketLoading: false,
        closeTickets: action.payload,
      };
    }

    case GET_CLOSED_TICKETS_ERROR: {
      return {
        ...state,
        closeTicketLoading: false,
      };
    }

    case GET_BACKEND_COUNTRY_STATS: {
      return {
        ...state,
        BackedCompanyStatsLoading: true,
      };
    }

    case GET_BACKEND_COUNTRY_STATS_SUCCESS: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
        BackendCountryStats: action.payload,
      };
    }

    case GET_BACKEND_COUNTRY_STATS_ERROR: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
      };
    }

    case GET_REGIONAL_STATS: {
      return {
        ...state,
        BackedCompanyStatsLoading: true,
      };
    }

    case GET_REGIONAL_STATS_SUCCESS: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
        BackendRegionStats: action.payload,
      };
    }

    case GET_REGIONAL_STATS_ERROR: {
      return {
        ...state,
        BackedCompanyStatsLoading: false,
      };
    }

    case GET_VIRTUAL_TOOL: {
      return {
        ...state,
        VirtualToolLoading: true,
      };
    }

    case GET_VIRTUAL_TOOL_SUCCESS: {
      return {
        ...state,
        VirtualToolLoading: false,
        VirtualTool: action.payload,
      };
    }

    case GET_VIRTUAL_TOOL_ERROR: {
      return {
        ...state,
        VirtualToolLoading: false,
      };
    }

    case GET_TOUR_THREE_SIXTY: {
      return {
        ...state,
        tourLoading: true,
      };
    }

    case GET_TOUR_THREE_SIXTY_SUCCESS: {
      return {
        ...state,
        tourLoading: false,
        tourData: action.payload,
      };
    }

    case GET_TOUR_THREE_SIXTY_ERROR: {
      return {
        ...state,
        tourLoading: false,
      };
    }

    case GET_WEBLINK_DETAILS: {
      return {
        ...state,
        WeblinkLoading: true,
      };
    }

    case GET_WEBLINK_DETAILS_SUCCESS: {
      return {
        ...state,
        WeblinkLoading: false,
        WeblinkData: action.payload,
      };
    }

    case GET_WEBLINK_DETAILS_ERROR: {
      return {
        ...state,
        WeblinkLoading: false,
      };
    }

    case GET_PREFERENCES: {
      return {
        ...state,
        PreferncesLoading: true,
      };
    }

    case GET_PREFERENCES_SUCCESS: {
      return {
        ...state,
        PreferncesLoading: false,
        Perferences: action.payload,
      };
    }

    case GET_PREFERENCES_ERROR: {
      return {
        ...state,
        PreferncesLoading: false,
      };
    }

    case GET_MUSIC_REQ: {
      return {
        ...state,
        MusicLoading: true,
      };
    }

    case GET_MUSIC_REQ_SUCCESS: {
      return {
        ...state,
        MusicLoading: false,
        MusicList: action.payload,
      };
    }

    case GET_MUSIC_REQ_ERROR: {
      return {
        ...state,
        MusicLoading: false,
      };
    }

    case GET_USER_TYPE: {
      return {
        ...state,
        UserTypeLoading: true,
      };
    }

    case GET_USER_TYPE_SUCCESS: {
      return {
        ...state,
        UserTypeLoading: false,
        UserTypes: action.payload,
      };
    }

    case GET_USER_TYPE_ERROR: {
      return {
        ...state,
        UserTypeLoading: false,
      };
    }

    case GET_PERMISSION_REQ: {
      return {
        ...state,
        PermissionsLoading: true,
      };
    }

    case GET_PERMISSION_REQ_SUCCESS: {
      return {
        ...state,
        PermissionsLoading: false,
        Permissions: action.payload,
      };
    }

    case GET_PERMISSION_REQ_ERROR: {
      return {
        ...state,
        PermissionsLoading: false,
      };
    }

    case GET_GROUPS_REQ: {
      return {
        ...state,
        GroupsLoading: true,
      };
    }

    case GET_GROUPS_REQ_SUCCESS: {
      return {
        ...state,
        GroupsLoading: false,
        Groups: action.payload,
      };
    }

    case GET_GROUPS_REQ_ERROR: {
      return {
        ...state,
        GroupsLoading: false,
      };
    }

    case GET_SLOTS_REQ: {
      return {
        ...state,
        SlotsLoading: true,
      };
    }

    case GET_SLOTS_REQ_SUCCESS: {
      return {
        ...state,
        SlotsLoading: false,
        SlotsLIst: action.payload,
      };
    }

    case GET_SLOTS_REQ_ERROR: {
      return {
        ...state,
        SlotsLoading: false,
      };
    }

    case GET_BEE_KIT_LIST: {
      return {
        ...state,
        beeKitListLoading: true,
      };
    }

    case GET_BEE_KIT_LIST_SUCCESS: {
      return {
        ...state,
        beeKitListLoading: false,
        beeKitList: action.payload,
      };
    }

    case GET_BEE_KIT_LIST_ERROR: {
      return {
        ...state,
        beeKitListLoading: false,
      };
    }

    case GET_REGION_REQ: {
      return {
        ...state,
        RegionsLoading: true,
      };
    }

    case GET_REGION_REQ_SUCCESS: {
      return {
        ...state,
        RegionsLoading: false,
        RegionsList: action.payload,
      };
    }

    case GET_REGION_REQ_ERROR: {
      return {
        ...state,
        RegionsLoading: false,
      };
    }

    case GET_BROCHURE_REQ: {
      return {
        ...state,
        BrochureLoading: true,
      };
    }

    case GET_BROCHURE_REQ_SUCCESS: {
      return {
        ...state,
        BrochureLoading: false,
        BrochureList: action.payload,
      };
    }

    case GET_BROCHURE_REQ_ERROR: {
      return {
        ...state,
        BrochureLoading: false,
      };
    }

    case GET_LISTING_SITE: {
      return {
        ...state,
        ListingSiteLoading: true,
      };
    }

    case GET_LISTING_SITE_SUCCESS: {
      return {
        ...state,
        ListingSiteLoading: false,
        ListingSite: action.payload,
      };
    }

    case GET_LISTING_SITE_ERROR: {
      return {
        ...state,
        ListingSiteLoading: false,
      };
    }

    case GET_PUBLIC_LOGO: {
      return {
        ...state,
        PublicLogo: true,
      };
    }

    case GET_PUBLIC_LOGO_SUCCESS: {
      return {
        ...state,
        PublicLogo: false,
        PublicLogoList: action.payload,
      };
    }

    case GET_PUBLIC_LOGO_ERROR: {
      return {
        ...state,
        PublicLogo: false,
      };
    }

    case GET_WATER_MARK: {
      return {
        ...state,
        WaterMarkLoading: true,
      };
    }

    case GET_WATER_MARK_SUCCESS: {
      return {
        ...state,
        WaterMarkLoading: false,
        WaterMarkList: action.payload,
      };
    }

    case GET_WATER_MARK_ERROR: {
      return {
        ...state,
        WaterMarkLoading: false,
      };
    }

    case GET_ACCOUNT_LIST: {
      return {
        ...state,
        AccountListLoading: true,
      };
    }

    case GET_ACCOUNT_LIST_SUCCESS: {
      return {
        ...state,
        AccountListLoading: false,
        AccountList: action.payload,
      };
    }

    case GET_ACCOUNT_LIST_ERROR: {
      return {
        ...state,
        AccountListLoading: false,
      };
    }

    case GET_PROJECT_FILE: {
      return {
        ...state,
        ProjectFileLoading: true,
      };
    }

    case GET_PROJECT_FILE_SUCCESS: {
      return {
        ...state,
        ProjectFileLoading: false,
        ProjectList: action.payload,
      };
    }

    case GET_PROJECT_FILE_ERROR: {
      return {
        ...state,
        ProjectFileLoading: false,
      };
    }

    case GET_ORDER_TOTAL_PRICE: {
      return {
        ...state,
        OrderTotalPriceLoading: true,
      };
    }

    case GET_ORDER_TOTAL_PRICE_SUCCESS: {
      return {
        ...state,
        OrderTotalPriceLoading: false,
        OrderTotalPrice: action.payload,
      };
    }

    case GET_ORDER_TOTAL_PRICE_ERROR: {
      return {
        ...state,
        OrderTotalPriceLoading: false,
      };
    }

    case POST_PENDING_ASSET: {
      return {
        ...state,
        ApporveTaskLoading: true,
      };
    }

    case POST_PENDING_ASSET_SUCCESS: {
      return {
        ...state,
        ApporveTaskLoading: false,
        ApproveTask: {},
      };
    }

    case POST_PENDING_ASSET_ERROR: {
      return {
        ...state,
        ApporveTaskLoading: false,
      };
    }

    case POST_APPROVE_ASSET_GROUP: {
      return {
        ...state,
        PostLoading: true,
        Post: action.payload,
      };
    }

    case POST_APPROVE_ASSET_GROUP_SUCCESS: {
      return {
        ...state,
        PostLoading: false,
        Post: action.payload,
      };
    }

    case POST_APPROVE_ASSET_GROUP_ERROR: {
      return {
        ...state,
        PostLoading: false,
      };
    }

    case POST_COMPANY_INVITATION: {
      return {
        ...state,
        PostInvitationLoading: true,
      };
    }

    case POST_COMPANY_INVITATION_SUCCESS: {
      return {
        ...state,
        PostInvitationLoading: false,
        PostInvitation: action.payload,
      };
    }

    case POST_COMPANY_INVITATION_ERROR: {
      return {
        ...state,
        PostInvitationLoading: false,
      };
    }

    case DELETE_COMPANY: {
      return {
        ...state,
        DeleteCompanyLoading: true,
      };
    }

    case DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        DeleteCompanyLoading: false,
        DeleteCompany: action.payload,
      };
    }

    case DELETE_COMPANY_ERROR: {
      return {
        ...state,
        DeleteCompanyLoading: false,
      };
    }

    case GET_COUNTRY_LIST: {
      return {
        ...state,
        GetCountryLoading: true,
      };
    }

    case GET_COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        GetCountryLoading: false,
        CountryList: action.payload,
      };
    }

    case GET_COUNTRY_LIST_ERROR: {
      return {
        ...state,
        GetCountryLoading: false,
      };
    }

    case UPDATE_COMPANY_DETAILS: {
      return {
        ...state,
        UpdateCompanyLoading: true,
      };
    }

    case UPDATE_COMPANY_DETAILS_SUCCESS: {
      return {
        ...state,
        UpdateCompanyLoading: false,
        UpdateCompany: action.payload,
      };
    }

    case UPDATE_COMPANY_DETAILS_ERROR: {
      return {
        ...state,
        UpdateCompanyLoading: false,
      };
    }

    case GET_COMPANY_LINK_STATUS: {
      return {
        ...state,
        linkLoading: true,
      };
    }

    case GET_COMPANY_LINK_STATUS_SUCCESS: {
      return {
        ...state,
        linkLoading: false,
        linkLoadData: action.payload,
      };
    }

    case GET_COMPANY_LINK_STATUS_ERROR: {
      return {
        ...state,
        linkLoading: false,
      };
    }

    case POST_NEW_TICKET: {
      return {
        ...state,
        PostTicketLoading: true,
      };
    }

    case POST_NEW_TICKET_SUCCESS: {
      return {
        ...state,
        PostTicketLoading: false,
        PostTicket: action.payload,
      };
    }

    case POST_NEW_TICKET_ERROR: {
      return {
        ...state,
        PostTicketLoading: false,
      };
    }

    case GET_AVAILABLE_LIST: {
      return {
        ...state,
        ReshootLoading: true,
      };
    }

    case GET_AVAILABLE_LIST_SUCCESS: {
      return {
        ...state,
        ReshootLoading: false,
        AvailableList: action.payload,
      };
    }

    case GET_AVAILABLE_LIST_ERROR: {
      return {
        ...state,
        ReshootLoading: false,
      };
    }

    case GET_UNAVAILABLE_LIST: {
      return {
        ...state,
        ReshootLoading: true,
      };
    }

    case GET_UNAVAILABLE_LIST_SUCCESS: {
      return {
        ...state,
        ReshootLoading: false,
        UnAvailableList: action.payload,
      };
    }

    case GET_UNAVAILABLE_LIST_ERROR: {
      return {
        ...state,
        ReshootLoading: false,
      };
    }

    case PUT_BEE_UPDATE: {
      return {
        ...state,
        UpdateBeeLoading: true,
      };
    }

    case PUT_BEE_UPDATE_SUCCESS: {
      return {
        ...state,
        UpdateBeeLoading: false,
        UpdateBeeDetails: action.payload,
      };
    }

    case PUT_BEE_UPDATE_ERROR: {
      return {
        ...state,
        UpdateBeeLoading: false,
      };
    }

    case POST_ZIP_ASSET_GROUP: {
      return {
        ...state,
        ZipDowloadLoading: true,
        ZipDETAILS: [action.payload],
      };
    }

    case POST_ZIP_ASSET_GROUP_ERROR: {
      return {
        ...state,
        ZipDowloadLoading: false,
      };
    }

    case POST_ZIP_ASSET_GROUP_SUCCESS: {
      return {
        ...state,
        ZipDowloadLoading: false,
      };
    }
    case GET_ALL_SHOOTERLIST: {
      return {
        ...state,
        AllShooterLoading: true,
      };
    }

    case GET_ALL_SHOOTERLIST_ERROR: {
      return {
        ...state,
        AllShooterLoading: false,
      };
    }

    case GET_ALL_SHOOTERLIST_SUCCESS: {
      return {
        ...state,
        AllShooterLoading: false,
        AllShooters: action.payload,
      };
    }

    case POST_RESHOOT_DETAILS: {
      return {
        ...state,
        ReshootPostLoading: true,
      };
    }

    case POST_RESHOOT_DETAILS_ERROR: {
      return {
        ...state,
        ReshootPostLoading: false,
      };
    }

    case POST_RESHOOT_DETAILS_SUCCESS: {
      return {
        ...state,
        ReshootPostLoading: false,
        Reshoot: action.payload,
      };
    }

    case POST_SHOOTER_ALLOCATION: {
      return {
        ...state,
        AllocationLoading: true,
      };
    }

    case POST_SHOOTER_ALLOCATION_ERROR: {
      return {
        ...state,
        AllocationLoading: false,
      };
    }

    case POST_SHOOTER_ALLOCATION_SUCCESS: {
      return {
        ...state,
        AllocationLoading: false,
        Allocation: action.payload,
      };
    }
    //GET_INVOICE_EDIT_DETAIL,GET_INVOICE_EDIT_DETAIL_SUCCESS,GET_INVOICE_EDIT_DETAIL_ERROR
    case GET_INVOICE_EDIT_DETAIL: {
      return {
        ...state,
        InvoiceEditLoading: true,
      };
    }

    case GET_INVOICE_EDIT_DETAIL_ERROR: {
      return {
        ...state,
        InvoiceEditLoading: false,
      };
    }

    case GET_INVOICE_EDIT_DETAIL_SUCCESS: {
      return {
        ...state,
        InvoiceEditLoading: false,
        InvoiceEditDetails: action.payload,
      };
    }
    //RENEW_HOISTING,RENEW_HOISTING_SUCCESS,RENEW_HOISTING_ERROR
    case RENEW_HOISTING: {
      return {
        ...state,
        RenewHoistingLoading: true,
      };
    }

    case RENEW_HOISTING_ERROR: {
      return {
        ...state,
        RenewHoistingLoading: false,
      };
    }

    case RENEW_HOISTING_SUCCESS: {
      return {
        ...state,
        RenewHoistingLoading: false,
        RenewHoisting: action.payload,
      };
    }
    //CANCEL_HOISTING,CANCEL_HOISTING_SUCCESS,CANCEL_HOISTING_ERROR
    case CANCEL_HOISTING: {
      return {
        ...state,
        CancelHoistingLoading: true,
      };
    }

    case CANCEL_HOISTING_ERROR: {
      return {
        ...state,
        CancelHoistingLoading: false,
      };
    }

    case CANCEL_HOISTING_SUCCESS: {
      return {
        ...state,
        CancelHoistingLoading: false,
        CancelHoisting: action.payload,
      };
    }
    // GET_MSL_DETAILS,GET_MSL_DETAILS_SUCCESS,GET_MSL_DETAILS_ERROR
    case GET_MSL_DETAILS: {
      return {
        ...state,
        msl_loading: true,
      };
    }

    case GET_MSL_DETAILS_ERROR: {
      return {
        ...state,
        msl_loading: false,
      };
    }

    case GET_MSL_DETAILS_SUCCESS: {
      return {
        ...state,
        msl_loading: false,
        msl_details: action.payload,
      };
    }
    default:
      return state;
  }
};

export default DashboardReducer;

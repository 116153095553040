import Button from '@components/Button';
import { useAuthUser } from '@crema/utility/AppHooks';
import IntlMessages from '@crema/utility/IntlMessages';
import { makeStyles, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MailIcon from '@material-ui/icons/Mail';
import clsx from 'clsx';
import routesConfig from 'modules/routesConfig';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import AppContext from '../../../utility/AppContext';
import AppFooterCopyright from './AppFooterCopyright';
import ColorStyles from 'shared/constants/AppColor';
import AppFooterLatest from './AppFooterLatest';
const countriesData = {
  UK: [
    'Aberdeen',
    'Birmingham',
    'Bournemouth',
    'Brighton',
    'Bristol',
    'Cardiff',
    'Doncaster',
    'Dundee',
    'Durham',
    'Edinburgh',
    'Essex',
    'Fife',
    'Glasgow',
    'Greater London',
    'Inverness',
    'Leeds',
    'Liverpool',
    'Manchester',
    'Newcastle',
    'Norwich',
    'Nottingham',
    'Peterborough',
    'Plymouth',
    'Sheffield',
    'Southampton',
    'Stirling',
    'Surrey',
    'York',
  ],
  USA: [
    'Atlanta',
    'Austin',
    'Chicago',
    'Dallas',
    'Greater Boston',
    'Houston',
    'Jupiter Island',
    'Los Angeles',
    'Miami',
    'Naples',
    'Nashville',
    'New Jersey',
    'New York City',
    'Orlando',
    'Palo Alto',
    'Philadelphia',
    'San Diego',
    'San Francisco',
    'Sarasota',
    'St Petersburg',
    'Tampa',
    'The Hamptons',
    'Washington, D.C.',
    'Westchester',
  ],
  world: ['Bahamas', 'French Riviera', 'Morocco', 'Toronto'],
};

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: 14,

    background: 'transparent',
    paddingLeft: '5px',
    border: '1px solid #212529',
    color: '#fafafa',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
  },
  logo: {
    height: 20,
    marginRight: 10,

    [theme.breakpoints.down('md')]: {
      height: 36,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff',
    fontSize: '14px',
    '&:hover': {
      color: '#fff',
    },
    '& a:hover': {

      opacity: .8
    },
  },

  footer: {
    margin: 'auto -20px 0',

    backgroundColor: ColorStyles.vistabeeBW80Gray,
    color: '#ccc',

    [theme.breakpoints.up('md')]: {
      marginLeft: '-32px',
      marginRight: '-32px',
    },
    '& .footerContainer': {
      padding: '5px 20px',
      gap: 20,
      alignItems: 'flex-start',
      flexWrap: 'wrap',

      [theme.breakpoints.up('md')]: {
        paddingLeft: 32,
        paddingRight: 32,
      },
      [theme.breakpoints.up('xl')]: {
        padding: '10px 32px',
      },
    },
    '& a:hover': {
      color: '#fafafa',
    },
  },
  footerContainer: {
    maxWidth: '1250px',
    margin: 'auto',

    marginTop: '60px',
    marginBottom: '60px',

    '@media (max-width: 1024px)': {
      maxWidth: '950px',
    },
  },
  btnRoot: {
    paddingLeft: 20,
    paddingRight: 20,
  },

  footerHeading: {
    fontSize: '15px',
    marginBottom: '18px',
    lineHeight: '22px',
    color: '#cccccc',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },

  blogDates: {
    fontSize: '13px',
    letterSpacing: '1px',
    lineHeight: '14px',
    color: '#888888',
    fontWeight: 400,
    fontStyle: 'italic',
    fontFamily: 'Poppins',
  },
  footerText: {
    fontSize: '14px',

    lineHeight: '21px',
    color: '#fcf9f9',
    fontWeight: 700,
    fontFamily: 'Poppins',
  },
  addressContainer: {
    fontSize: '14px',
    color: '#fff',
    '& h6': {
      fontWeight: 600,
      fontSize: 16,
      borderBottom: '1px solid',
      marginBottom: 4,
    },
  },
  secondSection: {
    border: '1px solid red',
    '& .icons-container': {
      marginBlock: 10,
      '& a': {
        display: 'inline-block',
        marginInlineEnd: 10,
        cursor: 'pointer',

        '& span': {
          padding: 8,
          height: 40,
          width: 40,

          borderRadius: '50%',
          backgroundColor: theme.palette.common.white,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& img': {
            height: 'inherit',
            width: 'auto',
            transition: '300ms ease-in-out',
          },
        },
        '&:hover span img': {
          transform: 'scale(1.2)',
        },
      },
    },

    '& .contact-us': {
      '& h5': {
        marginBottom: 10,
      },
      '& p': {
        fontSize: 18,

        '& a': {
          color: theme.palette.primary.main,

          '&:hover': {
            color: theme.palette.primary.dark,
          },
        },
      },
    },
  },
  thirdSection: {
    '& h5': {
      marginBottom: 10,
    },

    '& .join-team-card': {
      position: 'relative',
      padding: theme.spacing(4),
      backgroundColor: theme.palette.grey[800],
      borderRadius: 10,
      minWidth: 320,
      minHeight: 200,
      display: 'flex',
      flexDirection: 'column',

      '& img': {
        position: 'absolute',
        right: 0,
        bottom: 0,
      },

      '& p': {
        fontSize: 14,
        marginBottom: 5,
      },

      '& button': {
        marginTop: 'auto',
        width: 'fit-content',
      },
    },
  },

  countriesList: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    background:
      'url(/assets/images/footer_location_section.png) no-repeat center center',
    backgroundSize: 'cover',
    paddingBlock: 20,
    paddingInline: 16,
    width: '100%',

    '& > div': {
      color: theme.palette.common.white,
      '& h6': {
        fontSize: 14,
        fontWeight: 600,
        textDecoration: 'underline',
        paddingInlineStart: 16,
        marginBottom: 16,
      },
      '& ul': {
        columns: 2,
      },
    },

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      background:
        'url(/assets/images/footer_location_section.png) repeat center center',
    },
  },
  finalSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.grey[200],
    paddingTop: theme.spacing(6),

    '& > div': {
      display: 'flex',
      gap: 10,

      '& a': {
        color: theme.palette.grey[200],

        '&:hover': {
          color: theme.palette.grey[400],
        },
      },
    },
  },
  // link:{
  //   backgroundImage:'https://dev.vistabee.com/static/images/icons/widget-link-dark.png',
  // }
}));

const socialLinks = [
  { url: 'https://www.facebook.com/Vistabee', icon: '/assets/images/latestIcons/facebookwhite_latest.svg' },
  { url: 'https://www.instagram.com/vistabee/', icon: '/assets/images/latestIcons/Instawhite_latest.svg' },
  { url: 'https://www.youtube.com/@VistaBeeContent', icon: '/assets/images/latestIcons/YoutubeWhite_latest.svg' },
  // Add more social links as needed
];

interface AppFooterProps { }

const AppFooter: React.FC<AppFooterProps> = () => {
  const { footer, footerType } = useContext<AppContextPropsType>(AppContext);
  const classes = useStyles();
  const user = useAuthUser();


  // function onNewOrder() {
  //   if (user) {
  //     history.push('/order/new');
  //   } else {
  //     history.push('/signin');
  //   }
  // }
  return (
    <>
      {footer && footerType === 'fluid' ? (
        <Box >
          {/* <Box className={clsx(classes.footer, 'footer fixed-footer')}> */}
          {/* <Box
            className={classes.footerContainer}
            alignItems='flex-start'
            flexDirection='row'
            justifyContent='space-between'
            display='flex'>
            <Box>
              <Box marginBottom={5}>
                <img
                  className={classes.logo}
                  src={'/assets/images/core/logo.png'}
                  alt='crema-logo'
                />
              </Box>

              <Box className={clsx(classes.addressContainer)}>
                <Typography>United Kingdom HQ:</Typography>
                <Typography>
                  Citypoint, 65 Haymarket Terrace
                  <br />
                  Edinburgh,EH12 5HD
                  <br />
                  <br />
                  <span>Working hours:</span>
                  <br />
                  <span>Mon-Fri, 9am - 5:30pm (UTC)</span>
                </Typography>
                <br />

                <Typography className='mt-2'>United States HQ:</Typography>
                <Typography style={{ color: 'white' }}>
                  VistaBee Inc, 950 South Pine Island Road, <br />
                  Suite A150, Plantation,Florida 33324
                  <br />
                  <br />
                  <span>Working hours:</span>
                  <br />
                  <span>Mon-Fri, 9am - 5:30pm (ET)</span>
                </Typography>
              </Box>
            </Box>

            <Box>
              <Typography className={classes.footerHeading}>Pages</Typography>
              <Box>
                {routesConfig.filter(v=>v.id !='sign-in').map((_) => {
                  return (
                    <Box flexDirection='row' display='flex' marginTop={2}>
                      <ArrowRightIcon />
                      <Link className={classes.link} to={_.url}>
                        <IntlMessages
                          id={_.messageId}
                          defaultMessage={_.title}
                        />
                      </Link>
                    </Box>
                  );
                })}
                {!user && (
                  <Box flexDirection='row' display='flex' marginTop={2}>
                    <ArrowRightIcon />
                    <Link className={classes.link} to='/signin'>
                      <IntlMessages
                        id='common.signIn'
                        defaultMessage='Sign In'
                      />
                    </Link>
                  </Box>
                )}
              </Box>
            </Box>

            <Box width={250}>
              <Typography className={classes.footerHeading}>
                Recent Blog Posts
              </Typography>
              <Link
                className={classes.footerText}
                to='/blog/incredible-real-estate-videos-from-vistabee'>
                <Box>
                  <Typography>Incredible Real Estate Videos</Typography>
                  <Typography className={classes.blogDates}>
                    2019-08-21 07:44{' '}
                  </Typography>
                </Box>
              </Link>

              <Typography style={{ margin: '12px 0' }}>
                ---------------------------------------------------
              </Typography>

              <Link
                className={classes.footerText}
                to='/blog/add-touch-life-your-property-video'>
                <Box>
                  <Typography>
                    Add A Touch Of Life In Your Property Video
                  </Typography>
                  <Typography className={classes.blogDates}>
                    2019-01-31 10:02
                  </Typography>
                </Box>
              </Link>

              <Typography style={{ margin: '12px 0' }}>
                ---------------------------------------------------
              </Typography>
              <Link
                className={classes.footerText}
                to='/blog/dj-danny-tenaglia-selling-miami-escape-16-million'>
                <Box>
                  <Typography>
                    DJ Danny Tenaglia Selling Miami Escape for $1.6 Million
                  </Typography>
                  <Typography className={classes.blogDates}>
                    2018-09-06 09:48
                  </Typography>
                </Box>
              </Link>
            </Box>

            <Box className={classes.thirdSection}>
              <Box>
                <Typography>
                  Subscribe to Our Newsletter to get important <br /> News,
                  Amazing Offers &nbsp; Inside scopes{' '}
                </Typography>

                <Box flexDirection='row' display='flex' marginTop={5}>
                  <Box
                    height={40}
                    width={40}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    bgcolor={ColorStyles.vistabeeDarkGreen}>
                    <MailIcon />
                  </Box>
                  <input
                    className={classes.input}
                    placeholder='Enter your email'
                  />
                  <Button
                    color='secondary'
                    style={{
                      height: 40,
                      background: ColorStyles.vistabeeSoftGreen,
                      textTransform: 'capitalize',
                    }}>
                    <IntlMessages
                      id='common.newOrder'
                      defaultMessage='Subscribe'
                    />
                  </Button>
                </Box>
                <Box display='flex' marginTop={9} alignItems='center'>
                  <a
                    href='https://www.facebook.com/Vistabee'
                    className={classes.link}
                    target='_blank' rel="noreferrer">
                    <img
                      src='https://www.freepnglogos.com/uploads/facebook-logo-13.png'
                      alt='facebook icon'
                      width='40px'
                    />
                    <Box marginLeft={2}>
                      <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                        Like Us
                      </Typography>
                      <Typography style={{ fontSize: '12px', fontWeight: 600 }}>
                        on Facebook
                      </Typography>
                    </Box>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box> */}

          {/* <AppFooterCopyright /> */}
          <AppFooterLatest
            bookNowUrl="https://app.vistabee.com/Signin"
            faqUrl="/faq"
            bookingHelpUrl="/help-with-booking"
            bookUrl={user?'/order/new':'/signin'}
            privacyPolicyUrl="/privacy-policy"
            termsConditionsUrl="/terms-conditions"
            cookiesUrl="/cookies"
            socialLinks={socialLinks}
        />
        </Box>
      ) : null}
    </>
  );
};

export default AppFooter;

import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import React, {useContext} from 'react';
import {useLocation} from 'react-router-dom';
import {LayoutType} from '../../../../shared/constants/AppEnums';
import AppContextPropsType from '../../../../types/AppContextPropsType';
import {ContentView} from '../../../index';
import AppContext from '../../../utility/AppContext';
import AppFixedFooter from './AppFixedFooter';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import useStyles from './index.style';
interface HorLightNavProps {}
const isAssetsGroupLinkPage = () => {
  return window.location.href.indexOf('/asset-group-links') > -1
}



const HorLightNav: React.FC<HorLightNavProps> = () => {   
  const classes = useStyles();
  const {footer, layoutType, footerType} =
    useContext<AppContextPropsType>(AppContext);

  return (
    <Box
      className={clsx(
        classes.appMain,
        'appMainHor',
        layoutType === LayoutType.BOXED ? classes.boxedLayout : '',
        {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
        },
      )}>

      {!isAssetsGroupLinkPage()&&<AppHeader />}

      <Box className={classes.mainContent}>
        <AppSidebar />
        <Box className={classes.mainContainer}>
          <ContentView />
        </Box>
      </Box>
      {!isAssetsGroupLinkPage()&&<AppFooter />}
      {!isAssetsGroupLinkPage()&&<AppFixedFooter />}
    </Box>
  );
};

export default HorLightNav;

import {Box} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import React from 'react';

import {useStyles} from './UseStyles';
const AppLogoWhite = () => {
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <Hidden smUp>
         
        <img
          className={classes.logo}
          src={'/assets/images/core/logo.png'}
          alt='crema-logo'
        />
      </Hidden>
      <Hidden xsDown>
     
        <img
          className={classes.logo}
          src={'/assets/images/core/logo.png'}
          alt='crema-logo'
        />
      </Hidden>
    </Box>
  );
};

export default AppLogoWhite;

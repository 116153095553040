import React from 'react';
import {Box, Typography} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import CancelIcon from '@material-ui/icons/Cancel';
import {UseStyles} from './UseStyles';
import {Link} from 'react-router-dom';
import useTicketView from './useTicketView';
const TicketView = () => {
  const {PostTicket, open, handelClose} = useTicketView();
  const classes = UseStyles();

 
  return (
    <Box>
      {PostTicket && PostTicket?.id && (
        <Modal
          open={open}
          onClose={handelClose}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            height='100%'>
            <Box width={500} minHeight={200} bgcolor='#fff' borderRadius={5}>
              <Box
                className={classes.rootButton}
                display='flex'
                justifyContent='space-between'>
                <Typography>Ticket Submitted</Typography>
                <CancelIcon onClick={handelClose} style={{cursor: 'pointer'}} />
              </Box>

              <Box p={5}>
                <Typography align='center' className={classes.typos}>
                  Go to the tickets tab or{' '}
                  <Link to={`/dashboard/tickets/detail/${PostTicket?.id}`}>
                    click here
                  </Link>{' '}
                  to view the ticket.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default TicketView;

export const TaskType = [
    {
        title: 'Admin',
        id: 4,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 8,
                title: 'New Order',
                type: 'TARGET_NEW_ORDER'
            },
            {
                id: 9,
                title: 'Ticket',
                type: 'TARGET_TICKET'
            },
            {
                id: 11,
                title: 'Approve asset',
                type: 'TARGET_APPROVE_ASSET'
            },
            {
                id: 21,
                title: 'Approve change field',
                type: 'TARGET_FIELD_CHANGE'
            },
            {
                id: 25,
                title: 'verify customer',
                type: 'TARGET_VERIFY_CLIENT'
            },
            {
                id: 32,
                title: 'Client Follow up',
                type: 'TARGET_CLIENT_FOLLOWUP'
            },

        ]
    },
    {
        title: 'Create',
        id: 2,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 4,
                title: 'Brochure',
                type: 'TARGET_BROCHURE'
            },
            {
                id: 6,
                title: 'Voiceover',
                type: 'TARGET_VOICEOVER'
            },
            {
                id: 3,
                title: 'Floorplan',
                type: 'TARGET_FLOORPLAN'
            }, {
                id: 1,
                title: 'Movie',
                type: 'TARGET_MOVIE'
            }, {
                id: 2,
                title: 'Photography',
                type: 'TARGET_PHOTOGRAPHY'
            },
            {
                id: 5,
                title: 'Window Card',
                type: 'TARGET_WINDOW_CARD'
            },

            {
                id: 33,
                title: 'VB 360 Tour',
                type: 'TARGET_VB_360_TOUR'
            },
        ]
    },
    {
        title: 'Training',
        id: 5,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 12,
                title: 'Approve applied bee',
                type: 'TARGET_APPROVE_APPLIED_BEE'
            },
            {
                id: 13,
                title: 'Interview bee',
                type: 'TARGET_INTERVIEW_BEE'
            },

            {
                id: 15,
                title: 'Background check',
                type: 'TARGET_CHECKING'
            },
            {
                id: 16,
                title: 'Sign contract',
                type: 'TARGET_CONTRACT'
            },
            {
                id: 18,
                title: 'Approve trained bee',
                type: 'TARGET_APPROVE_TRAINED_BEE'
            },
            {
                id: 19,
                title: 'Check reference',
                type: 'TARGET_REFERENCE'
            },
            {
                id: 14,
                title: 'Pay deposit',
                type: 'TARGET_DEPOSIT'
            },
            {
                id: 17,
                title: 'TARGET_TRAINING',
                type: 'TARGET_TRAINING'
            },
            {
                id: 24,
                title: 'Approve new insurance',
                type: 'TARGET_INSURANCE_APPROVE'
            },
            {
                id: 23,
                title: 'Upload new insurance',
                type: 'TARGET_INSURANCE_UPLOAD'
            },

        ]
    }, {
        title: 'Edit',
        id: 1,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 4,
                title: 'Brochure',
                type: 'TARGET_BROCHURE'
            },
            {
                id: 6,
                title: 'Voiceover',
                type: 'TARGET_VOICEOVER'
            },
            {
                id: 3,
                title: 'Floorplan',
                type: 'TARGET_FLOORPLAN'
            }, {
                id: 1,
                title: 'Movie',
                type: 'TARGET_MOVIE'
            }, {
                id: 2,
                title: 'Photography',
                type: 'TARGET_PHOTOGRAPHY'
            },
            {
                id: 5,
                title: 'Window Card',
                type: 'TARGET_WINDOW_CARD'
            },
            {
                id: 7,
                title: 'Video',
                type: 'TARGET_VIDEO'
            },
            {
                id: 33,
                title: 'VB 360 Tour',
                type: 'TARGET_VB_360_TOUR'
            },
        ]
    }, {
        title: 'Other',
        id: 6,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 20,
                title: 'Approve invoice',
                type: 'TARGET_INVOICE'
            },
            {
                id: 31,
                title: 'Approve Company Invoice',
                type: 'TARGET_COMPANY_INVOICE'
            },
            {
                id: 27,
                title: 'Approve payment',
                type: 'TARGET_PAYMENT'
            },
            {
                id: 22,
                title: 'Enquiry',
                type: 'TARGET_ENQUIRY'
            },
            {
                id: 26,
                title: 'contact',
                type: 'TARGET_CONTACT'
            },
            {
                id: 28,
                title: 'questionnaire',
                type: 'TARGET_QUESTIONNAIRY'
            },
            {
                id: 29,
                title: 'Create 3D logo',
                type: 'TARGET_LOGO_3D'
            },
            {
                id: 34,
                title: 'Renew Hosting',
                type: 'TARGET_RENEW_HOSTING'
            },
            {
                id: 35,
                title: 'Extend Hosting',
                type: 'TARGET_EXTEND_HOSTING'
            },
            {
                id: 36,
                title: 'Deactivate Hosting',
                type: 'TARGET_DEACTIVATE_HOSTING'
            },
            {
                id: 37,
                title: 'Confirm to Client',
                type: 'TARGET_CONFIRM_TO_CLIENT'
            },

        ],
    }
]

export const CalendarTaskType = [
    {
        title: 'Admin',
        id: 4,
        target: [
            // {
            //     id: '',
            //     title: 'All',
            //     type: 'All'
            // },
            {
                id: 8,
                title: 'New Order',
                type: 'TARGET_NEW_ORDER'
            },
            {
                id: 9,
                title: 'Ticket',
                type: 'TARGET_TICKET'
            },
            {
                id: 11,
                title: 'Approve asset',
                type: 'TARGET_APPROVE_ASSET'
            },
            {
                id: 21,
                title: 'Approve change field',
                type: 'TARGET_FIELD_CHANGE'
            },
            {
                id: 25,
                title: 'verify customer',
                type: 'TARGET_VERIFY_CLIENT'
            },
            {
                id: 32,
                title: 'Client Follow up',
                type: 'TARGET_CLIENT_FOLLOWUP'
            },

        ]
    },
    {
        title: 'Create',
        id: 2,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 4,
                title: 'Brochure',
                type: 'TARGET_BROCHURE'
            },
            {
                id: 6,
                title: 'Voiceover',
                type: 'TARGET_VOICEOVER'
            },
            {
                id: 3,
                title: 'Floorplan',
                type: 'TARGET_FLOORPLAN'
            }, {
                id: 1,
                title: 'Movie',
                type: 'TARGET_MOVIE'
            }, {
                id: 2,
                title: 'Photography',
                type: 'TARGET_PHOTOGRAPHY'
            },
            {
                id: 5,
                title: 'Window Card',
                type: 'TARGET_WINDOW_CARD'
            },

            {
                id: 33,
                title: 'VB 360 Tour',
                type: 'TARGET_VB_360_TOUR'
            },
        ]
    },
    {
        title: 'Training',
        id: 5,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 12,
                title: 'Approve applied bee',
                type: 'TARGET_APPROVE_APPLIED_BEE'
            },
            {
                id: 13,
                title: 'Interview bee',
                type: 'TARGET_INTERVIEW_BEE'
            },

            {
                id: 15,
                title: 'Background check',
                type: 'TARGET_CHECKING'
            },
            {
                id: 16,
                title: 'Sign contract',
                type: 'TARGET_CONTRACT'
            },
            {
                id: 18,
                title: 'Approve trained bee',
                type: 'TARGET_APPROVE_TRAINED_BEE'
            },
            {
                id: 19,
                title: 'Check reference',
                type: 'TARGET_REFERENCE'
            },
            {
                id: 14,
                title: 'Pay deposit',
                type: 'TARGET_DEPOSIT'
            },
            {
                id: 17,
                title: 'TARGET_TRAINING',
                type: 'TARGET_TRAINING'
            },
            {
                id: 24,
                title: 'Approve new insurance',
                type: 'TARGET_INSURANCE_APPROVE'
            },
            {
                id: 23,
                title: 'Upload new insurance',
                type: 'TARGET_INSURANCE_UPLOAD'
            },

        ]
    }, {
        title: 'Edit',
        id: 1,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 4,
                title: 'Brochure',
                type: 'TARGET_BROCHURE'
            },
            {
                id: 6,
                title: 'Voiceover',
                type: 'TARGET_VOICEOVER'
            },
            {
                id: 3,
                title: 'Floorplan',
                type: 'TARGET_FLOORPLAN'
            }, {
                id: 1,
                title: 'Movie',
                type: 'TARGET_MOVIE'
            }, {
                id: 2,
                title: 'Photography',
                type: 'TARGET_PHOTOGRAPHY'
            },
            {
                id: 5,
                title: 'Window Card',
                type: 'TARGET_WINDOW_CARD'
            },
            {
                id: 7,
                title: 'Video',
                type: 'TARGET_VIDEO'
            },
            {
                id: 33,
                title: 'VB 360 Tour',
                type: 'TARGET_VB_360_TOUR'
            },
        ]
    }, {
        title: 'Other',
        id: 6,
        target: [
            {
                id: '',
                title: 'All',
                type: 'All'
            },
            {
                id: 20,
                title: 'Approve invoice',
                type: 'TARGET_INVOICE'
            },
            {
                id: 31,
                title: 'Approve Company Invoice',
                type: 'TARGET_COMPANY_INVOICE'
            },
            {
                id: 27,
                title: 'Approve payment',
                type: 'TARGET_PAYMENT'
            },
            {
                id: 22,
                title: 'Enquiry',
                type: 'TARGET_ENQUIRY'
            },
            {
                id: 26,
                title: 'contact',
                type: 'TARGET_CONTACT'
            },
            {
                id: 28,
                title: 'questionnaire',
                type: 'TARGET_QUESTIONNAIRY'
            },
            {
                id: 29,
                title: 'Create 3D logo',
                type: 'TARGET_LOGO_3D'
            },
            {
                id: 34,
                title: 'Renew Hosting',
                type: 'TARGET_RENEW_HOSTING'
            },
            {
                id: 35,
                title: 'Extend Hosting',
                type: 'TARGET_EXTEND_HOSTING'
            },
            {
                id: 36,
                title: 'Deactivate Hosting',
                type: 'TARGET_DEACTIVATE_HOSTING'
            },
            {
                id: 37,
                title: 'Confirm to Client',
                type: 'TARGET_CONFIRM_TO_CLIENT'
            },
            // {
            //     id: 38,
            //     title: 'Upload to Drive',
            //     type: 'TARGET_UPLOAD_TO_DRIVE'
            // },

        ],
    }
]
////////////////////

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Auth from './Auth';
import CommonReducer from './CommonReducer';
import DashboardReducer from './Dashboard';
import Enquiry from './EnquiryReducer';
import MyAccountReducer from './MyAccount';
import NewOrderReducer from './NewOrder';
import Settings from './Setting';
const persistConfig = {
  key: 'root',
  storage,
};

const auth = persistReducer(persistConfig, Auth);

const reducers = {
  settings: Settings,
  common: CommonReducer,
  newOrder: NewOrderReducer,
  auth,
  myAccount: MyAccountReducer,
  dashboard: DashboardReducer,
  Enquiry
};

export default reducers;

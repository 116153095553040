import {makeStyles} from '@material-ui/core/styles';
import {CremaTheme} from '../../../../types/AppContextPropsType';

const useStyles = makeStyles((theme: CremaTheme) => ({
  root: {
    minHeight: 48,
    padding: '4px 16px 4px 16px',
    width: 'auto',
    textAlign: 'center',
    color: theme.palette.common.white,
    whiteSpace: 'nowrap',

    '& span': {
      position: 'relative',

      '&:before': {
        content: '""',
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 2,
        transformOrigin: 'center',
        transform: 'scale(0)',
        transition: '200ms ease-out',
      },
    },

    '&:hover, &.active, &:focus, &:focus-within': {
      color: theme.palette.common.white,
      '& span:before': {
        transform: 'scale(1)',
      },
    },

    '&.isBgWhite': {
      color: theme.palette.common.black,
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,.08)',
        color: theme.palette.common.black,
      },

      '& span': {
        '&:before': {
          display: 'none',
        },
      },

      '&.active, &:focus, &:focus-within': {
        color: theme.palette.primary.dark,
      },
    },

    '& .list-item-icon': {},
    '& .list-item-text': {
      padding: '0 0 0 16px',
    },
    textDecoration: 'none!important',
    '&.dense': {
      padding: '8px 12px 8px 12px',
      minHeight: 40,
      '& .list-item-text': {
        padding: '0 0 0 8px',
      },
    },
  },
}));
export default useStyles;

import IntlMessages from '@crema/utility/IntlMessages';
import {makeStyles, Modal, Typography} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {CLoseHeader} from '../../../redux/actions';
import clsx from 'clsx';
import React, {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'redux/store';
import {Link, useHistory} from 'react-router-dom';
import {onJWTAuthSignout} from '../../../redux/actions';
import {Fonts} from '../../../shared/constants/AppEnums';
import {useAuthUser} from '../../utility/AppHooks';
import Feedback from 'modules/dashboard/components/Backend/components/BackendClient/Feedback';
import ColorStyles from 'shared/constants/AppColor';

const useStyles = makeStyles((theme) => {
  return {
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      marginInlineEnd: 20,
    },
    avatar: {
      fontSize: 24,
      backgroundColor: ColorStyles.vistabeeSoftGreen,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    signInLink: {
      color: '#fff',
      padding: '4px 16px 4px 16px',
      minHeight: '48px',
      whiteSpace: 'nowrap',
      marginLeft: '11px',
      fontWeight: 500,
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      textDecoration: 'none !important',

      '& span': {
        position: 'relative',

        '&:before': {
          content: '""',
          backgroundColor: theme.palette.common.white,
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: 2,
          transformOrigin: 'center',
          transform: 'scale(0)',
          transition: '200ms ease-out',
        },
      },

      '&:hover': {
        color: '#fff',

        '& span:before': {
          transform: 'scale(1)',
        },
      },
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props: {bgType: string}) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },
  };
});

interface HorUserInfoProps {
  bgType?: string;
  onlyAvatar?: Boolean;
}

const HorUserInfo: FC<HorUserInfoProps> = ({
  bgType = 'colored',
  onlyAvatar,
}) => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handelClickRedirect = () => {
    setTimeout(() => {
      dispatch(CLoseHeader(false));
    }, 2000);

    handleClose();
    history.push('/dashboard/dashboard');
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user && user.user.first_name) {
      return user.user.first_name.charAt(0).toUpperCase();
    }
    if (user && user.user.company) {
      return user.user.company.charAt(0).toUpperCase();
    }
  };
  const classes = useStyles({bgType});

  if (!user) {
    if (onlyAvatar) {
      return null;
    } else {
      return (
        <Link to={'/signin'} className={clsx(classes.signInLink)}>
          <Typography variant='inherit'>
            <IntlMessages id='common.signIn' defaultMessage='Sign In' />
          </Typography>
        </Link>
      );
    }
  }

  return (
    <>
      <Box py={2}>
        <Box
          className={classes.userRoot}
          mx={2}
          display='flex'
          onClick={handleClick}>
          {user && user.photoURL ? (
            <Avatar className={classes.avatar} src={user.photoURL} />
          ) : (
            <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
          )}

          {!onlyAvatar && (
            <Hidden mdDown>
              <Box ml={3} className={classes.userName}>
                {user &&
                  user.user &&
                  user.user.first_name &&
                  `${user.user.first_name} ${user.user.last_name}`}
                <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                  {user && user.user.company}
                </Box>
              </Box>
            </Hidden>
          )}
        </Box>
        <Box className={classes.userInfo}>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={() => history.push('/myaccount/details')}>
              {user.is_shooter || user.is_editor ? 'Profile' : 'My account'}
            </MenuItem>
            <MenuItem onClick={handelClickRedirect}>Dashboard</MenuItem>
            <MenuItem
              onClick={() => {
                setOpen(true);
                handleClose();
              }}>
              Feedback
            </MenuItem>
            <MenuItem onClick={() => dispatch(onJWTAuthSignout())}>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'>
        <Feedback close={handleCloseModal} isOpen={open} />
      </Modal>
    </>
  );
};

export default HorUserInfo;

import {api} from '@crema/services/ApiConfig';
import {Dispatch} from 'redux';
import {AppState} from 'redux/store';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {AppActions} from '../../types';
import {
  ADD_NEW_USER,
  ADD_NEW_USER_ERROR,
  ADD_NEW_USER_SUCCESS,
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {AuthUser} from '../../types/models/AuthUser';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';

export const onJwtUserSignUp = (body: {
  email: string;
  password: string;
  name: string;
}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await jwtAxios.post('users', body);
      localStorage.setItem('token', res.data.token);
      dispatch(setJWTToken(res.data.token));
      await loadJWTUser(dispatch);
    } catch (err) {
      dispatch(fetchError('someting went wrong!'));
    }
  };
};

export const onSignIn = (body: {username: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await api.post('accounts/login/', body);
      const tokenResponse = await api.post('accounts/token/', body);

      dispatch(fetchSuccess());

      const data = res.data.result || {};
      const token = tokenResponse.data;
      localStorage.setItem('token', token.access);
      localStorage.setItem('refresh-token', token.refresh);

      dispatch({
        type: UPDATE_AUTH_USER,
        payload: getUserObject(data),
      });

      dispatch(setJWTToken(token));

      setTimeout(() => {
        window.location.href = '/dashboard/dashboard';
      }, 2000);
    } catch (err) {
      dispatch(fetchError('Invalid login credentials'));
    }
  };
};

export const loadJWTUser = async (dispatch: Dispatch<AppActions>) => {
  dispatch(fetchStart());
  try {
    const res = await jwtAxios.get('/auth');
    dispatch(fetchSuccess());

    dispatch({
      type: UPDATE_AUTH_USER,
      payload: getUserObject(res.data),
    });
  } catch (err) {
    dispatch(fetchError(err.response.error));
  }
};

export const setJWTToken = (token: {
  refresh: string;
  access: string;
}): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  return authUser;
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>, state: AppState) => {
    dispatch(fetchSuccess());
    dispatch({type: SIGNOUT_AUTH_SUCCESS});
    setTimeout(() => {
      dispatch(fetchSuccess());
      localStorage.setItem('token', 'null');
      localStorage.setItem('refresh-token', 'null');
      localStorage.removeItem('statesForDropDown');
      localStorage.removeItem('RefId');
      window.location.href = '/';
    }, 500);
  };
};

export const addNewUser = (userPayload: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: ADD_NEW_USER,
      });

      const {data} = await jwtAxios.post(`accounts/invite-user/`, userPayload);

      dispatch({
        type: ADD_NEW_USER_SUCCESS,
        payload: data.result,
      });

      dispatch(showMessage('Signup successful'));

      setTimeout(() => {
        window.location.href = '/signin';
      }, 2000);
    } catch (error) {
      dispatch({
        type: ADD_NEW_USER_ERROR,
      });
      dispatch(fetchError());
    }
  };
};

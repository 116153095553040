import {
  IBlog,
  IBlogList,
  IClient,
  IJob,
  IReview,
  IVideoOverlay,
} from 'types/models/Common';

export const FETCH_START = 'FETCH_START';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const CURRENT_DATE = 'CURRENT_DATE';
export const EXPAND_ALL_TOGGLER = 'EXPAND_ALL_TOGGLER';
export const CLOSE_HEADER_BAR='CLOSE_HEADER_BAR';
export const INFO_MESSAGE = 'INFO_MESSAGE';
export const SET_VIRTUAL_TOOL = 'SET_VIRTUAL_TOOL';
export const WARNING_MESSAGE = 'WARNING_MESSAGE';
export const TOGGLE_APP_DRAWER = 'TOGGLE_APP_DRAWER';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const GET_MENUS_REQ = 'GET_MENUS_REQ';
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS';
export const GET_MENUS_ERROR = 'GET_MENUS_ERROR';
export const GET_PRODUCT_DATA_REQ = 'GET_PRODUCTS_DATA_REQ';
export const GET_PRODUCT_DATA_SUCCESS = 'GET_PRODUCTS_DATA_SUCCESS';
export const GET_PRODUCT_DATA_ERROR = 'GET_PRODUCTS_DATA_ERROR';
export const GET_CITY_DATA_REQ = 'GET_CITY_DATA_REQ';
export const GET_CITY_DATA_SUCCESS = 'GET_CITY_DATA_SUCCESS';
export const GET_CITY_DATA_ERROR = 'GET_CITY_DATA_ERROR';
export const POST_CONTACT_US_ENQUIRY_REQ = 'POST_CONTACT_US_ENQUIRY_REQ';
export const POST_CONTACT_US_ENQUIRY_SUCCESS =
  'POST_CONTACT_US_ENQUIRY_SUCCESS';
export const POST_CONTACT_US_ENQUIRY_ERROR = 'POST_CONTACT_US_ENQUIRY_ERROR';
export const GET_REVIEWS_DATA_REQ = 'GET_REVIEWS_DATA_REQ';
export const GET_REVIEWS_DATA_SUCCESS = 'GET_REVIEWS_DATA_SUCCESS';
export const GET_REVIEWS_DATA_ERROR = 'GET_REVIEWS_DATA_ERROR';
export const GET_CLIENTS_DATA_REQ = 'GET_CLIENTS_DATA_REQ';
export const GET_CLIENTS_DATA_SUCCESS = 'GET_CLIENTS_DATA_SUCCESS';
export const GET_CLIENTS_DATA_ERROR = 'GET_CLIENTS_DATA_ERROR';
export const GET_JOBS_DATA_REQ = 'GET_JOBS_DATA_REQ';
export const GET_JOBS_DATA_SUCCESS = 'GET_JOBS_DATA_SUCCESS';
export const GET_JOBS_DATA_ERROR = 'GET_JOBS_DATA_ERROR';
export const GET_BLOGS_DATA_REQ = 'GET_BLOGS_DATA_REQ';
export const GET_BLOGS_DATA_SUCCESS = 'GET_BLOGS_DATA_SUCCESS';
export const GET_BLOG_DATA_SUCCESS = 'GET_BLOG_DATA_SUCCESS';
export const GET_BLOGS_DATA_ERROR = 'GET_BLOGS_DATA_ERROR';
export const SET_VIDEO_OVERLAY_DATA = 'SET_VIDEO_OVERLAY_DATA';
export const CLEAR_VIDEO_OVERLAY_DATA = 'CLEAR_VIDEO_OVERLAY_DATA';
export interface GetMenusReq {
  type: typeof GET_MENUS_REQ;
}

export interface GetMenusSuccess {
  type: typeof GET_MENUS_SUCCESS;
  payload: {
    products: any;
    cities: any;
  };
}

export interface SetvirtualToolCode {
  type: typeof SET_VIRTUAL_TOOL,
  code: string
}
export interface GetMenusError {
  type: typeof GET_MENUS_ERROR;
}

export interface GetProductDataReq {
  type: typeof GET_PRODUCT_DATA_REQ;
}

export interface GetProductDataSuccess {
  type: typeof GET_PRODUCT_DATA_SUCCESS;
  payload: any;
}

export interface GetProductDataError {
  type: typeof GET_PRODUCT_DATA_ERROR;
}

export interface GetCityDataReq {
  type: typeof GET_CITY_DATA_REQ;
}

export interface GetCityDataSuccess {
  type: typeof GET_CITY_DATA_SUCCESS;
  payload: any;
}

export interface GetCityDataError {
  type: typeof GET_CITY_DATA_ERROR;
}

export interface GetReviewsDataReq {
  type: typeof GET_REVIEWS_DATA_REQ;
}

export interface GetReviewsDataSuccess {
  type: typeof GET_REVIEWS_DATA_SUCCESS;
  payload: Array<IReview>;
}

export interface GetReviewsDataError {
  type: typeof GET_REVIEWS_DATA_ERROR;
}

export interface GetClientsDataReq {
  type: typeof GET_CLIENTS_DATA_REQ;
}

export interface GetClientsDataSuccess {
  type: typeof GET_CLIENTS_DATA_SUCCESS;
  payload: Array<IClient>;
}

export interface GetClientsDataError {
  type: typeof GET_CLIENTS_DATA_ERROR;
}

export interface GetJobsDataReq {
  type: typeof GET_JOBS_DATA_REQ;
}

export interface GetJobsDataSuccess {
  type: typeof GET_JOBS_DATA_SUCCESS;
  payload: Array<IJob>;
}

export interface GetJobsDataError {
  type: typeof GET_JOBS_DATA_ERROR;
}

export interface GetBlogsDataReq {
  type: typeof GET_BLOGS_DATA_REQ;
}

export interface GetBlogsDataSuccess {
  type: typeof GET_BLOGS_DATA_SUCCESS;
  payload: Array<IBlogList>;
}

export interface GetBlogDataSuccess {
  type: typeof GET_BLOG_DATA_SUCCESS;
  payload: IBlog;
}

export interface GetBlogsDataError {
  type: typeof GET_BLOGS_DATA_ERROR;
}

export interface PostContactUsEnquiryReq {
  type: typeof POST_CONTACT_US_ENQUIRY_REQ;
}

export interface PostContactUsEnquirySuccess {
  type: typeof POST_CONTACT_US_ENQUIRY_SUCCESS;
  payload: any;
}

export interface PostContactUsEnquiryError {
  type: typeof POST_CONTACT_US_ENQUIRY_ERROR;
}

export interface FetchStartAction {
  type: typeof FETCH_START;
}

export interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
}

export interface FetchErrorAction {
  type: typeof FETCH_ERROR;
  error: string;
}

export interface ShowMessageAction {
  type: typeof SHOW_MESSAGE;
  message: string;
}

export interface ShowInfoAction {
  type: typeof INFO_MESSAGE;
  message: string;
}

export interface ShowCurrentDate {
  type: typeof CURRENT_DATE,
  label: string
}

export interface Handel_accordion {
  type: typeof EXPAND_ALL_TOGGLER,
  label: string
}


export interface CloseAccordion{
  type: typeof CLOSE_HEADER_BAR,
  payload: boolean

}


export interface ShowWarning {
  type: typeof WARNING_MESSAGE;
  message: string;
}
export interface ToggleDrawerAction {
  type: typeof TOGGLE_APP_DRAWER;
}

export interface HideMessageAction {
  type: typeof HIDE_MESSAGE;
}

export interface SetVideoOverlayData {
  type: typeof SET_VIDEO_OVERLAY_DATA;
  payload: IVideoOverlay;
}

export interface ClearVideoOverlayData {
  type: typeof CLEAR_VIDEO_OVERLAY_DATA;
}

export type CommonActionTypes =
  | GetMenusReq
  | GetMenusSuccess
  | GetMenusError
  | GetProductDataReq
  | GetProductDataSuccess
  | GetProductDataError
  | GetCityDataReq
  | Handel_accordion
  | GetCityDataSuccess
  | GetCityDataError
  | GetClientsDataReq
  | GetClientsDataSuccess
  | GetClientsDataError
  | GetJobsDataReq
  | GetJobsDataSuccess
  | GetJobsDataError
  | GetBlogsDataReq
  | GetBlogsDataSuccess
  | GetBlogDataSuccess
  |CloseAccordion
  | GetBlogsDataError
  | GetReviewsDataReq
  | GetReviewsDataSuccess
  | GetReviewsDataError
  | PostContactUsEnquiryReq
  | PostContactUsEnquirySuccess
  | PostContactUsEnquiryError
  | FetchStartAction
  | FetchSuccessAction
  | FetchErrorAction
  | ShowMessageAction
  | ToggleDrawerAction
  | HideMessageAction
  | SetVideoOverlayData
  | ShowInfoAction
  | SetvirtualToolCode
  | ShowWarning |
  ShowCurrentDate
  | {}
  | ClearVideoOverlayData;

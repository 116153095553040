import {
    EnquiryActionTypes,
    GET_ENQUIRY_DETAILS,
    GET_ENQUIRY_DETAILS_BY_ID, GET_ENQUIRY_DETAILS_BY_ID_FAILED, GET_ENQUIRY_DETAILS_BY_ID_SUCCESSFULL,
    GET_ENQUIRY_DETAILS_FAILED,
    GET_ENQUIRY_DETAILS_SUCCESSFULL,
    GET_ENQUIRY_LIST,
    GET_ENQUIRY_LIST_FAILED,
    GET_ENQUIRY_LIST_SUCCESSFULL,
    GET_FILTER_ENQUIRY_LIST,
    GET_FILTER_ENQUIRY_LIST_FAILED,
    GET_FILTER_ENQUIRY_LIST_SUCCESSFULL,
    POST_ENQUIRY_ACTIONS,
    POST_ENQUIRY_ACTIONS_FAILED,
    POST_ENQUIRY_ACTIONS_SUCCESSFULL,
} from '../../types/actions/Enquiry.action';
import { IEnquiry } from '../../types/models/Enquiry';

const initialSettings: IEnquiry = {
    EnquiryDetailsLoading: null,
    EnquiryDetails: {},
    EnquiryDetailsByid: {},
    EnquiryListLoading: null,
    EnquiryList: [],
    EnquiryActions: null,
    EnquiryAction: []
};

const Enquiry = (state = initialSettings, action: EnquiryActionTypes) => {
    switch (action.type) {
        case GET_ENQUIRY_DETAILS:
            return {
                ...state,
                EnquiryDetailsLoading: true,
            };

        case GET_ENQUIRY_DETAILS_SUCCESSFULL:
            return {
                ...state,
                EnquiryDetailsLoading: false,
                EnquiryDetails: action.payload
            };

        case GET_ENQUIRY_DETAILS_FAILED:
            return {
                EnquiryDetailsLoading: false

            };

        case GET_ENQUIRY_DETAILS_BY_ID:
            return {
                ...state,
                EnquiryDetailsLoading: true,
            };

        case GET_ENQUIRY_DETAILS_BY_ID_SUCCESSFULL:
            return {
                ...state,
                EnquiryDetailsLoading: false,
                EnquiryDetails: action.payload
            };

        case GET_ENQUIRY_DETAILS_BY_ID_FAILED:
            return {
                EnquiryDetailsLoading: false

            };

        case GET_ENQUIRY_LIST:
            return {
                ...state,
                EnquiryListLoading: true,
            };

        case GET_ENQUIRY_LIST_SUCCESSFULL:
            return {
                ...state,
                EnquiryListLoading: false,
                EnquiryList: action.payload
            };

        case GET_ENQUIRY_LIST_FAILED:
            return {
                EnquiryListLoading: false

            };

        case GET_FILTER_ENQUIRY_LIST:
            return {
                ...state,
                EnquiryListLoading: true,
            };

        case GET_FILTER_ENQUIRY_LIST_SUCCESSFULL:
            return {
                ...state,
                EnquiryListLoading: false,
                EnquiryList: action.payload
            };

        case GET_FILTER_ENQUIRY_LIST_FAILED:
            return {
                EnquiryListLoading: false

            };

       
        case POST_ENQUIRY_ACTIONS:
            return {
                ...state,
                EnquiryListLoading: true,
            };

        case POST_ENQUIRY_ACTIONS_SUCCESSFULL:
            return {
                ...state,
                EnquiryListLoading: false,
                EnquiryList: action.payload
            };

        case POST_ENQUIRY_ACTIONS_FAILED:
            return {
                EnquiryListLoading: false

            };

        default:
            return state;
    }
};

export default Enquiry;

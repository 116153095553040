import {Box, Icon, makeStyles} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearVideoOverlayData} from 'redux/actions';
import {AppState} from 'redux/store';
import {CremaTheme} from 'types/AppContextPropsType';

const REACT_APP_VIDEO_BASE_URL = process.env.REACT_APP_VIDEO_BASE_URL;

const useStyles = makeStyles((theme: CremaTheme) => ({
  videoContainer: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000a1',
    backdropFilter: 'blur(2px)',
    zIndex: 1200,

    '& > div': {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 'fit-content',

      '& .close-icon': {
        color: theme.palette.text.hint,
        fontSize: 24,
        fontWeight: 700,
        cursor: 'pointer',
        float: 'right',
        margin: 10,
      },
    },
  },
}));

const VideoOverlay = () => {
  const {videoOverlayData} = useSelector<AppState, AppState['common']>(
    ({common}) => common,
  );
  const dispatch = useDispatch();

  const {iframeSrc, onCloseCb, show, title} = videoOverlayData;
  const classes = useStyles();

  useEffect(() => {
    if (iframeSrc || show) {
      closeOverlayCb();
    } else {
      onCloseCb && onCloseCb();
      openOverlayCb();
    }
  }, [iframeSrc, onCloseCb, show]);

  const closeOverlayCb = () => {
    const header = document.querySelector('header');
    document.body.style.removeProperty('overflow');
    header && header.classList.add('position-sticky');
  };

  const openOverlayCb = () => {
    const header = document.querySelector('header');
    document.body.style.overflow = 'hidden';
    header && header.classList.remove('position-sticky');
  };

  if (!iframeSrc || !show) return null;

  return (
    <>
      <Box
        className={classes.videoContainer}
        onClick={() => dispatch(clearVideoOverlayData())}>
        <Box position='relative'>
          <Box>
            <Icon
              className='close-icon'
              onClick={() => dispatch(clearVideoOverlayData())}>
              close
            </Icon>
          </Box>

          <iframe
            src={
              iframeSrc.includes('http')
                ? iframeSrc
                : `${REACT_APP_VIDEO_BASE_URL}${iframeSrc}`
            }
            title={title || 'Video'}
            width={window.screen.width * 0.7}
            height={window.screen.height * 0.7}
          />
        </Box>
      </Box>
    </>
  );
};

export default VideoOverlay;

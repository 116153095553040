import List from '@material-ui/core/List';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusData } from 'redux/actions';

import { useAuthUser } from '@crema/utility/AppHooks';
import routesConfig, { NavItemProps } from '../../../../modules/routesConfig';
import { mergeProductsAndCitiesRoutes } from '../utility';
import VerticalCollapse from './VerticalCollapse';
import VerticalItem from './VerticalItem';
import VerticalNavGroup from './VerticalNavGroup';
interface NavigationProps { }

const Navigation: React.FC<NavigationProps> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { menus } = (state as any).common;
  const user = useAuthUser()
  useEffect(() => {
    dispatch(getMenusData());
    return () => { };
  }, [dispatch]);
  //id: 'sign-in',

  const [newRoutes, setRoutes] = useState([...routesConfig])
  useEffect(() => {
    if (user?.user) {
      let filterRoutes = newRoutes.filter((val) => val.id != 'sign-in');
      setRoutes(filterRoutes);
    }

  }, [user])
  return (
    <List>
      {mergeProductsAndCitiesRoutes(menus, newRoutes, true).map(
        (item: NavItemProps) => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && (
              <VerticalNavGroup item={item} level={0} />
            )}

            {item.type === 'collapse' && (
              <VerticalCollapse item={item} level={0} />
            )}

            {item.type === 'item' && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ),
      )}
    </List>
  );
};

export default Navigation;

export const FETCH_START_MY_ACCOUNT_TYPES = 'FETCH_START_MY_ACCOUNT_TYPES';
export const FETCH_SUCCESS_MY_ACCOUNT_TYPES = 'FETCH_SUCCESS_MY_ACCOUNT_TYPES';
export const FETCH_ERROR_MY_ACCOUNT_TYPES = 'FETCH_ERROR_MY_ACCOUNT_TYPES';

export const FETCH_START_MY_NOTIFICATION = 'FETCH_START_MY_NOTIFICATION';
export const FETCH_SUCCESS_MY_NOTIFICATION = 'FETCH_SUCCESS_MY_NOTIFICATION';
export const FETCH_ERROR_MY_NOTIFICATION = 'FETCH_ERROR_MY_NOTIFICATION';

export const PUT_START_MY_ACCOUNT = 'PUT_START_MY_ACCOUNT';
export const PUT_SUCCESS_MY_ACCOUNT = 'PUT_SUCCESS_MY_ACCOUNT';
export const PUT_ERROR_MY_ACCOUNT = 'PUT_ERROR_MY_ACCOUNT';

export const PUT_START_MY_NOTIFICATION = 'PUT_START_MY_NOTIFICATION';
export const PUT_SUCCESS_MY_NOTIFICATION = 'PUT_SUCCESS_MY_NOTIFICATION';
export const PUT_ERROR_MY_NOTIFICATION = 'PUT_ERROR_MY_NOTIFICATION';

export const POST_INVITE_USER = 'POST_INVITE_USER';
export const POST_INVITE_USER_SUCCESS = 'POST_INVITE_USER_SUCCESS';
export const POST_INVITE_USER_ERROR = 'POST_INVITE_USER_ERROR';

export interface PostNewRequest {
  type: typeof POST_INVITE_USER;
}
export interface PostNewRequestSuccess {
  type: typeof POST_INVITE_USER_SUCCESS;
}
export interface PostNewRequestError {
  type: typeof POST_INVITE_USER_ERROR;
}


export interface GetMyAccountReq {
  type: typeof FETCH_START_MY_ACCOUNT_TYPES;
}

export interface GetMyAccountSuccess {
  type: typeof FETCH_SUCCESS_MY_ACCOUNT_TYPES;
  payload: any;
}

export interface GetMyAccountError {
  type: typeof FETCH_ERROR_MY_ACCOUNT_TYPES;
  payload: any;
}

export interface GetMyNotification {
  type: typeof FETCH_START_MY_NOTIFICATION;
}

export interface GetMyNotificationSuccess {
  type: typeof FETCH_SUCCESS_MY_NOTIFICATION;
  payload: any;
}

export interface GetMyNotificationError {
  type: typeof FETCH_ERROR_MY_NOTIFICATION;
  payload: any;
}

export interface PostMyAccount {
  type: typeof PUT_START_MY_ACCOUNT;
}

export interface PostMyAccountSuccess {
  type: typeof PUT_SUCCESS_MY_ACCOUNT;
  payload: any;
}

export interface PostMyAccountError {
  type: typeof PUT_ERROR_MY_ACCOUNT;
  payload: any;
}

export interface PostMyNotification {
  type: typeof PUT_START_MY_ACCOUNT;
}

export interface PostMyNotificationSuccess {
  type: typeof PUT_SUCCESS_MY_ACCOUNT;
  payload: any;
}

export interface PostMyNotificationError {
  type: typeof PUT_ERROR_MY_ACCOUNT;
  payload: any;
}

export type MyAccountActionsTypes =
  | GetMyAccountReq
  | GetMyAccountSuccess
  | GetMyAccountError
  | PostMyAccount
  | PostMyAccountSuccess
  | PostMyAccountError
  | GetMyNotification
  | GetMyNotificationSuccess
  | GetMyNotificationError
  | PostMyNotification
  | PostMyNotificationSuccess
  | PostNewRequest | PostNewRequestSuccess | PostNewRequestError
  | PostMyNotificationError;

import {IButton} from '@components/ButtonsGroup';
import React, {useEffect} from 'react';
import {INewOrderPayload} from '../INewOrderPayload';
import {UseStyles} from '../UseStyles';
import {ADMIN_USER_TYPES, GetSteps} from '../staticData';
import useValidation from './useValidation';
interface IActiveButtonProps {
  onBackStep: VoidFunction;
  activeStep: number;
  user_type: string | undefined;
  onSkipRecommended: VoidFunction;
  onSkipUpgraded: VoidFunction;
  payload: INewOrderPayload;
  onNextStep: VoidFunction;
  onBackClick: VoidFunction;
  PlaceOrder: VoidFunction;
}
const useActiveButton = ({
  onBackStep,
  activeStep,
  user_type,
  onSkipRecommended,
  onSkipUpgraded,
  payload,
  onBackClick,
  onNextStep,
  PlaceOrder,
}: IActiveButtonProps) => {
  const {
    ContactDetailsValidationSchema,

    locationAndTimeDetailsValidationSchema,
    KeyPickupValidationSchema,
  } = useValidation({payload});
  const steps = GetSteps(user_type);
  const classes = UseStyles();
  const [disable, setDisable] = React.useState(true);
  const [contactDisable, setContactDisable] = React.useState(true);
  const [keyPickupDisable, setkeyPickupDisable] = React.useState(true);

  let bool = locationAndTimeDetailsValidationSchema
    .validate(payload.locationAndTimeDetails, {abortEarly: false})
    .then(() => {
      if (payload.locationAndTimeDetails?.errors) {
        return true;
      } else {
        return false;
      }
    })
    .catch((err) => {
      return true;
    });

  useEffect(() => {
    if (payload?.key_pick_up_type === 'nokeypickup') {
      setkeyPickupDisable(false);
    } else {
      KeyPickupValidationSchema.validate(payload.key_pick_up_locations, {
        abortEarly: false,
      })
        .then((res) => {
          if (payload.key_pick_up_locations?.errors) {
            setkeyPickupDisable(true);
          } else {
            setkeyPickupDisable(false);
          }
        })
        .catch((err) => {
          setkeyPickupDisable(true);
        });
    }
  }, [payload.key_pick_up_locations, payload?.key_pick_up_type]);

  bool.then((res) => {
    setDisable(res);
  });

  let contactProcessbutton = ContactDetailsValidationSchema.validate(
    payload.contactDetails,
    {abortEarly: false},
  )
    .then(() => {
      return false;
    })
    .catch((err) => {
      return true;
    });

  contactProcessbutton.then((res) => {
    setContactDisable(res);
  });

  const buttons: IButton[] = [
    {
      children: 'Back',
      variant: 'gray',
      onClick: onBackStep,
      show: activeStep > 0,
      className: classes.marginInlineEndAuto,
      hide: activeStep === 1 && !ADMIN_USER_TYPES.includes(user_type || ''),
    },
    {
      children: 'Skip Recommended',
      onClick: onSkipRecommended,
      variant: 'primary',
      show: activeStep === -1,
    },

    {
      children: 'Next Stage',

      onClick: onNextStep,
      show: activeStep < steps.length - 1,

      variant: 'secondary',
      disabled:
        Boolean(activeStep === 0 && !payload.clientDetails?.userId) ||
        Boolean(activeStep === 3 && disable) ||
        Boolean(activeStep === 3 && keyPickupDisable) ||
        Boolean(activeStep === 4 && contactDisable) ||
        Boolean(activeStep === 1 && !payload?.recommendedProductDetails) ||
        Boolean(activeStep === 2 && !payload?.addonsDetails?.addons),
    },
    {
      children: 'Place Order',
      show: activeStep === 6,
      variant: 'secondary',
      onClick: PlaceOrder,
    },
  ];

  const AddonButtons: IButton[] = [
    {
      children: 'Back',
      variant: 'gray',
      onClick: onBackClick,
      show: activeStep > 0,
      className: classes.marginInlineEndAuto,
      hide: activeStep === 1 && !ADMIN_USER_TYPES.includes(user_type || ''),
    },
    {
      children: 'Add to Package',

      onClick: onBackClick,

      variant: 'secondary',
    },
  ];
  return {buttons, AddonButtons};
};

export default useActiveButton;

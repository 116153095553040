const ColorStyles = {
  // vistaBeeBlue: '#1265c4',
  // vistaBeePink: '#f10558',
  vistaBeeBlue: '#003C32',
  vistaBeePink: '#839CA3',
  vistabeeYellow: '#659254',
  vistabeeSoftGreen:'#659254',
  vistabeeSageGreen:'#5B7360',
  vistabeeDarkGreen:'#052822',
  vistabeeLeafGreen:'#01563B',
  vistabeePaperWhite:'#F6F4F2',
  vistabeeCottonWhite:'#F1ECE8',
  vistabeeOffWhite:'#F2F1F0',
  vistabeeLightGray:'#DDDDDD',
  vistabeeBW90Gray:'#1E1E1E',
  vistabeeBW80Gray:'#383838',
  vistabeeBW70Gray:'#4A4A4A',
  vistabeeBW60Gray:'#595959',
  vistabeeBW50Gray:'#787878'

};

export default ColorStyles;

import React from 'react';

export const orderPagesConfig = [
  {
     
    routes: [
      {
        path: '/order/new',
        component: React.lazy(() => import('./Pages/NewOrder')),
      },
    ],
  },
];

import React from 'react';

export const commonPagesConfig = [
  {
    routes: [
      {
        path: '/',
        exact: true,
        component: React.lazy(() => import('./Pages/Home')),
      },
      {
        path: '/about-us',
        exact: true,
        component: React.lazy(() => import('./Pages/AboutUs')),
      },
      {
        path: '/contact-us',
        exact: true,
        component: React.lazy(() => import('./Pages/ContactUs')),
      },
      {
        path: '/reviews',
        exact: true,
        component: React.lazy(() => import('./Pages/Reviews')),
      },
      {
        path: '/clients',
        exact: true,
        component: React.lazy(() => import('./Pages/Clients')),
      },
      {
        path: '/blog',
        exact: true,
        component: React.lazy(() => import('./Pages/Blogs')),
      },
      {
        path: '/blog/:blog_slug',
        exact: true,
        component: React.lazy(
          () => import('./Pages/Blogs/Components/BlogDetails'),
        ),
      },
      {
        path: '/jobs',
        exact: true,
        component: React.lazy(() => import('./Pages/Jobs')),
      },
      {
        path: '/jobs/apply/:job_id',
        exact: true,
        component: React.lazy(() => import('./Pages/Jobs/Components/ApplyJob')),
      },
      {
        path: '/products/:url_key*',
        component: React.lazy(() => import('./Pages/ProductPage')),
      },
      {
        path: '/cities/:url_key*',
        component: React.lazy(() => import('./Pages/CityPage')),
      },
      {
        path: '/demo',
        component: React.lazy(() => import('./Pages/Demo/index')),
      },
      {
        path: '/popups/embed/:url',
        exact: true,
        component: React.lazy(() => import('./Pages/Popups/index')),
      },
    ],
  },
];

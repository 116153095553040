import React from 'react';

export const MyAccountRoutes = [
  {
    routes: [
      {
        path: '/myaccount',
        component: React.lazy(() => import('./component/Main/AccountDetails')),
      },
    ],
  },
];

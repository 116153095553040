import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusData } from 'redux/actions';
import routesConfig from '../../../../modules/routesConfig';
import { mergeProductsAndCitiesRoutes } from '../utility';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalGroup from './HorizontalGroup';
import HorizontalItem from './HorizontalItem';

interface HorizontalNavProps { }

const HorizontalNav: React.FC<HorizontalNavProps> = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { menus } = (state as any).common;

  useEffect(() => {
    dispatch(getMenusData());
    return () => { };
  }, [dispatch]);

  return (
    <List className='navbarNav'>
      {mergeProductsAndCitiesRoutes(menus, routesConfig.filter((v) => v.id != "sign-in")).map((item: any) => (
        <React.Fragment key={item.id}>
          {item.type === 'group' && (
            <HorizontalGroup item={item} nestedLevel={0} />
          )}

          {item.type === 'collapse' && (
            <HorizontalCollapse item={item} nestedLevel={0} />
          )}

          {item.type === 'item' && <HorizontalItem item={item} />}

          {item.type === 'divider' && (
            <Box my={5} clone>
              <Divider />
            </Box>
          )}
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;

import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './useStyles';

const HeadingComponent = (props: { title: string, clickFunction?: any, step?: number }) => {
  const { title, clickFunction, step } = props;
  const classes = useStyles();

   
  return (
    <Box className={classes.header}>
      <Typography className={classes.HeaderTitle}>{title}</Typography>
      {
        clickFunction && <Typography className={classes.editBtn} onClick={() => clickFunction(step)} style={{cursor:'pointer'}}>Edit</Typography>
      }

    </Box>
  );
};

export default HeadingComponent;

export const GET_ENQUIRY_DETAILS = 'GET_ENQUIRY_DETAILS';
export const GET_ENQUIRY_DETAILS_SUCCESSFULL = 'GET_ENQUIRY_DETAILS_SUCCESSFULL';
export const GET_ENQUIRY_DETAILS_FAILED = 'GET_ENQUIRY_DETAILS_FAILED';

export const GET_ENQUIRY_DETAILS_BY_ID = 'GET_ENQUIRY_DETAILS_BY_ID';
export const GET_ENQUIRY_DETAILS_BY_ID_SUCCESSFULL = 'GET_ENQUIRY_DETAILS_BY_ID_SUCCESSFULL';
export const GET_ENQUIRY_DETAILS_BY_ID_FAILED = 'GET_ENQUIRY_DETAILS_BY_ID_FAILED';

export const GET_ENQUIRY_LIST = 'GET_ENQUIRY_LIST';
export const GET_ENQUIRY_LIST_SUCCESSFULL = 'GET_ENQUIRY_LIST_SUCCESSFULL';
export const GET_ENQUIRY_LIST_FAILED = 'GET_ENQUIRY_LIST_FAILED';

export const GET_FILTER_ENQUIRY_LIST = 'GET_FILTER_ENQUIRY_LIST'
export const GET_FILTER_ENQUIRY_LIST_SUCCESSFULL = 'GET_FILTER_ENQUIRY_LIST_SUCCESSFULL'
export const GET_FILTER_ENQUIRY_LIST_FAILED = 'GET_FILTER_GET_FILTER_ENQUIRY_LIST_FAILEDENQUIRY_LIST_ERROR'

export const POST_ENQUIRY_ACTIONS = 'POST_ENQUIRY_ACTIONS'
export const POST_ENQUIRY_ACTIONS_SUCCESSFULL = 'POST_ENQUIRY_ACTIONS_SUCCESSFULL'
export const POST_ENQUIRY_ACTIONS_FAILED = 'GET_FILTER_POST_ENQUIRY_ACTIONS_FAILEDENQUIRY_LIST_ERROR'
export interface PostEnquiryActions {
    type: typeof POST_ENQUIRY_ACTIONS;
}
export interface PostEnquiryActionsSuccess {
    type: typeof POST_ENQUIRY_ACTIONS_SUCCESSFULL;
    payload: []
}
export interface PostEnquiryActionsError {
    type: typeof POST_ENQUIRY_ACTIONS_FAILED;
}

export interface GetFilteredEnquiry {
    type: typeof GET_FILTER_ENQUIRY_LIST;
}
export interface GetFilteredEnquirySuccess {
    type: typeof GET_FILTER_ENQUIRY_LIST_SUCCESSFULL;
    payload: []
}
export interface GetFilteredEnquiryError {
    type: typeof GET_FILTER_ENQUIRY_LIST_FAILED;
}


export interface GetEnquiryList {
    type: typeof GET_ENQUIRY_LIST;
}
export interface GetEnquiryListSuccess {
    type: typeof GET_ENQUIRY_LIST_SUCCESSFULL;
    payload: any
}
export interface GetEnquiryListError {
    type: typeof GET_ENQUIRY_LIST_FAILED;
}

export interface GetEnquiryDetails {
    type: typeof GET_ENQUIRY_DETAILS;
}
export interface GetEnquiryRequestSuccess {
    type: typeof GET_ENQUIRY_DETAILS_SUCCESSFULL;
    payload: any
}
export interface GetEnquiryRequestError {
    type: typeof GET_ENQUIRY_DETAILS_FAILED;
}

export interface GetEnquiryDetailsById {
    type: typeof GET_ENQUIRY_DETAILS_BY_ID;
}
export interface GetEnquiryDetailsByIdSuccess {
    type: typeof GET_ENQUIRY_DETAILS_BY_ID_SUCCESSFULL;
    payload: any
}
export interface GetEnquiryDetailsByIdError {
    type: typeof GET_ENQUIRY_DETAILS_BY_ID_FAILED;
}

export type EnquiryActionTypes = GetEnquiryDetailsByIdError
    | GetEnquiryList | GetEnquiryListSuccess | GetEnquiryListError
    | GetFilteredEnquiry | GetFilteredEnquirySuccess | GetFilteredEnquiryError
    | GetEnquiryDetailsByIdError | GetEnquiryDetailsById | GetEnquiryDetailsByIdSuccess | GetEnquiryDetails | GetEnquiryRequestSuccess | GetEnquiryRequestError | PostEnquiryActions | PostEnquiryActionsSuccess | PostEnquiryActionsError
import {Box} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from 'redux/store';
import {getOrderList, getOrderFilter} from '../../redux/actions/Dashboard';
import DashboardTable from './components/DashBoardTable';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);

  useEffect(() => {
    let apiBody = {
      beeId: '',
      companyId: '',
      status: '',
      packageId: '',
      addonId: '',
      searchKey: '',
      user_id: user?.user?.id,
      offset: 0,
      sort_by_shoot_date: 0,
      sort_by_order_date: 0,
    };

    dispatch(getOrderFilter(apiBody));
  }, []);

  return (
    <Box className='dashboardTable'>
      <DashboardTable />
    </Box>
  );
};

export const dashboardPagesConfig = [
  {
    routes: [
      {
        path: '/dashboard',
        component: React.lazy(() => import('./Dashboard')),
      },
    ],
  },
];

export default Dashboard;

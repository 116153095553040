import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import AppContext from '../../../@crema/utility/AppContext';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import {onJWTAuthSignout} from '../../../redux/actions';
import AppContextPropsType from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';
import useHeader from '../HeaderUser/useHeader';
import {useStyles} from './UseStyles';
import Feedback from 'modules/dashboard/components/Backend/components/BackendClient/Feedback';
import { Modal } from '@material-ui/core';

const UserInfo: React.FC<{}> = () => {
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const dispatch = useDispatch();
  const user: AuthUser | null = useAuthUser();
  const {anchorEl, handleClick, handleClose, getUserAvatar, history} =
    useHeader();
    const [open, setOpen] = useState(false)
    const handleCloseModal = () => {
      setOpen(false);
    };
  const classes = useStyles({themeMode});

  if (!user || !user.user) return null;

  return (
    <>
    <Box
      px={{xs: 4, xl: 7}}
      className={clsx(classes.crUserInfo, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        {user && user.photoURL ? (
          <Avatar className={classes.profilePic} src={user.photoURL} />
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}
        <Box ml={4} className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box mb={0} className={clsx(classes.userName)}>
              {user &&
                user.user &&
                user.user.first_name &&
                `${user.user.first_name} ${user.user.last_name}`}
            </Box>
            <Box
              ml={3}
              className={classes.pointer}
              color={themeMode === 'light' ? '#313541' : 'white'}>
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={() => history.push('/myaccount/details')}>
                   
                  {user.is_shooter || user.is_editor ? "Profile" : "My account"}  
                </MenuItem>
                <MenuItem onClick={() => history.push('/dashboard/dashboard')}>
                  Dashboard
                </MenuItem>
                <MenuItem onClick={() => setOpen(true)}>
            Feedback 
          </MenuItem>
                <MenuItem onClick={() => dispatch(onJWTAuthSignout())}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
      <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'>
      <Feedback close={handleCloseModal} />
    </Modal>
    
  </>
  );
};

export default UserInfo;

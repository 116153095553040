import {styled} from '@material-ui/core/styles';
import React, {useEffect} from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import clsx from 'clsx';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import {amber, blue, green, red} from '@material-ui/core/colors';
import Slide from '@material-ui/core/Slide';
import {useDispatch} from 'react-redux';
import {CremaTheme} from 'types/AppContextPropsType';
import {hideMessage} from '../../redux/actions/Common';

const PREFIX = 'AppMessageView';

const classes = {
  success: `${PREFIX}-success`,
  error: `${PREFIX}-error`,
  info: `${PREFIX}-info`,
  warning: `${PREFIX}-warning`,
  icon: `${PREFIX}-icon`,
  iconVariant: `${PREFIX}-iconVariant`,
  message: `${PREFIX}-message`,
};

const StyledSnackbar = styled(Snackbar)((theme: CremaTheme) => ({
  [`& .${classes.success}`]: {
    backgroundColor: green[600],
  },

  [`& .${classes.error}`]: {
    backgroundColor: red[600],
  },

  [`& .${classes.info}`]: {
    backgroundColor: blue[500],
  },

  [`& .${classes.warning}`]: {
    backgroundColor: amber[700],
  },

  [`& .${classes.icon}`]: {
    fontSize: 20,
  },

  [`& .${classes.iconVariant}`]: {
    opacity: 0.9,
    marginRight: 12,
  },

  [`& .${classes.message}`]: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function TransitionLeft(props) {
  return <Slide {...props} direction='left' />;
}

const AppMessageView = (props) => {
  const [open, setOpen] = React.useState(false);
  const {className, message, variant, ...other} = props;
  const Icon = variantIcon[variant];
  const dispatch = useDispatch();

  const onClose = () => {
    setOpen(false);
    setTimeout(() => dispatch(hideMessage()), 500);
  };
  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}
      autoHideDuration={6000}
      TransitionComponent={TransitionLeft}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={onClose}
            size='large'>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </StyledSnackbar>
  );
};

export default AppMessageView;

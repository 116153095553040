import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import {useDispatch} from 'react-redux';
import {toggleNavCollapsed} from '../../../../redux/actions';
import LanguageSwitcher from '../../LanguageSwitcher';
import useStyles from './AppHeader.style';
import Button from '@components/Button';
import {useAuthUser} from '@crema/utility/AppHooks';
import IntlMessages from '@crema/utility/IntlMessages';
import {Typography} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import AppLogoWhite from '../../../../shared/components/AppLogoWhite';
import { useSelector } from 'react-redux';
import HeaderMessages from '../../HeaderMessages';
import Notifications from '../../Notifications';
import { AppState } from 'redux/store';
import HorUserInfo from '../HorUserInfo';
import AppNavList from './AppNavList';
import NotificationBar from './NotificationBar';
import { CLoseHeader } from '../../../../redux/actions';
interface AppHeaderProps {}

const AppHeader: React.FC<AppHeaderProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {CloseHeader} = useSelector<
  AppState,
  AppState['common']
>(({common}) => common);
  const user = useAuthUser();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    useState<null | HTMLElement>(null);

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  useEffect(()=>{
   if(user){
    dispatch(CLoseHeader(false))
   }else{
    dispatch(CLoseHeader(true))
   }
  },[user])

 
  function onNewOrder() {
    if (user) {
      history.push('/order/new');
    } else {
      history.push('/signin');
    }
  }

  const [showBlackNavlist, setshowBlackNavlist] = useState(false);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}>
      <MenuItem className={classes.menuItemRoot}>
        <HeaderMessages />
      </MenuItem>
      <MenuItem className={classes.menuItemRoot}>
        <Notifications />
      </MenuItem>
      <LanguageSwitcher />
    </Menu>
  );

  return (
    <>
      <AppBar className='position-sticky top-0'>
        <NotificationBar />
        {/* {<AppNavList show={showBlackNavlist} setshowBlackNavlist={setshowBlackNavlist} />} */}

        <Toolbar className={classes.headerMain}>
          <Box className={classes.headerContainer}>
            <Box className={classes.headerMainFlex}>
              <Box mx={2} />
              <Hidden lgUp>
                <IconButton
                  edge='start'
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => dispatch(toggleNavCollapsed())}>
                  <MenuIcon className={classes.menuIconRoot} />
                </IconButton>
              </Hidden>

              {/* <Link to={'#'}  onClick={() => dispatch(CLoseHeader(!CloseHeader))}> */}
              <a href={'https://vistabee.com/'} >
                <AppLogoWhite />
              </a>

              <Box className={classes.grow} />

              <Box className={classes.sectionWeb}>
                <Box className={classes.FlagContainer}>
                  <img
                    src='/assets/images/flags/us_image.png'
                    width='30px'
                    alt='us_image'
                  />
                  <Typography> 1 646 583 0200</Typography>
                </Box>
                <Box className={classes.FlagContainer}>
                  <img
                    src='/assets/images/flags/uk_image.jpg'
                    width='30px'
                    alt='uk_image'
                  />
                  <Typography> 0333 044 0030 </Typography>
                </Box>
                <Box  width={300} display="flex" alignItems="center" justifyContent="flex-end"   >
                  <Button   height={35} variant='secondary' onClick={onNewOrder} 
                  disabled={user?.user_type.includes('bee')} >
                    <IntlMessages
                      id='common.newOrder'
                      defaultMessage={user ? 'Place Order' : 'Get Started'}
                    />
                  </Button>
                  {
                    !CloseHeader &&  <HorUserInfo onlyAvatar={true} />
                  }
                 
                </Box>
              </Box>

              <Box ml={1} className={classes.sectionMobile}>
                <Box className={classes.FlagContainer}>
                  <Button
                    variant='secondary'
                    onClick={onNewOrder}
                    className={classes.PlaceOrderButton}
                    disabled={user?.user_type.includes('bee')}
                    >
                    <IntlMessages
                      id='common.newOrder'
                      defaultMessage={user ? 'Place Order' : 'Get Started'}
                    />
                  </Button>

                 
                </Box>
               
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};
export default AppHeader;

import { makeStyles } from '@material-ui/core';
import ColorStyles from 'shared/constants/AppColor';
import { CremaTheme } from 'types/AppContextPropsType';
export const UseStyles = makeStyles((theme: CremaTheme) => ({
    rootButton: {
        padding: '0.5em 1rem',
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.background.gray.main,
        background: '#afafafa',
        [theme.breakpoints.down('md')]: {
            fontSize: 14,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
        ' & .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
    selectTag: {
        height: ' 37px',
        backgroundColor: '#ffffff',
        paddingLeft: '12px',
        textAlign: 'left',
        border: '2px solid #dddddd',
        '&:before ': {
          borderBottomStyle: 'none !important',
        },
        '& .MuiInput-underline:after': {
          borderBottom: '0px !important',
        },
        '& .MuiInput-underline:before': {
          borderBottom: '0px !important',
        },
      },
    textPadding: {
        padding: '0px 1rem',
    },
    heading: {
        fontWeight: 600,
      },
    logIn: {
        paddingLeft: '1rem',
        color: '#549ACD !important',
        cursor: 'pointer',
    },

    accordion: {
        borderRadius: 1,
        overflow: 'hidden',
        border: 'none !important',
    },
    container: {
        maxWidth: '1250px',
        margin: 'auto',
        '& button': {
            textTransform: 'capitalize'
        }
    },
    displayClass: {
        display: 'flex',
        alignItems: 'center',
    },
    hr: {
        borderTop: '1px solid gainsboro',
        width: '100%',
        padding: '6px',
        borderBottom: '1px solid gainsboro',
        marginTop: 3,
        marginBottom: 3,
    },
    typos: {
        fontSize: 16,
        marginBottom: 3,
        fontWeight: 500,
        textTransform:"capitalize",
        color: theme.palette.common.black,
    },
    labelTyposHeading: {
        textTransform: 'capitalize',
        marginTop: '2rem',
        fontWeight: 600,
        fontSize: '15px',
        color: theme.palette.common.black,
    },
    labelTypos: {
        marginBottom: 3,
        textTransform: 'capitalize',
        marginRight: 5,
        fontWeight: 600,
        color: theme.palette.common.black,
        fontSize: '17px',
    },
    bold: {
        fontWeight: 600,
    },
    root: {
        maxWidth: 245,
        margin: '12px',
        border: '1px solid gainsboron',
    },
    Background: {
        background: ColorStyles.vistaBeeBlue,
    },
    padding: {
        padding: '12px',
    },
    marginTopBottom: {
        margin: '20px auto',
    },
    marginLeftRight: {
        padding: '0 30px',
    },
    tableHeader: {
        color: '#FFFFFF !important',
        height: '60px',
        background: ColorStyles.vistaBeeBlue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    headingBackground: { background: '#C7BCA1' },
    icons: { fontSize: '30px', margin: '0 12px ' },
    SaveButton: {
        minWidth: '120px',
        minHeight: '38px',
        borderRadius: '2px',
        fontWeight: 700,
    },
    RadioButtonLabel: {
        marginLeft: '0px !important',
        marginRight: '4rem !important',
    },
    RadioButtonDiv: {
        display: 'block',
        padding: '20px 0px'
    },
    RadioButtonColor: {
        color: `${ColorStyles.vistaBeeBlue} !important`,
    },
    ChargesTableTypeHead: {
        background: ColorStyles.vistaBeeBlue,
        display: 'flex',
        padding: '0px 2rem',
    },
    inputFields: {
        minHeight: '40px',
        backgroundColor: '#ffffff',
        paddingLeft: '12px',

        textAlign: 'left',
        border: '2px solid #dddddd',
        '&:before ': {
            borderBottomStyle: 'none !important',
        },
        '& .MuiInput-underline:after': {
            borderBottom: '0px !important',
        },
        '& .MuiInput-underline:before': {
            borderBottom: '0px !important',
        },
    },
    selectorsTypos: {
        fontSize: '15px',
        color: '#444444',
        fontWeight: 700,
        fontFamily: 'Poppins',
        marginBottom: 3,
        marginTop: 20,
    },
    button: {
        minWidth: 200,
        height: 40,

    },
    radioGroup: {

        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row'
    },
}));

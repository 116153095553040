import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const STAGE_2_BASE_URL=process.env.REACT_APP_STAGE_2_API
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { Accept: 'application/json' }, 
});

export { api };


import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  logoRoot: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  logo: {
    height: 36,
    marginRight: 10,
  },
}));

import React, {useState} from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import HeadingComponent from 'modules/order/Pages/NewOrder/components/locationTimeDetails/Components/HeadingComponent';
import {UseStyles} from './UseStyles';

import {useDispatch} from 'react-redux';
import {useAuthUser} from '@crema/utility/AppHooks';
import {AddFeedback} from 'redux/actions/Dashboard';
import {Loader} from '@crema';

const Feedback = ({close, isOpen}: any) => {
  const dispatch = useDispatch();
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    user_id: user?.user.id,
    contact_us: 0,
    subject: '',
    text: '',

    error: {},
  });

  const handelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let {name, value} = event.target;

    let tempState = {...state};

    tempState[name] = value;
    setState({...tempState, error: ''});
  };

  const handelFeedback = () => {
    setIsLoading(true);
    if (state.subject == '') {
      setState((prev) => ({
        ...prev,
        error: {...state.error, subject: 'Please enter a subject'},
      }));
    } else if (state.text == '') {
      setState((prev) => ({
        ...prev,
        error: {...state.error, text: 'Please enter a note'},
      }));
    } else {
      delete state?.error;
      dispatch(AddFeedback(state, close));
    }
  };

  const classes = UseStyles();

  return (
    <Box
      width={500}
      style={{
        position: 'relative',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
      }}>
      {isLoading ? (
        <Box
          style={{position: 'relative'}}
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight={600}>
          <Loader />
        </Box>
      ) : (
        <Paper className={classes.padding}>
          <HeadingComponent title='Feedback' />
          <Box my={2} />
          <Grid item xs={12} md={12} lg={12}>
            <Typography className={classes.heading}>Subject*</Typography>
            <Box my={1} />
            <TextField
              className={classes.inputFields}
              fullWidth
              value={state.subject}
              onChange={handelChange}
              size='small'
              type='text'
              name='subject'
            />
            <Typography color='error'>{state?.error?.subject}</Typography>
          </Grid>

          <Box my={3} />
          <Grid item xs={12} md={12} lg={12}>
            <Typography className={classes.heading}>Note*</Typography>
            <Box my={1} />
            <TextField
              className={classes.inputFields}
              fullWidth
              size='small'
              value={state.text}
              multiline
              rows={4}
              onChange={handelChange}
              type='textarea'
              name='text'
            />
            <Typography color='error'>{state?.error?.text}</Typography>
          </Grid>

          <Box my={14}>
            <Grid container alignItems='center'>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  className={classes.button}
                  fullWidth
                  variant='contained'
                  onClick={handelFeedback}
                  color='primary'>
                  Submit
                </Button>
              </Grid>
            </Grid>
            <Box my={3} />
            <Grid container alignItems='center'>
              <Grid item xs={12} md={12} lg={12}>
                <Button
                  onClick={close}
                  fullWidth
                  className={classes.button}
                  variant='contained'>
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

export default Feedback;

import { makeStyles } from '@material-ui/core/styles';
import { CremaTheme } from 'types/AppContextPropsType';
export const useStyles = makeStyles((theme: CremaTheme) => ({
  logoRoot: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    alignItems: 'center',
  },
  typos: {
    fontWeight: 900,
  },
  logo: {
    height: 20,
    marginRight: 10,

    [theme.breakpoints.down('md')]: {
      height: 36,
    },
    '@media (max-width: 768px)': {
      height: 20,
      width: '100px !important',
      maxWidth: 'none !important',
    },
  },
}));

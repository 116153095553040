import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useStyles from '../../../shared/jss/common/common.style';
import AppContextPropsType from '../../../types/AppContextPropsType';
import { ContentView } from '../../index';
import AppContext from '../../utility/AppContext';
import Layouts from './Layouts';

const useStyle = makeStyles(() => ({
  appAuth: {
    flex: 1,
    display: 'flex',
    position: 'relative',
    height: '100vh',
    backgroundColor: '#f3f4f6',
    background: `url(/assets/images/auth-background.jpg) no-repeat center center`,
    backgroundSize: 'cover',

    '& .scrollbar-container': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '& .main-content-view': {},
    '& .footer': {
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));

interface CremaLayoutProps { }

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  useStyles();
  const { navStyle } = useContext<AppContextPropsType>(AppContext);
  // const {user} = useSelector<AppState, AppState['auth']>(({auth}) => auth);
  const AppLayout = Layouts[navStyle];

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => { };
  }, [location.pathname]);

  const classes = useStyle();
  return (
    <>
      {true ? (
        <AppLayout />
        // <MyCalendar />

      ) : (
        <Box className={classes.appAuth}>
          <ContentView />
        </Box>
      )}
    </>
  );
};

export default React.memo(CremaLayout);

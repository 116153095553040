import {
  Addons,
  IAgent,
  IRecommendedProducts,
  ProductCategory,
  SubProductType,
} from 'types/models/NewOrder';

export const FETCH_START_COMPANY_USERS = 'FETCH_START_COMPANY_USERS';
export const FETCH_SUCCESS_COMPANY_USERS = 'FETCH_SUCCESS_COMPANY_USERS';
export const FETCH_ERROR_COMPANY_USERS = 'FETCH_ERROR_COMPANY_USERS';

export const FETCH_ADDONS_REQUEST = 'FETCH_ADDONS_REQUEST';
export const FETCH_REQUEST_SUCCESS_ADDONS = 'FETCH_REQUEST_SUCCESS_ADDONS';
export const FETCH_REQUEST_ERROR_ADDONS = 'FETCH_REQUEST_ERROR_ADDONS';

export const FETCH_START_PRODUCT_CATEGORIES = 'FETCH_START_PRODUCT_CATEGORIES';
export const FETCH_SUCCESS_PRODUCT_CATEGORIES =
  'FETCH_SUCCESS_PRODUCT_CATEGORIES';
export const FETCH_ERROR_PRODUCT_CATEGORIES = 'FETCH_ERROR_PRODUCT_CATEGORIES';

export const FETCH_START_SUB_PRODUCT_TYPES = 'FETCH_START_SUB_PRODUCT_TYPES';
export const FETCH_SUCCESS_SUB_PRODUCT_TYPES =
  'FETCH_SUCCESS_SUB_PRODUCT_TYPES';
export const FETCH_ERROR_SUB_PRODUCT_TYPES = 'FETCH_ERROR_SUB_PRODUCT_TYPES';
export const FETCH_START_ADDONS = 'FETCH_START_ADDONS';
export const FETCH_SUCCESS_ADDONS = 'FETCH_SUCCESS_ADDONS';
export const FETCH_ERROR_ADDONS = 'FETCH_ERROR_ADDONS';
export const FETCH_START_GET_RECOMMENDED_PRODUCTS =
  'FETCH_START_GET_RECOMMENDED_PRODUCTS';
export const FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS =
  'FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS';
export const FETCH_ERROR_GET_RECOMMENDED_PRODUCTS =
  'FETCH_ERROR_GET_RECOMMENDED_PRODUCTS';

export const FETCH_START_GET_UPGRADED_PRODUCTS =
  'FETCH_START_GET_UPGRADED_PRODUCTS';
export const FETCH_SUCCESS_GET_UPGRADED_PRODUCTS =
  'FETCH_SUCCESS_GET_UPGRADED_PRODUCTS';
export const FETCH_ERROR_GET_UPGRADED_PRODUCTS =
  'FETCH_ERROR_GET_UPGRADED_PRODUCTS';

export const POST_NEW_ORDER_REQUEST = 'POST_NEW_ORDER_REQUEST';
export const POST_NEW_ORDER_REQUEST_SUCCESS = 'POST_NEW_ORDER_REQUEST_SUCCESS';

export const POST_NEW_ORDER_FAIL = 'POST_NEW_ORDER_FAIL';


export const GET_AVAILABLE_POINTS = 'GET_AVAILABLE_POINTS';
export const GET_AVAILABLE_POINTS_SUCCESS = 'GET_AVAILABLE_POINTS_SUCCESS';

export const GET_AVAILABLE_POINTS_ERROR = 'GET_AVAILABLE_POINTS_ERROR';

export interface PostNewOrder {
  type: typeof POST_NEW_ORDER_REQUEST;
}

export interface PostNewOrderSuccess {
  type: typeof POST_NEW_ORDER_REQUEST_SUCCESS;
  payload: {};
}

export interface PostNewOrderFail {
  type: typeof POST_NEW_ORDER_FAIL;
}

export interface GetCompanyUsersReq {
  type: typeof FETCH_START_COMPANY_USERS;
}

export interface GetCompanyUsersSuccess {
  type: typeof FETCH_SUCCESS_COMPANY_USERS;
  payload: {
    agents: Array<IAgent>;
    companies: Array<{
      id: number;
      name: string;
    }>;
  };
}

export interface GetCompanyUsersError {
  type: typeof FETCH_ERROR_COMPANY_USERS;
}

export interface GetProductCategoriesReq {
  type: typeof FETCH_START_PRODUCT_CATEGORIES;
}

export interface GetProductCategoriesSuccess {
  type: typeof FETCH_SUCCESS_PRODUCT_CATEGORIES;
  payload: Array<ProductCategory>;
}

export interface GetProductCategoriesError {
  type: typeof FETCH_ERROR_PRODUCT_CATEGORIES;
}

export interface GetSubProductTypesReq {
  type: typeof FETCH_START_SUB_PRODUCT_TYPES;
}

export interface GetSubProductTypesSuccess {
  type: typeof FETCH_SUCCESS_SUB_PRODUCT_TYPES;
  payload: Array<SubProductType>;
}

export interface GetSubProductTypesError {
  type: typeof FETCH_ERROR_SUB_PRODUCT_TYPES;
}

export interface getAddonsReq {
  type: typeof FETCH_START_ADDONS;
}

export interface getAddonsSuccess {
  type: typeof FETCH_SUCCESS_ADDONS;
  payload: Array<Addons> | any;
}

export interface getAddonsError {
  type: typeof FETCH_ERROR_ADDONS;
}

export interface getAvailablePoints {
  type: typeof GET_AVAILABLE_POINTS;
}

export interface getAvailablePointsSuccess {
  type: typeof GET_AVAILABLE_POINTS_SUCCESS;
  payload: Array<Addons> | any;
}

export interface getAvailablePointsError {
  type: typeof GET_AVAILABLE_POINTS_ERROR;
}

export interface getFalseAddonReq {
  type: typeof FETCH_ADDONS_REQUEST;
}

export interface getFalseAddonSuccess {
  type: typeof FETCH_REQUEST_SUCCESS_ADDONS;
  payload: any;
}

export interface getFalseAddonError {
  type: typeof FETCH_REQUEST_ERROR_ADDONS;
}

export interface getRecommendedProductsReq {
  type: typeof FETCH_START_GET_RECOMMENDED_PRODUCTS;
}

export interface getRecommendedProductsSuccess {
  type: typeof FETCH_SUCCESS_GET_RECOMMENDED_PRODUCTS;
  payload: IRecommendedProducts;
}

export interface getRecommendedProductsError {
  type: typeof FETCH_ERROR_GET_RECOMMENDED_PRODUCTS;
}

export interface getUpgradedProductsReq {
  type: typeof FETCH_START_GET_UPGRADED_PRODUCTS;
}

export interface getUpgradedProductsSuccess {
  type: typeof FETCH_SUCCESS_GET_UPGRADED_PRODUCTS;
  payload: Addons[];
}

export interface getUpgradedProductsError {
  type: typeof FETCH_ERROR_GET_UPGRADED_PRODUCTS;
}

export type NewOrderActionTypes =
  | GetCompanyUsersReq
  | GetCompanyUsersSuccess
  | GetCompanyUsersError
  | GetProductCategoriesReq
  | GetProductCategoriesSuccess
  | GetProductCategoriesError
  | getUpgradedProductsReq
  | getUpgradedProductsSuccess
  | getUpgradedProductsError
  | getRecommendedProductsReq
  | getRecommendedProductsSuccess
  | getRecommendedProductsError
  | getAddonsReq
  | getAddonsSuccess
  | getAddonsError
  | GetSubProductTypesReq
  | GetSubProductTypesSuccess
  | getFalseAddonReq
  | getFalseAddonSuccess
  | getFalseAddonError
  | GetSubProductTypesError
  | PostNewOrder
  | PostNewOrderSuccess
  | PostNewOrderFail
  | getAvailablePoints
  | getAvailablePointsSuccess
  | getAvailablePointsError;
